import type { CantonsDict } from 'traffic/traffic';
import { z } from 'zod';

/**
 * @see {isRobotDataSimulation} ts-auto-guard:type-guard
 **/
export interface RobotDataSimulation {
  carry: 0 | 1;
  state: {
    task: number;
    navigation: number;
    traffic: number;
    position: number;
    safety: number;
    communication: number;
  };
  position: {
    x: number;
    y: number;
    heading: number;
  };
  battery: {
    level?: number;
  };
  action?: number;
  odometry: number;
  speed?: number;
  forksHeight?: number;
  trackPosition?: {
    linearAbscissa?: number;
    portionID?: number;
  };
}

/**
 * The key is the id of the robot
 *
 * @see {isRobotsDataSimulation} ts-auto-guard:type-guard
 */
export type RobotsDataSimulation = Record<string, RobotDataSimulation>;

/**
 * @see {isSimulationThreadInfo} ts-auto-guard:type-guard
 */
export interface SimulationThreadInfo {
  targetSpeed: number;
  currentSpeed: number;
  meanSpeed?: number;
  simulatedTime: number;
}

/**
 * @see {isSimulationTaskEvents} ts-auto-guard:type-guard
 */
export interface SimulationTaskEvents {
  newTimeStamp?: number;
  deleteTimeStamp?: number;
  data?: TaskDataSDK;
}

/**
 * The data of a task in the simulation returned by the SDK
 */
interface TaskDataSDK {
  id?: number;
  /** (unique ID defnied by dispatcher) */
  uniqueId: string;
  /** (name of flow the task is part of) */
  taskName: string;
  /** (list of all tasks required to be finished before starting this one) */
  prev: number[];
  /** id of the source point */
  src?: number;
  /** id of the destination point */
  dst?: number;
  /** (priority of task) */
  priority: number;
  /** state of the task */
  state: number;
  /** (list of robots allowed to do task) */
  robAuth: [number | string][] | number[];
  /** robot doing task */
  rob?: number;
  currentId?: number;
  /** 0 (bool --> allow lock/cancel  task if robot empty) */
  allowSuspend?: number;
  /** (seconds since 1970) */
  creationDate?: number;
  /** (simulation time, if 0 task is not started) [s] */
  startDate?: number;
  /** (simulation time, if 0 task is not finished) [s] */
  endDate?: number;
  /** (simulation time, if 0 task is not finished) [s] */
  dueDate?: number;
  /** name of the current step */
  stepLabel?: string;
  /** "SRC" (name of source point) */
  startPoint?: string;
  /** name of the destination point "DST" */
  destinationPoint?: string;
  /** time when the steps ended (simulation time in seconds) */
  stepEndDate?: number[];
  /**
   * names of the steps
   * example: ["pick", "drop", "move", "batt"]
   **/
  stepNames?: string[];
}

/**
 * The key is the id of the task
 *
 * @see {isSimulationTasksEvents} ts-auto-guard:type-guard
 */
export type SimulationTasksEvents = Record<string, SimulationTaskEvents>;

/**
 * @see {isRobotTimers} ts-auto-guard:type-guard
 */
export interface RobotTimers {
  carry: number;
  total: number;

  task: {
    Calibration: number;
    ErrorNavigation: number;
    Manual: number;
    Switch: number;
    Available: number;
    ErrorBattery: number;
    ErrorMission: number;
    RunToTaxi: number;
    Run: number;
  };

  navigation: {
    BadCanton: number;
    Canton: number;
    ESCanton: number;
    Manual: number;
    NoDestination: number;
    StopPgm: number;
    Door: number;
    OK: number;
  };
  traffic: {
    ManuThief: number;
    AutoStart: number;
    Error: number;
    AutoAU: number;
    ManuOff: number;
    ManuOK: number;
    AutoNav: number;
    AutoWait: number;
    Auto: number;
    Bug: number;
    Init: number;
  };
  position: {
    NearTrack: number;
    Uninitialized: number;
    NoData: number;
    OffTrack: number;
    OK: number;
  };
  safety: {
    CurtainRear: number;
    CurtainFront: number;
    Front: number;
    ESCharge: number;
    AU: number;
    BA: number;
    Gpio: number;
    FC: number;
    SAU: number;
    Rear: number;
    Loc: number;
    OK: number;
  };
  communication: {
    CRC: number;
    CMD: number;
    DST: number;
    OK: number;
    OFF: number;
  };
}

/**
 * @see {isBalyoSimulationPromptMsg} ts-auto-guard:type-guard
 */
export interface BalyoSimulationPromptMsg {
  /** the message type */
  type: 'balyosimulation-prompt';
  /** the name of the category of the prompt */
  category: string;
  /** the category id of the prompt */
  categoryId: number;
  /** the title of the snackbar to display */
  title?: string;
  /** the message of the snackbar to display */
  msg: string;
  /** the available choices of the prompt to the user */
  actions?: string[];
  /** a parameter to give back to the balyo simulation module with the answer */
  parameter?: number;
  /** the style of the snackbar to display */
  severity?: 'info' | 'success' | 'warning' | 'error';
  /** the timeout of the snackbar to display, -1 = the user has to click on the cross to close the snackbar, otherwise a default timeout is applied if omitted [ms] */
  timeout?: number;
  /** whether or not we have to stop the simulation when we receive this message (can be used for critical errors) */
  stopSimu?: boolean;
  /** whether or not we display an (external) notification when the Road Editor tab is not focussed by the user */
  notification?: boolean;
}

export interface BalyoSimulationPromptMsgJSX extends Omit<BalyoSimulationPromptMsg, 'msg'> {
  msg: string | JSX.Element;
}

export const balyoSimulationLoadingMsgSchema = z.object({
  type: z.literal('balyosimulation-loading'),
  title: z.string(),
  brief: z.string(),
  percent: z.number().min(0).max(1),
});

/**
 * @see {isRobotOccupation} ts-auto-guard:type-guard
 */
export interface RobotOccupation {
  itinerary?: {
    x: number;
    y: number;
  }[];

  occupiedCantons?: CantonsDict;
  manualCantons?: CantonsDict;
  lockedCantons?: CantonsDict;
  reservedCantons?: CantonsDict;
  currentCanton?: CantonsDict;
  checkCanton?: CantonsDict;
  checkDescription?: string;
}

export type TriggerType = 'interval' | 'buffer' | 'replay';

export type Trigger = IntervalTrigger | BufferTrigger | ReplayTrigger;

export interface GeneralTrigger {
  /** id of the trigger */
  id: string;
  /** name of the trigger */
  name: string;
  /** type of the trigger */
  type: TriggerType;
  /** whether or not the trigger is enabled */
  enabled?: boolean;
  /** indicates if the trigger is directly linked to a flow */
  linked?: boolean;
}

export interface IntervalTrigger extends GeneralTrigger {
  type: 'interval';

  /** every which elapsed time we want to create a task [s] */
  interval: number;

  /**
   * from which flow do we create the task
   *
   * It can be a list, then all the tasks needs to be created in the correct order with the previous task as prev
   **/
  flowId: string | string[];

  /**
   * The number of tasks to create at each interval
   * If the value is omitted, we will create just one task.
   */
  count?: number;

  /** Priority of the tasks to create, = 1 when omitted */
  priority?: number;

  /** Time of the simulation from which the first task will be created in sec*/
  startAt?: number;
  /** Simulation time from which no more tasks will be created in sec*/
  stopAt?: number;
}

export interface BufferTrigger extends GeneralTrigger {
  type: 'buffer';

  /** the number of tasks to keep in the remaining state */
  size: number;
  /**
   * whether the buffer size is related to this flow only
   * false or undefined = will create task(s) if the number of remaining (available + running) tasks for all flows are under a certain threshold
   * true = will create task(s) if the number of remaining (available + running) tasks for this flow is under a certain threshold
   * */
  bufferForFlowOnly?: boolean;

  /** from which flow do we create the task */
  flowId: string | string[];

  /** Priority of the tasks to create, = 1 when omitted */
  priority?: number;

  /**
   * By default, all the tasks are considered if not done (i.e. in available or running state)
   * When this parameter is set, the trigger will ignore the tasks with current step number greater than this property
   *
   * @default = infinity
   */
  taskStepsToConsider?: number;
}

export const defaultTaskStepsToConsider = 1000;

export interface ReplayTrigger extends GeneralTrigger {
  type: 'replay';
  /** The tasks to create */
  tasks: (NewTaskDispatcherWithTimeAndSerialsAndPrev | NewTasksDispatcherWithTime)[];
}

export interface NewTaskDispatcher {
  /** Name of the flow the task is part of (= taskName once created) */
  flowName: string;
  /** The name of the start point of the task (for display purposes) */
  startPoint: string;
  /** The name of the destination point of the task (for display purposes) */
  destinationPoint: string;
  /**
   * The id of the source point
   * Mandatory even if you use the "stepN" fields
   **/
  src: number;
  /**
   * The id of the destination point
   * Mandatory even if you use the "stepN" fields
   **/
  dst: number;
  /** The ids of the different steps (step0, step1, etc.) */
  [key: `step${number}`]: number;
  /** The priority of the task */
  priority?: number;
  /** The due date parameter = the time when the task has to be finished */
  dueDate?: number;
  /** Ids of the robots authorized to do this task */
  robAuth?: number[];
  /** Whether the task can be paused */
  allowSuspend?: 0 | 1;
}

export interface NewTaskDispatcherWithTime extends NewTaskDispatcher {
  time: number;
}

export interface NewTaskDispatcherWithSerialsAndPrev extends NewTaskDispatcher {
  serials?: string[];
  prev?: boolean;
}

export interface NewTasksDispatcherWithTime {
  tasks: NewTaskDispatcherWithSerialsAndPrev[];
  time: number;
}

export interface NewTasksDispatcherWithTimeAndSerials extends NewTasksDispatcherWithTime {
  tasks: NewTaskDispatcherWithSerialsAndPrev[];
}

export interface NewTaskDispatcherWithTimeAndSerialsAndPrev extends NewTaskDispatcherWithTime {
  serials?: string[];
  prev?: boolean;
}

export interface NewTaskDispatcherReplayTrigger extends NewTaskDispatcherWithTimeAndSerialsAndPrev {
  priority?: number;
}
