import type { Position } from '@turf/helpers';
import { saveRackAction } from 'actions/racks';
import { saveZoneAction } from 'actions/zones';
import { rotatePoint } from 'drawings/helpers';
import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';
import type { LoadedZone } from 'reducers/circuit/state';
import store from 'store';
import { getConnectedTurnIds } from '../utils/findConnectedTurn';

/**
 * Processes a selected rack, applies rotation, and updates its geometry and properties.
 * It also tracks the associated turn IDs to be modified.
 *
 * @param selectedShape - The selected shape (rack) to process.
 * @param rotateValue - The rotation value to apply to the rack.
 * @param centerSelectedShapes - The center point for rotation.
 * @param actions - The array to collect actions to dispatch.
 * @param modifiedShapes - A set of modified shape IDs (including turns).
 */
export function processRotateRack(
  selectedShape: CircuitRack,
  rotateValue: number,
  centerSelectedShapes: Position,
  actions: any[],
  modifiedShapes: Set<string>,
  selectedShapes: (
    | CircuitRack
    | CircuitStockZone
    | CircuitMeasurer
    | CircuitZone
    | CircuitDevice
    | CircuitNote
    | CircuitSegment
    | CircuitTurn
    | CircuitPoint
  )[]
): void {
  const rack = selectedShape;
  if (!rack.id || rack.properties.locked) return;

  const { id, properties, geometry } = rack;

  // Apply rotation
  const newCap = properties.cap + rotateValue;
  const newCoords = geometry.coordinates[0].map((coord) => rotatePoint(centerSelectedShapes, [...coord], -rotateValue));

  actions.push(
    saveRackAction({
      ...rack,
      geometry: { ...geometry, coordinates: [newCoords] },
      properties: { ...properties, cap: newCap },
    })
  );

  // Add associated turns to modifiedShapes
  getConnectedTurnIds(id, 'rack').forEach((turnId) => modifiedShapes.add(turnId));

  if (properties.gravityRack) {
    const gravityRackId = properties.gravityRack.id;

    const associatedRack = selectedShapes.filter(
      (shape) =>
        shape.properties.type === 'RACK' && shape.properties.gravityRack?.id === gravityRackId && shape.id !== id
    );

    if (associatedRack.length < 1) {
      const racksEntities = store.getState().circuit.present.racks.entities;
      const rackIds = store.getState().circuit.present.racks.ids;

      rackIds.forEach((rackId) => {
        const otherRack = racksEntities[rackId];

        if (!otherRack.id) return;

        const isAssociatedGravityRack =
          otherRack.properties.gravityRack &&
          otherRack.properties.gravityRack.id === gravityRackId &&
          otherRack.id !== id;

        if (isAssociatedGravityRack) {
          // Apply rotation
          const newCapAssociatedRack = otherRack.properties.cap + rotateValue;
          const newCoordsAssociatedRack = otherRack.geometry.coordinates[0].map((coord) =>
            rotatePoint(centerSelectedShapes, [...coord], -rotateValue)
          );

          actions.push(
            saveRackAction({
              ...otherRack,
              geometry: { ...otherRack.geometry, coordinates: [newCoordsAssociatedRack] },
              properties: { ...otherRack.properties, cap: newCapAssociatedRack },
            })
          );

          // Add associated turns to modifiedShapes
          getConnectedTurnIds(otherRack.id, 'rack').forEach((turnId) => modifiedShapes.add(turnId));

          const associatedZoneId = rack.properties.gravityRack?.zone;
          const zones = store.getState().circuit.present.zones.entities;

          if (associatedZoneId) {
            const associatedZone = zones[associatedZoneId];

            const newZoneCoordinates = [
              [newCoords[0], newCoords[1], newCoordsAssociatedRack[0], newCoordsAssociatedRack[1], newCoords[0]],
            ];

            const newAssociatedZone: LoadedZone = {
              ...associatedZone,
              geometry: {
                ...associatedZone.geometry,
                coordinates: newZoneCoordinates,
              },
            };

            actions.push(saveZoneAction(newAssociatedZone));
          }
        }
      });
    }
  }
}
