import { TextField } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow } from 'flows/flows';
import { setFlow } from 'flows/flows';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useAppDispatch } from 'store';

interface FlowConfigurationProps {
  selectedFlow: Flow;
}
export function PalletsPerTaskTextfield(props: FlowConfigurationProps): JSX.Element {
  const { selectedFlow } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const minPalletsPerTaskValue = 0.001;
  const maxPalletsPerTaskValue = 50;

  const defaultValue = useMemo(() => selectedFlow.palletsPerTask.toString(), [selectedFlow.palletsPerTask]);

  const handleUpdate = useCallback(() => {
    if (!inputRef.current) return;

    let value = inputRef.current.valueAsNumber;
    if (isNaN(value)) {
      value = +defaultValue;
    } else if (value < minPalletsPerTaskValue) {
      value = minPalletsPerTaskValue;
    } else if (value > maxPalletsPerTaskValue) {
      value = maxPalletsPerTaskValue;
    } else {
      value = Math.round(value * 1000) / 1000;
    }

    inputRef.current.value = value.toString();
    dispatch(setFlow({ ...selectedFlow, palletsPerTask: value }));
  }, [defaultValue, dispatch, selectedFlow]);

  useLayoutEffect(() => {
    if (!inputRef.current) return;

    if (defaultValue !== inputRef.current.value) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  const preventNegativeValue = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  }, []);

  return (
    <TextField
      type="number"
      label={
        <>
          Number of pallets per task{' '}
          <HelpIconTooltip title="Number of pallets moved during one task. Warning ! This value can affect the throughput when expressed in pallets per hour." />
        </>
      }
      variant="outlined"
      fullWidth
      size="small"
      inputProps={{ min: minPalletsPerTaskValue, max: maxPalletsPerTaskValue }}
      InputProps={{
        endAdornment: 'pal/task',
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      inputRef={inputRef}
      onBlur={handleUpdate}
      onKeyDown={preventNegativeValue}
    />
  );
}
