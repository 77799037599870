import { Traffic } from '@mui/icons-material';
import { Box, Chip, ListItem, ListItemIcon, ListItemText, Switch, Tooltip } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useCallback, useEffect, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAsyncMemo } from 'use-async-memo';
import { PreferencesService } from 'utils/preferences';

export const deadlockSolverPref = 'traffic/enableDeadlockSolver';

interface EnableDeadlockSolverSwitchProps {
  projectOpened: boolean;
}

export function EnableDeadlockSolverSwitch(props: EnableDeadlockSolverSwitchProps): JSX.Element {
  const { projectOpened } = props;

  const [deadlockSolverEnabled, setDeadlockSolverEnabled] = useState(false);

  const handleChange = useCallback(async (newState: boolean) => {
    const [ok] = await PreferencesService.setPreferenceValue(deadlockSolverPref, newState ? '1' : '0', true);
    if (ok) {
      setDeadlockSolverEnabled(newState);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Could not ${newState ? 'enable' : 'disable'} deadlock solver`);
      SnackbarUtils.error(`Could not ${newState ? 'enable' : 'disable'} deadlock solver`);
    }
  }, []);

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  useEffect(() => {
    const enabled = (() => {
      try {
        return projectOpened && PreferencesService.getPreferenceValue(deadlockSolverPref) === '1';
      } catch (e) {
        return false;
      }
    })();

    setDeadlockSolverEnabled(enabled);
  }, [projectOpened]);

  if (!projectOpened) return <></>;

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <Traffic />
        </ListItemIcon>
        <ListItemText
          primary={
            <Tooltip title={!editProjectPerm ? 'You do not have the authorization to edit this value' : ''}>
              <Box component="span">
                <Switch
                  checked={deadlockSolverEnabled}
                  onChange={(e, checked) => handleChange(checked)}
                  disabled={!editProjectPerm}
                />
              </Box>
            </Tooltip>
          }
          secondary={
            <>
              Enable Deadlock Solver
              <HelpIconTooltip
                title="The deadlock solver is a traffic management feature that tries to resolve deadlocks by authorizing some robots to move in a specific order. It does not reroute robots. It is not always possible to resolve all deadlocks."
                sx={{
                  fontSize: '1rem',
                }}
              />
              <Chip label="BETA" sx={{ marginLeft: '10px' }} size="small" />
            </>
          }
        />
      </ListItem>
    </>
  );
}
