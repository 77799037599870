import { TextField } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { BufferTrigger, IntervalTrigger } from 'models/simulation';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { setTrigger } from 'simulation/triggers';
import { useAppDispatch } from 'store';
import { theme } from 'utils/mui-theme';

interface PriorityTextfieldProps {
  trigger: IntervalTrigger | BufferTrigger;
}
export function TriggerPriorityTextfield(props: PriorityTextfieldProps): JSX.Element {
  const { trigger } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const defaultValue = useMemo(() => (trigger.priority ?? 1).toString(), [trigger.priority]);

  const handleUpdate = useCallback(() => {
    if (!inputRef.current) return;

    const priority = inputRef.current.valueAsNumber;
    if (!priority || priority < +inputRef.current.min) {
      inputRef.current.value = defaultValue;

      return;
    }

    dispatch(
      setTrigger({
        ...trigger,
        priority: Math.round(priority),
      })
    );
  }, [defaultValue, dispatch, trigger]);

  useLayoutEffect(() => {
    if (!inputRef.current) return;

    if (defaultValue !== inputRef.current.value) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  const label = (
    <>
      Priority <HelpIconTooltip title="Set mission priority. Higher numbers mean higher priority." />
    </>
  );

  const preventNegativeValue = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  }, []);

  return (
    <TextField
      label={label}
      type="number"
      inputProps={{
        min: 1,
        step: 1,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      onBlur={handleUpdate}
      inputRef={inputRef}
      sx={{
        marginTop: theme.spacing(1),
      }}
      fullWidth
      size="small"
      variant="outlined"
      disabled={trigger.linked}
      onKeyDown={preventNegativeValue}
    />
  );
}
