import { CircuitService, getMaxDisplayPriority } from './circuit.service';
import type { GetTurnFeatureServiceProps } from './trajectory.service';
import { TrajectoryService } from './trajectory.service';

// const worker = new Worker(new URL('./trajectory.service.ts', import.meta.url), { type: 'module' });
// const trajectoryProxy = Comlink.wrap<{
//   getTurnFeature: GetTurnFeature;
// }>(worker);

// type GetTurnFeatureProps = Omit<GetTurnFeatureServiceProps, 'layerId' | 'prio'>;

// export async function getTurnFeature(props: GetTurnFeatureProps): Promise<ReturnType<GetTurnFeature>> {
//   const layerId = CircuitService.getSelectedLayer();
//   const prio = getMaxDisplayPriority();

//   const p = trajectoryProxy.getTurnFeature({ layerId, prio, ...props });

//   const r = await p;

//   return r;
// }

type GetTurnFeatureProps = Omit<GetTurnFeatureServiceProps, 'layerId' | 'prio'>;

export function getTurnFeature(props: GetTurnFeatureProps): ReturnType<(typeof TrajectoryService)['getTurnFeature']> {
  const layerId = CircuitService.getSelectedLayer();
  const prio = getMaxDisplayPriority();

  const p = TrajectoryService.getTurnFeature({ layerId, prio, ...props });

  return p;
}
