import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import type { SelectChangeEvent } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { deleteActionAction } from 'actions/actionsSlice';
import { isActionNode, isFolderNode } from 'components/core/project-settings/RMI-actions/actions.guard';
import type { ActionNode, InputTypeActions, RobotManagerInteractionArg } from 'models/action';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionsFolderMoveDialog } from './action-folder-move-dialog';

// FieldEditor remains basically unchanged, except you can remove
// the onDelete icon if you don't want it inside the expanded content anymore:
interface FieldEditorProps {
  arg: RobotManagerInteractionArg;
  onChange: (updatedArg: RobotManagerInteractionArg) => void;
  onDelete?: () => void;
}

export const FieldEditor: React.FC<FieldEditorProps> = ({ arg, onChange }) => {
  const { label, description, inputType, required, options } = arg;

  const updateArg = (updates: Partial<RobotManagerInteractionArg>): void => {
    const newArg = { ...arg, ...updates };
    onChange(newArg);
  };

  const handleInputTypeChange = (newType: string): void => {
    updateArg({ inputType: newType as InputTypeActions });
  };

  return (
    <Box component="div" mt={0.5}>
      {/* Remove the old delete icon if you want it *only* next to the expand icon:
      
      <Box component="div" display="flex" alignItems="center" flexDirection="row-reverse">
        {onDelete && (
          <Tooltip title="Delete Argument">
            <IconButton color="error" onClick={onDelete}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      */}

      {description && (
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {description}
        </Typography>
      )}

      <TextField
        label="Label"
        fullWidth
        variant="outlined"
        value={label}
        onChange={(e) => updateArg({ label: e.target.value })}
        size="small"
        sx={{ mt: 1 }}
      />

      <TextField
        label="Description"
        fullWidth
        multiline
        variant="outlined"
        value={description || ''}
        onChange={(e) => updateArg({ description: e.target.value })}
        size="small"
        sx={{ mt: 1 }}
      />

      <FormControlLabel
        control={<Switch checked={!!required} onChange={(e) => updateArg({ required: e.target.checked })} />}
        label="Required"
      />

      <FormControl fullWidth size="small" sx={{ mt: 1 }}>
        <InputLabel>Input Type</InputLabel>
        <Select
          onChange={(e: SelectChangeEvent) => handleInputTypeChange(e.target.value)}
          value={inputType}
          label="Input Type"
        >
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="number">Number</MenuItem>
          <MenuItem value="singleSelect">Single Select</MenuItem>
          <MenuItem value="multiSelectString">Multi Select String</MenuItem>
        </Select>
      </FormControl>

      {inputType === 'text' || inputType === 'number' ? (
        <TextField
          label="Value"
          type={inputType === 'number' ? 'number' : 'text'}
          fullWidth
          variant="outlined"
          size="small"
          sx={{ mt: 1 }}
        />
      ) : inputType === 'singleSelect' ? (
        <FormControl fullWidth size="small" sx={{ mt: 1 }}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={options?.[0] || ''}
            onChange={(e: SelectChangeEvent) => updateArg({ options: [e.target.value] })}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="ON">ON</MenuItem>
            <MenuItem value="OFF">OFF</MenuItem>
          </Select>
        </FormControl>
      ) : inputType === 'multiSelectString' ? (
        <Box component="div" sx={{ mt: 2 }}>
          <Box component="div" my={1} display="flex" alignItems="center">
            <Typography mr={0.5} variant="body2">
              Options
            </Typography>
            <Tooltip title="Add options by typing text and pressing enter to validate and create a new one">
              <InfoOutlinedIcon sx={{ fontSize: '15px' }} color="action" />
            </Tooltip>
          </Box>
          <Box component="div" display="flex" alignItems="center" gap={1}>
            <Autocomplete
              freeSolo
              multiple
              value={options || []}
              onChange={(_event, newValue) => {
                updateArg({ options: newValue });
              }}
              options={[]}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} deleteIcon={<CloseIcon />} />
                ))
              }
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
              sx={{ flex: 1 }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

interface JsonFieldEditorProps {
  open?: boolean;
  onClose?: () => void;
  action: ActionNode;
  onChange: (action: ActionNode) => void;
  onDelete?: (id: string) => void;
  allActions?: ActionNode[];
  addArg?: () => void;
  fileInputRef?: React.RefObject<HTMLInputElement>;
  handleUploadImageClick?: () => void;
  handleImageSelected?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  secondFileInputRef?: React.RefObject<HTMLInputElement>;
  handleRemoveImage?: () => void;
}

export const JsonFiledEditor: React.FC<JsonFieldEditorProps> = ({
  handleUploadImageClick,
  handleImageSelected,
  handleRemoveImage,
  addArg,
  action,
  onChange,
  onDelete,
  fileInputRef,
  allActions,
}) => {
  const dispatch = useDispatch();

  // For "move" dialog
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);

  // Confirm Delete Action
  const [deleteActionConfirmOpen, setDeleteActionConfirmOpen] = useState(false);

  // Confirm Delete Arg
  const [deleteArgConfirmOpen, setDeleteArgConfirmOpen] = useState(false);
  const [argToDeleteIndex, setArgToDeleteIndex] = useState<number | null>(null);

  // Helper to handle partial field changes
  const handleFieldChange = (field: string, value: string | boolean): void => {
    const updated = { ...action, [field]: value };
    onChange(updated);
  };

  // To save the changes in the argument
  const handleArgChange = (index: number, updatedArg: RobotManagerInteractionArg): void => {
    if (!isActionNode(action)) return;
    const newArgs = [...(action.args || [])];
    newArgs[index] = updatedArg;
    onChange({ ...action, args: newArgs });
  };

  // Request to delete an argument
  const handleRemoveArgRequest = (index: number): void => {
    setArgToDeleteIndex(index);
    setDeleteArgConfirmOpen(true);
  };

  // Confirm Delete Argument
  const handleRemoveArgConfirm = (): void => {
    setDeleteArgConfirmOpen(false);
    if (argToDeleteIndex !== null && isActionNode(action)) {
      const newArgs = [...(action.args || [])];
      newArgs.splice(argToDeleteIndex, 1);
      onChange({ ...action, args: newArgs });
    }

    setArgToDeleteIndex(null);
  };

  // Cancel Delete Argument
  const handleRemoveArgCancel = (): void => {
    setArgToDeleteIndex(null);
    setDeleteArgConfirmOpen(false);
  };

  // Delete entire action/folder
  const handleDeleteActionClick = (): void => {
    setDeleteActionConfirmOpen(true);
  };

  const handleDeleteActionConfirm = (): void => {
    setDeleteActionConfirmOpen(false);
    if (onDelete) {
      dispatch(deleteActionAction(action.id));
      onDelete(action.id);
    }
  };

  const handleDeleteActionCancel = (): void => {
    setDeleteActionConfirmOpen(false);
  };

  return (
    <Box component="div">
      <Box
        component="div"
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom>
            {isFolderNode(action) ? 'Folder Editor' : 'Action Editor'}
          </Typography>
          <Box component="div" display="flex" alignItems="center" gap={1}>
            {onDelete && (
              <Tooltip title="Delete Action/Folder">
                <IconButton color="error" onClick={handleDeleteActionClick}>
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Move to">
              <IconButton color="primary" onClick={() => setMoveDialogOpen(true)}>
                <DriveFileMoveIcon />
              </IconButton>
            </Tooltip>
            <ActionsFolderMoveDialog
              open={moveDialogOpen}
              onClose={() => setMoveDialogOpen(false)}
              action={action}
              allActions={allActions ?? []}
            />
          </Box>
        </Box>
      </Box>

      {/* Main Content */}
      <Box component="div">
        {/* If it's a Folder, just show Folder fields */}
        {isFolderNode(action) && (
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardContent>
              <Box component="div" mt={2}>
                <TextField
                  label="Display name"
                  fullWidth
                  variant="outlined"
                  value={action.displayName}
                  onChange={(e) => handleFieldChange('displayName', e.target.value)}
                  size="small"
                />
              </Box>
            </CardContent>
          </Card>
        )}

        {/* If it's an Action, show all Action fields */}
        {isActionNode(action) && (
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardContent>
              {/* Display Name */}
              <Box component="div" mt={2}>
                <TextField
                  label="Display Name"
                  fullWidth
                  variant="outlined"
                  value={action.displayName}
                  onChange={(e) => handleFieldChange('displayName', e.target.value)}
                  size="small"
                />
              </Box>

              {/* Description */}
              <Box component="div" mt={2}>
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={action.description}
                  onChange={(e) => handleFieldChange('description', e.target.value)}
                  size="small"
                />
              </Box>

              {/* Requires Confirmation */}
              <Box component="div" mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!action.requiresConfirmation}
                      onChange={(e) => onChange({ ...action, requiresConfirmation: e.target.checked })}
                    />
                  }
                  label="Requires Confirmation"
                />
              </Box>

              {/* Callback */}
              <Box component="div" mt={2}>
                <Box component="div" my={1} display="flex" alignItems="center">
                  <Typography mr={0.5} variant="body2">
                    Callback
                  </Typography>
                  <Tooltip title="Callback is the name of the project code function triggered by the button">
                    <InfoOutlinedIcon sx={{ fontSize: '15px' }} color="action" />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={action.callback}
                  onChange={(e) => handleFieldChange('callback', e.target.value)}
                  size="small"
                />
              </Box>

              {/* Image Name */}
              <Box component="div" mt={2}>
                <TextField
                  disabled
                  label="Image Name"
                  fullWidth
                  variant="outlined"
                  value={action.imgName || ''}
                  onChange={(e) => handleFieldChange('imgName', e.target.value)}
                  size="small"
                />
              </Box>

              {/* Preview */}
              {action.imgData && (
                <Box component="div" mt={2}>
                  <Typography variant="body2" gutterBottom>
                    Preview:
                  </Typography>
                  <img
                    src={action.imgData}
                    alt="Preview"
                    style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                  />
                </Box>
              )}

              {/* Upload / Remove Image */}
              <Box component="div" mt={2} display="flex" gap={2}>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImageSelected}
                />
                <Tooltip title={action.imgData ? 'Replace Image' : 'Upload Image'}>
                  <IconButton color="primary" onClick={handleUploadImageClick}>
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </Tooltip>
                {action.imgData && (
                  <Tooltip title="Remove Image">
                    <IconButton color="error" onClick={handleRemoveImage}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </CardContent>
          </Card>
        )}

        {/* Arguments only exist for an Action */}
        {isActionNode(action) && (
          <Card>
            <CardContent>
              <Box component="div" sx={{ my: 1, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Arguments
                </Typography>
                {isActionNode(action) && (
                  <Tooltip title="Add an argument">
                    <IconButton color="primary" onClick={addArg}>
                      <WysiwygIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>

              {action.args && action.args.length > 0 && (
                <Box component="div">
                  {action.args.map((arg, index) => (
                    <Accordion key={index} sx={{ mt: 1 }}>
                      <AccordionSummary
                        classes={{ expandIconWrapper: 'myExpandIconWrapper' }}
                        sx={{
                          '& .myExpandIconWrapper': {
                            transform: 'none !important',
                            transition: 'none !important',
                          },
                          '& .myExpandIconWrapper.Mui-expanded': {
                            transform: 'none !important',
                          },

                          '& .myArrowIcon': {
                            transition: 'transform 0.2s ease',
                          },
                          '& .myExpandIconWrapper.Mui-expanded .myArrowIcon': {
                            transform: 'rotate(180deg)',
                          },
                        }}
                        expandIcon={
                          <Box component="div" display="flex" alignItems="center" gap={1}>
                            <Tooltip title="Delete Argument">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleRemoveArgRequest(index);
                                }}
                              >
                                <DeleteForeverIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <ExpandMoreIcon className="myArrowIcon" />
                          </Box>
                        }
                      >
                        <Typography sx={{ fontWeight: 'regular' }}>{arg.label || `Argument #${index + 1}`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FieldEditor arg={arg} onChange={(updatedArg) => handleArgChange(index, updatedArg)} />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        )}
      </Box>

      {/* Confirm Delete Action/Folder Dialog */}
      <Dialog open={deleteActionConfirmOpen} onClose={handleDeleteActionCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this action/folder?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteActionCancel}>Cancel</Button>
          <Button onClick={handleDeleteActionConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Argument Dialog */}
      <Dialog open={deleteArgConfirmOpen} onClose={handleRemoveArgCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this argument?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveArgCancel}>Cancel</Button>
          <Button onClick={handleRemoveArgConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
