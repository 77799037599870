// NestedMenuItem.tsx
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import type { MenuProps } from '@mui/material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import type { FolderNodeInMenu } from './helpers';

interface NestedMenuItemProps extends React.ComponentPropsWithoutRef<typeof MenuItem> {
  isOpen?: boolean;
  parentMenuOpen: boolean;
  label?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
  menuProps?: Partial<MenuProps>;
}

/**
 * A <MenuItem> that opens a nested sub-<Menu> when hovered.
 */
export function NestedMenuItem(props: NestedMenuItemProps): JSX.Element {
  const { parentMenuOpen, isOpen = false, label, rightIcon, children, menuProps, ...menuItemProps } = props;

  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLLIElement>): void => {
    // Always compute bounding rect if the parent menu is open,
    // so we know where to show this sub-menu if/when isOpen becomes true.
    if (parentMenuOpen) {
      const rect = event.currentTarget.getBoundingClientRect();
      setMenuPosition({ top: rect.top, left: rect.right });
    } else {
      setMenuPosition(null);
    }

    // Forward the mouseEnter event if the parent also provided one
    if (menuItemProps.onMouseEnter) {
      menuItemProps.onMouseEnter(event);
    }
  };

  return (
    <>
      {/* The parent onMouseEnter sets 'activeItemId'. We'll also compute the position here. */}
      <MenuItem {...menuItemProps} onMouseEnter={handleMouseEnter}>
        {label}
        {rightIcon}
      </MenuItem>

      <Menu
        // Sub-menu is open only if isOpen === true and we actually have a position
        open={Boolean(menuPosition) && isOpen}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition || undefined}
        onClose={() => setMenuPosition(null)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        style={{ pointerEvents: 'none' }} // allow pointer to move into child
        {...menuProps}
      >
        <div style={{ pointerEvents: 'auto' }} onMouseLeave={() => setMenuPosition(null)}>
          {children}
        </div>
      </Menu>
    </>
  );
}

interface FolderMenusProps {
  folders: FolderNodeInMenu[];
  onFolderClick: (folderId: string) => void;
  parentMenuOpen: boolean;
}

/** Renders a list of folders as nested menu items (recursively).
 *  - If a folder has children, we use <NestedMenuItem> to show the sub-menu on hover.
 *  - If no children, we just show a normal <MenuItem>.
 */
export function FolderMenus({ folders, onFolderClick, parentMenuOpen }: FolderMenusProps): JSX.Element {
  // Only one "hovered" folder at a time.
  // If activeItemId = X, then the folder with id=X opens its submenu, all others stay closed.
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  return (
    <>
      {folders.map((folder) => {
        const hasChildren = folder.children && folder.children.length > 0;

        // If this folder has no children => just a normal MenuItem
        if (!hasChildren) {
          return (
            <MenuItem
              key={folder.id}
              onMouseEnter={() => setActiveItemId(null)} // ensuring no sub-menu stays open
              onClick={() => onFolderClick(folder.id)}
            >
              {folder.displayName}
            </MenuItem>
          );
        }

        // If this folder has children => nested menu item
        return (
          <NestedMenuItem
            key={folder.id}
            label={folder.displayName}
            parentMenuOpen={parentMenuOpen}
            rightIcon={<ArrowRightIcon fontSize="small" />}
            // isOpen = parent is open and this item is the "active" hovered item
            isOpen={parentMenuOpen && activeItemId === folder.id}
            // When hover this item, make it active:
            onMouseEnter={() => setActiveItemId(folder.id)}
            onClick={() => onFolderClick(folder.id)}
          >
            <FolderMenus
              folders={folder.children}
              onFolderClick={onFolderClick}
              parentMenuOpen={parentMenuOpen && activeItemId === folder.id}
            />
          </NestedMenuItem>
        );
      })}
    </>
  );
}
