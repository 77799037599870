import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TextField } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow } from 'flows/flows';
import { setFlow } from 'flows/flows';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useAppDispatch } from 'store';

interface ObjectiveTextfieldProps {
  selectedFlow: Flow;
}
export function ObjectiveTextfield(props: ObjectiveTextfieldProps): JSX.Element {
  const { selectedFlow } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const defaultValue = useMemo(
    () => (selectedFlow.objective ? selectedFlow.objective.toString() : ''),
    [selectedFlow.objective]
  );

  const handleUpdate = useCallback(() => {
    if (!inputRef.current) return;

    const value = inputRef.current.valueAsNumber;
    if (!value || value < +inputRef.current.min) {
      inputRef.current.value = defaultValue;
    }

    dispatch(setFlow({ ...selectedFlow, objective: value }));
  }, [defaultValue, dispatch, selectedFlow]);

  useLayoutEffect(() => {
    if (!inputRef.current) return;

    if (defaultValue !== inputRef.current.value) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  const preventNegativeValue = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  }, []);

  const handleClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      handleUpdate();
    }
  }, [handleUpdate]);

  return (
    <TextField
      type="number"
      label={
        <>
          Objective <HelpIconTooltip title="Objective for the flows in task per hour (optional)" />
        </>
      }
      variant="outlined"
      fullWidth
      size="small"
      inputProps={{ min: 0, step: 1 }}
      InputProps={{
        endAdornment: (
          <>
            <IconButton onClick={handleClear} size="small">
              <ClearIcon fontSize="small" />
            </IconButton>
            tasks/hr
          </>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      inputRef={inputRef}
      onBlur={handleUpdate}
      onKeyDown={preventNegativeValue}
    />
  );
}
