import { Alert } from '@mui/material';
import { useEffect } from 'react';
import { isDevVersion } from 'utils/env';
import { theme } from 'utils/mui-theme';

interface TimeoutAlertProps {
  onClose: () => void;
}

/**
 * Custom hook to auto-clear an alert after a specified duration
 * @param onClose Callback to close the alert
 * @param duration Duration in milliseconds before auto-clearing
 */
export const useAutoClearAlert = (onClose: () => void, duration = 5000): void => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);
};

export function TimeoutAlert({ onClose }: TimeoutAlertProps): JSX.Element | null {
  useAutoClearAlert(onClose);

  // Only show the alert in dev mode
  if (!isDevVersion()) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      onClose={onClose}
      sx={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      }}
    >
      A timeout occurred while retrieving simulation data. The simulation will continue running.
    </Alert>
  );
}
