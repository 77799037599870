import { green, orange, red } from '@mui/material/colors';
import * as d3 from 'd3';
import type { SimpleSelection } from 'drawings/shared';
import type { CollisionPolygon, Lidar } from 'models/maps';
import { LidarPosition } from 'models/maps';
import { getLidarBounds } from 'services/maps.service';
import tinygradient from 'tinygradient';
import { BaseLayer } from './base.layer';
import { LayerNames } from './shared';

interface DataMapWorker {
  type: 'answer-need-canvas-transfer' | 'render-completed';
  needCanvasTransfer?: boolean;
  finished?: boolean;
}

interface MapWorkerMessage {
  type: string;
  canvas?: OffscreenCanvas | null;
  coordinates?: number[][];
  opts?: {
    minX: number;
    minY: number;
    ratio: number;
    maxX: number;
    maxY: number;
    width: number;
    height: number;
    maxValue: number;
    opacity: number;
  };
  collisionPolygon?: CollisionPolygon;
  data?: {
    width: number;
    height: number;
    transform: unknown;
    lidarData: unknown;
    lidarBounds: unknown;
    gradient: unknown;
  };
}

let worker: Worker | null = null;
let proxy: Worker | null = null;

if (!window.__TEST__) {
  const importWorker = async (): Promise<void> => {
    const workerModule = await import('../../editor/map.worker.ts?worker');
    worker = new workerModule.default({
      name: `lidar-map-worker-${Date.now()}`,
    });
    proxy = worker;
  };

  void importWorker();
}

/**
 * Safely gets the map worker proxy instance
 * @throws Error if worker proxy is not available
 */
const getMapWorkerProxy = (): Worker => {
  if (!proxy) {
    throw new Error('Map worker proxy not initialized');
  }

  return proxy;
};

export const mapWorkerProxy = proxy;
export const terminateMapWorker = (): void => worker?.terminate();

export class LidarLayer extends BaseLayer<undefined> {
  protected canvas: SimpleSelection<HTMLCanvasElement, undefined>;
  protected readonly gradient = tinygradient(
    { color: green[500], pos: 0 },
    { color: green[300], pos: 0.5 },
    { color: orange[500], pos: 0.67 },
    { color: red[500], pos: 0.7 },
    { color: red[500], pos: 1 }
  );

  constructor(position: LidarPosition) {
    super(lidarPositionToLayerName(position));
    this.canvas = this.createCanvas();
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    return d3.create<SVGGElement>('svg:g');
  }

  public createCanvas(): SimpleSelection<HTMLCanvasElement, undefined> {
    return this.node
      .append('svg:foreignObject')
      .attr('x', 0)
      .attr('y', 0)
      .attr('overflow', 'visible')
      .attr('width', '100%')
      .attr('height', '100%')
      .append<HTMLCanvasElement>('xhtml:canvas')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('position', 'absolute')
      .style('left', 0)
      .style('z-index', 1)
      .style('image-rendering', 'pixelated');
  }

  public async setPoints(
    lidar: Lidar,
    opacity = 100,
    invalidate = false,
    collisionPolygon?: CollisionPolygon
  ): Promise<void> {
    if (localStorage[`${this.name}-isDrawingPoints`] === 'true') return;
    localStorage[`${this.name}-isDrawingPoints`] = 'true';

    const { coordinates } = lidar;

    const { minX, minY, maxY, maxX, width, height, maxValue } = getLidarBounds(coordinates);

    const foreignObject = this.node.select<SVGForeignObjectElement>('foreignObject');

    // Based on max allowed size in browsers,
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#Maximum_canvas_size
    const MAX_DIM_ALLOWED = 15000;
    let ratio = MAX_DIM_ALLOWED / Math.max(width, height);

    if (ratio > 1) ratio = 1;

    const scaledWidth = Math.floor(width * ratio);
    const scaledHeight = Math.floor(height * ratio);

    if (invalidate) {
      // size of the canvas to be changed, we destruct the former one
      this.canvas.remove();

      this.node
        .select('foreignObject')
        .append<HTMLCanvasElement>('xhtml:canvas')
        .attr('width', '100%')
        .attr('height', '100%')
        .style('position', 'absolute')
        .style('left', 0)
        .style('z-index', 1)
        .style('image-rendering', 'pixelated');

      this.canvas = this.node.select('canvas');

      if (this.name === LayerNames.BackgroundLidar) {
        try {
          const workerProxy = getMapWorkerProxy();
          workerProxy.postMessage({ type: 'invalidate-canvas' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Failed to invalidate canvas:', error);
        }
      }
    }

    // Position foreignObject (canvas wrapper)'s top left corner
    // at [minX, minY] of lidar coordinates
    foreignObject.attr('x', minX).attr('y', -minY).attr('width', width).attr('height', height);

    // Make canvas fill its wrapper
    // Also position it above foreignObject
    // Top left corner of FO is bottom left of Canvas
    this.canvas
      .attr('width', scaledWidth)
      .attr('height', scaledHeight)
      .style('width', `${width}px`)
      .style('height', `${height}px`)
      .style('top', `-${height}px`)
      .style('opacity', `${opacity}%`);

    const canvas = this.canvas.node();
    if (!canvas) return;

    if (this.name === LayerNames.BackgroundLidar) {
      try {
        const workerProxy = getMapWorkerProxy();
        const needCanvasTransfer: boolean = await new Promise((resolve) => {
          function handleEventAnswerNeedCanvas(message: { data: DataMapWorker }): void {
            if (message.data.type === 'answer-need-canvas-transfer') {
              workerProxy.removeEventListener('message', handleEventAnswerNeedCanvas);
              resolve(!!message.data.needCanvasTransfer);
            }
          }

          workerProxy.addEventListener('message', handleEventAnswerNeedCanvas);
          workerProxy.postMessage({ type: 'ask-need-canvas-transfer' });
        });

        const offscreenCanvas = needCanvasTransfer ? canvas.transferControlToOffscreen() : null;
        workerProxy.postMessage(
          {
            type: 'render-lidar',
            canvas: offscreenCanvas,
            coordinates,
            opts: {
              minX,
              minY,
              ratio,
              maxX,
              maxY,
              width,
              height,
              maxValue,
              opacity,
            },
            collisionPolygon,
          } as MapWorkerMessage,
          offscreenCanvas ? [offscreenCanvas] : []
        );

        await new Promise((resolve, reject): void => {
          function handleEventMapRendered(message: { data: DataMapWorker }): void {
            if (message.data.type === 'render-completed' && message.data.finished) {
              workerProxy.removeEventListener('message', handleEventMapRendered);
              resolve(message.data.finished);
            }
          }

          workerProxy.addEventListener('message', handleEventMapRendered);
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to use map worker:', error);
        // Handle the error appropriately - maybe fallback to non-worker rendering
      }
    } else {
      const context = this.canvas.node()?.getContext('2d') as CanvasRenderingContext2D;

      if (!context) {
        return;
      }

      const translateX = -minX * ratio;
      const translateY = (minY + height) * ratio;

      context.restore();
      context.save();

      context.setTransform(1, 0, 0, -1, translateX, translateY);
      context.scale(ratio, ratio);

      context.globalAlpha = 1;
      // we disable the anti-aliasing to avoid a blurred image
      context.imageSmoothingEnabled = false;
      context.fillStyle = green[500];

      context.clearRect(minX, minY, maxX - minX, maxY - minY);

      const opaSq = (opacity / 100) * (opacity / 100);
      for (let i = 0, l = coordinates.length; i < l; i++) {
        const coord = coordinates[i];
        if (opacity !== 100) context.globalAlpha = Math.log(coord[2] || 1) * opaSq;
        context.fillRect(coord[0], coord[1], 4, 4);
      }

      /*
        Next line is quite important, it's workaround for a chromium bug where
        the canvas encapsulated in a svg is always rendered at the top.
        Resources about it:
        - https://bugs.chromium.org/p/chromium/issues/detail?id=148499
        - https://observablehq.com/@mootari/embed-canvas-into-svg
      */
      context.getImageData(0, 0, 1, 1);
    }

    await setImmediatePromise();

    localStorage[`${this.name}-isDrawingPoints`] = 'false';
  }

  public invalidate(): void {
    if (this.name === LayerNames.BackgroundLidar) {
      try {
        const workerProxy = getMapWorkerProxy();
        workerProxy.postMessage({ type: 'invalidate-canvas' });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to invalidate canvas:', error);
      }
    }
  }

  private async renderOffscreen(canvas: HTMLCanvasElement): Promise<void> {
    try {
      const workerProxy = getMapWorkerProxy();

      const needCanvasTransfer = await new Promise<boolean>((resolve) => {
        function handleEventAnswerNeedCanvas(message: { data: DataMapWorker }): void {
          if (message.data.type === 'answer-need-canvas-transfer') {
            workerProxy.removeEventListener('message', handleEventAnswerNeedCanvas);
            resolve(!!message.data.needCanvasTransfer);
          }
        }

        workerProxy.addEventListener('message', handleEventAnswerNeedCanvas);
        workerProxy.postMessage({ type: 'ask-need-canvas-transfer' });
      });

      const offscreenCanvas = needCanvasTransfer ? canvas.transferControlToOffscreen() : null;
      workerProxy.postMessage(
        {
          type: 'render',
          canvas: offscreenCanvas,
          data: {
            width: canvas.width,
            height: canvas.height,
            gradient: this.gradient,
          },
        } as MapWorkerMessage,
        offscreenCanvas ? [offscreenCanvas] : []
      );

      await new Promise<boolean>((resolve) => {
        function handleEventMapRendered(message: { data: DataMapWorker }): void {
          if (message.data.type === 'render-completed' && message.data.finished) {
            workerProxy.removeEventListener('message', handleEventMapRendered);
            resolve(message.data.finished);
          }
        }

        workerProxy.addEventListener('message', handleEventMapRendered);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to render offscreen:', error);
    }
  }
}

function lidarPositionToLayerName(position: LidarPosition): LayerNames {
  switch (position) {
    case LidarPosition.Foreground:
      return LayerNames.ForegroundLidar;
    case LidarPosition.Background:
      return LayerNames.BackgroundLidar;
  }
}

function setImmediatePromise(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 0);
  });
}
