import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EditIcon from '@mui/icons-material/Edit';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import { closeDialogAction } from 'actions';
import {
  addLayerAction,
  addLayerGroupAction,
  changeLayerOrderAction,
  deleteLayerAction,
  deleteLayerGroupAction,
  updateLayerAction,
  updateLayerGroupAction,
} from 'actions/circuit';
import clsx from 'clsx';
import { GabaritSelectLayer } from 'components/layers/gabarit-select-layer';
import { ColorPicker } from 'components/utils/color-picker';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useMemo, useState } from 'react';
import { sortLayersByOrder } from 'reducers/circuit/layers.reducer';
import type { LayerData, LayerGroupData, LayerGroupDataObject } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import { useAppDispatch, useAppSelector } from 'store';
import BlinkingWarningIcon from 'utils/animated-icon';
import { initializeLegacyLayerGroups } from 'utils/circuit/initialize-layer-groups';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { balyoGradient } from 'utils/colors/colors';
import { PreferencesService } from 'utils/preferences';
import { CleanUpExcessSegmentLengths } from './clean-up-excess-segment-lengths';

const useStyles = makeStyles((theme) =>
  createStyles({
    editNameIcon: {
      verticalAlign: 'sub',
    },
    noPaddingTop: {
      paddingTop: 0,
    },
    draftIcon: {
      fill: 'none',
      stroke: 'currentColor',
      strokeDasharray: '4 1',
    },
    lineThrough: {
      textDecoration: 'line-through',
    },
    verticalAlignMiddle: {
      verticalAlign: 'middle',
    },
    suggestedName: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    listItemGray: {
      background: 'rgba(0, 0, 0, 0.02)',
    },
    scrollableList: {
      maxHeight: '35vh',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    scrollableListLayerGroups: {
      maxHeight: '70vh',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    whiteBg: {
      backgroundColor: theme.palette.common.white,
    },
  })
);

interface LayerGroupListProps {
  layerGroupId: string;
  nestedLevel: number;
  key?: string;
}

function LayerGroupList({ layerGroupId, nestedLevel }: LayerGroupListProps): JSX.Element {
  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const layerGroups = useAppSelector((state) => state.circuit.present.layers.layerGroups);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const layerGroup = layerGroups[layerGroupId];
  const children: (LayerData | LayerGroupData)[] = useMemo(() => {
    return layerGroup.children.map((childId) => {
      return layers[childId] || layerGroups[childId];
    });
  }, [layerGroup.children, layerGroups, layers]);

  const handleRemoveChildToLayerGroup = useCallback(
    (layerGroupId: string, childId: string) => {
      const layerGroup = layerGroups[layerGroupId];

      if (layerGroup && layerGroup.children) {
        const newChildren = layerGroup.children.filter((child) => child !== childId);

        dispatch(
          updateLayerGroupAction({
            layerGroupId,
            children: newChildren,
            userAction: true,
          })
        );
      }
    },
    [dispatch, layerGroups]
  );

  return (
    <>
      {children.map((child, index) => {
        if (!child || !child.id || !child.name) return <span key={`no-child_${index}`}></span>;

        return (
          <span key={`${child.id}_${nestedLevel}`}>
            <ListItem
              className={nestedLevel === 0 && index % 2 === 0 ? classes.listItemGray : ''}
              style={{ marginLeft: `${nestedLevel * 20}px` }}
            >
              <ListItemText
                sx={{
                  textDecoration: 'isDraft' in child && child.isDraft ? 'line-through' : 'none',
                }}
              >
                {child.name}
              </ListItemText>
              {nestedLevel === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    title={`Remove ${child.name} from ${layerGroup.name}`}
                    onClick={() => handleRemoveChildToLayerGroup(layerGroup.id, child.id)}
                    size="large"
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {(child as LayerGroupData)?.children && (child as LayerGroupData)?.children.length ? (
              <LayerGroupList layerGroupId={child.id} nestedLevel={nestedLevel + 1} />
            ) : (
              <></>
            )}
          </span>
        );
      })}
    </>
  );
}

interface LayersDialogProps {
  closeDialog?: () => void;
}

export default function LayersDialog({ closeDialog }: LayersDialogProps): JSX.Element {
  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const layerGroups = useAppSelector((state) => state.circuit.present.layers.layerGroups);
  const selectedLayer = useAppSelector((state) => state.circuit.present.layers.selectedLayer);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const standardMode = useAppSelector((state) => state.local.standardMode);

  const [selectedLayerGroup, setSelectedLayerGroup] = useState<string | null>(
    layerGroups && Object.keys(layerGroups).length ? layerGroups[Object.keys(layerGroups)[0]].id : null
  );

  const haveLayerGroupEmpty = useMemo(
    () => Object.values(layerGroups).some((layerGroup: LayerGroupData) => !layerGroup.children.length),
    [layerGroups]
  );

  const handleClose = (): void => {
    dispatch(closeDialogAction());
  };

  const [tab, setTab] = useState(0);
  const handleTabChange = useCallback((e, newValue) => {
    setTab(newValue);
  }, []);

  const [renamingLayer, setRenamingLayer] = useState<string | null>(null);
  const [layerName, setLayerName] = useState<string | null>(null);
  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');

  const layersName = useMemo(() => {
    const res: string[] = [];
    for (const id in layers) {
      if (id !== renamingLayer) res.push(layers[id].name);
    }

    for (const id in layerGroups) {
      if (id !== renamingLayer) res.push(layerGroups[id].name);
    }

    return res;
  }, [layerGroups, layers, renamingLayer]);
  const handleClickChangeVisibilityLayer = useCallback(
    (id: string) => {
      const newVisibilityState = !layers[id].visibility;

      dispatch(
        updateLayerAction({
          layerId: id,
          visibility: newVisibilityState,
          userAction: true,
        })
      );
    },
    [dispatch, layers]
  );
  const handleClickDeleteLayer = useCallback(
    (layerId: string) => {
      const layer = layers[layerId];

      confirm({
        title: 'Layer deletion',
        description: `Are you sure you want to delete the layer ${layer.name} as well as all its shapes?`,
        allowClose: false,
      })
        .then(() => {
          if (selectedLayer === layerId) {
            const newSelectedLayerId = Object.keys(layers).find((id) => id !== layerId);
            if (newSelectedLayerId) {
              dispatch(
                updateLayerAction({
                  layerId: newSelectedLayerId,
                  selectedLayer: newSelectedLayerId,
                  userAction: true,
                })
              );
            }
          }

          const CurrentLayerVisibility = layers[layerId].visibility;
          const currentLayerName = layers[layerId].name;
          if (Object.keys(layers).length > 1) {
            dispatch(
              deleteLayerAction({
                layerId,
                visibility: CurrentLayerVisibility,
                name: currentLayerName,
              })
            );
          }
        })
        .catch(() => undefined);
    },
    [confirm, dispatch, layers, selectedLayer]
  );
  const handleClickDeleteLayerGroup = useCallback(
    (layerGroupId: string) => {
      const layerGroup = layerGroups[layerGroupId];
      if (!layerGroup) return;

      confirm({
        title: 'Layer group deletion',
        description: `Are you sure you want to delete ${layerGroup.name}?`,
        allowClose: false,
      })
        .then(() => {
          if (selectedLayerGroup === layerGroupId) {
            setSelectedLayerGroup(null);
          }

          dispatch(deleteLayerGroupAction({ layerGroupId, userAction: true }));
        })
        .catch(() => undefined);
    },
    [confirm, dispatch, layerGroups, selectedLayerGroup]
  );

  const handleAddLayer = useCallback(
    (e): void => {
      let name: string;
      do {
        name = CircuitService.generateLayerName();
      } while (layersName.includes(name));

      dispatch(
        addLayerAction({
          name,
          visibility: true,
          isDraft: false,
          color: balyoGradient[Math.floor(Math.random() * balyoGradient.length)],
          userAction: true,
          id: generateShapeId(),
        })
      );
    },
    [dispatch, layersName]
  );
  const handleAddLayerGroup = useCallback(
    (e): void => {
      let name: string;
      let n = 0;
      do {
        name = `LayerGroup-${++n}`;
      } while (layersName.includes(name));

      dispatch(
        addLayerGroupAction({
          name,
          userAction: true,
          id: generateShapeId(),
        })
      );
    },
    [dispatch, layersName]
  );

  const handleColorChange = useCallback(
    (color: string, layerId: string) => {
      dispatch(
        updateLayerAction({
          layerId,
          color,
          userAction: true,
        })
      );
    },
    [dispatch]
  );

  const handleDraftChangeLayer = useCallback(
    (layerId: string) => {
      dispatch(
        updateLayerAction({
          layerId,
          isDraft: !layers[layerId].isDraft,
          userAction: true,
        })
      );
    },
    [dispatch, layers]
  );

  const onLayerNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      if (newName?.length < 50) {
        setLayerName(newName);
      }

      if (layersName.includes(newName)) {
        setErrorName(true);
        setSuggestedName(CircuitService.generateLayerName());
      } else if (!newName) {
        setErrorName(true);
        setSuggestedName('');
      } else {
        setErrorName(false);
      }
    },
    [layersName]
  );

  const handleSaveLayerName = useCallback(
    (layerId: string) => {
      if (errorName) return;

      if (layerName) {
        dispatch(
          updateLayerAction({
            layerId,
            name: layerName,
            userAction: true,
          })
        );

        setRenamingLayer(null);
      }
    },
    [dispatch, errorName, layerName]
  );

  const handleSaveLayerGroupName = useCallback(
    (layerGroupId: string) => {
      if (errorName) return;

      if (layerName) {
        dispatch(
          updateLayerGroupAction({
            layerGroupId,
            name: layerName,
            userAction: true,
          })
        );

        setRenamingLayer(null);
      }
    },
    [dispatch, errorName, layerName]
  );

  const handleAddChildToLayerGroup = useCallback(
    (layerGroupId: string, childId: string) => {
      const layerGroup = layerGroups[layerGroupId];
      // add some check for the infinitie loop here
      const childIdExists = Object.keys(layerGroups).includes(childId) || Object.keys(layers).includes(childId);

      if (layerGroup && childIdExists && couldElementBeAChildOfLayerGroup(layerGroupId, childId, layerGroups)) {
        dispatch(
          updateLayerGroupAction({
            layerGroupId,
            children: [...layerGroup.children, childId],
            userAction: true,
          })
        );
      }
    },
    [dispatch, layerGroups, layers]
  );

  const changerLayerOrder = useCallback(
    (layerId: string, changeIndex: number) => {
      dispatch(
        changeLayerOrderAction({
          layerId,
          changeIndex,
        })
      );
    },
    [dispatch]
  );

  const handleInitializeLegacyLayerGroups = useCallback(() => {
    if (!PreferencesService.arePreferencesFullyLoaded()) {
      // eslint-disable-next-line no-console
      console.error(`Preferences are not loaded yet, cannot initialize legacy layer groups`);

      return;
    }

    initializeLegacyLayerGroups();
  }, []);

  //To hide the more options tooltip when the more options popup is open
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="md" onClose={handleClose}>
      <Paper>
        <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
          <Tab label="Layers" />
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems={'center'}>
                <span>Layer Groups</span>
                {haveLayerGroupEmpty && (
                  <div>
                    <Tooltip arrow title="At least one layer group is empty">
                      <span>
                        <BlinkingWarningIcon />
                      </span>
                    </Tooltip>
                  </div>
                )}
              </Stack>
            }
          />
        </Tabs>
      </Paper>

      {tab === 0 && (
        <>
          <DialogContent>
            <List>
              {Object.values(layers)
                .sort(sortLayersByOrder)
                .map((layer, index) => (
                  <ListItem
                    key={layer.id}
                    className={index % 2 === 0 ? classes.listItemGray : ''}
                    style={{ boxShadow: `inset 3px 0px 0px 0px ${layer.color}` }}
                    selected={layer.id === selectedLayer}
                    data-testid="layer-item"
                  >
                    <ListItemText
                      primary={
                        renamingLayer === layer.id ? (
                          <>
                            <TextField
                              id="input-newname-layer"
                              error={errorName}
                              value={layerName}
                              helperText={errorName && suggestedName ? 'Name already used' : ''}
                              onChange={onLayerNameChangeHandler}
                              classes={{ root: clsx(classes.verticalAlignMiddle) }}
                              data-testid="input-newname-layer"
                              variant="standard"
                            />
                            <IconButton
                              disabled={errorName}
                              onClick={() => handleSaveLayerName(layer.id)}
                              aria-label="Validate new layer name"
                              size="large"
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton onClick={() => setRenamingLayer(null)} size="large">
                              <ClearIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Tooltip title="Move up the layer">
                              <>
                                <IconButton
                                  disabled={index === 0}
                                  className={classes.editNameIcon}
                                  onClick={() => changerLayerOrder(layer.id, -1)}
                                  aria-label="Move up the layer"
                                  size="large"
                                >
                                  <KeyboardArrowUpIcon />
                                </IconButton>
                              </>
                            </Tooltip>
                            <Tooltip title="Move down the layer">
                              <>
                                <IconButton
                                  disabled={index === Object.values(layers).length - 1}
                                  className={classes.editNameIcon}
                                  onClick={() => changerLayerOrder(layer.id, +1)}
                                  aria-label="Move down the layer"
                                  size="large"
                                >
                                  <KeyboardArrowDownIcon />
                                </IconButton>
                              </>
                            </Tooltip>
                            <span className={layer.isDraft ? classes.lineThrough : undefined}>{layer.name}</span>{' '}
                            <Tooltip title="Rename">
                              <IconButton
                                className={classes.editNameIcon}
                                onClick={() => {
                                  setLayerName(layer.name);
                                  setRenamingLayer(layer.id);
                                }}
                                aria-label="Rename layer"
                                size="large"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )
                      }
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title={layer.visibility ? 'Hide' : 'Show'}>
                        <IconButton onClick={() => handleClickChangeVisibilityLayer(layer.id)} size="large">
                          {layer.visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={layer.isDraft ? 'Make it real' : 'Change to draft'}>
                        <IconButton onClick={() => handleDraftChangeLayer(layer.id)} size="large">
                          {layer.isDraft ? (
                            <InsertDriveFileIcon classes={{ root: clsx(classes.draftIcon) }} />
                          ) : (
                            <InsertDriveFileIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleClickDeleteLayer(layer.id)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Pick a new color">
                        <span>
                          <ColorPicker
                            defaultColor={layer.color}
                            onChangeFinished={(color) => handleColorChange(color, layer.id)}
                          />
                        </span>
                      </Tooltip>
                      <Tooltip title="Clean up excess segment lengths">
                        <span>
                          <CleanUpExcessSegmentLengths layerId={layer.id} layerName={layer.name} />
                        </span>
                      </Tooltip>
                      <Tooltip title={isMoreOptionsOpen ? '' : 'More options'}>
                        <span>
                          <GabaritSelectLayer layer={layer} setIsMoreOptionsOpen={setIsMoreOptionsOpen} />
                        </span>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions className={classes.noPaddingTop}>
            <Button onClick={handleClose} color="primary" variant="outlined" endIcon={<CloseIcon />}>
              Close
            </Button>
            <Button onClick={handleAddLayer} variant="outlined" endIcon={<LibraryAddIcon />}>
              Add Layer
            </Button>
          </DialogActions>
        </>
      )}

      {tab === 1 && (
        <>
          <DialogContent>
            <Grid container>
              <Grid item xs={4}>
                <List
                  className={classes.scrollableListLayerGroups}
                  subheader={<ListSubheader className={classes.whiteBg}>Layer groups</ListSubheader>}
                >
                  {Object.values(layerGroups).map((layerGroup, index) => (
                    <ListItem
                      key={layerGroup.id}
                      className={index % 2 === 0 ? classes.listItemGray : ''}
                      selected={layerGroup.id === selectedLayerGroup}
                      button
                      onClick={() => setSelectedLayerGroup(layerGroup.id)}
                      data-testid="layer-group-item"
                    >
                      <ListItemText
                        primary={
                          renamingLayer === layerGroup.id ? (
                            <>
                              <TextField
                                id="input-newname-layer"
                                error={errorName}
                                value={layerName}
                                helperText={errorName && suggestedName ? 'Name already used' : ''}
                                onChange={onLayerNameChangeHandler}
                                classes={{ root: clsx(classes.verticalAlignMiddle) }}
                                variant="standard"
                              />
                              <IconButton
                                disabled={errorName}
                                onClick={() => handleSaveLayerGroupName(layerGroup.id)}
                                size="large"
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton onClick={() => setRenamingLayer(null)} size="large">
                                <ClearIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              {layerGroup.name}
                              <Tooltip title="Rename">
                                <IconButton
                                  className={classes.editNameIcon}
                                  onClick={() => {
                                    setLayerName(layerGroup.name);
                                    setRenamingLayer(layerGroup.id);
                                  }}
                                  size="large"
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )
                        }
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleClickDeleteLayerGroup(layerGroup.id)} size="large">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  borderRight: '3px solid rgba(0, 0, 0, 0.1)',
                  borderLeft: '3px solid rgba(0, 0, 0, 0.1)',
                }}
              >
                {selectedLayerGroup && layerGroups[selectedLayerGroup] && (
                  <List
                    subheader={
                      <ListSubheader>Layer group bucket - {layerGroups[selectedLayerGroup].name}</ListSubheader>
                    }
                    className={classes.scrollableListLayerGroups}
                  >
                    <LayerGroupList key={`${selectedLayerGroup}_0`} layerGroupId={selectedLayerGroup} nestedLevel={0} />
                  </List>
                )}
              </Grid>
              <Grid item xs={4}>
                <List
                  subheader={<ListSubheader className={classes.whiteBg}>Available layers</ListSubheader>}
                  className={classes.scrollableList}
                >
                  {Object.values(layers).map((layer, index) => (
                    <ListItem key={layer.id} className={index % 2 === 0 ? classes.listItemGray : ''}>
                      <ListItemText
                        sx={{
                          textDecoration: layer.isDraft ? 'line-through' : undefined,
                        }}
                      >
                        {layer.name}
                      </ListItemText>
                      {selectedLayerGroup && (
                        <ListItemSecondaryAction>
                          <IconButton
                            title={`Add ${layer.name} to ${layerGroups[selectedLayerGroup]?.name}`}
                            onClick={() => handleAddChildToLayerGroup(selectedLayerGroup, layer.id)}
                            disabled={!couldElementBeAChildOfLayerGroup(selectedLayerGroup, layer.id, layerGroups)}
                            size="large"
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
                </List>
                <List
                  subheader={<ListSubheader className={classes.whiteBg}>Available layer groups</ListSubheader>}
                  className={classes.scrollableList}
                >
                  {Object.values(layerGroups).map((layerGroup, index) => (
                    <ListItem key={layerGroup.id} className={index % 2 === 0 ? classes.listItemGray : ''}>
                      <ListItemText>{layerGroup.name}</ListItemText>
                      {selectedLayerGroup && (
                        <ListItemSecondaryAction>
                          <IconButton
                            title={`Add ${layerGroup.name} to ${layerGroups[selectedLayerGroup]?.name}`}
                            onClick={() => handleAddChildToLayerGroup(selectedLayerGroup, layerGroup.id)}
                            disabled={!couldElementBeAChildOfLayerGroup(selectedLayerGroup, layerGroup.id, layerGroups)}
                            size="large"
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.noPaddingTop}>
            {standardMode && (
              <Tooltip title="The layer groups will be named after the robot models defined in your project. This is needed when exporting in standard mode.">
                <Button
                  variant="outlined"
                  endIcon={<DynamicFeedIcon />}
                  disabled={!PreferencesService.arePreferencesFullyLoaded()}
                  onClick={handleInitializeLegacyLayerGroups}
                >
                  Initialize Legacy Layer Groups
                </Button>
              </Tooltip>
            )}

            <Button onClick={handleClose} variant="outlined" endIcon={<CloseIcon />}>
              Close
            </Button>
            <Button onClick={handleAddLayerGroup} variant="outlined" endIcon={<LibraryAddIcon />}>
              Add Layer Group
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

/**
 * Test whether an element (id) is a child of a layer group (recursive)
 * @param layerGroupId parent id to test if it has the child
 * @param childId
 * @param layerGroups
 * @returns true or false :)
 */
function isItDeepChild(layerGroupId: string, childId: string, layerGroups: LayerGroupDataObject, deep = true): boolean {
  const layerGroup = layerGroups[layerGroupId];

  if (!layerGroup) return false;

  const isItDirectChild = layerGroup.children.includes(childId);

  if (isItDirectChild) return true;

  if (!deep) return isItDirectChild;

  return layerGroup.children.some((nestedChildId) => isItDeepChild(nestedChildId, childId, layerGroups));
}

/**
 * Test whether we consider if a element could be a child of a layer group, i.e. it will not lead to a circular reference
 * @param layerGroupId
 * @param childId
 * @param layerGroups
 * @returns
 */
function couldElementBeAChildOfLayerGroup(
  layerGroupId: string,
  childId: string,
  layerGroups: LayerGroupDataObject
): boolean {
  const notSameLayerGroup = layerGroupId !== childId; // a layer group cannot contained itself
  const isItAlreadyAChild = isItDeepChild(layerGroupId, childId, layerGroups, false);

  if (!notSameLayerGroup || isItAlreadyAChild) return false;

  if (!layerGroups[childId] || !layerGroups[childId].children.length) return true;

  const children = layerGroups[childId].children;

  return children.every((nestedChildId) =>
    layerGroups[nestedChildId] ? couldElementBeAChildOfLayerGroup(childId, nestedChildId, layerGroups) : true
  );
}
