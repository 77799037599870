import { awareness, remoteDoc } from 'multiplayer/globals';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useUsers } from 'y-presence';

export function useDisconnectedUserDataCleanup(): void {
  if (!awareness) throw new Error('Missing awareness');

  const size = useUsers(
    awareness,
    (state) => state.size,
    (prev, next) => prev === next
  );
  const prevSizeRef = useRef(size);

  const users = useUsers(awareness);
  const cleanupDisconnectedUserData = useCallback(() => {
    if (!remoteDoc) return;

    const profiles = remoteDoc.getMap('profiles');
    const selectedShapes = remoteDoc.getMap('selectedShapes');

    const usersToRemove = new Set<string>();

    profiles.forEach((_, key) => {
      if (!users.has(+key)) usersToRemove.add(key);
    });

    selectedShapes.forEach((_, key) => {
      if (!users.has(+key)) usersToRemove.add(key);
    });

    usersToRemove.forEach((key) => {
      profiles.delete(key);
      selectedShapes.delete(key);
    });
  }, [users]);

  useLayoutEffect(() => {
    if (prevSizeRef.current > size) {
      cleanupDisconnectedUserData();
    }

    prevSizeRef.current = size;
  }, [cleanupDisconnectedUserData, size]);
}
