import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { isActionNode, isFolderNode } from 'components/core/project-settings/RMI-actions/actions.guard';
import type { ActionNode } from 'models/action';
import { CustomLabel, CustomTreeItem } from './custom-tree-item';

interface TreeRendererProps {
  nodes: ActionNode[] | undefined;
  handleNodeClick: (node: ActionNode) => void;
  handleNodeEditClick?: (node: ActionNode) => void;
}

export default function TreeRenderer({
  nodes,
  handleNodeClick,
  handleNodeEditClick,
}: TreeRendererProps): JSX.Element | null {
  if (!nodes) return null;

  return (
    <>
      {nodes.map((node) => {
        // finding if folder vs. action using type guards:
        if (isFolderNode(node)) {
          // It's a FOLDER
          const hasChildren = node.nodes && node.nodes.length > 0;

          return (
            <CustomTreeItem
              key={node.id}
              itemId={node.id}
              label={
                <CustomLabel icon={FolderIcon} expandable={hasChildren}>
                  {node.displayName}
                </CustomLabel>
              }
              onClick={() => handleNodeClick(node)}
              slots={{
                expandIcon: hasChildren ? KeyboardArrowRightIcon : undefined,
                collapseIcon: hasChildren ? KeyboardArrowDownIcon : undefined,
              }}
            >
              {hasChildren && (
                <TreeRenderer
                  nodes={node.nodes}
                  handleNodeClick={handleNodeClick}
                  handleNodeEditClick={handleNodeEditClick}
                />
              )}
            </CustomTreeItem>
          );
        } else if (isActionNode(node)) {
          // It's an ACTION
          // Action nodes don’t have children  but we can still check for safety:
          const hasChildren = false;

          return (
            <CustomTreeItem
              key={node.id}
              itemId={node.id}
              label={
                <CustomLabel icon={TouchAppIcon} expandable={hasChildren}>
                  {node.displayName}
                </CustomLabel>
              }
              onClick={() => handleNodeClick(node)}
              slots={{
                expandIcon: hasChildren ? KeyboardArrowRightIcon : undefined,
                collapseIcon: hasChildren ? KeyboardArrowDownIcon : undefined,
              }}
            ></CustomTreeItem>
          );
        }

        return null;
      })}
    </>
  );
}
