import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ReplayTrigger, Trigger } from 'models/simulation';

export const defaultIntervalTriggerInterval = 5 * 60; // seconds
export const defaultSizeTriggerBuffer = 10; // tasks

export interface TriggersSliceState {
  /** list of triggers */
  triggers: Trigger[];

  /** Map of enabled triggers by id key */
  enabledTriggers: { [id: string]: boolean };

  /** id of the selected trigger */
  selectedTriggerId: string | undefined;
}

export const triggersInitialState: TriggersSliceState = {
  triggers: [],
  enabledTriggers: {},
  selectedTriggerId: undefined,
};

const triggersSliceLocal = createSlice({
  initialState: triggersInitialState,
  name: 'triggers',
  reducers: {
    addTrigger: (state, action: PayloadAction<Trigger>) => {
      const trigger = action.payload;
      state.triggers.push(trigger);

      if (state.enabledTriggers[trigger.id]) return;

      state.enabledTriggers[trigger.id] = true;
      state.selectedTriggerId = trigger.id;
    },
    addTriggerFromYJS: (state, action: PayloadAction<Trigger>) => {
      const trigger = action.payload;
      state.triggers.push(trigger);

      if (state.enabledTriggers[trigger.id]) return;

      state.enabledTriggers[trigger.id] = false;
    },
    selectTrigger: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTriggerId = action.payload;
    },
    setTrigger: (state, action: PayloadAction<Trigger>) => {
      const index = state.triggers.findIndex((t) => t.id === action.payload.id);
      state.triggers[index] = action.payload;
    },
    setTriggerFromYJS: (state, action: PayloadAction<Trigger>) => {
      triggersSliceLocal.caseReducers.setTrigger(state, action);
    },
    changeEnableStateTrigger: (state, action: PayloadAction<{ id: string; enabled: boolean }>) => {
      const { enabled, id } = action.payload;
      state.enabledTriggers[id] = enabled;
    },
    setEnableStateAllTriggers: (state, action: PayloadAction<{ enabled: boolean; triggerIds?: string[] }>) => {
      const { enabled, triggerIds } = action.payload;

      state.triggers.forEach((trigger) => {
        if (!triggerIds || triggerIds.includes(trigger.id)) {
          state.enabledTriggers[trigger.id] = enabled;
        }
      });
    },
    setTriggers: (state, action: PayloadAction<Trigger[]>) => {
      const triggers = action.payload;
      state.triggers = triggers;

      state.enabledTriggers = triggers.reduce(
        (acc, trigger) => {
          acc[trigger.id] = 'enabled' in trigger && typeof trigger.enabled === 'boolean' ? trigger.enabled : false;

          return acc;
        },
        {} as { [id: string]: boolean }
      );
    },
    setTriggerName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const index = state.triggers.findIndex((t) => t.id === action.payload.id);
      state.triggers[index].name = action.payload.name;
    },
    removeTrigger: (state, action: PayloadAction<string>) => {
      state.triggers = state.triggers.filter((t) => t.id !== action.payload);

      delete state.enabledTriggers[action.payload];
    },
    removeTriggerFromYJS: (state, action: PayloadAction<string>) => {
      triggersSliceLocal.caseReducers.removeTrigger(state, action);
    },
    removeTriggers: (state, action: PayloadAction<string[]>) => {
      const triggerIdsToRemove = new Set(action.payload);

      state.triggers = state.triggers.filter((t) => !triggerIdsToRemove.has(t.id));

      triggerIdsToRemove.forEach((id) => delete state.enabledTriggers[id]);
    },
    removeTaskFromReplayTrigger: (state, action: PayloadAction<{ triggerId: string; taskIndex: number }>) => {
      const taskIndex = action.payload.taskIndex;
      const trigger = state.triggers.find((t) => t.id === action.payload.triggerId);
      if (trigger && trigger.type === 'replay' && 'tasks' in trigger) {
        trigger.tasks = trigger.tasks.filter((_, index) => index !== taskIndex);
      }
    },
    addTasksToReplayTrigger: (state, action: PayloadAction<{ triggerId: string; tasks: ReplayTrigger['tasks'] }>) => {
      const trigger = state.triggers.find((t) => t.id === action.payload.triggerId);
      if (trigger && trigger.type === 'replay' && 'tasks' in trigger) {
        trigger.tasks.push(...action.payload.tasks);
      }
    },
  },
});

export const triggersSlice = {
  reducer: triggersSliceLocal.reducer,
};

export const {
  addTrigger,
  selectTrigger,
  setTrigger,
  changeEnableStateTrigger,
  setTriggers,
  setTriggerName,
  removeTrigger,
  removeTaskFromReplayTrigger,
  addTasksToReplayTrigger,
  setEnableStateAllTriggers,
  removeTriggers,
  addTriggerFromYJS,
  removeTriggerFromYJS,
  setTriggerFromYJS,
} = triggersSliceLocal.actions;
