import type { CircuitDevice, DeviceType } from 'models/circuit';
import { setStopSendingInstallXML } from 'multiplayer/globals';
import type { LayersDataObject } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import { devicePositionToCpxStr } from 'utils/circuit';
import { MIN_VERSION_NEW_EXPORT_DEVICES, frequencyDefaultValue } from 'utils/config';
import { getDeviceSamplePeriodPrefName } from 'utils/device';
import { prettifyXml } from 'utils/export/prettify-xml';
import { PreferencesService, minimumSDKVersionComboxGen2Export, writeToFile } from 'utils/preferences';
import { isDefined } from 'utils/ts/is-defined';
import { versionCompare } from 'utils/version-compare';

interface ExportDevicesProps {
  devices: CircuitDevice[];
  layers: LayersDataObject;
  xml: Document | undefined;
}

interface ExportDevicesReturn {
  xml: Document | undefined;
}

export async function exportDevices({ devices, layers, xml }: ExportDevicesProps): Promise<ExportDevicesReturn> {
  setStopSendingInstallXML(true);

  const new4xVersion = PreferencesService.getNEW4XCoreVersion();
  if (new4xVersion && new4xVersion >= MIN_VERSION_NEW_EXPORT_DEVICES) {
    const deviceSamplePeriodPrefName = getDeviceSamplePeriodPrefName();
    // eslint-disable-next-line no-console
    console.time('Update Devices Preferences');

    // we don't want to export devices that are in draft layer
    const devicesToExport = (devices ?? []).filter((device) => {
      const layer = layers[device.properties.layerId];

      return !layer.isDraft;
    });

    // the 3BT Comboxes
    const comboxes3BT = (devicesToExport ?? []).filter((device) => device.properties.deviceType === '3BTCombox');
    if (comboxes3BT.length) {
      const names = comboxes3BT.map((combox) => combox.properties.name);
      const displayNames = comboxes3BT.map((combox) => combox.properties.displayName);
      const ips = comboxes3BT.map((combox) => combox.properties.IP);
      const frequencies = comboxes3BT.map((combox) =>
        combox.properties.frequency ? combox.properties.frequency.toString() : frequencyDefaultValue
      );
      const positions = comboxes3BT.map((combox) => devicePositionToCpxStr(combox));

      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/3BTComboxes/name',
        names,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/3BTComboxes/displayName',
        displayNames,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue('devices/3BTComboxes/IP', ips, true, 'pref', false, xml);

      [, xml] = await PreferencesService.setPreferenceValue(
        `devices/3BTComboxes/${deviceSamplePeriodPrefName}`,
        frequencies,
        true,
        'pref',
        false,
        xml
      );

      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/3BTComboxes/position',
        positions,
        true,
        'pref',
        true,
        xml
      );
    } else {
      [, xml] = await PreferencesService.setPreferenceValue('devices/3BTComboxes', '', false, 'category', true, xml);
    }

    // the IOEComboxes
    const comboxesIOE = (devicesToExport ?? []).filter((device) => device.properties.deviceType === 'IOECombox');
    if (comboxesIOE.length) {
      const names = comboxesIOE.map((combox) => combox.properties.name);
      const displayNames = comboxesIOE.map((combox) => combox.properties.displayName);
      const ips = comboxesIOE.map((combox) => combox.properties.IP);
      const frequencies = comboxesIOE.map((combox) =>
        combox.properties.frequency ? combox.properties.frequency.toString() : frequencyDefaultValue
      );

      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/IOEComboxes/name',
        names,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/IOEComboxes/displayName',
        displayNames,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue('devices/IOEComboxes/IP', ips, true, 'pref', false, xml);
      [, xml] = await PreferencesService.setPreferenceValue(
        `devices/IOEComboxes/${deviceSamplePeriodPrefName}`,
        frequencies,
        true,
        'pref',
        true,
        xml
      );
    } else {
      [, xml] = await PreferencesService.setPreferenceValue('devices/IOEComboxes', '', false, 'category', true, xml);
    }

    // the mobilde docking stations
    const mobileDockingStations = (devicesToExport ?? []).filter(
      (device) => device.properties.deviceType === 'mobileDockingStation'
    );
    if (mobileDockingStations.length) {
      const names = mobileDockingStations.map((combox) => combox.properties.name);
      const displayNames = mobileDockingStations.map((combox) => combox.properties.displayName);
      const ips = mobileDockingStations.map((combox) => combox.properties.IP);
      const frequencies = mobileDockingStations.map((combox) =>
        combox.properties.frequency ? combox.properties.frequency.toString() : frequencyDefaultValue
      );

      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/mobileDockingStations/name',
        names,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/mobileDockingStations/displayName',
        displayNames,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/mobileDockingStations/IP',
        ips,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        `devices/mobileDockingStations/${deviceSamplePeriodPrefName}`,
        frequencies,
        true,
        'pref',
        true,
        xml
      );
    } else {
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/mobileDockingStations',
        '',
        false,
        'category',
        true,
        xml
      );
    }

    // the smart chargers
    const smartChargers = (devicesToExport ?? []).filter((device) => device.properties.deviceType === 'smartCharger');
    if (smartChargers.length) {
      const names = smartChargers.map((combox) => combox.properties.name);
      const displayNames = smartChargers.map((combox) => combox.properties.displayName);
      const ips = smartChargers.map((combox) => combox.properties.IP);
      const frequencies = smartChargers.map((combox) =>
        combox.properties.frequency ? combox.properties.frequency.toString() : frequencyDefaultValue
      );

      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/smartChargers/name',
        names,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/smartChargers/displayName',
        displayNames,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue('devices/smartChargers/IP', ips, true, 'pref', false, xml);
      [, xml] = await PreferencesService.setPreferenceValue(
        `devices/smartChargers/${deviceSamplePeriodPrefName}`,
        frequencies,
        true,
        'pref',
        true,
        xml
      );
    } else {
      [, xml] = await PreferencesService.setPreferenceValue('devices/smartChargers', '', false, 'category', true, xml);
    }

    const portDefaultValue = '502';
    // the modbus devices
    const modbusDeviceDefaultPort = portDefaultValue;
    const modbusDevices = (devicesToExport ?? []).filter((device) => device.properties.deviceType === 'modbusDevice');
    if (modbusDevices.length) {
      const names = modbusDevices.map((combox) => combox.properties.name);
      const displayNames = modbusDevices.map((combox) => combox.properties.displayName);
      const ports = modbusDevices.map((combox) =>
        combox.properties.port ? combox.properties.port.toString() : modbusDeviceDefaultPort
      );
      const ips = modbusDevices.map((combox) => combox.properties.IP);
      const frequencies = modbusDevices.map((combox) =>
        combox.properties.frequency ? combox.properties.frequency.toString() : frequencyDefaultValue
      );

      const ioCountValues = modbusDevices.map((combox) => {
        let ioCount = '[0,0,0,0]';
        if (combox.properties.modbusType === 'Register') {
          ioCount = `[0,0,${combox.properties.pinsIn?.length},${combox.properties.pinsOut?.length}]`;
        } else if (combox.properties.modbusType === 'Bit') {
          ioCount = `[${combox.properties.pinsIn?.length},${combox.properties.pinsOut?.length},0,0]`;
        }

        return ioCount;
      });

      const ioAddresses = modbusDevices.map((combox) => {
        return combox.properties && combox.properties.ioAddresses
          ? `[${combox.properties.ioAddresses[0]},${combox.properties.ioAddresses[1]},${combox.properties.ioAddresses[2]},${combox.properties.ioAddresses[3]}]`
          : '[0,0,0,0]';
      });

      const keepWritingOutputs = modbusDevices.map((combox) => (combox.properties.keepWritingOutputs ? '1' : '0'));

      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/modbusDevices/name',
        names,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/modbusDevices/displayName',
        displayNames,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/modbusDevices/port',
        ports,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue('devices/modbusDevices/IP', ips, true, 'pref', false, xml);
      [, xml] = await PreferencesService.setPreferenceValue(
        `devices/modbusDevices/${deviceSamplePeriodPrefName}`,
        frequencies,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/modbusDevices/ioCount',
        ioCountValues,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/modbusDevices/ioAddresses',
        ioAddresses,
        true,
        'pref',
        false,
        xml
      );
      [, xml] = await PreferencesService.setPreferenceValue(
        'devices/modbusDevices/keepWritingOutputs',
        keepWritingOutputs,
        true,
        'pref',
        true,
        xml
      );
    } else {
      [, xml] = await PreferencesService.setPreferenceValue('devices/modbusDevices', '', false, 'category', true, xml);
    }

    const comboxGen2Types: DeviceType[] = ['comboxGen2'];

    const comboxGen2Unsorted = (devicesToExport ?? []).filter(
      (device) => comboxGen2Types.includes(device.properties.deviceType) && device.properties.network !== 'Standalone'
    );

    const comboxGen2Gateways = comboxGen2Unsorted.filter((device) => device.properties.network === 'Gateway');
    const comboxGen2Devices = comboxGen2Unsorted.filter((device) => device.properties.network === 'Device');

    const comboxGen2 = comboxGen2Gateways.flatMap((gateway) => {
      const gatewayDevices = comboxGen2Devices.filter((device) => device.properties.gateway === gateway.id);

      return [gateway, ...gatewayDevices];
    });

    const unexportedComboxGen2 = comboxGen2Unsorted.filter((device) => !comboxGen2.includes(device));
    if (unexportedComboxGen2.length > 0) {
      // eslint-disable-next-line no-console
      console.warn('The following comboxGen2 devices have not been exported:', unexportedComboxGen2);
      SnackbarUtils.warning(
        `Some comboxGen2 devices have not been exported because they are not part of a gateway/device pair. (${unexportedComboxGen2
          .map((device) => device.properties.name)
          .join(', ')})`
      );
    }

    const sdkVersion = PreferencesService.getSDKVersion() || '0.0.0';
    if (versionCompare(sdkVersion, minimumSDKVersionComboxGen2Export) >= 0) {
      if (comboxGen2.length) {
        const names = comboxGen2.map((combox) => combox.properties.name);
        const displayNames = comboxGen2.map((combox) => combox.properties.displayName);
        const ips = comboxGen2.map((combox) => combox.properties.IP);
        const frequencies = comboxGen2.map((combox) =>
          combox.properties.frequency ? combox.properties.frequency.toString() : frequencyDefaultValue
        );

        const comboxVersionsWithLoraId: string[] = ['ComboxGen2Standard', 'ComboxGen2Extended', 'ComboxGen2Button'];
        const loraId = comboxGen2
          .map((combox) => {
            if (comboxVersionsWithLoraId.includes(combox.properties.deviceType)) {
              return '0';
            }

            return combox.properties.loraID;
          })
          .filter(isDefined);

        const hardware = comboxGen2
          .map((combox) => {
            const comboxType = combox.properties.deviceType;
            const comboxTypeFirstLetterUppercaseForXml = comboxType.charAt(0).toUpperCase() + comboxType.slice(1);
            const comboxVersion = combox.properties.comboxVersion;
            const network = combox.properties.network;
            const hardware = `${comboxTypeFirstLetterUppercaseForXml}${comboxVersion}${network}`;

            return hardware;
          })
          .filter(isDefined);

        const positions = comboxGen2.map((combox) => devicePositionToCpxStr(combox));

        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2/name',
          names,
          true,
          'pref',
          false,
          xml
        );

        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2/loraID',
          loraId,
          true,
          'pref',
          false,
          xml
        );

        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2/displayName',
          displayNames,
          true,
          'pref',
          false,
          xml
        );

        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2/hardware',
          hardware,
          true,
          'pref',
          false,
          xml
        );

        [, xml] = await PreferencesService.setPreferenceValue('devices/comboxGen2/IP', ips, true, 'pref', false, xml);
        [, xml] = await PreferencesService.setPreferenceValue(
          `devices/comboxGen2/${deviceSamplePeriodPrefName}`,
          frequencies,
          true,
          'pref',
          true,
          xml
        );
        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2/position',
          positions,
          true,
          'pref',
          true,
          xml
        );
      } else {
        [, xml] = await PreferencesService.setPreferenceValue('devices/comboxGen2', '', false, 'category', true, xml);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(`ComboxGen2 devices not exported because the SDK version is too old`, {
        sdkVersion,
        minimumSDKVersionComboxGen2Export,
      });
    }

    // the comboxGen2Standalone devices
    const comboxGen2StandaloneBaseOnNetwork = ['Standalone'];
    const comboxGen2Standalone = (devicesToExport ?? []).filter((device) =>
      comboxGen2StandaloneBaseOnNetwork.includes(device.properties.network as string)
    );
    if (versionCompare(sdkVersion, minimumSDKVersionComboxGen2Export) >= 0) {
      if (comboxGen2Standalone.length) {
        const possibleHardware = ['ModbusTCPComboxADAM6050', 'ModbusTCPComboxADAM6060'];
        const comboxStandaloneButton = 'Button';
        // we check if comboxVersion of the comboxGen2Standalone is Button or not, if its button then the hardware is ModbusTCPComboxADAM6050 and if its not then the hardware is ModbusTCPComboxADAM6060
        const hardware = comboxGen2Standalone.map((combox) =>
          combox.properties.comboxVersion === comboxStandaloneButton ? possibleHardware[0] : possibleHardware[1]
        );

        const names = comboxGen2Standalone.map((combox) => combox.properties.name);
        const displayNames = comboxGen2Standalone.map((combox) => combox.properties.displayName);
        const ips = comboxGen2Standalone.map((combox) => combox.properties.IP);
        const frequencies = comboxGen2Standalone.map((combox) =>
          combox.properties.frequency ? combox.properties.frequency.toString() : frequencyDefaultValue
        );

        const ioCountValues = comboxGen2Standalone.map((combox) => {
          let ioCount = '[0,0,0,0]';

          ioCount = `[${combox.properties.pinsIn?.length},${combox.properties.pinsOut?.length},0,0]`;

          return ioCount;
        });

        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2Standalone/name',
          names,
          true,
          'pref',
          false,
          xml
        );
        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2Standalone/displayName',
          displayNames,
          true,
          'pref',
          false,
          xml
        );
        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2Standalone/IP',
          ips,
          true,
          'pref',
          false,
          xml
        );
        [, xml] = await PreferencesService.setPreferenceValue(
          `devices/comboxGen2Standalone/${deviceSamplePeriodPrefName}`,
          frequencies,
          true,
          'pref',
          false,
          xml
        );
        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2Standalone/ioCount',
          ioCountValues,
          true,
          'pref',
          false,
          xml
        );
        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2Standalone/hardware',
          hardware,
          true,
          'pref',
          true,
          xml
        );
      } else {
        [, xml] = await PreferencesService.setPreferenceValue(
          'devices/comboxGen2Standalone',
          '',
          false,
          'category',
          true,
          xml
        );
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(`ComboxGen2Standalone devices not exported because the SDK version is too old`, {
        sdkVersion,
        minimumSDKVersionComboxGen2Export,
      });
    }

    const serializer = new XMLSerializer();

    const installXmlFileHandle = await PreferencesService.getFileHandleByPath('PREF/install.xml');
    if (installXmlFileHandle && xml) {
      // we prettify the xml document before write it
      const outXmlString = prettifyXml(serializer.serializeToString(xml), {
        removeBlankLines: true,
      });

      // we add a new line before the <Preferences> tag
      const outXmlStringWithNewLine = outXmlString
        .replace('<Preferences ', '\n<Preferences ')
        .replace('></Preferences>', '>\n</Preferences>');

      writeToFile(installXmlFileHandle, outXmlStringWithNewLine);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Error while getting the install.xml file handle`);
    }

    setStopSendingInstallXML(false);

    // eslint-disable-next-line no-console
    console.timeEnd('Update Devices Preferences');
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      `NEW4X Version detected: ${new4xVersion}, minimum version to export the devices is ${MIN_VERSION_NEW_EXPORT_DEVICES}`
    );

    if (devices?.length) {
      const msg =
        new4xVersion === undefined
          ? `The devices have not been exported because the NEW4X version used has not been detected (coreVersion file is missing).`
          : `The devices have not been exported because the NEW4X version used is too old (detected version: ${new4xVersion}, minimum version: ${MIN_VERSION_NEW_EXPORT_DEVICES}).`;
      SnackbarUtils.warning(msg);
    }
  }

  return {
    xml,
  };
}
