import BorderColorIcon from '@mui/icons-material/BorderColor';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import Crop54Icon from '@mui/icons-material/Crop54';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LockResetIcon from '@mui/icons-material/LockReset';
import SearchIcon from '@mui/icons-material/Search';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import UndoIcon from '@mui/icons-material/Undo';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import type { SvgIconProps } from '@mui/material';
import { Button, Divider, ListItem, Menu, MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { changeLockOrientation, openDialogAction, selectSecondaryToolAction } from 'actions';
import { clearShapesSelectionAction } from 'actions/circuit';
import { CorneredButton } from 'components/utils/cornered-button';
import { GetComponent } from 'components/utils/get-component';
import { DialogTypes } from 'models';
import { Tools } from 'models/tools';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { setCameraPosition } from 'reducers/local/camera-position.reducer';
import type { FilterState } from 'reducers/local/filters.reducer';
import type { ToolState } from 'reducers/tools';
import { checkPermission } from 'services/check-permission';
import { CircuitService } from 'services/circuit.service';
import { getLidarBounds } from 'services/maps.service';
import type { RootState } from 'store';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { PreferencesService } from 'utils/preferences';
import { ConveyorIcon, GravityRackIcon, RackIcon } from '../core/icons';
import { PredefinedShapesMenu } from './predefined-shapes-menu';

const marginBottomValue = 0.5;

export interface ToolBarProps {
  activeTool?: Tools;
  selectNewTool: (toolName: Tools) => void;
  snap90deg?: boolean;
  change90DegSnap: (snap90deg: boolean) => void;
}

export function ToolBarComponent({ activeTool, selectNewTool, snap90deg, change90DegSnap }: ToolBarProps): JSX.Element {
  const dispatch = useAppDispatch();
  const editorMode = useAppSelector((state) => state.editor.editorMode);
  const isLibCirEnabled = useAppSelector((state) => state.editor.isRender2Enabled);
  const buttonDrawSegmentTurnRef = useRef(null);
  const buttonDrawRackConveyorRef = useRef(null);
  const buttonDrawZoneRef = useRef(null);

  const toolClickHandler = useCallback(
    (tool: Tools) => {
      selectNewTool(tool);

      if (editorMode === 'flow') {
        dispatch(clearShapesSelectionAction());
      }
    },
    [dispatch, editorMode, selectNewTool]
  );

  const toggle90DegSnap = useCallback(
    (e: any): void => {
      const newState = !snap90deg;

      change90DegSnap(newState);
    },
    [change90DegSnap, snap90deg]
  );
  const filterOpacity = 'opacity(60%)';
  const snap90DegEnabled = useSelector((state: RootState) => state.tool.snap90deg);
  const lockOrientationToolEnabled = useSelector((state: RootState) => state.tool.lockOrientation);

  const toggleLockOrientationTool = useCallback(() => {
    dispatch(
      changeLockOrientation({
        lockOrientation: !lockOrientationToolEnabled,
      })
    );
  }, [dispatch, lockOrientationToolEnabled]);

  const [displayMenuTurns, setDisplayMenuTurns] = useState(false);
  const [displayTurnToolTip, setDisplayTurnToolTip] = useState(false);
  const [displayTurnToolTipCon, setDisplayTurnToolTipCon] = useState(false);
  const [displayMenuRackCon, setDisplayMenuRackCon] = useState(false);

  const [displayMenuZones, setDisplayMenuZones] = useState(false);
  const [displayZoneToolTip, setDisplayZoneToolTip] = useState(false);

  const [displayPredefinedShapesToolTip, setDisplayPredefinedShapesToolTip] = useState(false);

  const turnType = useAppSelector((state) => state.tool.turnType);

  const turnButtonHandler = useCallback(
    (tool: Tools, turnType_: ToolState['turnType']): void => {
      toolClickHandler(tool);

      dispatch(selectSecondaryToolAction({ turnType: turnType_ }));
    },
    [dispatch, toolClickHandler]
  );

  const zoneType = useAppSelector((state) => state.tool.zoneType);

  const zoneButtonHandler = useCallback(
    (tool: Tools, zoneType_: ToolState['zoneType']): void => {
      toolClickHandler(tool);

      dispatch(selectSecondaryToolAction({ zoneType: zoneType_ }));
    },
    [dispatch, toolClickHandler]
  );

  const handleOpenSearchShape = useCallback(() => {
    dispatch(openDialogAction({ type: DialogTypes.SearchShape, payload: undefined }));
  }, [dispatch]);

  const handleResetView = useCallback(() => {
    // for 2D lib
    if (!isLibCirEnabled) {
      CircuitService.getDrawingReference()?.centerZoomToBackground();

      return;
    }

    // For 3D lib
    const lidarData = store.getState().maps.lidar['background-lidar'];
    const mapImages = store.getState().maps.mapImage.mapImages;
    // 8: good scale level to see the whole circuit
    const chosenScale = 8;
    const mapImageTiles = store.getState().maps.mapImageTiles.mapImageTilesData?.tiles[chosenScale];
    const tilesPixelSize = store.getState().maps.mapImageTiles.mapImageTilesData?.tilePixelSize;

    if (lidarData) {
      const { minX, maxY, minY, maxX } = getLidarBounds(lidarData.coordinates);
      dispatch(
        setCameraPosition({ position: { minX: minX * 0.01, maxY: maxY * 0.01, minY: minY * 0.01, maxX: maxX * 0.01 } })
      );
    } else if (mapImageTiles && tilesPixelSize) {
      let numberOfTilesOnTheWidth = 1;

      for (let i = 0; i < mapImageTiles.length - 1; i++) {
        if (mapImageTiles[i].y === mapImageTiles[i + 1].y) {
          numberOfTilesOnTheWidth = numberOfTilesOnTheWidth + 1;
        } else {
          break;
        }
      }

      let numberOfTilesOnTheHeight = 1;

      for (let i = 0; i < mapImageTiles.length - 1; i++) {
        if (mapImageTiles[i].y !== mapImageTiles[i + 1].y) {
          numberOfTilesOnTheHeight = numberOfTilesOnTheHeight + 1;
        }
      }

      const fullImgWidth = tilesPixelSize * chosenScale * numberOfTilesOnTheWidth;
      const fullImgHeight = tilesPixelSize * chosenScale * numberOfTilesOnTheHeight;

      const minX = mapImageTiles[0].x * 100;
      const minY = -mapImageTiles[0].y * 100 - fullImgHeight - fullImgHeight;
      const maxX = mapImageTiles[0].x * 100 + fullImgWidth;
      const maxY = -mapImageTiles[0].y * 100 + fullImgHeight;

      dispatch(
        setCameraPosition({
          position: { minX: minX * 0.01, maxY: maxY * 0.001, minY: minY * 0.001, maxX: maxX * 0.01 },
        })
      );
    } else if (mapImages && mapImages?.length > 0) {
      const mapImage = mapImages[0];

      if (mapImage.height) {
        const minX = mapImage.x;
        const minY = mapImage.y - mapImage.height;
        const maxX = mapImage.x + mapImage.originalWidth * (mapImage.height / mapImage.originalHeight);
        const maxY = mapImage.y;

        dispatch(
          setCameraPosition({
            position: { minX: minX * 0.01, maxY: maxY * 0.01, minY: minY * 0.01, maxX: maxX * 0.01 },
          })
        );
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('No lidar data or map image found to center on');
    }
  }, [dispatch, isLibCirEnabled]);

  const filteredShapes = useAppSelector((state) => state.local.filters);
  const isShapeFiltered = useCallback((tool: Tools, filtered: FilterState) => {
    const shapeNames = {
      [Tools.Move]: 'move',
      [Tools.AddDevice]: 'devices',
      [Tools.DrawMeasurer]: 'measurers',
      [Tools.AddNote]: 'notes',
      [Tools.DrawPoint]: 'points',
      [Tools.DrawRack]: 'racks',
      [Tools.DrawSegment]: 'segments',
      [Tools.DrawStockZone]: 'stockZones',
      [Tools.AddTurn]: 'turns',
      [Tools.DrawZone]: 'zones',
    };

    const isSegmentAndTurnFiltered = !filtered[shapeNames[Tools.DrawSegment]] && !filtered[shapeNames[Tools.AddTurn]];

    if (tool === Tools.DrawShape) {
      return isSegmentAndTurnFiltered;
    }

    const filterValue: boolean | undefined = filtered[shapeNames[tool]] as boolean | undefined;
    if (filterValue === undefined) {
      return false;
    }

    return shapeNames[tool] ? !filtered[shapeNames[tool]] : true;
  }, []);

  const isRackFiltered = isShapeFiltered(Tools.DrawRack, filteredShapes);
  const isSegmentFiltered = isShapeFiltered(Tools.DrawSegment, filteredShapes);
  const isZoneFiltered = isShapeFiltered(Tools.DrawZone, filteredShapes);

  const [arePreferencesFullyLoaded, setArePreferencesFullyLoaded] = useState(
    PreferencesService.arePreferencesFullyLoaded()
  );
  const updateProjectLoaded = useCallback(() => {
    setArePreferencesFullyLoaded(PreferencesService.arePreferencesFullyLoaded());
  }, []);
  useEffect(() => {
    window.addEventListener('projectLoaded', updateProjectLoaded);

    return () => {
      window.removeEventListener('projectLoaded', updateProjectLoaded);
    };
  }, [updateProjectLoaded]);

  const circuitTools = useAppSelector((state) => state.tool.circuitTools);
  const trafficTools = useAppSelector((state) => state.tool.trafficTools);
  const flowTools = useAppSelector((state) => state.tool.flowTools);

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  const toolsToDisplay = (() => {
    switch (editorMode) {
      case 'circuit':
        return circuitTools;
      case 'traffic':
        return trafficTools;
      case 'flow':
        return flowTools;
      default:
        return [];
    }
  })();

  return (
    <>
      {toolsToDisplay.map((toolInfo, index) => {
        const { name, tool, icon: MenuIcon, hotkey, help } = toolInfo;
        const iconWithoutExtension =
          name !== Tools.DrawSegmentOrTurn &&
          name !== Tools.DrawZone &&
          name !== Tools.DrawConveyor &&
          name !== Tools.DrawRack &&
          name !== Tools.DrawGravityRack &&
          name !== Tools.PredefinedShapes;

        const projectRequired = !!('projectRequired' in toolInfo && toolInfo.projectRequired);
        const authorized = toolInfo.authorized;
        const projectOk = !(projectRequired && !arePreferencesFullyLoaded);
        const isToolDisabled = !projectOk || !authorized;

        const menuIconProps = iconWithoutExtension
          ? {
              color: (tool === activeTool ? 'primary' : undefined) as SvgIconProps['color'] | undefined,
              sx: {
                transform: tool === Tools.DrawMeasurer ? 'rotate(90deg)' : undefined,
                filter: isShapeFiltered(tool, filteredShapes) ? filterOpacity : undefined,
              },
              colorBlackOnly: tool === Tools.DrawStockZone ? true : undefined,
            }
          : undefined;

        if (menuIconProps && !menuIconProps.colorBlackOnly) delete menuIconProps?.colorBlackOnly;

        return (
          <Tooltip
            title={
              <>
                {name}
                {!!hotkey && <> [{hotkey}]</>}
                {help ? (
                  <span>
                    <br />
                    <HelpOutlineIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: '3px' }} />
                    <i style={{ verticalAlign: 'text-top' }}>{help}</i>
                  </span>
                ) : (
                  <></>
                )}
                {authorized && !projectOk && (
                  <>
                    <br />
                    (A project needs to be opened to use it)
                  </>
                )}
                {!authorized && (
                  <>
                    <br />
                    (You are not authorized to use this tool)
                  </>
                )}
              </>
            }
            key={name}
            disableHoverListener={
              name === Tools.DrawSegmentOrTurn ||
              name === Tools.DrawRack ||
              name === Tools.DrawZone ||
              name === Tools.DrawConveyor ||
              name === Tools.DrawGravityRack ||
              name === Tools.PredefinedShapes
            }
            open={
              name === Tools.DrawSegmentOrTurn
                ? displayTurnToolTip && tool !== activeTool
                : name === Tools.DrawRack
                  ? displayTurnToolTipCon && tool !== activeTool
                  : name === Tools.DrawZone
                    ? displayZoneToolTip && tool !== activeTool
                    : name === Tools.PredefinedShapes
                      ? displayPredefinedShapesToolTip && tool !== activeTool
                      : undefined
            }
            placement="right"
            data-testid={tool}
          >
            <Box component="span">
              {iconWithoutExtension && menuIconProps ? (
                <ListItem dense={true} sx={{ padding: 0, marginBottom: marginBottomValue }}>
                  <Button
                    onClick={() => toolClickHandler(tool)}
                    color="inherit"
                    disabled={isToolDisabled}
                    id={`button-${tool}`}
                  >
                    <MenuIcon {...menuIconProps} />
                  </Button>
                </ListItem>
              ) : (
                (name === Tools.DrawSegmentOrTurn && (
                  <div>
                    <Tooltip title={!editCircuitPerm ? 'You do not have the permission to edit circuits' : ''}>
                      <Box component="span">
                        <ListItem disabled={!editCircuitPerm} sx={{ padding: 0, marginBottom: marginBottomValue }}>
                          <CorneredButton
                            id="button-draw-segment-or-turn"
                            ref={buttonDrawSegmentTurnRef}
                            onMouseEnter={() => {
                              setDisplayMenuTurns(true);
                              setDisplayTurnToolTip(true);
                            }}
                            onMouseLeave={() => {
                              setDisplayTurnToolTip(false);
                            }}
                            onClick={() => toolClickHandler(tool)}
                            data-testid="button-draw-segment-or-turn"
                            color="inherit"
                          >
                            <GetComponent
                              color={
                                Tools.DrawSegmentOrTurn === activeTool ||
                                Tools.AddTurn === activeTool ||
                                Tools.DrawSegment === activeTool
                                  ? 'primary'
                                  : undefined
                              }
                              sx={{ filter: isSegmentFiltered ? filterOpacity : undefined }}
                              component={
                                activeTool === Tools.DrawSegmentOrTurn
                                  ? BorderColorIcon
                                  : activeTool === Tools.AddTurn
                                    ? UndoIcon
                                    : activeTool === Tools.DrawSegment
                                      ? LinearScaleIcon
                                      : BorderColorIcon
                              }
                            />
                          </CorneredButton>
                        </ListItem>
                      </Box>
                    </Tooltip>

                    <Menu
                      anchorEl={buttonDrawSegmentTurnRef.current}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      open={
                        displayMenuTurns &&
                        (Tools.DrawSegmentOrTurn === activeTool ||
                          Tools.AddTurn === activeTool ||
                          Tools.DrawSegment === activeTool)
                      }
                      MenuListProps={{
                        onMouseLeave: () => setDisplayMenuTurns(false),
                        sx: { paddingTop: 0, paddingBottom: 0 },
                      }}
                      onClose={() => setDisplayMenuTurns(false)}
                      keepMounted
                    >
                      <Tooltip title="Draw Segment Or Turn" placement="right" sx={{ padding: 0 }}>
                        <MenuItem sx={{ padding: 0 }}>
                          <Button
                            onClick={() => toolClickHandler(tool)}
                            color="inherit"
                            sx={{ paddingTop: '6px', paddingBottom: '6px' }}
                          >
                            <MenuIcon color={tool === activeTool ? 'primary' : undefined} />
                          </Button>
                        </MenuItem>
                      </Tooltip>

                      <Tooltip title="Normal turn" placement="right" sx={{ padding: 0 }}>
                        <MenuItem sx={{ padding: 0 }}>
                          <Button
                            onClick={() => turnButtonHandler(Tools.AddTurn, 'Normal')}
                            color="inherit"
                            sx={{ paddingTop: '6px', paddingBottom: '6px' }}
                          >
                            <UndoIcon
                              color={activeTool === Tools.AddTurn && turnType === 'Normal' ? 'primary' : undefined}
                            />
                          </Button>
                        </MenuItem>
                      </Tooltip>
                    </Menu>
                  </div>
                )) ||
                (name === Tools.DrawRack && (
                  <div>
                    <Tooltip title={!editCircuitPerm ? 'You do not have the permission to edit circuits' : ''}>
                      <Box component="span">
                        <ListItem sx={{ padding: 0, marginBottom: marginBottomValue }} disabled={!editCircuitPerm}>
                          <CorneredButton
                            id="button-draw-rack-or-conveyor"
                            ref={buttonDrawRackConveyorRef}
                            onMouseEnter={() => {
                              setDisplayMenuRackCon(true);
                              setDisplayTurnToolTipCon(true);
                            }}
                            onMouseLeave={() => setDisplayTurnToolTipCon(false)}
                            onClick={() => toolClickHandler(tool)}
                            data-testid="button-draw-rack-or-conveyor"
                            color="inherit"
                          >
                            <GetComponent
                              color={
                                Tools.DrawRack === activeTool ||
                                Tools.DrawConveyor === activeTool ||
                                Tools.DrawGravityRack === activeTool
                                  ? 'primary'
                                  : undefined
                              }
                              sx={{ filter: isRackFiltered ? filterOpacity : undefined }}
                              colorBlackOnly
                              component={
                                activeTool === Tools.DrawRack
                                  ? RackIcon
                                  : activeTool === Tools.DrawConveyor
                                    ? ConveyorIcon
                                    : activeTool === Tools.DrawGravityRack
                                      ? GravityRackIcon
                                      : RackIcon
                              }
                            />
                          </CorneredButton>
                        </ListItem>
                      </Box>
                    </Tooltip>
                    <Menu
                      anchorEl={buttonDrawRackConveyorRef.current}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      open={
                        displayMenuRackCon &&
                        (Tools.DrawRack === activeTool ||
                          Tools.DrawConveyor === activeTool ||
                          Tools.DrawGravityRack === activeTool)
                      }
                      MenuListProps={{
                        onMouseLeave: () => setDisplayMenuRackCon(false),
                        sx: { paddingTop: 0, paddingBottom: 0 },
                      }}
                      onClose={() => setDisplayMenuRackCon(false)}
                      keepMounted
                    >
                      <Tooltip title="Draw Rack" placement="right">
                        <MenuItem sx={{ padding: 0 }}>
                          <Button
                            onClick={() => toolClickHandler(tool)}
                            color="inherit"
                            sx={{ paddingTop: '6px', paddingBottom: '6px' }}
                          >
                            <RackIcon color={Tools.DrawRack === activeTool ? 'primary' : undefined} colorBlackOnly />
                          </Button>
                        </MenuItem>
                      </Tooltip>
                      <Tooltip title="Draw Conveyor" placement="right">
                        <MenuItem sx={{ padding: 0 }}>
                          <Button
                            onClick={() => turnButtonHandler(Tools.DrawConveyor, 'Normal')}
                            color="inherit"
                            sx={{ paddingTop: '6px', paddingBottom: '6px' }}
                          >
                            <ConveyorIcon
                              color={activeTool === Tools.DrawConveyor ? 'primary' : undefined}
                              colorBlackOnly
                            />
                          </Button>
                        </MenuItem>
                      </Tooltip>
                      <Tooltip title="Draw Gravity Rack" placement="right">
                        <MenuItem sx={{ padding: 0 }}>
                          <Button
                            onClick={() => turnButtonHandler(Tools.DrawGravityRack, 'Normal')}
                            color="inherit"
                            sx={{ paddingTop: '6px', paddingBottom: '6px' }}
                          >
                            <GravityRackIcon color={Tools.DrawGravityRack === activeTool ? 'primary' : undefined} />
                          </Button>
                        </MenuItem>
                      </Tooltip>
                    </Menu>
                  </div>
                )) ||
                (name === Tools.DrawZone && (
                  <div>
                    <Tooltip title={!editCircuitPerm ? 'You do not have the permission to edit circuits' : ''}>
                      <Box component="span">
                        <ListItem sx={{ padding: 0, marginBottom: marginBottomValue }} disabled={!editCircuitPerm}>
                          <CorneredButton
                            id="button-draw-zone"
                            ref={buttonDrawZoneRef}
                            onMouseEnter={() => {
                              setDisplayMenuZones(true);
                              setDisplayZoneToolTip(true);
                            }}
                            onMouseLeave={() => setDisplayZoneToolTip(false)}
                            onClick={() => toolClickHandler(tool)}
                            data-testid="button-draw-zone"
                            color="inherit"
                          >
                            <GetComponent
                              component={zoneType === 'Rectangle' ? Crop54Icon : ChangeHistoryIcon}
                              color={tool === activeTool ? 'primary' : undefined}
                              sx={{ filter: isZoneFiltered ? filterOpacity : undefined }}
                            />
                          </CorneredButton>
                        </ListItem>
                      </Box>
                    </Tooltip>

                    <Menu
                      anchorEl={buttonDrawZoneRef.current}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      open={displayMenuZones && tool === activeTool}
                      MenuListProps={{
                        onMouseLeave: () => setDisplayMenuZones(false),
                        sx: { paddingTop: 0, paddingBottom: 0 },
                      }}
                      onClose={() => setDisplayMenuZones(false)}
                      keepMounted
                    >
                      <Tooltip title="Rectangle" placement="right" sx={{ padding: 0 }}>
                        <MenuItem sx={{ padding: 0 }}>
                          <Button
                            onClick={() => zoneButtonHandler(tool, 'Rectangle')}
                            color="inherit"
                            sx={{ paddingTop: '6px', paddingBottom: '6px' }}
                          >
                            <Crop54Icon color={zoneType === 'Rectangle' ? 'primary' : undefined} />
                          </Button>
                        </MenuItem>
                      </Tooltip>
                      <Tooltip title="Triangle" placement="right">
                        <MenuItem sx={{ padding: 0 }}>
                          <Button onClick={() => zoneButtonHandler(tool, 'Triangle')} color="inherit">
                            <ChangeHistoryIcon color={zoneType === 'Triangle' ? 'primary' : undefined} />
                          </Button>
                        </MenuItem>
                      </Tooltip>
                    </Menu>
                  </div>
                )) ||
                (name === Tools.PredefinedShapes && (
                  <PredefinedShapesMenu
                    setDisplayPredefinedShapesToolTip={setDisplayPredefinedShapesToolTip}
                    editCircuitPerm={editCircuitPerm}
                    toolClickHandler={toolClickHandler}
                    tool={tool}
                    activeTool={activeTool}
                    marginBottomValue={marginBottomValue}
                  />
                )) || <></>
              )}
            </Box>
          </Tooltip>
        );
      })}
      <Divider />
      {editorMode === 'circuit' && (
        <Tooltip title={<>Snap at 90 degrees [D]</>} placement="right">
          <ListItem sx={{ padding: 0, marginBottom: marginBottomValue }} data-testid="snap" dense={true}>
            <Button onClick={toggle90DegSnap} color="inherit">
              <SquareFootIcon color={snap90DegEnabled ? 'secondary' : undefined} />
            </Button>
          </ListItem>
        </Tooltip>
      )}
      {editorMode === 'circuit' && (
        <Tooltip title={<>Lock orientation</>} placement="right">
          <ListItem sx={{ padding: 0, marginBottom: marginBottomValue }} data-testid="lock-orientation" dense={true}>
            <Button onClick={toggleLockOrientationTool} color="inherit">
              <LockResetIcon color={lockOrientationToolEnabled ? 'secondary' : undefined} />
            </Button>
          </ListItem>
        </Tooltip>
      )}
      <Tooltip title="Search a shape" placement="right">
        <ListItem sx={{ padding: 0, marginBottom: marginBottomValue }} data-testid="search a shape" dense={true}>
          <Button onClick={handleOpenSearchShape} color="inherit">
            <SearchIcon />
          </Button>
        </ListItem>
      </Tooltip>
      <Tooltip title="Reset view [C]" placement="right">
        <ListItem sx={{ padding: 0, marginBottom: marginBottomValue }} data-testid="reset view" dense={true}>
          <Button onClick={handleResetView} color="inherit">
            <ViewInArIcon />
          </Button>
        </ListItem>
      </Tooltip>
    </>
  );
}
