import { Tools } from 'models/tools';
import { remoteDoc } from 'multiplayer/globals';
import { setSchedulerConfigurationFromYJS } from 'project/project';
import { SnackbarUtils } from 'services/snackbar.service';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import store, { useAppSelector } from 'store';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export function useSchedulerConfigSubscription(): void {
  const inSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const schedulerConfigText = remoteDoc?.getText('schedulerConfig');

  const updateSchedulerConfig = (): void => {
    if (!schedulerConfigText || !schedulerConfigText.length) return;

    const schedulerConfigData = JSON.parse(schedulerConfigText.toString()) as SchedulerConfig;

    store.dispatch(setSchedulerConfigurationFromYJS(schedulerConfigData));

    SnackbarUtils.info('A user updated the scheduler configuration', {
      autoHideDuration: 3000,
    });
  };

  useConditionalQueueSubscriptionHook({
    YType: schedulerConfigText,
    callback: updateSchedulerConfig,
    condition: inSimulation,
  });
}
