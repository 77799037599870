import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import { Button, Card, CardContent, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { emergencySave } from 'components/export/emergency-save';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import ReactDOMClient from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import 'reflect-metadata';
import { initializeServiceWorker } from 'services/pwa-service-worker';
import { SnackbarUtilsConfigurator } from 'services/snackbar.service';
import { getConfig } from 'utils/config';
import { isDevVersion, isSentryReplaysModeEnabled } from 'utils/env';
import packageJson from '../package.json';
import { App } from './app.container';
import './index.css';
import store, { history } from './store';
import { theme } from './utils/mui-theme';

initializeServiceWorker();

const sentryURL = import.meta.env.VITE_SENTRY_URL;

let lastTimeSentrySent = 0; // the date of the last time sentry sent an event
let sentrySampleRate = 1.0; // the current sample rate for sentry
const resetSentrySampleRateAfterTime = 5 * 60 * 1000; // 5 minutes in ms
let stopSample = false;

export const changeStopSampleState = (newState: boolean): boolean => (stopSample = newState);

if (sentryURL) {
  const integrations: any[] = [
    Sentry.browserTracingIntegration(),
    captureConsoleIntegration({
      levels: ['error'],
    }),
  ];

  const environment = !isDevVersion() ? (document.location.host.includes('staging') ? 'staging' : 'production') : 'dev';

  const hasReplays = isSentryReplaysModeEnabled();
  if (hasReplays) integrations.push(Sentry.replayIntegration(), Sentry.replayCanvasIntegration());

  Sentry.init({
    dsn: sentryURL,
    integrations,
    release: `${getConfig('roadEditorVersion') || packageJson.version}${isDevVersion() ? '-dev' : ''}`,
    environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    replaysSessionSampleRate: hasReplays ? 0.1 : 0.0,
    replaysOnErrorSampleRate: hasReplays ? 1.0 : 0.0,

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (
        event.exception &&
        hint?.originalException instanceof Error &&
        hint.originalException.message === 'Manual Report'
      ) {
        const profile = store.getState().auth.profile;
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: 'Report an issue',
          user: {
            name: profile && 'name' in profile ? profile.name : undefined,
            email: profile && 'email' in profile ? profile.email : undefined,
          },
        });

        /**
         * We have an issue, when we click outside of the sentry form, the
         * sentry dialog is closed, but we don't want to close it unless we
         * manually send (or close) the feedback
         * https://redmine.balyo.com/issues/40557
         */
        const intervalID = setInterval(() => {
          const wrapperSentryEl = document.querySelector('.sentry-error-embed-wrapper');

          if (wrapperSentryEl && wrapperSentryEl instanceof HTMLElement) {
            wrapperSentryEl.style.zIndex = '100000';

            wrapperSentryEl.addEventListener(
              'click',
              (e) => {
                const target = e.target as HTMLElement;
                const dialogSentryEl = document.querySelector('.sentry-error-embed') as HTMLElement;

                if (
                  dialogSentryEl &&
                  dialogSentryEl instanceof HTMLElement &&
                  e?.target instanceof HTMLElement &&
                  !dialogSentryEl.contains(target)
                ) {
                  e.stopImmediatePropagation();
                  e.preventDefault();
                }
              },
              true
            );

            clearInterval(intervalID);
          }
        }, 100);
      }

      const now = Date.now();
      if (lastTimeSentrySent + resetSentrySampleRateAfterTime < now) {
        sentrySampleRate = 1.0;
      }

      lastTimeSentrySent = now;

      if (stopSample) {
        changeStopSampleState(false);

        return event;
      }

      const randomNb = Math.random();
      const shouldSend = randomNb < sentrySampleRate;
      if (shouldSend) {
        sentrySampleRate /= 2;

        return event;
      }

      return null;
    },
  });

  Sentry.setTag('replay.active', hasReplays ? 'yes' : 'no');
} else {
  // eslint-disable-next-line no-console
  console.log('No Sentry URL environment variable found. Sentry will not be initialized.');
}

function onAppCrash(): void {
  emergencySave();
}

const themeMantine = createTheme({
  /** Put your mantine theme override here */
});

function FallbackComponentAppCrash(): JSX.Element {
  return (
    <Box sx={{ width: 500, margin: 'auto' }} component="div">
      <Card variant="outlined">
        <CardContent>
          <h1>Road Editor has crashed</h1>
          <p>We emergency saved the circuit. It has been placed into your download folder.</p>
          <Button variant="outlined" onClick={() => window.location.reload()}>
            Restart Road Editor
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root');
  if (!container) throw new Error('No root container found');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
  const root = ReactDOMClient.createRoot(container);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  root.render(
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <MantineProvider theme={themeMantine}>
          <ThemeProvider theme={theme}>
            {/** ts ignore to be removed once this issue is fixed: https://github.com/supasate/connected-react-router */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <ConnectedRouter history={history}>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} dense={true}>
                <SnackbarUtilsConfigurator />
                <StrictMode>
                  <ErrorBoundary onError={onAppCrash} FallbackComponent={FallbackComponentAppCrash}>
                    <App />
                  </ErrorBoundary>
                </StrictMode>
              </SnackbarProvider>
            </ConnectedRouter>
          </ThemeProvider>
        </MantineProvider>
      </StyledEngineProvider>
    </Provider>
  );
});

/**
 * Preload resources for offline pwa use
 */
window.addEventListener('load', () => {
  fetch('/wasm/libTurnGenerator.wasm');
});
