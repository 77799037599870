import { Tools } from 'models/tools';
import { remoteDoc } from 'multiplayer/globals';
import { setOpcDataFromYJS } from 'project/project';
import { SnackbarUtils } from 'services/snackbar.service';
import type { OPCConfiguration } from 'simulation/opc';
import store, { useAppSelector } from 'store';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export function useOpcConfigSubscription(): void {
  const inSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const opcConfigText = remoteDoc?.getText('opcConfig');

  const updateOpcConfig = (): void => {
    if (!opcConfigText || !opcConfigText.length) return;

    const opcConfigData = JSON.parse(opcConfigText.toString()) as OPCConfiguration;

    store.dispatch(setOpcDataFromYJS(opcConfigData));

    SnackbarUtils.info('A user updated the opc configuration', {
      autoHideDuration: 3000,
    });
  };

  useConditionalQueueSubscriptionHook({ YType: opcConfigText, callback: updateOpcConfig, condition: inSimulation });
}
