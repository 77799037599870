import type { Station } from 'flows/flows';
import { addStationFromYJS, removeStationFromYJS, setStationFromYJS } from 'flows/flows';
import { isEqual } from 'lodash';
import { Tools } from 'models/tools';
import { remoteDoc } from 'multiplayer/globals';
import { useRef } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import type { YMap } from 'yjs/dist/src/internals';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export function useStationsSubscription(): void {
  const inSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const stationsMap = remoteDoc?.getMap('stations') as YMap<Station>;
  const initRef = useRef(true);

  const updateStation = (): void => {
    const stations = store.getState().flows.stations;
    if (stations?.length) {
      stations.forEach((station) => {
        if (stationsMap.has(station.id)) return;

        store.dispatch(removeStationFromYJS(station.id));

        if (!initRef.current) {
          SnackbarUtils.info('A user deleted a station', {
            autoHideDuration: 3000,
          });
        }
      });
    }

    if (!stationsMap.size) return;

    stationsMap.forEach((yjsStation, key) => {
      const localStationExists = stations?.find((station) => station.id === key);
      if (!localStationExists) {
        store.dispatch(addStationFromYJS(yjsStation));

        if (!initRef.current) {
          SnackbarUtils.info('A user created a station', {
            autoHideDuration: 3000,
          });
        }

        return;
      }

      const sameStation = isEqual(localStationExists, yjsStation);
      if (sameStation) return;

      store.dispatch(setStationFromYJS(yjsStation));

      if (!initRef.current) {
        SnackbarUtils.info('A user updated a station', {
          autoHideDuration: 3000,
        });
      }
    });

    if (initRef.current) {
      initRef.current = false;
    }
  };

  useConditionalQueueSubscriptionHook({ YType: stationsMap, callback: updateStation, condition: inSimulation });
}
