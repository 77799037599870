import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { latestBalyoSimulationVersion } from 'simulation/BalyoSimulation/available-balyo-simulation-versions';
import type { OPCConfiguration } from 'simulation/opc';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';

export interface GitBranchInformation {
  branch: string | undefined;
  sha: string | undefined;
}
export interface ProjectSliceState {
  /** whether Road Editor manage the itself the device preferences (= saving install.xml) */
  enableDevicePrefManagement: boolean;
  circuitDescription: string;
  circuitVersion: string;
  projectName?: string;
  /** the project name to use for map editor (to fetch the birdview), by default the project name */
  projectNameMapEditor?: string;
  /** the current circuit name of the loaded circuit */
  circuitName?: string;
  opcData?: OPCConfiguration;
  schedulerConfig?: SchedulerConfig;
  /** BalyoSimulation version to use */
  balyoSimulationVersion: string;
  /** Information about the project local repository currently opened */
  gitBranchInformation?: GitBranchInformation;
}

export const projectSliceInitialState: ProjectSliceState = {
  enableDevicePrefManagement: true,
  circuitDescription: '',
  circuitVersion: '0.0.0',
  balyoSimulationVersion: latestBalyoSimulationVersion.version,
};

const projectSlicePrivate = createSlice({
  initialState: projectSliceInitialState,
  name: 'project',
  reducers: {
    setProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload;
    },
    setCircuitName: (state, action: PayloadAction<string>) => {
      state.circuitName = action.payload;
    },
    setProjectNameMapEditor: (state, action: PayloadAction<string>) => {
      state.projectNameMapEditor = action.payload;
    },
    setDevicePrefManagement: (state, action: PayloadAction<boolean>) => {
      state.enableDevicePrefManagement = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.circuitDescription = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.circuitVersion = action.payload;
    },
    setDevicePrefManagementFromYJS: (state, action: PayloadAction<boolean>) => {
      state.enableDevicePrefManagement = action.payload;
    },
    setDescriptionFromYJS: (state, action: PayloadAction<string>) => {
      state.circuitDescription = action.payload;
    },
    setVersionFromYJS: (state, action: PayloadAction<string>) => {
      state.circuitVersion = action.payload;
    },
    setOpcData: (state, action: PayloadAction<OPCConfiguration>) => {
      state.opcData = action.payload;
    },
    setOpcDataFromYJS: (state, action: PayloadAction<OPCConfiguration>) => {
      state.opcData = action.payload;
    },
    setSchedulerConfiguration: (state, action: PayloadAction<SchedulerConfig>) => {
      state.schedulerConfig = action.payload;
    },
    setSchedulerConfigurationFromYJS: (state, action: PayloadAction<SchedulerConfig>) => {
      state.schedulerConfig = action.payload;
    },
    setBalyoSimulationVersion: (state, action: PayloadAction<string>) => {
      state.balyoSimulationVersion = action.payload;
    },
    setGitBranchInformation: (state, action: PayloadAction<GitBranchInformation>) => {
      state.gitBranchInformation = action.payload;
    },
    setBalyoSimulationVersionFromYJS: (state, action: PayloadAction<string>) => {
      state.balyoSimulationVersion = action.payload;
    },
  },
});

export const projectSlice = {
  reducer: projectSlicePrivate.reducer,
  name: projectSlicePrivate.name,
};

export const {
  setDevicePrefManagement,
  setDescription,
  setVersion,
  setDevicePrefManagementFromYJS,
  setDescriptionFromYJS,
  setVersionFromYJS,
  setProjectName,
  setOpcData,
  setOpcDataFromYJS,
  setSchedulerConfiguration,
  setSchedulerConfigurationFromYJS,
  setProjectNameMapEditor,
  setCircuitName,
  setBalyoSimulationVersion,
  setGitBranchInformation,
  setBalyoSimulationVersionFromYJS,
} = projectSlicePrivate.actions;
