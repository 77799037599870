import { combineEpics } from 'redux-observable';
import { combineAuthEpics } from './auth.epic';
import { combineCircuitEpics } from './circuit.epic';
import { combineCopyPasteEpics } from './copy-paste.epic';
import { combineImagesEpics } from './images.epic';
import { combineMapsEpics } from './maps.epic';
import { combineSimulationConfigurationEpics } from './simulation-configuration.epic';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const rootEpic = combineEpics(
  combineAuthEpics(),
  combineMapsEpics(),
  combineCircuitEpics,
  combineImagesEpics,
  combineCopyPasteEpics,
  combineSimulationConfigurationEpics
);

export default rootEpic;
