import { remoteDoc } from 'multiplayer/globals';
import { setBalyoSimulationVersionFromYJS } from 'project/project';
import { loadPreferencesToLibTrack } from 'services/project';
import { createSimulationService, waitForSimulationService } from 'services/simulation.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useBalyoSimulationVersionSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const balyoSimulationVersionText = remoteDoc?.getText('balyoSimulationVersion');

  const updateBalyoSimulationVersion = (): void => {
    if (!balyoSimulationVersionText || !balyoSimulationVersionText.length) return;
    const balyoSimulationVersion = balyoSimulationVersionText.toString();

    store.dispatch(setBalyoSimulationVersionFromYJS(balyoSimulationVersion));
    createSimulationService({
      balyoSimulationVersion,
    });
    waitForSimulationService.then(() => {
      loadPreferencesToLibTrack();
    });

    if (store.getState().dialog.open && store.getState().dialog.type === 'project-settings') {
      SnackbarUtils.info('A user changed the balyo simulation version', {
        autoHideDuration: 3000,
      });
    }
  };

  useConditionalQueueSubscriptionHook({
    YType: balyoSimulationVersionText,
    callback: updateBalyoSimulationVersion,
    condition: notCircuitEditionMode,
  });
};
