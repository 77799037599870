import type { RoadEditorAction } from 'actions/circuit';
import type { Failure } from 'actions/shared';
import { failureAction } from 'actions/shared';
import type { Feature } from 'geojson';
import type { CircuitShape, FeatureCollectionWithProperties, GeoJsonCircuit } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { CircuitService } from 'services/circuit.service';
import type { Action } from 'utils/redux';

localStorage.turnInTheClipBoard = false;

export enum ClipboardTypes {
  CopySelection = '[Clipboard] Copy selection to clipboard',
  CopySelectionSuccess = '[Clipboard] Copy selection to clipboard success',
  CopySelectionFailure = '[Clipboard] Copy selection to clipboard failure',
  CopyPredefinedShapes = '[Clipboard] Copy predefined shapes to clipboard',
  CopyPredefinedShapesSuccess = '[Clipboard] Copy predefined shapes to clipboard success',
  CopyPredefinedShapesFailure = '[Clipboard] Copy predefined shapes to clipboard failure',
  PasteSelection = '[Clipboard] Paste selection from clipboard',
  PasteSelectionSuccess = '[Clipboard] Paste selection from clipboard success',
  PasteSelectionFailure = '[Clipboard] Paste selection from clipboard failure',
}

export interface CopySelection extends Action {
  type: ClipboardTypes.CopySelection;
}
export interface CopySelectionSuccess extends Action {
  type: ClipboardTypes.CopySelectionSuccess;
  payload: {
    shapesData: Feature[];
  };
}
export interface CopySelectionFailure extends Failure<ClipboardTypes.CopySelectionFailure> {}

export interface CopyPredefinedShapes extends Action {
  type: ClipboardTypes.CopyPredefinedShapes;
  payload: {
    predefinedShapes: CircuitShape[];
    defaultPredefinedShapes: GeoJsonCircuit;
  };
}

export interface CopyPredefinedShapesSuccess extends Action {
  type: ClipboardTypes.CopyPredefinedShapesSuccess;
  payload: {
    shapesData: Feature[];
  };
}

export interface CopyPredefinedShapesFailure extends Failure<ClipboardTypes.CopyPredefinedShapesFailure> {}

export function copySelectionAction(): CopySelection {
  return { type: ClipboardTypes.CopySelection, payload: {} };
}

export function copySelectionSuccessAction(payload: CopySelectionSuccess['payload']): CopySelectionSuccess {
  return { type: ClipboardTypes.CopySelectionSuccess, payload };
}

export function copySelectionFailureAction(error: Error): CopySelectionFailure {
  return failureAction(ClipboardTypes.CopySelectionFailure, error);
}

export function copyPredefinedShapesAction(payload: CopyPredefinedShapes['payload']): CopyPredefinedShapes {
  return { type: ClipboardTypes.CopyPredefinedShapes, payload };
}

export function copyPredefinedShapesSuccessAction(
  payload: CopyPredefinedShapesSuccess['payload']
): CopyPredefinedShapesSuccess {
  return { type: ClipboardTypes.CopyPredefinedShapesSuccess, payload };
}

export function copyPredefinedShapesFailureAction(error: Error): CopyPredefinedShapesFailure {
  return failureAction(ClipboardTypes.CopyPredefinedShapesFailure, error);
}

export interface PasteSelection extends Action {
  type: ClipboardTypes.PasteSelection;
  payload: { userAction: boolean; shortcut: boolean };
}
export interface PasteSelectionSuccess extends Action {
  type: ClipboardTypes.PasteSelectionSuccess;
  payload: {
    shapesData: Feature[];
  } & RoadEditorAction['payload'];
}
export interface PasteSelectionFailure extends Failure<ClipboardTypes.PasteSelectionFailure> {}

export function pasteSelectionAction(manual?: boolean, shortcut?: boolean): PasteSelection {
  return { type: ClipboardTypes.PasteSelection, payload: { userAction: !!manual, shortcut: !!shortcut } };
}

export function pasteSelectionSuccessAction(payload: PasteSelectionSuccess['payload']): PasteSelectionSuccess {
  return { type: ClipboardTypes.PasteSelectionSuccess, payload };
}

export function pasteSelectionFailureAction(error: Error): PasteSelectionFailure {
  return failureAction(ClipboardTypes.PasteSelectionFailure, error);
}

export function generateCopiedName(oldName: string): string {
  return CircuitService.generateDifferentName(oldName);
}

interface ClipboardMetaData {
  isRoadEditorData: boolean;
  nbShapes: number;
  nbTurns: number;
}

export async function getClipboardData(): Promise<ClipboardMetaData> {
  let clipboardTxt: string;
  try {
    clipboardTxt = await navigator.clipboard.readText();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    return { isRoadEditorData: false, nbShapes: NaN, nbTurns: NaN };
  }

  if (clipboardTxt) {
    const clipboardJson: FeatureCollectionWithProperties | null = (() => {
      try {
        return JSON.parse(clipboardTxt) as FeatureCollectionWithProperties;
      } catch (e) {
        return null;
      }
    })();

    if (clipboardJson && clipboardJson.properties && clipboardJson.properties.software === 'Balyo Road Editor') {
      let nbShapes = 0;
      let nbTurns = 0;

      clipboardJson.features.forEach((feature) => {
        if (feature && feature.properties && feature.properties.type) {
          nbShapes++;
          if (feature.properties.type === ShapeTypes.TurnShape) nbTurns++;
        }
      });

      return { isRoadEditorData: true, nbShapes, nbTurns };
    }
  }

  return { isRoadEditorData: false, nbShapes: NaN, nbTurns: NaN };
}
