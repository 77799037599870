import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { closeDialogAction } from 'actions';
import { CollapseMore } from 'components/utils/collapse-more';
import { DisableChildrenConditionally } from 'components/utils/disable-children-conditionally';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { Tools } from 'models/tools';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { checkNeedUpgradeSDK, getMinimumViableSdkVersion } from 'utils/incompatible-versions';
import { PreferencesService } from 'utils/preferences';
import { CircuitVersionAndDescription } from './circuit-version-and-description';
import { DetectAislesBtn } from './detect-aisles-btn';
import { EditCommunicationMode } from './edit-communication-mode';
import { EditLanguage } from './edit-language';
import { EditLidarMap } from './edit-lidar-map';
import EditSupervisorIP from './edit-supervisor-ip';
import { EnableDeadlockSolverSwitch } from './enable-deadlock-solver-switch';
import { EnableElevatorSwitch } from './enable-elevator-switch';
import { ExportAllSlotsBtn } from './export-all-slots-btn';
import { FireAlarm } from './fire-alarm';
import { ImportMapImageListItem } from './import-map-image-list-item';
import { ManageDevicePreferences } from './manage-device-preferences';
import { MaxHeightItemsPreferences } from './max-height-items-preferences';
import { RackAnalysisBtn } from './rack-analysis-btn';
import { ActionEditor } from './RMI-actions/actions-editor';
import { SimulationSettingsSection } from './simulation-settings';
import { TranslateRotateWholeCircuitBtns } from './translate-rotate-whole-circuit-btns';
import VersionTooLowChip from './version-too-low-chip';

export function ProjectSettingsDialog(): JSX.Element {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  /* New state to control the display of the ActionEditor dialog */
  const [openActionEditorDialog, setOpenActionEditorDialog] = useState(false);

  /* Project opened is used as a component refresh when it is incremented */
  const [projectOpened, setProjectOpened] = useState(PreferencesService.arePreferencesFullyLoaded() ? 1 : 0);

  /* Increment the project opened state when there is a remote pref update so that you don't have to close & re-open the dialog to have the data updated */
  useEffect(() => {
    const listener = (): void => {
      setProjectOpened((prev) => prev + 1);
    };

    document.addEventListener('update-pref-remote', listener);

    return () => document.removeEventListener('update-pref-remote', listener);
  }, []);

  const [open, setOpen] = useState(true);

  const handleClose = useCallback((): void => {
    setOpen(false);

    setTimeout(() => {
      dispatch(closeDialogAction());
    }, theme.transitions.duration.leavingScreen);
  }, [dispatch, theme.transitions.duration.leavingScreen]);

  const projectName = useMemo(() => {
    return projectOpened ? PreferencesService.getProjectName() : undefined;
  }, [projectOpened]);

  const sdkVersion = useMemo(() => {
    return projectOpened ? PreferencesService.getSDKVersion() : undefined;
  }, [projectOpened]);

  const isSdkVersionOk = useMemo(() => {
    return !sdkVersion || typeof sdkVersion !== 'string' ? true : !checkNeedUpgradeSDK(sdkVersion);
  }, [sdkVersion]);

  const minimumViableSdkVersion = useMemo(() => {
    return !isSdkVersionOk ? getMinimumViableSdkVersion() : undefined;
  }, [isSdkVersionOk]);

  const NEW4XCoreVersion = useMemo(() => {
    return projectOpened ? PreferencesService.getNEW4XCoreVersion() : undefined;
  }, [projectOpened]);

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  const displayManageDevicePreference = !!projectName;
  const displayAdvancedParameters = displayManageDevicePreference;

  const collabSimulationMode = useAppSelector(
    (state) => state.editor.editorMode !== 'circuit' && state.multiplayer.multiplayer
  );
  const inSimulationConfig = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);

  const dialogContentRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (!collabSimulationMode || !dialogContentRef.current) return;

    dialogContentRef.current.children[0].scrollIntoView({ block: 'end', behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collabSimulationMode, dialogContentRef.current]);

  const collabSimuTooltip = 'Go back to circuit edition to change this setting';
  const simulationConfigTooltip = 'Exit simulation configuration mode to change this setting';

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="project-settings-title">
        <DialogTitle
          id="project-settings-title"
          sx={{ backgroundColor: 'white', zIndex: 1, paddingBottom: 0, marginBottom: '16px' }}
        >
          Project Settings {!!projectName && <Chip label={projectName} sx={{ float: 'right' }} />}
        </DialogTitle>

        <DialogContent
          sx={{
            flexDirection: 'row',
            columnGap: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            zIndex: 10,
            overflowY: 'unset',
          }}
        >
          <VersionTooLowChip
            projectOpened={!!projectOpened}
            isSdkVersionOk={isSdkVersionOk}
            sdkVersion={sdkVersion}
            minimumViableSdkVersion={minimumViableSdkVersion}
            NEW4XCoreVersion={NEW4XCoreVersion}
          />
        </DialogContent>
        <DialogContent sx={{ marginTop: '-70px' }} ref={dialogContentRef}>
          <List>
            <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
              <CircuitVersionAndDescription />
            </DisableChildrenConditionally>

            <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
              <EditSupervisorIP editProjectPerm={editProjectPerm} projectOpened={!!projectOpened} />
            </DisableChildrenConditionally>

            <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
              <EditCommunicationMode projectOpened={!!projectOpened} />
            </DisableChildrenConditionally>

            <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
              <EditLanguage projectOpened={!!projectOpened} />
            </DisableChildrenConditionally>

            <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
              <EditLidarMap projectOpened={!!projectOpened} editProjectPerm={editProjectPerm} />
            </DisableChildrenConditionally>

            <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
              <ImportMapImageListItem />
            </DisableChildrenConditionally>

            {projectOpened ? (
              <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
                <MaxHeightItemsPreferences projectOpened={projectOpened} />
              </DisableChildrenConditionally>
            ) : null}

            <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
              <DetectAislesBtn />
            </DisableChildrenConditionally>

            {false && ( // temporarily disable the button before the next release
              <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
                <Box component="div" my={0.5}>
                  <ListItem>
                    <ListItemIcon>
                      <SettingsSuggestIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <Button variant="outlined" onClick={() => setOpenActionEditorDialog(true)}>
                            RMI Action Editor
                          </Button>
                        </>
                      }
                      secondary={
                        <>
                          Create or update RMI actions
                          <HelpIconTooltip
                            title="The RMI (Robot Manager Interface) lets the user configure custom actions (such as bringing a robot to the maintenance area, open a door, etc.). This menu lets you configure these RMI actions."
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </>
                      }
                    />
                  </ListItem>
                </Box>
              </DisableChildrenConditionally>
            )}

            {projectOpened ? (
              <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
                <FireAlarm projectOpened={projectOpened} />
              </DisableChildrenConditionally>
            ) : null}

            {projectOpened ? (
              <DisableChildrenConditionally condition={inSimulationConfig} tooltip={simulationConfigTooltip}>
                <SimulationSettingsSection projectOpened={!!projectOpened} />
              </DisableChildrenConditionally>
            ) : null}

            {displayAdvancedParameters && (
              <DisableChildrenConditionally condition={collabSimulationMode} tooltip={collabSimuTooltip}>
                <CollapseMore title="Advanced User">
                  {displayManageDevicePreference && <ManageDevicePreferences />}

                  <EnableDeadlockSolverSwitch projectOpened={!!projectOpened} />

                  <EnableElevatorSwitch projectOpened={!!projectOpened} />

                  <TranslateRotateWholeCircuitBtns />

                  <RackAnalysisBtn />

                  <ExportAllSlotsBtn />
                </CollapseMore>
              </DisableChildrenConditionally>
            )}
          </List>
        </DialogContent>
      </Dialog>
      {/* New dialog for the RMI Action Editor */}
      <Dialog
        open={openActionEditorDialog}
        onClose={() => setOpenActionEditorDialog(false)}
        fullWidth={true}
        maxWidth="lg"
        aria-labelledby="action-editor-dialog-title"
      >
        <DialogTitle id="action-editor-dialog-title">RMI Action Editor</DialogTitle>
        <DialogContent>
          <ActionEditor />
        </DialogContent>
      </Dialog>
    </>
  );
}
