import { setLastCircuitTransactionOrigin } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import type { YArray, YMap, YText } from 'yjs/dist/src/internals';

export interface UseYJSSubscriptionHookProps {
  YType: YText | YMap<any> | YArray<any> | undefined;
  callback: () => void;
  updateLastCircuitTransactionOrigin?: boolean;
}

export function useYJSSubscriptionHook(props: UseYJSSubscriptionHookProps): void {
  const { YType, callback, updateLastCircuitTransactionOrigin = false } = props;

  useLayoutEffect(() => {
    if (!YType) return;

    const observerHandle = (_, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      if (updateLastCircuitTransactionOrigin) setLastCircuitTransactionOrigin(isTransactionLocal ? 'local' : 'remote');
      if (isTransactionLocal) return;

      callback();
    };

    YType?.observe(observerHandle);

    return () => {
      YType?.unobserve(observerHandle);
    };
  }, [YType, callback, updateLastCircuitTransactionOrigin]);
}
