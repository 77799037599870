import type { RackPosition } from 'components/editor/search-shape';
import type { Position } from 'geojson';
import type { CellLoad, CircuitRack, RackCellTemplate } from 'models/circuit';
import { rotateCoordinates } from '../../drawings/helpers';
import { getDistanceBetweenPoints } from '../../librarycircuit/utils/geometry/vectors';
import { toRad } from '../../utils/helpers';

/**
 * Minimum distance between a load and a load or between a load and an upright [m]
 */
export const MINI_MARGIN_LOAD = 0.05;

/**
 * Minimum height of a cell [m]
 */
export const cellHeightMinHeight = 0.144;
/**
 * Maximum height of a cell [m], define it to undefined to delete this limitation
 */
export const cellHeightMaxHeight = 5.0;

/** Minimum beam thickness [m] */
export const beamThicknessMin = 0.0;
/** Maxmimum beam thickness [m] */
export const beamThicknessMax = 1.0;

/** Minimum number of levels in a rack [1] */
export const nbLevelsMin = 1;
/** Maximum number of levels in a rack [1] */
export const nbLevelsMax = 50;

/** Minimum number of columns in a rack [1] */
export const nbColumnsMin = 1;
/** Maximum number of columns in a rack [1] */
export const nbColumnsMax = 100;

/** Minimum extended length [m] */
export const extendedLengthMin = 0.0;

/** Minimum start height [m] */
export const startHeightMin = 0.0;
/** Maximum start height [m] */
export const startHeightMax = 100;

/** Minimum column width [m] */
export const columnWidthMin = 0.1;
/** Maximum column width [m] */
export const columnWidthMax = 10;

/** Minimum Approach Distance [m] */
export const approachDistanceMin = 0.0;

/** Minimum number of loads in a cell [1] */
export const nbLoadsMin = 1;
/** Maximum number of loads in a cell [1] */
export const nbLoadsMax = 50;

/** Minimum upright width [m] */
export const uprightWidthMin = 0;
/** Maximum upright width [m] */
export const uprightWidthMax = 5;

/** Height of the upright (upright height = column height + uprightOverflow) [m] */
export const uprightOverflow = 0.1;

/** default width of a cell for a conveyor [m] */
export const defaultConveyorWidth = 1.0;
/** default cell height for a conveyor [m] */
export const defaultConveyorCellHeight = 1.5;

/** default start height for a conveyor [m] */
export const defaultConveyorStartHeight = 1.0;

/**
 * Compute the width taken by the pallets (loads) in a cell
 * @param load the load
 * @param includesClearance true if want to include the clearance/margin
 * @returns the width taken by the load [m]
 */
export function computeWidthTakenByLoads(load: CellLoad, includesClearance = true): number {
  let widthTakenByLoads = load.N * load.W + load.a1 + (load.N !== 1 ? load.a2 ?? load.a1 : 0);

  if (includesClearance && load.N >= 1) {
    widthTakenByLoads += (load.N - 1) * MINI_MARGIN_LOAD;
  }

  return widthTakenByLoads;
}

/**
 * Checks if the positions names of a list of racks are unique,
 * including a new set of position names.
 * @param racks The list of racks.
 * @param newPositionNames A list of new position names to check.
 * @param option Optional parameters, such as displayWarning.
 * @returns Whether the positions names are unique.
 */
export function checkUnicityRackPositionsName(
  racks: CircuitRack[],
  newPositionNames?: string[][] | undefined,
  option?: { displayWarning?: boolean }
): boolean {
  const posNames = new Set<string>();
  let res = true;
  const duplicatedNames = new Set<string>();

  racks.forEach((rack) => {
    rack.properties.columns.forEach((column) => {
      column.cells.forEach((cell) => {
        if (cell.names)
          cell.names.forEach((name) => {
            name.forEach((n) => {
              const sizeBefore = posNames.size;
              posNames.add(n.value);
              const sizeAfter = posNames.size;

              // if the size of the set does not change after an addition, it means that the name is already used
              if (sizeBefore === sizeAfter) {
                res = false;

                duplicatedNames.add(n.value);
              }
            });
          });
      });
    });
  });

  if (newPositionNames) {
    newPositionNames.forEach((nameList) => {
      nameList.forEach((name) => {
        if (posNames.has(name)) {
          res = false;
          duplicatedNames.add(name);
        } else {
          posNames.add(name);
        }
      });
    });
  }

  if (duplicatedNames.size && option?.displayWarning) {
    // eslint-disable-next-line no-console
    console.warn(
      `The following ${duplicatedNames.size} names are duplicated: ${Array.from(duplicatedNames).join(', ')}`
    );
  }

  return res;
}

/**
 * Get all rack positions in the circuit
 * @returns An array of rack positions with their corresponding rack ID
 */
export function getAllRackPositions(): (RackPosition & { rackId: string })[] {
  const rackIds = window.getStoreState().circuit.present.racks.ids;
  const racks = window.getStoreState().circuit.present.racks.entities;
  const racksArray = rackIds.map((id) => racks[id]);

  const resByColumn: Record<string, CircuitRack['properties']['columns'][0]['cells'][0]['names'][0]> = {};

  racksArray.forEach((rack) => {
    const rackId = rack.id?.toString();
    if (!rackId) return;

    const cellPositions = rack.properties.columns
      .flatMap((column) => column.cells.flatMap((cell) => cell.names))
      .flat();
    resByColumn[rackId] = cellPositions;
  });

  const res = Object.keys(resByColumn).flatMap((rackId) => {
    const posByRack = resByColumn[rackId];
    const pos = posByRack.map((pos) => ({ ...pos, rackId }));

    return pos;
  });

  return res;
}

/**
 * Get the position of a rack in the circuit by its ID
 * @param positionId - The ID of the position
 * @returns The rack position if found, otherwise undefined
 */
export function getRackPosition(positionId: string): RackPosition | undefined {
  const rackIds = window.getStoreState().circuit.present.racks.ids;
  const racks = window.getStoreState().circuit.present.racks.entities;
  const racksArray = rackIds.map((id) => racks[id]);

  for (let i = 0; i < racksArray.length; i++) {
    const rack = racksArray[i];
    const rackId = rack.id?.toString();
    if (!rackId) return;

    const cellPositions = rack.properties.columns
      .flatMap((column) => column.cells.flatMap((cell) => cell.names))
      .flat();

    for (let j = 0; j < cellPositions.length; j++) {
      const pos = cellPositions[j];
      if (pos.id === positionId) {
        return pos;
      }
    }
  }
}

/**
 * Compute the position of the uprights of a rack
 * @param rack the rack
 * @returns an object with the position of the uprights, their width, and wether they are enabled
 */
export function computeUprightsGeometry(
  rack: CircuitRack
): { coords: Position[]; width: number; enabled: boolean; polygon: Position[]; posInX: number }[] {
  const res: ReturnType<typeof computeUprightsGeometry> = [];

  const coords = rack.geometry.coordinates[0];
  const props = rack.properties;
  const angle = toRad(props.cap);

  const columns = props.columns;
  const uprights = props.uprights;

  // const uprightHeight = computeRackHeight(columns) + uprightOverflow;

  // from 0 to 1; percent of progress from the left of the rack to the right of the rack
  let progress = 0;

  const x0 = coords[0][0];
  const y0 = coords[0][1];
  const x01 = coords[3][0];
  const y01 = coords[3][1];

  const widthRack = getDistanceBetweenPoints(coords[0], coords[1]);
  // const depthRack = getDistanceBetweenPoints(coords[0], coords[3]);

  let posInX = 0;

  // we draw the uprights that are not in the extremities
  for (let i = 1, nbColumns = columns.length; i < nbColumns; i++) {
    const prevColumn = columns[i - 1];
    const prevUpright = uprights[i - 1];
    const upright = uprights[i];
    const prevUprightWidth = prevUpright.enabled ? prevUpright.width : 0;
    const uprightWidth = upright.enabled ? upright.width : 0;
    progress += ((prevUprightWidth + prevColumn.width) * 100) / widthRack;
    posInX = progress * widthRack;

    // if (!upright.enabled) continue;

    // we center the upright and draw a line with the upright width
    const x1 = x0 + (progress + (uprightWidth * 100) / 2 / widthRack) * widthRack * Math.cos(angle);
    const y1 = y0 + (progress + (uprightWidth * 100) / 2 / widthRack) * widthRack * Math.sin(angle);
    const x2 = x01 + (progress + (uprightWidth * 100) / 2 / widthRack) * widthRack * Math.cos(angle);
    const y2 = y01 + (progress + (uprightWidth * 100) / 2 / widthRack) * widthRack * Math.sin(angle);

    const topLeftX = x1 - ((uprightWidth * 100) / 2) * Math.cos(angle);
    const topLeftY = y1 - ((uprightWidth * 100) / 2) * Math.sin(angle);
    const topRightX = x1 + ((uprightWidth * 100) / 2) * Math.cos(angle);
    const topRightY = y1 + ((uprightWidth * 100) / 2) * Math.sin(angle);
    const bottomLeftX = x2 - ((uprightWidth * 100) / 2) * Math.cos(angle);
    const bottomLeftY = y2 - ((uprightWidth * 100) / 2) * Math.sin(angle);
    const bottomRightX = x2 + ((uprightWidth * 100) / 2) * Math.cos(angle);
    const bottomRightY = y2 + ((uprightWidth * 100) / 2) * Math.sin(angle);

    res.push({
      coords: [
        [x1, y1],
        [x2, y2],
      ],
      polygon: [
        [topLeftX, topLeftY],
        [topRightX, topRightY],
        [bottomRightX, bottomRightY],
        [bottomLeftX, bottomLeftY],
      ],
      width: uprightWidth,
      enabled: upright.enabled,
      posInX,
    });
  }

  // and then the uprights at the extremities of the rack
  // if (uprights[0].enabled) {
  {
    const uprightWidth = uprights[0].width;
    const progress = ((uprightWidth / 2) * 100) / widthRack;
    const x1 = x0 + progress * widthRack * Math.cos(angle);
    const y1 = y0 + progress * widthRack * Math.sin(angle);
    const x2 = x01 + progress * widthRack * Math.cos(angle);
    const y2 = y01 + progress * widthRack * Math.sin(angle);

    const topLeftX = x1 - ((uprightWidth * 100) / 2) * Math.cos(angle);
    const topLeftY = y1 - ((uprightWidth * 100) / 2) * Math.sin(angle);
    const topRightX = x1 + ((uprightWidth * 100) / 2) * Math.cos(angle);
    const topRightY = y1 + ((uprightWidth * 100) / 2) * Math.sin(angle);
    const bottomLeftX = x2 - ((uprightWidth * 100) / 2) * Math.cos(angle);
    const bottomLeftY = y2 - ((uprightWidth * 100) / 2) * Math.sin(angle);
    const bottomRightX = x2 + ((uprightWidth * 100) / 2) * Math.cos(angle);
    const bottomRightY = y2 + ((uprightWidth * 100) / 2) * Math.sin(angle);

    res.unshift({
      coords: [
        [x1, y1],
        [x2, y2],
      ],
      polygon: [
        [topLeftX, topLeftY],
        [topRightX, topRightY],
        [bottomRightX, bottomRightY],
        [bottomLeftX, bottomLeftY],
      ],
      width: uprightWidth,
      enabled: uprights[0].enabled,
      posInX: 0,
    });
  }
  // }

  // if (uprights[uprights.length - 1].enabled) {
  {
    const uprightWidth = uprights[uprights.length - 1].width;
    const progress = (widthRack - (uprightWidth / 2) * 100) / widthRack;
    const x1 = x0 + progress * widthRack * Math.cos(angle);
    const y1 = y0 + progress * widthRack * Math.sin(angle);
    const x2 = x01 + progress * widthRack * Math.cos(angle);
    const y2 = y01 + progress * widthRack * Math.sin(angle);

    const topLeftX = x1 - ((uprightWidth * 100) / 2) * Math.cos(angle);
    const topLeftY = y1 - ((uprightWidth * 100) / 2) * Math.sin(angle);
    const topRightX = x1 + ((uprightWidth * 100) / 2) * Math.cos(angle);
    const topRightY = y1 + ((uprightWidth * 100) / 2) * Math.sin(angle);
    const bottomLeftX = x2 - ((uprightWidth * 100) / 2) * Math.cos(angle);
    const bottomLeftY = y2 - ((uprightWidth * 100) / 2) * Math.sin(angle);
    const bottomRightX = x2 + ((uprightWidth * 100) / 2) * Math.cos(angle);
    const bottomRightY = y2 + ((uprightWidth * 100) / 2) * Math.sin(angle);

    res.push({
      coords: [
        [x1, y1],
        [x2, y2],
      ],
      polygon: [
        [topLeftX, topLeftY],
        [topRightX, topRightY],
        [bottomRightX, bottomRightY],
        [bottomLeftX, bottomLeftY],
      ],
      width: uprightWidth,
      enabled: uprights[uprights.length - 1].enabled,
      posInX: progress * widthRack,
    });
  }

  return res;
}

/**
 * Compute the position of the pallet overflow
 * @param rack the rack
 * @param cellTemplates the cell templates presente in the circuit
 * @param performanceModeEnabled is the performance mode enabled or not
 * @returns an object with the position of the pallet overflow and wether they are enabled
 */
export function computePalletOverflowGeometry(
  rack: CircuitRack,
  cellTemplates: Record<string, RackCellTemplate>
): { coords: Position[] }[] {
  const res: ReturnType<typeof computePalletOverflowGeometry> = [];

  const coords = rack.geometry.coordinates[0];
  const props = rack.properties;
  const angle = toRad(props.cap + 90);
  const columns = props.columns;

  // in cm
  let maxPalletOverflow = 0;

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    const cells = column.cells;

    for (let j = 0; j < cells.length; j++) {
      const cell = cells[j];

      if (cell.disabled) continue;

      const cellTemplateId = cell.cellTemplate;
      if (!cellTemplateId) continue; // no cell template, no position

      const cellTemplate = cellTemplates[cellTemplateId];

      // in cm
      const palletOverflow = cellTemplate.palletOverflow * 100;

      if (palletOverflow > maxPalletOverflow) {
        maxPalletOverflow = palletOverflow;
      }
    }
  }

  const orientedDistanceDown = [-maxPalletOverflow * Math.cos(angle), -maxPalletOverflow * Math.sin(angle)];

  const coordsWithOffsetDown = coords.map((c) => [c[0] + orientedDistanceDown[0], c[1] + orientedDistanceDown[1]]);

  if (maxPalletOverflow > 0) {
    res.push({
      coords: [coordsWithOffsetDown[2], coordsWithOffsetDown[3]],
    });
  }

  return res;
}

/**
 * Computes the height of a rack column
 * @param column the column to compute the height
 * @returns the computed height [m]
 */
export function computeRackColumnHeight(column: CircuitRack['properties']['columns'][0]): number {
  let columnHeight = column.startHeight;
  for (let i = 0; i < column.cells.length; i++) {
    const cell = column.cells[i];

    columnHeight += cell.height;
  }

  return columnHeight;
}

/**
 * Computes the height of a rack, e.g. the max height of all the columns
 * @param rackOrColumns the rack (or the columns) to compute the height
 * @returns the computed height [m]
 */
export function computeRackHeight(rackOrColumns: CircuitRack | CircuitRack['properties']['columns']): number {
  const columns = Array.isArray(rackOrColumns) ? rackOrColumns : rackOrColumns.properties.columns;

  let h = 0;
  for (const column of columns) {
    const columnHeight = computeRackColumnHeight(column);

    h = Math.max(h, columnHeight);
  }

  return h;
}

/**
 * Compute the length of a rack
 * @param columns the columns of the rack
 * @param uprights the uprights of the rack
 * @returns the length of the rack (or the width depending of the point of view) [m]
 */
export function computeRackLength(
  columns: CircuitRack['properties']['columns'],
  uprights: CircuitRack['properties']['uprights']
): number {
  const wColumns = columns.reduce((acc, column) => acc + column.width, 0);
  const wUprights = uprights.reduce((acc, upright) => acc + (upright.enabled ? upright.width : 0), 0);

  return wColumns + wUprights;
}

/**
 * The function compute the rack coordinates from the rack origin (top left point)
 * @param rack the rack
 * @param columns the new columns
 * @param uprights the new uprights
 * @returns the coordinates of the rack
 */
export function computeRackCoordinates(
  rack: CircuitRack,
  columns: CircuitRack['properties']['columns'],
  uprights: CircuitRack['properties']['uprights']
): CircuitRack['geometry']['coordinates'][0] {
  const x1 = rack.geometry.coordinates[0][0][0];
  const y1 = rack.geometry.coordinates[0][0][1];
  const orientation = rack.properties.cap;

  // we need to compute the geometry of the racks with the new data
  const actualLength = computeRackLength(columns, uprights) * 100;

  const depth = rack.properties.depth * 100;

  const xx = x1 + actualLength;
  const yy = y1 - depth;
  let coordinates = [
    [x1, y1],
    [xx, y1],
    [xx, yy],
    [x1, yy],
    [x1, y1],
  ];

  if (orientation) {
    const rotationCenter = [x1, y1];
    [coordinates] = rotateCoordinates(rotationCenter, [coordinates], -orientation);
  }

  return coordinates;
}
