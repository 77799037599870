import type { SelectChangeEvent } from '@mui/material';
import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { IntervalTrigger, Trigger } from 'models/simulation';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { setTrigger } from 'simulation/triggers';
import { useAppDispatch, useAppSelector } from 'store';
import { integerInputIfNeeded } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { ListFlowsInTriggers } from './list-flows-in-triggers';
import { TriggerPriorityTextfield } from './priority-textfield';

interface IntervalTriggerConfigurationProps {
  trigger: IntervalTrigger;
}
export function IntervalTriggerConfiguration(props: IntervalTriggerConfigurationProps): JSX.Element {
  const { trigger } = props;

  const [errorValue, setErrorValue] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const inputRefStartAt = useRef<HTMLInputElement>(null);
  const inputRefStopAt = useRef<HTMLInputElement>(null);
  const inputRefInterval = useRef<HTMLInputElement>(null);
  const inputRefThroughput = useRef<HTMLInputElement>(null);
  const inputRefCount = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const flows = useAppSelector((state) => state.flows.flows);

  const flowIds = useMemo(() => (Array.isArray(trigger.flowId) ? trigger.flowId : [trigger.flowId]), [trigger.flowId]);

  const handleChangeFlow = useCallback(
    (e: SelectChangeEvent<string>, index: number) => {
      const selectFlowId = e.target.value;

      const newFlowIds = flowIds.map((flowId, i) => (i === index ? selectFlowId : flowId));

      dispatch(
        setTrigger({
          ...trigger,
          flowId: newFlowIds,
        })
      );
    },
    [dispatch, flowIds, trigger]
  );

  const handleAddFlow = useCallback(() => {
    dispatch(
      setTrigger({
        ...trigger,
        flowId: [...flowIds, flows[0].id],
      })
    );
  }, [dispatch, flowIds, flows, trigger]);

  const handleRemoveFlow = useCallback(
    (index: number) => {
      const newFlowIds = flowIds.filter((_, i) => i !== index);

      dispatch(
        setTrigger({
          ...trigger,
          flowId: newFlowIds,
        })
      );
    },
    [dispatch, flowIds, trigger]
  );

  const defaultStartAtValue = useMemo(
    () => (trigger.startAt && trigger.startAt > 0 ? trigger.startAt.toString() : ''),
    [trigger.startAt]
  );

  const defaultStopAtValue = useMemo(
    () => (trigger.stopAt && trigger.stopAt > 0 ? trigger.stopAt.toString() : ''),
    [trigger.stopAt]
  );

  const defaultIntervalValue = useMemo(() => {
    const interval = trigger.interval;

    return integerInputIfNeeded(interval, 1);
  }, [trigger.interval]);

  const defaultThroughputValue = useMemo(
    () => Math.round(((trigger.count ?? 1) * 3600) / trigger.interval).toString(),
    [trigger.count, trigger.interval]
  );

  const defaultCountValue = useMemo(() => (trigger.count ?? 1).toString(), [trigger.count]);

  const handleUpdateInterval = useCallback(() => {
    if (!inputRefInterval.current) return;

    const interval = inputRefInterval.current.valueAsNumber;
    if (!interval || interval < +inputRefInterval.current.min) {
      inputRefInterval.current.value = defaultIntervalValue;

      return;
    }

    const intervalRounded = integerInputIfNeeded(interval, 1);

    dispatch(
      setTrigger({
        ...trigger,
        interval: Math.round(+intervalRounded),
      })
    );
  }, [defaultIntervalValue, dispatch, trigger]);

  const handleUpdateThroughput = useCallback(() => {
    if (!inputRefThroughput.current) return;

    const throughput = inputRefThroughput.current.valueAsNumber;
    if (!throughput || throughput < +inputRefThroughput.current.min) {
      inputRefThroughput.current.value = defaultThroughputValue;

      return;
    }

    const interval = (+defaultCountValue * 3600) / throughput;

    dispatch(
      setTrigger({
        ...trigger,
        interval: Math.round(+interval),
      })
    );
  }, [defaultCountValue, defaultThroughputValue, dispatch, trigger]);

  const handleUpdateCount = useCallback(() => {
    if (!inputRefCount.current) return;

    const count = inputRefCount.current.valueAsNumber;
    if (!count || count < +inputRefCount.current.min) {
      inputRefCount.current.value = defaultCountValue;

      return;
    }

    dispatch(
      setTrigger({
        ...trigger,
        count: Math.round(count),
      })
    );
  }, [defaultCountValue, dispatch, trigger]);

  const handleUpdateStartAtValue = useCallback(() => {
    const startAt = inputRefStartAt.current?.valueAsNumber ?? 0;

    const stopAt = inputRefStopAt.current?.valueAsNumber;
    if (stopAt && stopAt <= startAt) {
      dispatch(
        setTrigger({
          ...trigger,
          startAt,
          stopAt: Infinity,
        })
      );

      return;
    }

    dispatch(
      setTrigger({
        ...trigger,
        startAt,
      })
    );
  }, [dispatch, trigger]);

  const handleUpdateStopAtValue = useCallback(() => {
    let stopAt = inputRefStopAt.current?.valueAsNumber;

    if ((stopAt !== undefined && (isNaN(stopAt) || stopAt < 0)) || stopAt === 0) {
      stopAt = Infinity;
    }

    if (trigger.startAt && stopAt && trigger.startAt >= stopAt) {
      setErrorValue(true);

      setErrorMessage(trigger.startAt === stopAt ? 'Equal to start at' : 'Less than start at');

      // To avoid saving a wrong stop at value
      dispatch(
        setTrigger({
          ...trigger,
          stopAt: Infinity,
        })
      );
    } else {
      setErrorValue(false);
      setErrorMessage('');

      dispatch(
        setTrigger({
          ...trigger,
          stopAt: stopAt ?? Infinity,
        })
      );
    }
  }, [dispatch, trigger]);

  const intervalLabel = (
    <>
      Interval <HelpIconTooltip title="A task will be triggered every X seconds." />
    </>
  );
  const throughputLabel = (
    <>
      Throughput{' '}
      <HelpIconTooltip
        title={`A task will be triggered every ${trigger.interval.toFixed(0)} seconds to reach the target throughput.`}
      />
    </>
  );
  const countLabel = (
    <>
      Count <HelpIconTooltip title="Number of tasks to create every time the trigger runs." />
    </>
  );

  const startAtLabel = (
    <>
      Start At <HelpIconTooltip title="Time of the simulation from which the first task will be created" />
    </>
  );

  const stopAtLabel = (
    <>
      Stop At <HelpIconTooltip title="Simulation time from which no more tasks will be created" />
    </>
  );

  useLayoutEffect(() => {
    if (inputRefInterval.current && defaultIntervalValue !== inputRefInterval.current.value) {
      inputRefInterval.current.value = defaultIntervalValue;
    }

    if (inputRefThroughput.current && defaultThroughputValue !== inputRefThroughput.current.value) {
      inputRefThroughput.current.value = defaultThroughputValue;
    }

    if (inputRefCount.current && defaultCountValue !== inputRefCount.current.value) {
      inputRefCount.current.value = defaultCountValue;
    }

    if (inputRefStartAt.current && defaultStartAtValue !== inputRefStartAt.current.value) {
      inputRefStartAt.current.value = defaultStartAtValue;
    }

    if (inputRefStopAt.current && defaultStopAtValue !== inputRefStopAt.current.value) {
      inputRefStopAt.current.value = defaultStopAtValue;
    }
  }, [defaultCountValue, defaultIntervalValue, defaultStartAtValue, defaultStopAtValue, defaultThroughputValue]);

  const preventNegativeValue = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  }, []);

  return (
    <Border
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <ListFlowsInTriggers
        flowIds={flowIds}
        flows={flows}
        handleAddFlow={handleAddFlow}
        handleChangeFlow={handleChangeFlow}
        handleRemoveFlow={handleRemoveFlow}
        disabled={trigger.linked}
      />

      <Stack direction="row" spacing={1} useFlexGap>
        <TextField
          label={startAtLabel}
          InputLabelProps={{ shrink: true }}
          type="number"
          defaultValue={defaultStartAtValue}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 's',
          }}
          inputProps={{
            min: 0,
            step: 1,
          }}
          inputRef={inputRefStartAt}
          onBlur={handleUpdateStartAtValue}
          fullWidth
          disabled={trigger.linked}
          onKeyDown={preventNegativeValue}
        />
        <TextField
          label={stopAtLabel}
          InputLabelProps={{ shrink: true }}
          type="number"
          defaultValue={defaultStopAtValue}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 's',
          }}
          inputProps={{
            min: 1,
            step: 1,
          }}
          inputRef={inputRefStopAt}
          onBlur={handleUpdateStopAtValue}
          error={errorValue}
          helperText={errorMessage}
          fullWidth
          disabled={trigger.linked}
          onKeyDown={preventNegativeValue}
        />
      </Stack>

      <Stack direction="row" spacing={1} useFlexGap>
        <TextField
          label={intervalLabel}
          type="number"
          defaultValue={defaultIntervalValue}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 's',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={inputRefInterval}
          onBlur={handleUpdateInterval}
          inputProps={{
            min: 1,
            step: 1,
          }}
          fullWidth
          disabled={trigger.linked}
          onKeyDown={preventNegativeValue}
        />

        <TextField
          label={throughputLabel}
          type="number"
          defaultValue={defaultThroughputValue}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 'tasks/hr',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 1,
            step: 0.1,
          }}
          onBlur={handleUpdateThroughput}
          inputRef={inputRefThroughput}
          fullWidth
          disabled={trigger.linked}
          onKeyDown={preventNegativeValue}
        />
      </Stack>

      <Stack direction="row" spacing={1} useFlexGap>
        <TextField
          label={countLabel}
          type="number"
          defaultValue={defaultCountValue}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 1,
            step: 1,
          }}
          onBlur={handleUpdateCount}
          inputRef={inputRefCount}
          fullWidth
          disabled={trigger.linked}
          onKeyDown={preventNegativeValue}
        />

        <TriggerPriorityTextfield trigger={trigger} />
      </Stack>
    </Border>
  );
}

/**
 * Type guard for intervalTrigger
 * @param trigger - The trigger to be checked
 * @returns A boolean indicating whether the trigger is a intervalTrigger
 */
export function isIntervalTrigger(trigger: Trigger): trigger is IntervalTrigger {
  return trigger.type === 'interval';
}
