import { updateGridCoordinateThrottled, updateZoomLevel } from 'actions';
import {
  selectCircuitShapeAction,
  selectCircuitShapesInRectAction,
  selectMultipleCircuitShapesAction,
} from 'actions/circuit';
import { saveSegmentAction } from 'actions/segment';
import * as d3 from 'd3';
import type { Position } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { ShapeTypes } from 'models/circuit';
import { isShapeType } from 'models/circuit.guard';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import { Tools } from 'models/tools';
import { startTransition } from 'react';
import type { SelectedShapesData } from 'reducers/local/state';
import { CircuitService } from 'services/circuit.service';
import store from 'store';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';
import type { DrawLayer } from './draw.layer';
import { getClosestPointInSegment } from './helpers';
import type { BaseLayer, CircuitLayer } from './layers';
import { AxisLayer, LayerNames } from './layers';
import type { MousePosition } from './mouse-data';
import { mouseData } from './mouse-data';
import type { SimpleSelection } from './shared';
import type { AskSegmentsConnectionDetail, StartConnectSegmentsDetail } from './utils';

const MIN_ZOOM = parseFloat(getConfig('editor').zoom.min);
const MAX_ZOOM = parseFloat(getConfig('editor').zoom.max);
const thresholdMouseMoved = 0.1;

const zoomRangeLevel: [[number, number], string][] = [
  [[0, 0.01], 'xxl'],
  [[0.01, 0.02], 'xl'],
  [[0.02, 0.06], 'l'],
  [[0.06, 0.1], 'm'],
  [[0.1, 0.3], 's'],
  [[0.3, 1.3], 'xs'],
  [[1.3, Infinity], 'xxs'],
];

export class BaseDrawing {
  protected container: SimpleSelection<HTMLDivElement>;
  public rootNode: SimpleSelection<SVGElement>;
  protected mainNode: SimpleSelection<SVGGElement>;
  public layers: Map<string, BaseLayer>;
  protected zoom: d3.ZoomBehavior<SVGElement, unknown> = d3
    .zoom<SVGElement, unknown>()
    // Set min -> max zoom level
    .scaleExtent([MIN_ZOOM, MAX_ZOOM])
    .translateExtent([
      [-1e100, -1e100],
      [1e100, 1e100],
    ])
    .on('zoom', () => this.handleZoom())
    .filter(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const event = d3.event;

      return event.type !== 'mousedown' || !(event.ctrlKey || event.button === 2);
    })
    .on('start', () => this.startZoom())
    .on('end', () => this.endZoom());

  protected zoomScale = 1;

  private lastClick: MousePosition = { x: 0, y: 0 };
  private isDraggingTurn = false;
  private lastIdSegmentDrag = '';
  private connectingSegments: string[] = [];

  private isRightClickDragging = false;
  private rightClickDragStartPos: MousePosition = { x: 0, y: 0 };

  constructor(container: HTMLDivElement) {
    this.layers = new Map();
    this.container = d3.select(container);
    this.rootNode = d3.select('#root-node-svg') as unknown as SimpleSelection<SVGElement, unknown>;
    this.mainNode = this.rootNode.select('g.translate-container');

    this.asyncConstructor();
  }

  public get isDraggingTurnEnabled(): boolean {
    return this.isDraggingTurn;
  }

  private async asyncConstructor(): Promise<void> {
    do {
      this.rootNode = d3.select('#root-node-svg') as unknown as SimpleSelection<SVGElement, unknown>;

      if (!this.rootNode.node()) await new Promise((resolve) => setTimeout(resolve, 10));
    } while (!this.rootNode.node());

    this.initZoomAndAxes();
    this.mainNode = this.rootNode.select('g.translate-container');
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.enableMousePosition();

    this.rootNode.on('mousedown', this.onClick);
    this.rootNode.on('click', this.onClick);
    this.rootNode.on('dragstart', this.onClick);
    this.rootNode.on('mouseup', this.onMouseUp);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.rootNode.on('contextmenu', () => d3.event.preventDefault() as null);

    this.rootNode.on('startConnectSegments', () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const event = d3.event;
      const detail = event.detail as StartConnectSegmentsDetail;
      const segmentsIds = detail.segmentsIds;

      this.isDraggingTurn = true;
      this.connectingSegments = segmentsIds;
    });
    this.rootNode.on('askSegmentsConnection', () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const e = d3.event;
      const detail = e.detail as AskSegmentsConnectionDetail;

      const segmentIdOrigin = detail.segmentId;
      const x = detail.x;
      const y = detail.y;

      const segments = store.getState().circuit.present.segments.entities;

      if (segmentIdOrigin && !isNaN(x) && !isNaN(y) && this.isDraggingTurn && this.connectingSegments?.length) {
        const segment = segments[segmentIdOrigin];

        if (!segment) {
          // eslint-disable-next-line no-console
          console.error(`Segment ${segmentIdOrigin} not found`);

          return;
        }

        if (segment.properties.locked) {
          store.dispatch(
            saveSegmentAction({
              id: segment.id,
              properties: {
                ...segment.properties,
                locked: false,
              },
            })
          );
        }

        const coords = segment.geometry.coordinates;

        const [closestPoint] = getClosestPointInSegment([x, y], coords[0], coords[1]);

        const segmentsIds = this.lastIdSegmentDrag ? [this.lastIdSegmentDrag] : this.connectingSegments;

        segmentsIds.forEach((segmentId, index) => {
          // const pt = segments[segmentId].geometry.coordinates[0];
          d3.select(`[uuid='${segmentId}'] path`).dispatch('fakeDragEnd', {
            bubbles: true,
            detail: {
              x: closestPoint[0],
              y: -closestPoint[1],
              destSegmentId: segmentIdOrigin,
              forceDrag: true,
              preventUserAction: index !== 0,
            },
          } as d3.CustomEventParameters);
        });

        this.isDraggingTurn = false;
        this.lastIdSegmentDrag = '';
        this.connectingSegments = [];
      }
    });
    this.rootNode.on('stopDraggingSegments', () => {
      if (this.isDraggingTurn) {
        this.isDraggingTurn = false;

        const connectingSegments = [...this.connectingSegments];
        if (this.lastIdSegmentDrag) connectingSegments.push(this.lastIdSegmentDrag);

        connectingSegments.forEach((segmentId) => {
          document.querySelector(`[uuid='${segmentId}']`)?.dispatchEvent(new CustomEvent('endDraggingMode'));
        });

        this.connectingSegments = [];
        this.lastIdSegmentDrag = '';
      }
    });
  }

  private enableMousePosition(): void {
    this.rootNode.on('mousemove', this.onMouseMove);
    this.rootNode.on('mouseover', this.onMouseMove);

    const previousParentNode = d3.select('#mouseInfoContainer').node();
    if (previousParentNode) d3.select(previousParentNode).remove();

    this.rootNode
      .append('svg:foreignObject')
      .attr('pointer-events', 'none')
      .attr('width', '100%')
      .attr('height', '100%')
      .append('xhtml:div')
      .attr('id', 'mouseInfoContainer')
      .style('position', 'absolute')
      .style('bottom', 0)
      .style('right', 0)
      .style('padding', `${theme.spacing(1)} ${theme.spacing(2)}`)
      .style('color', theme.palette.grey[700])
      .style('text-align', 'left')
      .style('background', 'rgba(255,255,255,0.5)')
      .style('display', 'grid')
      .style('grid-template-rows', 'repeat(3, 1fr)')
      .style('grid-template-columns', '1fr')
      .style('grid-auto-flow', 'column')
      .style('column-gap', '20px')
      .style('user-select', 'none');

    const container = this.rootNode.select('#mouseInfoContainer');

    mouseData.labelsToDisplay.forEach((label, i) => {
      /* Category title of the first label column */
      if (i === 0) {
        container
          .append('xhtml:div')
          .attr('mouse-data', true)
          .attr('label', 'Mouse position')
          .html('Mouse position')
          .style('font-weight', 300);
      }

      /* Category title of the second label column */
      if (i === 2) {
        container
          .append('xhtml:div')
          .attr('mouse-data', true)
          .attr('label', 'Last click')
          .html('Last click')
          .style('font-weight', 300);
      }

      const uppercaseLabel = label.toUpperCase();
      const isDistance = uppercaseLabel.startsWith('D');
      const distanceLabel = `d<sub>${label?.[1] || ''}</sub>`;
      const labelToDisplay = uppercaseLabel.replace('LASTCLICK', '');

      container
        .append('xhtml:div')
        .attr('mouse-data', true)
        .attr('label', label)
        .html(`${isDistance ? distanceLabel : labelToDisplay}: <span class='data'>0</span> m`);
    });
  }

  protected onMouseMove(): void {
    const rootNode = this.rootNode.node() as SVGSVGElement | undefined;
    if (rootNode) {
      const unscaledMousePosition = d3.mouse(rootNode); // relative to specified container
      const transformer = d3.zoomTransform(rootNode);

      const scaledMousePosition = transformer.invert(unscaledMousePosition); // relative to zoom
      mouseData.previousX = mouseData.X;
      mouseData.previousY = mouseData.Y;
      mouseData.X = scaledMousePosition[0] / DISPLAY_UNIT_FACTOR;
      mouseData.Y = -scaledMousePosition[1] / DISPLAY_UNIT_FACTOR;
      mouseData.dx = Math.abs(mouseData.X - mouseData.lastClickX);
      mouseData.dy = Math.abs(mouseData.Y - mouseData.lastClickY);
      mouseData.d = getDistanceBetweenPoints([mouseData.X, mouseData.Y], [mouseData.lastClickX, mouseData.lastClickY]);

      const mousePostionUpdatedEvent = new CustomEvent('mousePositionUpdated', {
        detail: {
          X: mouseData.X,
          Y: mouseData.Y,
        },
      });
      document.dispatchEvent(mousePostionUpdatedEvent);

      this.refreshMouseData();

      if (this.isRightClickDragging) {
        this.rootNode.selectAll('#select-shapes-rectangle').remove();

        let x = this.rightClickDragStartPos.x;
        let y = this.rightClickDragStartPos.y;
        let w = unscaledMousePosition[0] - this.rightClickDragStartPos.x;
        let h = unscaledMousePosition[1] - this.rightClickDragStartPos.y;

        if (w < 0) {
          w *= -1;
          x -= w;
        }

        if (h < 0) {
          h *= -1;
          y -= h;
        }

        this.rootNode
          .append('svg:rect')
          .attr('id', 'select-shapes-rectangle')
          .attr('width', w)
          .attr('height', h)
          .attr('x', x)
          .attr('y', y)
          .attr('stroke', theme.palette.primary.light)
          .attr('fill', 'none')
          .style('pointer-events', 'none')
          .style('stroke-width', '5px');
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const event = d3.event;
      if (!event.ctrlKey && !this.connectingSegments?.length && this.isDraggingTurn) {
        this.isDraggingTurn = false;

        const isPerformanceModeEnabled = store.getState().editor.isPerformanceModeEnabled;
        if (!isPerformanceModeEnabled) {
          document.querySelectorAll('.predraw').forEach((el) => el.remove());
        }

        return;
      }

      if ((event.ctrlKey && this.isDraggingTurn) || (this.connectingSegments.length > 0 && this.isDraggingTurn)) {
        const segmentsIds = this.lastIdSegmentDrag ? [this.lastIdSegmentDrag] : this.connectingSegments;
        const areTheyConnectingSegments = this.connectingSegments?.length && this.connectingSegments.length >= 1;

        segmentsIds.forEach((segmentId) => {
          d3.select(`[uuid='${segmentId}'] path`).dispatch('fakeDrag', {
            bubbles: true,
            detail: {
              x: scaledMousePosition[0],
              y: scaledMousePosition[1],
              forceDrag: true,
              areTheyConnectingSegments,
            },
          } as d3.CustomEventParameters);
        });
      }
    }
  }

  protected refreshMouseData(): void {
    const container = this.rootNode.select<HTMLDivElement>('#mouseInfoContainer');

    for (const label of mouseData.labelsToDisplay) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      container.select(`[label=${label}] [class='data']`).text(mouseData[label].toFixed(3));
    }
  }

  protected onClick(): void {
    const rootNode = this.rootNode.node();

    if (rootNode) {
      const event = d3.event as MouseEvent | PointerEvent | CustomEvent;

      let [x, y] = ['x' in event ? event.x : NaN, 'y' in event ? event.y : NaN] as [number, number];
      const unscaledMousePosition =
        'detail' in event && event?.detail?.unscaledMousePosition
          ? (event.detail.unscaledMousePosition as [number, number])
          : d3.mouse(rootNode as SVGSVGElement); // relative to specified container
      const transformer = d3.zoomTransform(rootNode as SVGSVGElement);
      [x, y] = transformer.invert(unscaledMousePosition);
      y *= -1;

      mouseData.lastClickX = x / DISPLAY_UNIT_FACTOR;
      mouseData.lastClickY = y / DISPLAY_UNIT_FACTOR;
      mouseData.dx = 0;
      mouseData.dy = 0;
      mouseData.d = 0;
      this.refreshMouseData();

      const storeState = store.getState();
      const tool = storeState.tool.activeTool;

      this.isRightClickDragging = 'button' in event && event.button === 2 && tool === Tools.Move;
      if (this.isRightClickDragging && 'x' in event && 'y' in event) {
        this.rightClickDragStartPos.x = unscaledMousePosition[0];
        this.rightClickDragStartPos.y = unscaledMousePosition[1];

        this.lastClick.x = event.x;
        this.lastClick.y = event.y;
      }

      const isCtrlPressed = 'ctrlKey' in event && event.ctrlKey;
      const isAltPressed = 'altKey' in event && event.altKey;

      if (!isCtrlPressed && !isAltPressed) return;

      if (tool !== Tools.Move && tool !== Tools.AddTurn && tool !== Tools.DrawSegmentOrTurn) return;

      const currentLayerId = storeState.circuit.present.layers.selectedLayer;
      const segment = CircuitService.getBestMatchingSegmentForTurn([x, y], currentLayerId, {
        ignoreHiddenLayers: true,
      });

      if (tool === Tools.Move && segment && isAltPressed) {
        const target = event?.target;

        const clickedOnAShape = target instanceof SVGElement ? target.id !== 'root-node-svg' : false;

        if (!clickedOnAShape) {
          store.dispatch(
            selectCircuitShapeAction({
              selectedShapeId: segment.id as string,
              selectedShapeType: ShapeTypes.SegmentShape,
              shape: segment,
            })
          );
        }
      } else if (
        (tool === Tools.AddTurn || tool === Tools.DrawSegmentOrTurn) &&
        segment &&
        (event as any).type !== 'click' &&
        isCtrlPressed
      ) {
        const coords = segment.geometry.coordinates;
        let closestPoint: Position;
        if (segment.properties.stockLine || segment.properties.rack) {
          closestPoint = coords[1];
        } else {
          [closestPoint] = getClosestPointInSegment([x, y], coords[0], coords[1]);
        }

        //d3.select(`[uuid='${minIndex}'] path`).dispatch('drag', { bubbles: true } as d3.CustomEventParameters);
        d3.select(`[uuid='${segment.id as string}'] path`).dispatch('fakeDrag', {
          bubbles: true,
          detail: { x: closestPoint[0], y: -closestPoint[1] },
        } as d3.CustomEventParameters);

        this.isDraggingTurn = true;
        this.lastIdSegmentDrag = segment.id as string;
      }
    }

    mouseData.d = 0;
    mouseData.dx = 0;
    mouseData.dy = 0;
  }

  protected onMouseUp(): void {
    const rootNode = this.rootNode.node();
    if (rootNode) {
      const event = d3.event as D3ClickEvent;
      const unscaledMousePosition = d3.mouse(rootNode as SVGSVGElement); // relative to specified container

      // when the mouse is released, we remove the preview lines
      if (!event.ctrlKey) {
        this.isDraggingTurn = false;

        const isPerformanceModeEnabled = store.getState().editor.isPerformanceModeEnabled;
        if (!isPerformanceModeEnabled) {
          document.querySelectorAll('.predraw').forEach((el) => el.remove());
        }
      }

      // if the user was dragging with the right click
      // we need to dispatch an action to select all the shapes in the rectangle
      if (this.isRightClickDragging) {
        this.isRightClickDragging = false;

        this.rootNode.selectAll('#select-shapes-rectangle').remove();

        const dDrag = Math.sqrt(
          (this.rightClickDragStartPos.x - unscaledMousePosition[0]) ** 2 +
            (this.rightClickDragStartPos.y - unscaledMousePosition[1]) ** 2
        );

        if (dDrag > thresholdMouseMoved) {
          // let's not dispatch a selectCircuitShapesInRectAction if we didn't move the mouse
          let x = this.rightClickDragStartPos.x;
          let y = this.rightClickDragStartPos.y;
          let w = unscaledMousePosition[0] - this.rightClickDragStartPos.x;
          let h = unscaledMousePosition[1] - this.rightClickDragStartPos.y;

          if (w < 0) {
            w *= -1;
            x -= w;
          }

          if (h < 0) {
            h *= -1;
            y -= h;
          }

          let x2 = x + w;
          let y2 = y + h;
          const transformer = d3.zoomTransform(rootNode as SVGSVGElement);
          [x, y] = transformer.invert([x, y]);
          [x2, y2] = transformer.invert([x2, y2]);

          store.dispatch(
            selectCircuitShapesInRectAction({
              x: x < x2 ? x : x2,
              y: -y < -y2 ? -y : -y2,
              x2: x2 >= x ? x2 : x,
              y2: -y2 >= -y ? -y2 : -y,
            })
          );
        } else {
          // but dispatch en event to open the context menu instead
          document.querySelector('#context-menu-editor')?.dispatchEvent(
            new CustomEvent('open-context-menu', {
              detail: {
                x: this.lastClick.x,
                y: this.lastClick.y,
              } as OpenContextMenuParams,
            })
          );

          this.isRightClickDragging = false;

          const shapesUnderClick = (document.elementsFromPoint(event.x, event.y) || [])
            .filter((shape) => {
              if (!shape) return false;
              if (shape.nodeName !== 'path') return false;

              const parent = shape.parentElement;
              if (!parent) return false;
              if (parent.nodeName !== 'g') return false;
              if (!parent.getAttribute('type') || !parent.getAttribute('uuid')) return false;

              return true;
            })
            .map((shape) => shape.parentElement);

          // we select the shape (if present) under the right click
          if (shapesUnderClick && shapesUnderClick.length) {
            const shapeToSelect = shapesUnderClick[0];

            if (!shapeToSelect) {
              throw new Error('shapeToSelect is undefined');
            }

            const shapeId = shapeToSelect.getAttribute('uuid');
            const shapeType = shapeToSelect.getAttribute('type');

            if (shapeId && shapeType && isShapeType(shapeType)) {
              const shape = CircuitService.getShape(shapeId, shapeType);

              // Select a zone with the right click selects the entire gravity rack
              if (shape?.properties.type === 'ZONE' && shape.properties.gravityRack) {
                const shapesToSelect: SelectedShapesData = [];
                const gravityRackId = shape.properties.gravityRack;

                const racksEntities = store.getState().circuit.present.racks.entities;
                const racksIds = store.getState().circuit.present.racks.ids;

                const gravityRacksIds = racksIds.filter(
                  (rackId) => racksEntities[rackId].properties.gravityRack?.id === gravityRackId
                );

                shapesToSelect.push({ id: shape.id as string, type: shape.properties.type });

                gravityRacksIds.forEach((gravityRackId) => {
                  shapesToSelect.push({ id: gravityRackId, type: ShapeTypes.RackShape });
                });

                startTransition(() => {
                  store.dispatch(selectMultipleCircuitShapesAction(shapesToSelect));
                });
              }

              startTransition(() => {
                store.dispatch(
                  selectCircuitShapeAction({
                    selectedShapeId: shapeId,
                    selectedShapeType: shapeType,
                    shape,
                  })
                );
              });
            }
          }
        }
      }

      if (!event.ctrlKey) return;

      const transformer = d3.zoomTransform(rootNode as SVGSVGElement);
      // eslint-disable-next-line prefer-const
      let [x, y] = transformer.invert(unscaledMousePosition);
      y *= -1;

      const storeState = store.getState();
      const tool = storeState.tool.activeTool;

      if (tool !== Tools.Move && tool !== Tools.AddTurn && tool !== Tools.DrawSegmentOrTurn) return;

      if (tool === Tools.AddTurn || tool === Tools.DrawSegmentOrTurn || this.isDraggingTurn) {
        const currentSelectedLayer = storeState.circuit.present.layers.selectedLayer;
        const segmentsIdsToDrag = this.lastIdSegmentDrag ? [this.lastIdSegmentDrag] : this.connectingSegments;
        const segment = CircuitService.getBestMatchingSegmentForTurn([x, y], currentSelectedLayer, {
          ignoreHiddenLayers: true,
          ignoreSegmentsIds: segmentsIdsToDrag,
        });
        if (!segment) {
          // eslint-disable-next-line no-console
          console.log('no segment found');

          return;
        }

        const coords = segment.geometry.coordinates;
        const [closestPoint] = getClosestPointInSegment([x, y], coords[0], coords[1]);

        segmentsIdsToDrag.forEach((segmentId) => {
          d3.select(`[uuid='${segmentId}'] path`).dispatch('fakeDragEnd', {
            bubbles: true,
            detail: {
              x: closestPoint[0],
              y: -closestPoint[1],
              destSegmentId: segment.id,
              forceDrag: true,
            },
          } as d3.CustomEventParameters);
        });

        this.isDraggingTurn = false;
        this.lastIdSegmentDrag = '';
        this.connectingSegments = [];
      }
    }
  }

  protected async initZoomAndAxes(): Promise<void> {
    let axisLayerAdded = false;
    do {
      const rect = this.rootNode.node()?.getBoundingClientRect();
      if (rect) {
        const { height, width } = rect;
        this.addOverlay(new AxisLayer(width, height));
        axisLayerAdded = true;
      }

      if (!axisLayerAdded) await new Promise((resolve) => setTimeout(resolve, 1000));
    } while (!axisLayerAdded);

    this.rootNode?.call(this.zoom);

    window.addEventListener('resize', () => this.onResize());

    this.zoomTo(0, 0, 1, true);
  }

  public zoomTo(x: number, y: number, scale: number, skipAnim = false, center = false): void {
    let transformation = d3.zoomIdentity;

    if (center) {
      const w = this.rootNode.node()?.clientWidth;
      const h = this.rootNode.node()?.clientHeight;
      if (w && h) {
        transformation = transformation.translate(w / 2, h / 2);
      }
    }

    transformation = transformation.translate(-x * scale, y * scale).scale(scale);

    this.rootNode
      .transition()
      .delay(skipAnim ? 0 : 500)
      .duration(skipAnim ? 0 : 3000)
      .call(this.zoom.transform, transformation);
    this.rootNode?.on('dblclick.zoom', null);
  }

  public translateBy(x: number, y: number): void {
    this.zoom.translateBy(this.rootNode, x / this.zoomScale, y / this.zoomScale);
  }

  public scaleBy(k: number): void {
    this.zoom.scaleBy(this.rootNode, k);
  }

  public goTo(x: number, y: number, scale: number): void {
    let transformation = d3.zoomIdentity;
    transformation = transformation.translate(x, y).scale(scale);

    this.rootNode.call(this.zoom.transform, transformation);
  }

  protected onResize(): void {
    const rect = this.rootNode.node()?.getBoundingClientRect();
    if (rect) {
      const { height, width } = rect;
      const axisLayer = this.getLayer<AxisLayer>(LayerNames.Axis);
      axisLayer.updateDimensions(width, height);
    }
  }

  protected handleZoom(transform?: d3.ZoomTransform): void {
    let axisLayer: AxisLayer;
    let drawLayer: DrawLayer;
    let circuit: CircuitLayer;

    try {
      axisLayer = this.getLayer<AxisLayer>(LayerNames.Axis);
      drawLayer = this.getLayer<DrawLayer>(LayerNames.Draw);
      circuit = this.getLayer<CircuitLayer>(LayerNames.Circuit);
    } catch (e) {
      return;
    }

    if (transform) {
      // we don't want to zoom when doing a double click while using the draw shape tool
      if (store.getState().tool.activeTool === Tools.DrawShape) return;

      axisLayer.rescale(transform);
      drawLayer.updateScaleFactor(transform);
      circuit.updateZoomScale(transform);
      this.zoomScale = transform.k;
    } else {
      const event = d3.event as D3ZoomEvent;
      const transform = event.transform;

      const zoomContainer = this.mainNode.select('.zoom-container');

      this.mainNode.attr('transform', `translate(${transform.x}, ${transform.y})`);

      this.rootNode
        .select('.secondary-translate-container')
        .attr('transform', `translate(${transform.x}, ${transform.y})`);
      const translateUpdatedEvent = new CustomEvent('translateUpdated', {
        detail: {
          X: transform.x,
          Y: transform.y,
        },
      });
      document.dispatchEvent(translateUpdatedEvent);

      zoomContainer.attr('transform', `scale(${transform.k})`);
      this.rootNode.select('.secondary-zoom-container').attr('transform', `scale(${transform.k})`);

      axisLayer.rescale(d3.event.transform);
      drawLayer.updateScaleFactor(d3.event.transform);
      this.zoomScale = transform.k;
    }

    window.currentZoom = this.zoomScale;

    const gridSvg = this.rootNode.node();

    if (gridSvg) {
      const event = d3.event as D3ZoomEvent;
      const transform = event.transform;

      const x0 = -transform.x / this.zoomScale;
      const x1 = (-transform.x + gridSvg.clientWidth) / this.zoomScale;

      const y0 = transform.y / this.zoomScale;
      const y1 = (transform.y - gridSvg.clientHeight) / this.zoomScale;

      window.x0 = x0;
      window.y0 = y0;
      window.x1 = x1;
      window.y1 = y1;

      updateGridCoordinateThrottled({ gridCoordinate: { x0, y0, x1, y1 } });
    }

    const zoomUpdatedEvent = new CustomEvent('zoomUpdated', {
      detail: {
        zoomScale: this.zoomScale,
      },
    });
    document.dispatchEvent(zoomUpdatedEvent);

    if (this.mainNode) {
      const newZoomLevel = zoomRangeLevel.find((z) => +z[0][0] < this.zoomScale && this.zoomScale < +z[0][1]);

      if (newZoomLevel) {
        const newZoomLevelClass = newZoomLevel[1];
        const node = this.mainNode.node();
        const currentZoomLevelClass = node?.dataset.zoomLevel;

        if (newZoomLevelClass !== currentZoomLevelClass && node) {
          node.dataset.zoomLevel = newZoomLevelClass.toString();

          window.currentZoomLevel = newZoomLevelClass;
          store.dispatch(updateZoomLevel({ zoomLevel: newZoomLevelClass }));
        }
      }
    }
  }

  protected startZoom(): void {
    // enable some optimizations, is it really useful? i think it is worse with it
    this.mainNode.classed('zooming', true);
  }

  protected endZoom(): void {
    // disable the optimizations
    this.mainNode.classed('zooming', false);
  }

  protected createRootNode(): SimpleSelection<SVGElement> {
    return this.container
      .append<SVGElement>('svg:svg')
      .attr('id', 'root-node-svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('pointer-events', 'visible')
      .attr('font-family', 'var(--sans-serif)')
      .attr('font-size', 16);
  }

  protected createMainNode(): SimpleSelection<SVGGElement> {
    return this.rootNode.append<SVGGElement>('svg:g').classed('translate-container', true);
  }

  protected addLayer(layer: BaseLayer<any>): void {
    this.layers.set(layer.name, layer);
    //this.mainNode.append(() => layer.node.node());
  }

  /**
   * An overlay is a fixed layer matching root dimensions
   * It is not impacted by transform (scale, translate)
   * @param layer
   */
  protected addOverlay(layer: BaseLayer<any>): void {
    this.layers.set(layer.name, layer);
    //this.rootNode.append(() => layer.node.node());
  }

  public getLayer<T extends BaseLayer<any>>(layerName: LayerNames): T {
    const layer = this.layers.get(layerName);

    if (!layer) {
      throw Error('Requested layer does not exist');
    }

    return layer as T;
  }

  public getRootNode(): SimpleSelection<SVGElement> {
    return this.rootNode;
  }
}

export interface D3ClickEvent extends d3.ClientPointEvent {
  x: number;
  y: number;
  ctrlKey: boolean;
  button: number;
  path?: HTMLElement[];
}

export interface D3ZoomEvent {
  transform: d3.ZoomTransform;
}

export interface OpenContextMenuParams {
  x: number;
  y: number;
  /** coord x on the circuit (m) */
  xCircuit?: number;
  /** Coord y on the circuit (m) */
  yCircuit?: number;
}
