import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow } from 'flows/flows';
import { setFlow } from 'flows/flows';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store';
import { formatSecondsToHHMMSS } from 'utils/time';

interface MaximumTaskTimeTextfieldProps {
  selectedFlow: Flow;
}
export function MaximumTaskTimeTextfield(props: MaximumTaskTimeTextfieldProps): JSX.Element {
  const { selectedFlow } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const defaultValue = useMemo(
    () => (selectedFlow?.maximumTaskTime ? selectedFlow?.maximumTaskTime.toString() : ''),
    [selectedFlow?.maximumTaskTime]
  );

  const handleUpdate = useCallback(() => {
    if (!inputRef.current) return;

    const value = inputRef.current.valueAsNumber;
    if (!value || value < +inputRef.current.min) {
      inputRef.current.value = defaultValue;
    }

    dispatch(setFlow({ ...selectedFlow, maximumTaskTime: value }));
  }, [defaultValue, dispatch, selectedFlow]);

  useLayoutEffect(() => {
    if (!inputRef.current) return;

    if (defaultValue !== inputRef.current.value) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  const preventNegativeValue = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  }, []);

  const handleClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      handleUpdate();
    }
  }, [handleUpdate]);

  const [maximumTaskTimeForTooltip, setMaximumTaskTimeForTooltip] = useState<number | undefined>(
    selectedFlow?.maximumTaskTime
  );
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    setMaximumTaskTimeForTooltip(selectedFlow?.maximumTaskTime);
  }, [selectedFlow?.maximumTaskTime]);

  const maximumTaskTimeFormatted =
    maximumTaskTimeForTooltip !== undefined ? formatSecondsToHHMMSS(maximumTaskTimeForTooltip) : '';

  return (
    <Tooltip
      title={maximumTaskTimeFormatted}
      arrow
      open={inputFocus && !!maximumTaskTimeForTooltip && !isNaN(maximumTaskTimeForTooltip)}
    >
      <TextField
        type="number"
        label={
          <>
            Maximum Task Time <HelpIconTooltip title="Maximum task time for the flows in seconds (optional)" />
          </>
        }
        variant="outlined"
        fullWidth
        size="small"
        defaultValue={defaultValue}
        inputProps={{ min: 0, step: 1 }}
        InputProps={{
          endAdornment: (
            <>
              <IconButton onClick={handleClear} size="small">
                <ClearIcon fontSize="small" />
              </IconButton>
              s
            </>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onFocus={() => setInputFocus(true)}
        onBlur={() => {
          setInputFocus(false);
          handleUpdate();
        }}
        onChange={(e) => {
          const input = e.target;
          if (!(input instanceof HTMLInputElement)) return;
          setMaximumTaskTimeForTooltip(input.valueAsNumber);
        }}
        inputRef={inputRef}
        onKeyDown={preventNegativeValue}
      />
    </Tooltip>
  );
}
