/* eslint-disable no-useless-escape */
import type { Position } from 'geojson';
import type { CircuitShape, GeoJsonCircuit, LayerFeatureCollection, ZoneRule } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { isShapeType } from 'models/circuit.guard';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import { MAX_EDITING_USERS } from 'multiplayer/globals';
import type { ExportCircuitFeatures } from 'services/circuit.service';
import { CircuitService } from 'services/circuit.service';
import store from 'store';
import { text2sha1 } from 'utils/crypto';
import packageJson from '../../../package.json';
import { fixPerceptionCellTemplateConveyors } from './fix-perception-cell-templates-conveyors';
import { stockDBConfiguration } from './stockDB-config';

/**
 * This function produces a geojson circuit file from a list of geojson features (shapes)
 * @param feats an object with different geojson features categorized by shape type
 * @param pretty wether we want to pretiffy the json output
 * @returns a promise that resolves to an array that contains: [0] the geojson string, [1] the sha1 hash of the geojson string, [2] the javascript geojson object
 */
export async function generateGeoJSON(
  feats: ExportCircuitFeatures,

  pretty = false,
  options = {
    projectName: 'unknown project name',
  }
): Promise<[string, string, GeoJsonCircuit]> {
  const circuitService = CircuitService;
  const shapeFeatures = circuitService.convertFeatureArrayCoordinates(
    circuitService.circuitDataToFeatureArray(feats),
    (val) => val / DISPLAY_UNIT_FACTOR
  ) as unknown as CircuitShape[];

  const storeState = store.getState();
  const layers = storeState.circuit.present.layers;
  const stations = storeState.flows.stations;
  const flows = storeState.flows.flows;

  const filters = storeState.local.filters;
  let cellTemplates = storeState.circuit.present.cellTemplates.entities;
  const manageDevicePrefEnabled = storeState.project.enableDevicePrefManagement;
  const nextFreeId = window.nextFreeId ?? 1;
  let nextFreeIdArray = window.nextFreeIdArray ?? [];
  const description = storeState.project.circuitDescription;
  const version = storeState.project.circuitVersion;
  const balyoSimulationVersion = storeState.project.balyoSimulationVersion;

  const schedulerConfigName = storeState.simulation.schedulerConfigurationName;

  const simulationMaxDuration = storeState.simulation.maxDuration;
  const robotsBatteryLevel = storeState.simulation.robotsBatteryLevel;
  const customSteps = storeState.flows.customSteps;
  const enableCharging = storeState.simulation.enableCharging;

  // fix previously broken conveyors that have a number of perception references that does not match the number of pallet in the load (load.N)
  cellTemplates = fixPerceptionCellTemplateConveyors(cellTemplates);

  const triggers = storeState.triggers.triggers;
  const enabledTriggers = storeState.triggers.enabledTriggers;
  const updatedTriggers = triggers.map((trigger) => ({
    ...trigger,
    enabled: !!enabledTriggers[trigger.id],
  }));

  const shapesCountLayer: Record<string, number> = {};
  Object.values(ShapeTypes).forEach((shapeType) => {
    shapesCountLayer[shapeType] = 0;
  });

  const layerFeatures: LayerFeatureCollection[] = Object.values(layers.layers).map((layer) => {
    return {
      id: layer.id,
      type: 'FeatureCollection',
      features: shapeFeatures.filter((shape) => shape?.properties?.layerId === layer.id),
      properties: {
        name: layer.name,
        shapesCount: { ...shapesCountLayer } as LayerFeatureCollection['properties']['shapesCount'],
      },
    };
  });

  const shapesCount: Record<string, number> = {};
  Object.values(ShapeTypes).forEach((shapeType, index) => {
    shapesCount[shapeType] = 0;
  });

  layerFeatures.forEach((layer) => {
    const sc = layer.properties.shapesCount;

    layer.features.forEach((shape) => {
      const shapeType = shape.properties?.type;
      if (isShapeType(shapeType)) {
        shapesCount[shapeType]++;
        sc[shapeType]++;
      }
    });
  });

  if (nextFreeIdArray.length !== MAX_EDITING_USERS) {
    nextFreeIdArray = nextFreeIdArray.slice(0, MAX_EDITING_USERS);
  }

  const sha1: string = await text2sha1(JSON.stringify(shapeFeatures));
  const geojson: GeoJsonCircuit = {
    type: 'FeatureCollection',
    properties: {
      roadEditorVersion: packageJson.version,
      projectName: options.projectName,
      sha1,
      thisFile: 'is a circuit that tells Balyo robots where to drive',
      enableDevicePrefManagement: manageDevicePrefEnabled,
      nextFreeId,
      nextFreeIdArray,
      circuitDescription: description,
      circuitVersion: version,
      balyoSimulationVersion,
      layers: layers,
      filters,
      cellTemplates,
      stations,
      flows,
      triggers: updatedTriggers,
      schedulerConfiguration: schedulerConfigName,
      simulationParameters: {
        maxDuration: simulationMaxDuration,
        robotsBatteryLevel: robotsBatteryLevel,
        customSteps: customSteps,
        enableCharging: enableCharging,
      },

      shapesCount: shapesCount as GeoJsonCircuit['properties']['shapesCount'],

      stockDBConfiguration,
    },
    features: layerFeatures,
  };

  const mapImagePtr = storeState.maps.mapImage.mapImages;
  if (mapImagePtr?.length) {
    const mapImageArray: typeof geojson.properties.mapImageArray = [];
    geojson.properties.mapImageArray = mapImageArray;

    mapImagePtr.forEach((mapImage) => {
      if (
        mapImage &&
        mapImage.height &&
        mapImage.name &&
        mapImage.x !== undefined &&
        mapImage.y !== undefined &&
        mapImage.URL
      ) {
        mapImageArray.push({
          name: mapImage.name,
          height: mapImage.height,
          x: mapImage.x,
          y: mapImage.y,
          scaling: mapImage.scaling,
          originalWidth: mapImage.originalWidth,
          originalHeight: mapImage.originalHeight,
        });
      }
    });
  }

  // circuit image
  const circuitImages = storeState.images.circuitImages;
  if (circuitImages?.length) {
    const circuitImageArray: typeof geojson.properties.circuitImageArray = [];
    geojson.properties.circuitImageArray = circuitImageArray;

    circuitImages.forEach((image) => {
      if (image && image.id && image.name) {
        circuitImageArray.push({
          id: image.id,
          name: image.name,
        });
      }
    });
  }

  const mapObstacleName = storeState.maps.lidar['foreground-lidar']?.name;
  if (mapObstacleName && geojson && geojson.properties) {
    geojson.properties.mapObstacleData = {
      name: mapObstacleName,
    };
  }

  const str = generateStrFromJSON(geojson, pretty ? 2 : 0);

  return [str, sha1, geojson];
}

export function generateStrFromJSON(geojson: GeoJsonCircuit, pretty: 2 | 0): string {
  const replacer = pretty
    ? (k: string, v: any) => {
        // k=key, v=value
        if (v instanceof Array && k === 'cap') {
          return `*[${v.join(', ')}]*`;
        } else if (
          v instanceof Array &&
          (k === 'coordinates' ||
            k === 'points' ||
            k === 'inStart' ||
            k === 'inEnd' ||
            k === 'outStart' ||
            k === 'outEnd')
        ) {
          if (typeof v?.[0] === 'number') {
            const v1 = v as Position;

            return `*[${v1.join(', ')}]*`;
          } else if (typeof v?.[0] === 'string') {
            const v1 = v as string[];

            return `*[${v1.map((a) => `"${a}"`).join(', ')}]*`;
          }

          if (typeof v?.[0]?.[0] === 'number') {
            const v2 = v as Position[];

            return `*[${v2.map((coord) => `[${coord.join(', ')}]`).join(', ')}]*`.replace(/[0-9.]+,[0-9.]+/gm, `[$&]`);
          }

          const v3 = v as Position[][];

          // the replace regex is used when the geometry is a polygon to correct the output
          return `*[${v3
            .map((pos) => `[${pos.map((coord) => `[${coord.join(', ')}]`).join(', ')}]`)
            .join(', ')}]*`.replace(/[0-9.]+,[0-9.]+/gm, `[$&]`);
        } else if (v instanceof Array && k === 'rules') {
          return `*[${(v as ZoneRule)
            .map(
              (rule) =>
                `[${rule
                  .map((ruleNameOrParam) =>
                    typeof ruleNameOrParam === 'string' ? `"${ruleNameOrParam}"` : ruleNameOrParam
                  )
                  .join(', ')}]`
            )
            .join(', ')}]*`;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return v;
      }
    : undefined;

  let str = JSON.stringify(geojson, replacer, pretty);

  if (pretty) {
    /**
     * In the replacer function defined above, we change how the geojson output is formatted.
     * We custom the prettifying function to make it more readable.
     * For example, the coordinates are output on a single line.
     * To do so, we output strings that are escaped by the JSON.stringify function.
     * Ex: coordinates: [[0, 0]] becomes coordinates: [\"[\"0\", 0\"]]" or something like that.
     * The idea is not remove the extra backslashes that are added by the JSON.stringify function.
     * But we don't want to remove all backslashes because some are needed.
     * Ex: name: "super \"name\"", we want to keep the backslash in the name otherwise it breaks the json.
     * The replace method is quite compute intensive but I don't see other way of doing it.
     *
     * We run these replace methods only when the pretty option is enabled because otherwise the replacer is not called.
     */

    str = str
      .replace(/\[\\"/g, '["')
      .replace(/\\"\]/g, '"]')
      .replace(/\\",/g, '",')
      .replace(/, \\"/g, ', "')
      .replace(/\"\*\[/g, '[')
      .replace(/\]\*\"/g, ']');
  }

  return str;
}
