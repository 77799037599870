import { awareness, remoteDoc } from 'multiplayer/globals';
import { useEffect } from 'react';
import type { UserProfileData } from 'shared';
import { useAppSelector } from 'store';

export function useCollabWindowVisibility(): void {
  const render2Mode = useAppSelector((state) => state.editor.isRender2Enabled);

  const visibilityHandler = (): void => {
    if (!awareness || !remoteDoc) return;

    const inactiveMap = remoteDoc.getMap('inactive');

    if (document.visibilityState === 'hidden') {
      if (inactiveMap.has(awareness.clientID.toString())) return;

      inactiveMap.set(awareness.clientID.toString(), true);

      return;
    }

    if (document.visibilityState === 'visible') {
      if (inactiveMap.has(awareness.clientID.toString())) {
        inactiveMap.delete(awareness.clientID.toString());
      }

      const profilesMap = remoteDoc.getMap('profiles');
      if (profilesMap.has(awareness.clientID.toString())) return;

      const profile = JSON.parse(localStorage.getItem('profile') || '{}') as UserProfileData;
      profilesMap.set(awareness.clientID.toString(), profile);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityHandler);
  }, [render2Mode]);
}
