import { getRobotsEmulation } from 'components/toolboxes/simulation/use-set-robots';
import type { LayerGroupData } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { getRobotLayerGroup } from 'utils/circuit/get-robot-layer-group';
import { getLayerGroupsParents } from 'utils/export/generate-xml-utils';
import { PreferencesService, getPreferenceValue, writeToFile } from 'utils/preferences';
import { isDefined } from 'utils/ts/is-defined';
import { z } from 'zod';
import type { DispatcherConfig } from './dispatcher-config';
import type { SimulationTask } from './simulation';

/** Schema for OPCGroup */
const OPCGroupSchema = z.object({
  /** id of the opc group */
  id: z.number(),
  /** ids of the robots in this group */
  robotList: z.array(z.number()),
  /**
   * Low Work Load threshold in percentage [0, 100]
   * work load = 0% => all robots are available
   * work load = 100% => all robots are producing
   **/
  lowWLTrigger: z.number().min(0).max(100),
  /** High Work Load threshold in percentage [0, 100] */
  highWLTrigger: z.number().min(0).max(100),
  /** Low Battery threshold in percentage [0, 100] */
  lowWLBattThreshold: z.number().min(0).max(100),
  /** Medium Battery threshold in percentage [0, 100] */
  mediumWLBattThreshold: z.number().min(0).max(100),
  /** High Battery threshold in percentage [0, 100] */
  highWLBattThreshold: z.number().min(0).max(100),

  /** Strategy to use for the workload */
  workloadStrategy: z.enum(['default', 'past', 'none']).optional(),
});

/** Schema for OPCConfiguration */
export const OPCConfigurationSchema = z.object({
  /** All the groups of robots in the OPC configuration */
  groups: z.record(OPCGroupSchema),
  /** All the chargers in the OPC configuration */
  chargers: z.record(
    z.object({
      /** Name of the battery points in this charger */
      destPoints: z.array(z.string()),
      /** Charger type (example: LeadAcid_24V) */
      chargerType: z.string(),
      /** Whether the charger needs a manual operation */
      dockingType: z.enum(['MANUAL', 'AUTO', 'FIXED', 'MOBILE', 'BUSBAR']),
      /** Robots allowed to charge on charger  */
      robotAuth: z.array(z.number()),
    })
  ),
});

type OPCGroup = z.infer<typeof OPCGroupSchema>;
export type OPCConfiguration = z.infer<typeof OPCConfigurationSchema>;

export type OPCCharger = OPCConfiguration['chargers'][0];

export function getDefaultOPCThresholds(): OPCGroup {
  return {
    id: 0,
    robotList: [],
    lowWLTrigger: 10,
    highWLTrigger: 50,
    lowWLBattThreshold: 50,
    mediumWLBattThreshold: 35,
    highWLBattThreshold: 25,
    workloadStrategy: 'default',
  };
}

/**
 * Returns the default OPC configuration for the loaded project.
 *
 * @returns {OPCConfiguration | undefined} The default OPC configuration if the preferences are fully loaded, undefined otherwise.
 */
export function getDefaultOPCConfigForTheLoadedProject(): OPCConfiguration | undefined {
  if (!PreferencesService.arePreferencesFullyLoaded()) return;

  const trucks = PreferencesService.getTrucks();

  const robots = getRobotsEmulation();

  const circuitState = store.getState().circuit.present;
  const pointIds = circuitState.points.ids;
  const points = circuitState.points.entities;

  const battPoints = pointIds
    .filter((pointId) => {
      const point = points[pointId];

      return point.properties.isBattery;
    })
    .map((pointId) => {
      return points[pointId];
    });
  const battPointsNames = battPoints.map((point) => point.properties.name);

  const chargers: Record<string, OPCCharger> = {};
  const groups: Record<string, OPCGroup> = {};

  const allBatteryTypesSet = new Set<string>();
  /**
   * key: battery type
   * value: list of layer groups
   */
  const batteryTypesToLayerGroups: Record<string, LayerGroupData[]> = {};
  trucks.forEach((truck) => {
    try {
      const truckBatteryType = getPreferenceValue('battery/batteryType', truck.serial);
      if (typeof truckBatteryType !== 'string') {
        // eslint-disable-next-line no-console
        console.error(`Could not find battery type for truck ${truck.serial}`);

        return;
      }

      allBatteryTypesSet.add(truckBatteryType);

      const layerGroup = getRobotLayerGroup(truck);

      if (!batteryTypesToLayerGroups[truckBatteryType]) batteryTypesToLayerGroups[truckBatteryType] = [];
      if (layerGroup) batteryTypesToLayerGroups[truckBatteryType].push(layerGroup);
    } catch (e) {}
  });

  const allBatteryTypes = Array.from(allBatteryTypesSet);

  const group = getDefaultOPCThresholds();
  group.id = 1;
  group.robotList = robots
    .map((robot, index) => {
      // if (!robot.emulationEnabled) return null;

      return robot.ID;
    })
    .filter(isDefined);
  groups[group.id] = group;

  let batteryTypeIndex = 0;
  battPoints.forEach((point, pointNameIndex) => {
    const pointName = point.properties.name;

    const layerGroups = getLayerGroupsParents(
      Object.values(circuitState.layers.layerGroups).map((layerGroup) => ({
        id: parseInt(layerGroup.id, 10),
        modelName: layerGroup.name,
      })),
      circuitState.layers.layers,
      point,
      circuitState.layers.layerGroups
    );

    const randomBatteryType = (() => {
      let i = 100;
      let battTypeInLayerOfPoint = false;
      do {
        const battType = allBatteryTypes[batteryTypeIndex++ % allBatteryTypes.length];

        if (battType in batteryTypesToLayerGroups) {
          const layerGroupsOfBatteryType = batteryTypesToLayerGroups[battType];
          battTypeInLayerOfPoint = layerGroups.some((layerGroup) =>
            layerGroupsOfBatteryType.some(
              (layerGroupOfBatteryType) => layerGroup.id.toString() === layerGroupOfBatteryType.id
            )
          );
        }

        if (battTypeInLayerOfPoint) return battType;
      } while (!battTypeInLayerOfPoint && i--);

      return null;
    })();

    const batteryType = point.properties.batteryType ? point.properties.batteryType : randomBatteryType;

    if (!batteryType) {
      // eslint-disable-next-line no-console
      console.error('Could not find battery type for point', pointName);

      return;
    }

    const chargerName = `${batteryType}_${pointName}`;
    const dockingType: OPCCharger['dockingType'] = batteryType.toLowerCase().includes('lead') ? 'MANUAL' : 'AUTO';
    const robotAuth: number[] = robots
      .map((robot) => {
        if (batteryType.includes(robot.batteryModel)) {
          return robot.ID;
        }

        return undefined;
      })
      .filter(isDefined);

    const charger: OPCCharger = {
      destPoints: [pointName],
      chargerType: batteryType,
      dockingType: dockingType,
      robotAuth: robotAuth,
    };

    chargers[chargerName] = charger;
  });

  if (battPointsNames.length < allBatteryTypes.length) {
    SnackbarUtils.warning(
      `Not enough battery points (${battPointsNames.length}) for all battery types (${allBatteryTypes.length}). Some chargers have not been created.`
    );
  }

  const config: OPCConfiguration = {
    chargers,
    groups,
  };

  return config;
}

/**
 * Returns the default OPC flow for the SchedulerConfig.
 * @returns {DispatcherConfig['flows']} The default OPC flow
 */
export function getDefaultOPCFlow(): DispatcherConfig['flows'] {
  const flows = {
    opcBattery: {
      robAuth: [-1],
      allowSuspend: false,
      cancelCbk: 'anyTask_cancelCbk',
      defaultMissionList: [
        {
          missionName: 'battery' as const,
          args: ['dst'],
        },
      ],
    },
  };

  return flows;
}

export const getOpcConfig = async (): Promise<OPCConfiguration | undefined> => {
  const customOpcConfig = await (async () => {
    try {
      const content = await (await PreferencesService.getFileByPath('opcConfig.json'))?.text();
      if (!content) return null;

      const parsedOpcConfig = OPCConfigurationSchema.safeParse(JSON.parse(content));
      if (!parsedOpcConfig.success) {
        // eslint-disable-next-line no-console
        console.warn('Invalid OPC configuration:', parsedOpcConfig.error);

        return null;
      }

      // eslint-disable-next-line no-console
      console.log('A custom OPC configuration has been loaded');

      return parsedOpcConfig.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  })();

  const opcConfig = customOpcConfig || getDefaultOPCConfigForTheLoadedProject();

  return opcConfig;
};

/**
 * Checks if a task is an OPC battery task.
 * @param {SimulationTask} task - The task to check
 * @returns {boolean} Whether the task is an OPC battery task
 */
export function isTaskABattTask(task: SimulationTask): boolean {
  return task.taskName === 'opcBattery';
}

export async function saveOpcConfigurationOnDisk(opcData: OPCConfiguration): Promise<void> {
  const dirHandle = await PreferencesService.getDirectoryHandle();

  if (dirHandle) {
    const opcConfigBlob = new Blob([JSON.stringify(opcData, null, 2)], {
      type: 'text/plain',
    });
    const opcConfigFileHandle = await dirHandle.getFileHandle('opcConfig.json', { create: true });
    await writeToFile(opcConfigFileHandle, opcConfigBlob);
  }
}
