import { Backdrop, CircularProgress } from '@mui/material';
import { useMultiplayerInit } from 'components/presence/hooks/useMultiplayerInit';
import { Multiplayer } from 'components/presence/multiplayer';
import type { EditorDrawing } from 'drawings/editor.drawing';
import type { ShapeTypes } from 'models/circuit';
import type { lineStrokeSize, pointSize } from 'models/drawings';
import { projectHost } from 'multiplayer/globals';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { AxisLayerComponent } from './axis.layer';
import { BaseDrawingComponent } from './base.drawing';
import { CircuitComputedStyles } from './computed-styles';
import { DisplayTrafficComponentMemo } from './display-traffic';
import { DrawLayerComponent } from './draw.layer';
import { ItinerariesComponent } from './itineraries';
import { RobotPathsComponent } from './robot-paths';
import { RobotsComponent } from './robots';
import { CircuitLayerComponentMemo } from './shapes/circuit.layer';

interface EditorDrawingComponentProps {
  drawing: EditorDrawing;
  drawingEl: HTMLDivElement | null;
  onRectangleDrawn: (type: string, points: [number, number][]) => void;
  onSegmentDrawn: (points: [number, number][]) => void;
  onMeasurerDrawn: (points: [number, number][]) => void;
  onPointDrawn: (point: [number, number], angle: number) => void;
  onShapeSelected: (selectedShapeId: string, selectedShapeType: ShapeTypes) => void;
  onShapeUnselected: (unselectedShapeId: string, unselectedShapeType: ShapeTypes) => void;
  onShapesSelectionCleared: () => void;
  /** choose how how big are the points displayed */
  pointsSize?: pointSize;
  /** size of the stroke width of the lines (segments, turns, etc.) */
  lineStrokeSize?: lineStrokeSize;
}

export function EditorDrawingComponent(props: EditorDrawingComponentProps): JSX.Element {
  const axisLayer = <AxisLayerComponent drawing={props.drawing} />;
  const drawLayer = <DrawLayerComponent drawing={props.drawing} />;

  // we have to read this because we have to display the map image on top of the lidar
  // when we want to drag it
  const mapImagePropertiesOpened = useAppSelector((state) => state.maps.mapImage.openProperties !== undefined);

  const multiplayer = useAppSelector((state) => state.multiplayer.multiplayer);
  const synced = useAppSelector((state) => state.multiplayer.synced);

  useMultiplayerInit({ multiplayer, synced });

  const showBackdrop = useMemo(() => multiplayer && !synced && !projectHost, [multiplayer, synced]);

  return (
    <>
      <BaseDrawingComponent axisLayer={axisLayer} drawLayer={drawLayer} drawing={props.drawing}>
        <g className="zoom-container">
          <CircuitLayerComponentMemo
            pointsSize={props.pointsSize}
            lineStrokeSize={props.lineStrokeSize}
            disabled={mapImagePropertiesOpened}
          />
          <RobotPathsComponent />
          <ItinerariesComponent />
          <DisplayTrafficComponentMemo />
          <RobotsComponent />
          <CircuitComputedStyles />
          {multiplayer ? <Multiplayer drawing={props.drawing} /> : null}
        </g>
      </BaseDrawingComponent>
      {showBackdrop ? (
        <Backdrop open={showBackdrop} sx={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </>
  );
}
