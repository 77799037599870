import { Tools } from 'models/tools';
import { remoteDoc } from 'multiplayer/globals';
import { SnackbarUtils } from 'services/snackbar.service';
import type { RobotBattery } from 'simulation/simulation';
import { setRobotsInitialBatteryLevelFromYJS } from 'simulation/simulation';
import store, { useAppSelector } from 'store';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export function useRobotBatteryLevelSubscription(): void {
  const inSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const robotBatteryLevelText = remoteDoc?.getText('robotBatteryLevel');

  const updateRobotBatteryLevel = (): void => {
    if (!robotBatteryLevelText || !robotBatteryLevelText.length) return;

    const robotBatteryLevelData = JSON.parse(robotBatteryLevelText.toString()) as RobotBattery[];

    store.dispatch(setRobotsInitialBatteryLevelFromYJS({ robots: robotBatteryLevelData }));
    SnackbarUtils.info('A user updated the robot initial battery level', {
      autoHideDuration: 3000,
    });
  };

  useConditionalQueueSubscriptionHook({
    YType: robotBatteryLevelText,
    callback: updateRobotBatteryLevel,
    condition: inSimulation,
  });
}
