import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { awareness, provider, remoteDoc, setAwareness, setProvider, setRemoteDoc } from 'multiplayer/globals';
import { WebsocketProvider } from 'y-websocket';
import { Doc } from 'yjs';
import { connectRoomSideEffect } from './side-effect';

export interface MultiplayerSliceState {
  /** current mode for the Multiplayer */
  multiplayer: boolean;

  /** initial state synced */
  synced: boolean;

  /** state.provider.awarenessClientId of the user to follow */
  followClientId?: number;
}

const initialState: MultiplayerSliceState = {
  multiplayer: false,
  synced: false,
  followClientId: undefined,
};

const multiplayerSlicePrivate = createSlice({
  initialState,
  name: 'multiplayer',
  reducers: {
    connectRoom: (state, action: PayloadAction<{ roomId: string }>) => {
      connectRoomSideEffect();

      state.multiplayer = true;
      setRemoteDoc(new Doc());
      if (!remoteDoc) throw new Error('Missing remote doc');

      const API_DNS = import.meta.env.VITE_API_DNS;
      const API_LIVE_PREFIX_URL = import.meta.env.VITE_API_LIVE_PREFIX_URL;
      if (!API_DNS && import.meta.env.MODE === 'production') throw new Error('Missing API_DNS env variable');
      if (!API_LIVE_PREFIX_URL && import.meta.env.MODE === 'production')
        throw new Error('Missing API_LIVE_PREFIX_URL env variable');

      const wsUrl =
        API_DNS && API_LIVE_PREFIX_URL && import.meta.env.MODE === 'production'
          ? `wss://${API_DNS}${API_LIVE_PREFIX_URL}/websocket`
          : 'ws://localhost:8080/websocket';
      const roomUrl = `room-${action.payload.roomId}`;

      setProvider(new WebsocketProvider(wsUrl, roomUrl, remoteDoc));
      if (!provider) throw new Error('Missing provider');

      setAwareness(provider.awareness);
      if (!awareness) throw new Error('Missing awareness');

      awareness.setLocalState({
        /* Color */
        c: '#ffffff',

        /* Zoom */
        z: 1,

        /* Translation */
        t: [0, 0],

        /* SessionId */
        i: '1',
      });
    },
    disconnectRoom: (state) => {
      provider?.disconnect();

      setRemoteDoc();
      setProvider();
      setAwareness();
      state.followClientId = undefined;
      state.multiplayer = false;
      state.synced = false;
    },
    setFollowUser: (state, action: PayloadAction<{ clientId: number }>) => {
      state.followClientId = action.payload.clientId;
    },
    unsetFollowUser: (state) => {
      state.followClientId = undefined;
    },
    setSynced: (state) => {
      state.synced = true;
    },
  },
});

export const multiplayerSlice = {
  reducer: multiplayerSlicePrivate.reducer,
  name: multiplayerSlicePrivate.name,
};

export const { connectRoom, disconnectRoom, setFollowUser, unsetFollowUser, setSynced } =
  multiplayerSlicePrivate.actions;
