import { useLayoutEffect, useRef } from 'react';
import type { UseYJSSubscriptionHookProps } from './useYJSSubscriptionHook';
import { useYJSSubscriptionHook } from './useYJSSubscriptionHook';

interface UseSimulationQueueSubscriptionHook extends UseYJSSubscriptionHookProps {
  condition: boolean;
}

export function useConditionalQueueSubscriptionHook(props: UseSimulationQueueSubscriptionHook): void {
  const { YType, callback, updateLastCircuitTransactionOrigin, condition } = props;

  const queuedChange = useRef<boolean>(false);

  const cancelOnSimulationModeCallback = (): void => {
    if (condition) {
      queuedChange.current = true;

      return;
    }

    callback();
  };

  useYJSSubscriptionHook({ YType, updateLastCircuitTransactionOrigin, callback: cancelOnSimulationModeCallback });

  useLayoutEffect(() => {
    if (condition || !queuedChange.current) return;

    callback();
    queuedChange.current = false;
  }, [callback, condition]);
}
