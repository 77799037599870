import { PictureAsPdf, Warning } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { closeDialogAction } from 'actions';
import { HelpIconTooltip, WarningIconTooltip } from 'components/utils/tooltips';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import type { RobotTimers } from 'models/simulation';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import generatePDF, { Margin } from 'react-to-pdf';
import { humanifyErrorType } from 'services/simu-errors-monitoring-action';
import { SnackbarUtils } from 'services/snackbar.service';
import { isTaskABattTask } from 'simulation/opc';
import type { SimulationTask } from 'simulation/simulation';
import { setStartCollectingTime } from 'simulation/simulation';
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowFlowTimes,
  styledTableCellColor,
} from 'simulation/styled-table';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { Equals, Not } from 'tsafe';
import { assert } from 'tsafe/assert';
import { useAsyncMemo } from 'use-async-memo';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';
import {
  formatSecondsToHHMMSS,
  formatSecondsToHHhMM,
  formatSecondsToHMinSec,
  formatTime,
  hoursToSeconds,
} from 'utils/time';
import { deepMerge } from 'utils/ts/deep-merge';
import { isDefined } from 'utils/ts/is-defined';
import { divideAllPropertiesBy, resetObject } from 'utils/ts/reset-object';
import packageJson from '../../../../package.json';
import { DisplaySchedulerConfig } from './display-scheduler-config';
import { ExtractRecordsButton } from './extract-records-button';
import { FlowThroughputTable } from './flow-throughputs-table';
import { ListOfFlowsTable } from './list-of-flows-table';
import { AlertVnaPerformance } from './report/alert-vna-performance';
import { TaskPerformanceAnalysis } from './task-performance-analysis';
import { TasksDurationChartReport } from './tasks-duration-chart-report';

const borderStyleSeparator = `2px solid ${theme.palette.grey[400]} !important`;

/**
 * Minimum duration of the simulation to be considered relevant [s]
 */
const minimumRelevantSimulationDuration = hoursToSeconds(1);

const estimateToDisplay = ['Average', 'Median', 'Min', 'Max'] as const;

interface FlowTimes {
  carryTime: number;
  emptyTime: number;
  pickTime: number;
  dropTime: number;
  totalTime: number;
  waitingTime: number;
}

interface FlowsStatUnique {
  id: string;
  name: string;
  nbTasks: number;
  nbStartedTasks: number;
  nbFinishedTasks: number;
  times: {
    totals: FlowTimes;
    averages: FlowTimes;
    medians: FlowTimes;
    mins: FlowTimes;
    maxs: FlowTimes;
  };
  objective: number;
  palletsPerTask: number;
}

export type FlowsStats = FlowsStatUnique[];

export interface TotalFlowsStats {
  objective: number;
  nbTasks: number;
  nbFinishedTasks: number;
  throughput: number;
  throughputWithUtilizationRate: number;
  deltaWithUtilizationRate: number | null;
}

function hasUnmetThroughputWithAvailableRobots(
  flowsStats: FlowsStats,
  robotTimers: RobotTimers | undefined,
  elapsedTime: number
): boolean {
  if (!robotTimers) return false;

  // Calculate total available time
  const taxiTime = (robotTimers.task.Available ?? 0) + (robotTimers.task.RunToTaxi ?? 0);
  const totalTime = robotTimers.total ?? 0;
  const availabilityRatio = taxiTime / totalTime;

  // Check if robots were significantly available (more than 5%)
  if (availabilityRatio < 0.05) return false;

  // Check if any flow didn't meet its objective despite robot availability
  return flowsStats.some((flowStat) => {
    if (!flowStat.objective) return false;

    const actualThroughput = (flowStat.nbFinishedTasks * flowStat.palletsPerTask) / (elapsedTime / 3600);

    return actualThroughput < flowStat.objective;
  });
}

export function SimulationReportDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const simulationData = useAppSelector((state) => state.dialog.data);

  const flows = useAppSelector((state) => state.flows.flows);
  const stations = useAppSelector((state) => state.flows.stations);

  const elapsedTimeSimulation = store.getState().simulation.simulatedTime ?? 1;
  const simulationTimeEpoch =
    simulationData && 'simulationEpoch' in simulationData ? simulationData.simulationEpoch : 0;

  const gitBranchInformation = useAppSelector((state) => state.project.gitBranchInformation);

  const startSimulationDate = useAppSelector((state) => state.simulation.startEpochTime);
  const startCollectingTime = useAppSelector((state) => state.simulation.startCollectingTime);
  const robotsTimersHistory = useAppSelector((state) => state.simulation.robotsTimersHistory);

  const robotsHistoryReset = useAppSelector((state) => state.simulation.robotsHistoryReset);
  const robotsHistoryResetFiltered = useMemo(() => {
    return robotsHistoryReset.filter((robot) => robot.simulationTime > startCollectingTime);
  }, [robotsHistoryReset, startCollectingTime]);

  const robotsResetCount = useMemo(() => {
    return robotsHistoryReset.reduce(
      (count, robot) => {
        if (robot.simulationTime > startCollectingTime) {
          const name = robot.robotName;
          count[name] = (count[name] || 0) + 1;
        }

        return count;
      },
      {} as Record<string, number | undefined>
    );
  }, [robotsHistoryReset, startCollectingTime]);

  const numberOfRobotError = useMemo(
    () => robotsHistoryResetFiltered.filter((robot) => robot.errorType === 'robotError'),
    [robotsHistoryResetFiltered]
  );

  const handleChangeStartCollectingTime = (value: number): void => {
    if (value < 0) {
      value = 0;
    }

    if (isNaN(value)) {
      value = 0;
    }

    dispatch(setStartCollectingTime(value));
  };

  const tasks = useAppSelector((state) => state.simulation.tasks);
  const tasksArr = useMemo(() => Object.values(tasks), [tasks]);

  const [open, setOpen] = useState(true);

  const handleClose = useCallback((): void => {
    setOpen(false);

    setTimeout(() => {
      dispatch(closeDialogAction());
    }, theme.transitions.duration.leavingScreen);
  }, [dispatch]);

  const dialogContentRef = useRef<HTMLDivElement>(null);

  const [considerWaitingTime, setConsiderWaitingTime] = useState(false);

  const handleChangeConsiderWaitingTime = (event: ChangeEvent<HTMLInputElement>): void => {
    setConsiderWaitingTime(event.target.checked);
  };

  const [generatingPdf, setGeneratingPdf] = useState(false);
  const handleDownloadPdf = useCallback(async () => {
    const el = dialogContentRef.current;
    if (!el) {
      return;
    }

    const projectName =
      (simulationData && 'projectName' in simulationData ? simulationData?.projectName : undefined) ?? 'unknown';
    const time = (simulationData && 'time' in simulationData ? simulationData?.time : undefined) ?? new Date();
    const filename = `${projectName} - ${formatTime(time)}.pdf`;

    setGeneratingPdf(true);

    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(true);
      }, 1000)
    );

    try {
      await generatePDF(() => el, {
        page: {
          margin: Margin.SMALL,
        },
        filename,
      });
    } catch (error) {
      SnackbarUtils.error('An error occured while generating the PDF');
    }

    setGeneratingPdf(false);
  }, [simulationData]);

  const stationsWithPositionsNames = useMemo(
    () =>
      stations.map((station) => {
        return {
          stationName: station.name,
          id: station.id,
          names: station.positions.map((position) => {
            return positionIdToPositionName(position.id, position.type);
          }),
        };
      }),
    [stations]
  );

  const robotsSimulationData = useMemo(() => {
    if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
      return undefined;
    }

    return simulationData.robots;
  }, [simulationData]);
  const [robotsBatteryDuration, setRobotsBatteryDuration] = useState<{ robotId: number; batteryDuration: number }[]>(
    []
  );

  const flowsStats: FlowsStats = useMemo(() => {
    const emptyTimes = {
      carryTime: 0,
      emptyTime: 0,
      pickTime: 0,
      dropTime: 0,
      totalTime: 0,
      waitingTime: 0,
    };
    const emptyTimesList: Record<keyof typeof emptyTimes, number[]> = {
      totalTime: [],
      carryTime: [],
      emptyTime: [],
      pickTime: [],
      dropTime: [],
      waitingTime: [],
    };

    const emptyFlowStat = {
      id: 'defaultFlow',
      name: 'defaultFlow',
      nbTasks: 0,
      nbStartedTasks: 0,
      nbFinishedTasks: 0,
      times: {
        totals: {
          ...structuredClone(emptyTimes),
        },
        averages: {
          ...structuredClone(emptyTimes),
        },
        medians: {
          ...structuredClone(emptyTimes),
        },
        mins: {
          ...structuredClone(emptyTimes),
        },
        maxs: {
          ...structuredClone(emptyTimes),
        },
      },
      objective: 0,
      palletsPerTask: 1,
    };
    const flowsStatsTmp = flows.map((flow) => {
      const newFlowStat = structuredClone(emptyFlowStat);
      newFlowStat.id = flow.id;
      newFlowStat.name = flow.name;
      newFlowStat.objective = flow.objective ?? 0;
      newFlowStat.palletsPerTask = flow.palletsPerTask ?? 1;

      return newFlowStat;
    });

    const newRobotsBatteryDuration: { robotId: number; batteryDuration: number }[] = [];

    if (robotsSimulationData) {
      robotsSimulationData.forEach((robot) => {
        newRobotsBatteryDuration.push({
          robotId: robot.robotId,
          batteryDuration: 0,
        });
      });

      tasksArr.forEach((task) => {
        if (!isTaskABattTask(task) || task.robotID === undefined) return;

        const robotBatteryDurationToChange = newRobotsBatteryDuration.find(
          (robotBattery) => robotBattery.robotId === task.robotID
        );

        if (!robotBatteryDurationToChange) return;

        const startDate = task.startDate;
        const adjustedStartCollectingTime = startSimulationDate + startCollectingTime;
        const effectiveEndDate =
          task.endDate && task.endDate > adjustedStartCollectingTime ? task.endDate : simulationTimeEpoch;

        if (startDate && startDate > adjustedStartCollectingTime) {
          robotBatteryDurationToChange.batteryDuration += effectiveEndDate - startDate;
        } else if (effectiveEndDate) {
          robotBatteryDurationToChange.batteryDuration += effectiveEndDate - adjustedStartCollectingTime;
        }
      });
    }

    const tasks = store.getState().simulation.tasks;

    // key = flow id, value = array of durations
    const timesPerFlow: Record<string, typeof emptyTimesList> = {};

    const doesDefaultFlowExists = Object.values(tasks).some((task) => task.taskName === 'defaultFlow');
    if (doesDefaultFlowExists) {
      flowsStatsTmp.push(emptyFlowStat);
    }

    for (const taskId in tasks) {
      const task = tasks[taskId];

      if (startCollectingTime > 0) {
        if (!task.startDate) {
          continue;
        }

        if (startSimulationDate && task.startDate < startSimulationDate + startCollectingTime) {
          continue;
        }
      }

      if (!task) continue;

      const flowStat = flowsStatsTmp.find((flow) => flow.name === task.taskName);
      const flow = flows.find((flow) => flow.name === task.taskName);
      if (!flowStat || !flow) {
        continue;
      }

      if (!timesPerFlow[flowStat.id]) timesPerFlow[flowStat.id] = structuredClone(emptyTimesList);

      flowStat.nbTasks++;
      if (task.startDate) {
        flowStat.nbStartedTasks++;
      }

      if (task.endDate) {
        flowStat.nbFinishedTasks++;
      }

      if (task.startDate && task.endDate && task.creationDate) {
        const taskDuration = task.endDate - task.startDate;
        const taskWaitingDuration = task.startDate - task.creationDate;

        flowStat.times.totals.totalTime += taskDuration;
        flowStat.times.totals.waitingTime += taskWaitingDuration;

        timesPerFlow[flowStat.id].totalTime.push(taskDuration);
        timesPerFlow[flowStat.id].waitingTime.push(taskWaitingDuration);
      }

      if (
        task.stepNames &&
        task.stepEndDate &&
        task.stepEndDate.length &&
        task.stepNames.length === task.stepEndDate.length
      ) {
        for (let i = task.stepNames.length - 1; i >= 0; i--) {
          let keyTimeNextStep: 'emptyTime' | 'carryTime' = 'emptyTime';
          const stepName = task.stepNames[i];
          const stepDuration = task.stepEndDate[i] - (task.stepEndDate[i - 1] ?? task.startDate);

          if (isNaN(stepDuration)) continue;

          if (stepName === 'pick') {
            flowStat.times.totals.emptyTime += stepDuration;
            keyTimeNextStep = 'emptyTime';

            timesPerFlow[flowStat.id].emptyTime.push(stepDuration);
          } else if (stepName === 'drop') {
            flowStat.times.totals.carryTime += stepDuration;
            keyTimeNextStep = 'carryTime';

            timesPerFlow[flowStat.id].carryTime.push(stepDuration);
          } else if (stepName === 'move') {
            flowStat[keyTimeNextStep] = stepDuration;

            timesPerFlow[flowStat.id][keyTimeNextStep].push(stepDuration);
          } else if (stepName === 'battery' || stepName === 'batteryWaiting') {
            if (task.robotID !== undefined) {
              const robotBatteryDurationToChange = newRobotsBatteryDuration.find(
                (robotBattery) => robotBattery.robotId === task.robotID
              );

              if (robotBatteryDurationToChange) {
                robotBatteryDurationToChange.batteryDuration += stepDuration;
              }
            }
          } else {
            // eslint-disable-next-line no-console
            console.warn(`Unknown step name: ${stepName}`);
          }
        }
      }
    }

    // sort the times
    Object.keys(timesPerFlow).forEach((flowId) => {
      const times = timesPerFlow[flowId];

      Object.keys(times).forEach((uncastedKey) => {
        const key = uncastedKey as keyof typeof times;
        const timesForKey = times[key];

        timesForKey.sort((a, b) => a - b);
      });
    });

    // compute the averages
    flowsStatsTmp.forEach((flowStat) => {
      const { totalTime, carryTime, emptyTime, pickTime, dropTime, waitingTime } = flowStat.times.totals;
      let { nbTasks, nbStartedTasks, nbFinishedTasks } = flowStat;

      if (nbTasks === 0) {
        nbTasks = 1;
        nbStartedTasks = 1;
        nbFinishedTasks = 1;
      }

      flowStat.times.averages.totalTime = totalTime / nbFinishedTasks;
      flowStat.times.averages.carryTime = carryTime / nbFinishedTasks;
      flowStat.times.averages.emptyTime = emptyTime / nbFinishedTasks;
      flowStat.times.averages.pickTime = pickTime / nbStartedTasks;
      flowStat.times.averages.dropTime = dropTime / nbFinishedTasks;
      flowStat.times.averages.waitingTime = waitingTime / nbTasks;
    });
    // compute the medians and min/max
    flowsStatsTmp.forEach((flowStat) => {
      const flowTimes = timesPerFlow[flowStat.id];

      if (!flowTimes) return;

      const { totalTime, carryTime, emptyTime, pickTime, dropTime, waitingTime } = flowTimes;

      flowStat.times.medians.totalTime = totalTime[Math.floor(totalTime.length / 2)];
      flowStat.times.medians.carryTime = carryTime[Math.floor(carryTime.length / 2)];
      flowStat.times.medians.emptyTime = emptyTime[Math.floor(emptyTime.length / 2)];
      flowStat.times.medians.pickTime = pickTime[Math.floor(pickTime.length / 2)];
      flowStat.times.medians.dropTime = dropTime[Math.floor(dropTime.length / 2)];
      flowStat.times.medians.waitingTime = waitingTime[Math.floor(waitingTime.length / 2)];

      flowStat.times.mins.totalTime = totalTime[0];
      flowStat.times.mins.carryTime = carryTime[0];
      flowStat.times.mins.emptyTime = emptyTime[0];
      flowStat.times.mins.pickTime = pickTime[0];
      flowStat.times.mins.dropTime = dropTime[0];
      flowStat.times.mins.waitingTime = waitingTime[0];

      flowStat.times.maxs.totalTime = totalTime[totalTime.length - 1];
      flowStat.times.maxs.carryTime = carryTime[carryTime.length - 1];
      flowStat.times.maxs.emptyTime = emptyTime[emptyTime.length - 1];
      flowStat.times.maxs.pickTime = pickTime[pickTime.length - 1];
      flowStat.times.maxs.dropTime = dropTime[dropTime.length - 1];
      flowStat.times.maxs.waitingTime = waitingTime[waitingTime.length - 1];

      // replace NaNs by 0
      Object.keys(flowStat.times).forEach((key) => {
        const times = flowStat.times[key as keyof typeof flowStat.times];

        Object.keys(times).forEach((uncastedKey) => {
          const key = uncastedKey as keyof typeof times;
          const time = times[key];

          if (isNaN(time)) times[key] = 0;
        });
      });
    });

    setRobotsBatteryDuration(newRobotsBatteryDuration);

    return flowsStatsTmp;
  }, [flows, robotsSimulationData, simulationTimeEpoch, startCollectingTime, startSimulationDate, tasksArr]);

  const availableUtilizationRates = useMemo(
    () =>
      [
        { label: '0%', value: 0 },
        { label: '5%', value: 0.05 },
        { label: '10%', value: 0.1 },
        { label: '15%', value: 0.15 },
        { label: '20%', value: 0.2 },
        { label: '25%', value: 0.25 },
        { label: '30%', value: 0.3 },
        { label: '35%', value: 0.35 },
        { label: '40%', value: 0.4 },
        { label: '45%', value: 0.45 },
        { label: '50%', value: 0.5 },
        { label: '55%', value: 0.55 },
        { label: '60%', value: 0.6 },
        { label: '65%', value: 0.65 },
        { label: '70%', value: 0.7 },
        { label: '75%', value: 0.75 },
        { label: 'Low (80%)', value: 0.8 },
        { label: 'Medium (85%)', value: 0.85 },
        { label: 'High (90%)', value: 0.9 },
        { label: '95%', value: 0.95 },
        { label: '100%', value: 1 },
      ] as const,
    []
  );

  const [utilizationRateValue, setUtilizationRateValue] = useState('0.85');

  const handleSelectInputChange = (event: SelectChangeEvent): void => {
    setUtilizationRateValue(event.target.value);
  };

  const [selectedTaskId, setSelectedTaskId] = useState(Object.keys(store.getState().simulation.tasks)?.[0]);

  const roadEditorVersion = useMemo(() => getConfig('roadEditorVersion') || packageJson.version || undefined, []);
  const balyoSimulationVersion = useAppSelector((state) => state.project.balyoSimulationVersion);

  const exactBalyoSimulationVersion = useAsyncMemo(async () => {
    const balyoSimulationVersionPath = (
      await import(`../../../simulation/BalyoSimulation/${balyoSimulationVersion}/balyo_simulation_version.txt`)
    ).default as string;

    return (await fetch(balyoSimulationVersionPath).then((res) => res.text())).trim();
  }, []);

  //New robot timer value with the startCollectingTime option
  const robotsTimersAfterStartCollectingTime = useMemo(() => {
    if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
      return undefined;
    }

    if (startCollectingTime === 0) {
      return undefined;
    }

    const robotsTimersAtStartCollectingTime = robotsTimersHistory.find(
      (timer) => timer.simulationTime === startCollectingTime
    );

    if (!robotsTimersAtStartCollectingTime) {
      return undefined;
    }

    const timers: RobotTimers[] = robotsTimersAtStartCollectingTime.robotsTimers
      .map((robotTimersAtCollectingTime, i) => {
        const robotTimersAtCurrentTime = simulationData.robots[i].timers;
        if (!!robotTimersAtCurrentTime) {
          //We must substract the timers of the newRobotsTimersHistory from the timers of the entire simulation to get the right data.
          return deepMerge(robotTimersAtCurrentTime, robotTimersAtCollectingTime.timers, true) as RobotTimers;
        }

        return undefined;
      })
      .filter(isDefined);

    return timers;
  }, [robotsTimersHistory, simulationData, startCollectingTime]);

  const robotTimersSum = useMemo(() => {
    if (startCollectingTime === 0) {
      if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
        return undefined;
      }

      let timersSum = simulationData.robots[0]?.timers
        ? resetObject(structuredClone(simulationData.robots[0].timers), 0)
        : undefined;
      if (!timersSum) return;

      simulationData.robots.forEach((robot) => {
        if (!robot.timers) return;

        if (robot.timers && timersSum) {
          timersSum = deepMerge(timersSum, robot.timers) as typeof timersSum;
        }
      });

      return timersSum;
    }

    if (startCollectingTime > 0) {
      if (!robotsTimersAfterStartCollectingTime) {
        return undefined;
      }

      let timersSumAfterStartCollectingTime = robotsTimersAfterStartCollectingTime[0];

      robotsTimersAfterStartCollectingTime.forEach((robotTimers) => {
        timersSumAfterStartCollectingTime = deepMerge(
          timersSumAfterStartCollectingTime,
          robotTimers
        ) as typeof timersSumAfterStartCollectingTime;
      });

      return timersSumAfterStartCollectingTime;
    }
  }, [robotsTimersAfterStartCollectingTime, simulationData, startCollectingTime]);

  const robotTimersAvg = useMemo(() => {
    if (startCollectingTime === 0) {
      if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
        return undefined;
      }

      const timersSum = robotTimersSum;
      if (!timersSum) return;

      const timersAvg = divideAllPropertiesBy(structuredClone(timersSum), simulationData.robots.length);

      return timersAvg;
    }

    if (startCollectingTime > 0) {
      if (!robotsTimersAfterStartCollectingTime) {
        return undefined;
      }

      const timersSum = robotTimersSum;
      if (!timersSum) return;

      const timersAvgAfterStartCollectingTime = divideAllPropertiesBy(
        structuredClone(timersSum),
        robotsTimersAfterStartCollectingTime.length
      );

      return timersAvgAfterStartCollectingTime;
    }
  }, [robotTimersSum, robotsTimersAfterStartCollectingTime, simulationData, startCollectingTime]);

  const ratioPalTask = flowsStats.map((flowStat) => flowStat.palletsPerTask);

  const isAllRatioAtOne = ratioPalTask.every((e) => e === 1);

  const [simplifiedMode, setSimplifiedMode] = useState(isAllRatioAtOne);

  const handleChangeSimplifiedMode = (event: ChangeEvent<HTMLInputElement>): void => {
    setSimplifiedMode(event.target.checked);
  };

  const tasksAfterStartCollectingTime = useMemo((): SimulationTask[] => {
    return tasksArr
      .map((task) => {
        if (task.startDate && task.startDate > startSimulationDate + startCollectingTime) {
          return task;
        }

        return undefined;
      })
      .filter(isDefined);
  }, [startCollectingTime, startSimulationDate, tasksArr]);

  const atLeastOneTaskFinished = tasksAfterStartCollectingTime.some((task) => task.endDate);

  const hasUnmetObjectives = useMemo(
    () =>
      hasUnmetThroughputWithAvailableRobots(flowsStats, robotTimersSum, elapsedTimeSimulation - startCollectingTime),
    [flowsStats, robotTimersSum, elapsedTimeSimulation, startCollectingTime]
  );

  if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
    return <></>;
  }

  const simulationTime = simulationData.time;
  const simulationTimeFormatted = formatTime(simulationTime);
  const simulationDurationFormatted = formatSecondsToHHMMSS(elapsedTimeSimulation);
  const consideredSimultationTime = formatSecondsToHHMMSS(elapsedTimeSimulation - startCollectingTime);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="simulation-report-dialog-title"
      id="simulation-report"
    >
      <DialogTitle id="simulation-report-dialog-title">
        Simulation Report
        <Box
          component="span"
          sx={{
            float: 'right',
            display: 'flex',
            gap: 1,
          }}
        >
          <ExtractRecordsButton />
          <Tooltip title="Download as PDF">
            <IconButton aria-label="Download as PDF" onClick={handleDownloadPdf} disabled={generatingPdf}>
              {!generatingPdf ? <PictureAsPdf /> : <CircularProgress size="1rem" />}
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent
        ref={dialogContentRef}
        sx={{
          overflow: generatingPdf ? 'visible' : undefined,
        }}
      >
        <Card
          variant="outlined"
          sx={{
            pageBreakInside: 'avoid',
          }}
        >
          <CardHeader title="Start collecting time" />
          <CardContent>
            <Stack direction="row" alignItems={'center'} spacing={2}>
              <div>Define the time at which the data will be considered</div>
              <Select
                variant="outlined"
                size="small"
                value={startCollectingTime.toString()}
                onChange={(event: SelectChangeEvent) => {
                  handleChangeStartCollectingTime(Number(event.target.value));
                }}
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
              >
                <MenuItem value={0}>0 min</MenuItem>
                {robotsTimersHistory.map((robotTimer) => {
                  const totalTime = Math.round(robotTimer.simulationTime);

                  const timer = formatSecondsToHMinSec(totalTime);

                  return (
                    <MenuItem key={robotTimer.simulationTime} value={robotTimer.simulationTime}>
                      {timer}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            pageBreakInside: 'avoid',
            marginTop: '1rem',
          }}
        >
          <CardHeader title="Simulation Configuration" />
          <CardContent>
            <Table size="small">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>Simulation Time</StyledTableCell>
                  <StyledTableCell>{simulationTimeFormatted}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Simulation Duration</StyledTableCell>
                  <StyledTableCell>
                    <Box component="span" data-testid="simulation-duration">
                      {simulationDurationFormatted}
                    </Box>
                    {startCollectingTime > 0 && `${' '}(considered simulation time: ${consideredSimultationTime})`}
                    {elapsedTimeSimulation < minimumRelevantSimulationDuration ? (
                      <>
                        {' '}
                        <Tooltip
                          title={`The simulation has been carried out on quite a short duration. You should have this in mind when looking at the result.`}
                        >
                          <Warning
                            color="warning"
                            fontSize="small"
                            sx={{
                              verticalAlign: 'middle',
                              marginLeft: '0.5rem',
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Project Name</StyledTableCell>
                  <StyledTableCell>{simulationData.projectName}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Project SDK</StyledTableCell>
                  <StyledTableCell>{simulationData.sdkVersion}</StyledTableCell>
                </StyledTableRow>
                {gitBranchInformation && (
                  <StyledTableRow>
                    <StyledTableCell>Project Git Branch</StyledTableCell>
                    <StyledTableCell>
                      {gitBranchInformation.branch} {gitBranchInformation.sha && `(${gitBranchInformation.sha.trim()})`}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                <StyledTableRow>
                  <StyledTableCell>Road Editor Version / Balyo Simulation Version</StyledTableCell>
                  <StyledTableCell>
                    {roadEditorVersion} /{' '}
                    <Box component="span" data-testid="balyo-simulation-version">
                      {exactBalyoSimulationVersion ?? balyoSimulationVersion}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Circuit</StyledTableCell>
                  <StyledTableCell>{simulationData.circuitName}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Circuit version</StyledTableCell>
                  <StyledTableCell>{simulationData.circuitVersion}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Battery missions</StyledTableCell>
                  <StyledTableCell>
                    <Box component="span" data-testid="battery-status">
                      {simulationData.battery.enabled ? 'Enabled' : 'Disabled'}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Opportunity Charging (OPC)</StyledTableCell>
                  <StyledTableCell>{simulationData.battery.OPC ? 'Enabled' : 'Disabled'}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            marginTop: '1rem',
            pageBreakInside: 'avoid',
          }}
        >
          <CardHeader title="Robots" subheader="Model and parameters" />
          <CardContent>
            <Table size="small" key={'model-and-parameters-1'}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Serial</StyledTableCell>
                  <StyledTableCell>Model</StyledTableCell>
                  <StyledTableCell>Battery</StyledTableCell>
                  <StyledTableCell>Max Forward Speed</StyledTableCell>
                  <StyledTableCell>Max Backward Speed</StyledTableCell>
                  <StyledTableCell>Max Lateral Speed</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {simulationData.robots.map((robot) => (
                  <StyledTableRow key={robot.serial}>
                    <StyledTableCell>{robot.name}</StyledTableCell>
                    <StyledTableCell>{robot.serial}</StyledTableCell>
                    <StyledTableCell>{robot.modelName}</StyledTableCell>
                    <StyledTableCell>{robot.batteryModel}</StyledTableCell>
                    <StyledTableCell>
                      {robot.maxForwardSpeed !== undefined ? robot.maxForwardSpeed.toFixed(2) : 'unknown'} m/s
                    </StyledTableCell>
                    <StyledTableCell>
                      {robot.maxBackwardSpeed !== undefined ? robot.maxBackwardSpeed.toFixed(2) : 'unknown'} m/s
                    </StyledTableCell>
                    <StyledTableCell>
                      {robot.maxLateralSpeed !== undefined ? robot.maxLateralSpeed.toFixed(2) : 'unknown'} m/s
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>

            <Table size="small" key={'model-and-parameters-2'}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Serial</StyledTableCell>
                  <StyledTableCell>Charge Duration</StyledTableCell>
                  <StyledTableCell>Discharge Duration</StyledTableCell>
                  <StyledTableCell>Battery Capacity</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {simulationData.robots.map((robot) => (
                  <StyledTableRow key={robot.serial}>
                    <StyledTableCell>{robot.name}</StyledTableCell>
                    <StyledTableCell>{robot.serial}</StyledTableCell>
                    <StyledTableCell>
                      {robot.chargeTime ? formatSecondsToHHhMM(robot.chargeTime) : 'Unknown'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {robot.dischargeTime ? formatSecondsToHHhMM(robot.dischargeTime) : 'Unknown'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {robot.batteryCapacity ? `${robot.batteryCapacity} Ah` : 'Unknown'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>

          <CardHeader title="Robots" subheader="Times and counters" />
          <CardContent>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>
                    Carry{' '}
                    <HelpIconTooltip
                      title="Duration spent when the robot was carrying a load"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    Empty{' '}
                    <HelpIconTooltip
                      title="Duration spent when the robot was not carrying a load"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    Available{' '}
                    <HelpIconTooltip
                      title="Duration spent with no mission, the robot was either waiting at its taxi point or going to its taxi point."
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderLeft: borderStyleSeparator,
                    }}
                  >
                    Battery{' '}
                    <HelpIconTooltip
                      title="Duration spent going to a battery point (docking station or table change) and charging"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderLeft: borderStyleSeparator,
                    }}
                  >
                    Traffic{' '}
                    <HelpIconTooltip
                      title="Duration spent waiting for traffic authorization (waiting for a robot to free the area for example)"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {simulationData.robots.map((robot, robotIndex) => {
                  if (!robot.timers) return <></>;

                  if (startCollectingTime > 0 && robotsTimersAfterStartCollectingTime) {
                    return (
                      <RobotTimerRow
                        key={robot.serial}
                        robotTimers={robotsTimersAfterStartCollectingTime[robotIndex]}
                        label={robot.name}
                        battTime={
                          robotsBatteryDuration.find((robotBattery) => robotBattery.robotId === robot.robotId)
                            ?.batteryDuration ?? 0
                        }
                        flowsStats={flowsStats}
                        elapsedTime={elapsedTimeSimulation - startCollectingTime}
                      />
                    );
                  }

                  return (
                    <RobotTimerRow
                      key={robot.serial}
                      robotTimers={robot.timers}
                      label={robot.name}
                      battTime={
                        robotsBatteryDuration.find((robotBattery) => robotBattery.robotId === robot.robotId)
                          ?.batteryDuration ?? 0
                      }
                      flowsStats={flowsStats}
                      elapsedTime={elapsedTimeSimulation - startCollectingTime}
                    />
                  );
                })}
              </TableBody>
              {(robotTimersAvg || robotTimersSum) && (
                <TableFooter
                  sx={{
                    borderTop: borderStyleSeparator,
                  }}
                >
                  {robotTimersAvg && (
                    <RobotTimerRow
                      key="Average"
                      robotTimers={robotTimersAvg}
                      battTime={
                        robotsBatteryDuration.reduce((acc, val) => acc + val.batteryDuration, 0) /
                        robotsBatteryDuration.length
                      }
                      label="Average"
                      flowsStats={flowsStats}
                      elapsedTime={elapsedTimeSimulation - startCollectingTime}
                    />
                  )}

                  {robotTimersSum && (
                    <RobotTimerRow
                      key="Total"
                      robotTimers={robotTimersSum}
                      battTime={robotsBatteryDuration.reduce((acc, val) => acc + val.batteryDuration, 0)}
                      label="Total"
                      displayPercents={false}
                      flowsStats={flowsStats}
                      elapsedTime={elapsedTimeSimulation - startCollectingTime}
                    />
                  )}
                </TableFooter>
              )}
            </Table>
          </CardContent>

          <Box
            component="div"
            sx={{
              background: startCollectingTime > 0 ? theme.palette.grey[100] : undefined,
            }}
          >
            <CardHeader title="Robots" subheader="Distance traveled and average speed" />
            {startCollectingTime > 0 && (
              <Alert
                severity="info"
                sx={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                }}
              >
                The following table does not consider the "start collecting time" parameter.
              </Alert>
            )}

            <CardContent>
              <Table size="small">
                <TableHead>
                  <StyledTableRow style={{ backgroundColor: 'red', color: 'white' }}>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Distance Traveled</StyledTableCell>
                    <StyledTableCell>Average Speed</StyledTableCell>
                    {robotsHistoryResetFiltered.length > 0 && <StyledTableCell>Number of reset</StyledTableCell>}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {simulationData.robots.map((robot) => {
                    const distance = robot.traveledDistance ?? 0;
                    const speed = distance / elapsedTimeSimulation;
                    const speedKmHr = speed * 3.6;

                    return (
                      <StyledTableRow key={robot.serial}>
                        <StyledTableCell>{robot.name}</StyledTableCell>
                        <StyledTableCell>{distance.toFixed(2)} m</StyledTableCell>
                        <StyledTableCell>
                          {speed.toFixed(2)} m/s ({speedKmHr.toFixed(1)} km/h)
                        </StyledTableCell>
                        {robotsHistoryResetFiltered.length > 0 && (
                          <StyledTableCell>{robotsResetCount[robot.name]}</StyledTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Box>

          {robotsHistoryResetFiltered.length > 0 && (
            <>
              <CardHeader
                title="Robots"
                subheader="During the simulation, certain robots are reset to their taxi points to address errors or deadlocks. This allows for a preliminary version of the simulation, despite minor issues. Note that this approach may affect throughput since, in reality, robots cannot teleport to their taxi points."
              />

              <CardContent>
                <Table size="small">
                  <TableHead>
                    <StyledTableRow style={{ color: 'white' }}>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Reset reason</StyledTableCell>
                      <StyledTableCell>Time</StyledTableCell>
                      <StyledTableCell>x</StyledTableCell>
                      <StyledTableCell>y</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {robotsHistoryResetFiltered.map((robot, i) => {
                      const name = robot.robotName;
                      const errorType = humanifyErrorType(robot.errorType);
                      const simulationTime = formatSecondsToHHMMSS(robot.simulationTime);

                      return (
                        <StyledTableRow key={i}>
                          <StyledTableCell>{name}</StyledTableCell>
                          <StyledTableCell>{errorType}</StyledTableCell>
                          <StyledTableCell>{simulationTime}</StyledTableCell>
                          <StyledTableCell>{robot.position.x}</StyledTableCell>
                          <StyledTableCell>{robot.position.y}</StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                  <TableHead key="robots-reset-footer">
                    <StyledTableRow>
                      <StyledTableCell>Total</StyledTableCell>
                      <StyledTableCell colSpan={5}>{`${robotsHistoryResetFiltered.length} ${
                        numberOfRobotError.length > 0 && numberOfRobotError.length !== robotsHistoryResetFiltered.length
                          ? `(${numberOfRobotError.length} robot error + ${
                              robotsHistoryResetFiltered.length - numberOfRobotError.length
                            } deadlocks)`
                          : ''
                      }`}</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                </Table>
              </CardContent>
            </>
          )}
        </Card>

        <Card
          variant="outlined"
          sx={{
            marginTop: '1rem',
            pageBreakInside: 'avoid',
            overflow: 'visible',
          }}
        >
          <CardHeader title="Flows" subheader="List of flows" />
          <ListOfFlowsTable flows={flows} stationsWithPositionsNames={stationsWithPositionsNames} />

          <CardHeader title="Flows" subheader="Durations" />
          <CardContent>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    Waiting{' '}
                    <HelpIconTooltip
                      title="Duration between the task creation time and when a robot actually began to do it."
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>Empty</StyledTableCell>
                  <StyledTableCell>Carry</StyledTableCell>
                  <StyledTableCell>
                    Total{' '}
                    <HelpIconTooltip
                      title="Carry + Empty durations"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {flowsStats.map((flowStat) => {
                  return estimateToDisplay.map((estimateName, index) => {
                    let keyName: keyof typeof flowStat.times | undefined;
                    if (estimateName === 'Average') keyName = 'averages';
                    else if (estimateName === 'Median') keyName = 'medians';
                    else if (estimateName === 'Min') keyName = 'mins';
                    else if (estimateName === 'Max') keyName = 'maxs';

                    assert<Not<Equals<typeof keyName, undefined>>>();

                    if (keyName === undefined) return <></>;

                    const flowTime = flowStat.times[keyName];

                    return (
                      <StyledTableRowFlowTimes key={`${flowStat.id}-${estimateName}`}>
                        {!generatingPdf && (
                          <StyledTableCell
                            rowSpan={index === 0 ? 4 : 1}
                            sx={{
                              display: index === 0 ? 'table-cell' : 'none',
                            }}
                          >
                            {flowStat.name}
                          </StyledTableCell>
                        )}
                        {
                          /**
                           * This block is used because there's a display issue in the generated PDF
                           */
                          generatingPdf && (
                            <>
                              {index === 0 && <StyledTableCell>{flowStat.name}</StyledTableCell>}
                              {index !== 0 && <StyledTableCell></StyledTableCell>}
                            </>
                          )
                        }
                        <StyledTableCell>{estimateName}</StyledTableCell>
                        <StyledTableCell>{formatSecondsToHHMMSS(flowTime.waitingTime)}</StyledTableCell>
                        <StyledTableCell>
                          {formatSecondsToHHMMSS(flowTime.emptyTime)} (
                          {((flowTime.emptyTime / flowTime.totalTime || 0) * 100).toFixed(0)}%)
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatSecondsToHHMMSS(flowTime.carryTime)} (
                          {((flowTime.carryTime / flowTime.totalTime || 0) * 100).toFixed(0)}%)
                        </StyledTableCell>
                        <StyledTableCell>{formatSecondsToHHMMSS(flowTime.totalTime)}</StyledTableCell>
                      </StyledTableRowFlowTimes>
                    );
                  });
                })}
              </TableBody>
            </Table>

            <Alert
              severity="info"
              sx={{
                marginTop: theme.spacing(2),
              }}
            >
              <AlertTitle>Steps Legend</AlertTitle>
              <ul>
                <li>
                  <b>Waiting</b>: Duration spent waiting for a robot to be available
                </li>
                <li>
                  <b>Carry</b>: Duration spent when the robot was carrying a load
                </li>
                <li>
                  <b>Empty</b>: Duration spent when the robot was not carrying a load
                </li>
                <li>
                  <b>Pick</b>: Duration spent picking a load (lifting the forks in front of the slot, etc.)
                </li>
                <li>
                  <b>Drop</b>: Duration spent dropping a load (lowering the forks, etc.)
                </li>
                <li>
                  <b>Total</b>: Total duration of the flow
                </li>
              </ul>
              <AlertTitle>Estimates Legend</AlertTitle>
              Beware that for <b>Average</b> and <b>Median</b> estimates, the values totals are not the sum of the
              values.
            </Alert>
          </CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CardHeader title="Flows" subheader="Throughputs" />
            <Box
              component={'div'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'flex-end'}
              sx={{ marginRight: '15px' }}
            >
              {isAllRatioAtOne && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={simplifiedMode}
                      onChange={handleChangeSimplifiedMode}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Simplified mode"
                />
              )}
              <Box component={'div'}>
                <Stack direction="row">
                  <InputLabel>Utilization rate</InputLabel>
                  <HelpIconTooltip
                    title="Percentage of time the robot is actually moving pallets. The remaining percentage is spent on incidents. Incidents are defined as any event provoked by the customer (warehouse operators) that blocks the robot."
                    sx={{
                      display: generatingPdf ? 'none' : undefined,
                    }}
                  ></HelpIconTooltip>
                </Stack>
                <Select defaultValue={'0.85'} onChange={handleSelectInputChange} size="small" sx={{ minWidth: 155 }}>
                  {availableUtilizationRates.map((number) => (
                    <MenuItem key={number.value} value={number.value}>
                      {number.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Stack>

          <CardContent>
            <AlertVnaPerformance robotsSimulationData={robotsSimulationData} />
            {hasUnmetObjectives && (
              <Alert
                severity="warning"
                sx={{
                  marginBottom: 2,
                }}
              >
                <AlertTitle>Throughputs not met while robots were idling</AlertTitle>
                Some flows are not meeting their objectives despite having available robots. This may indicate a need to
                optimize the robot allocation strategy or review the trigger configuration.
              </Alert>
            )}

            <FlowThroughputTable
              elapsedTimeSimulation={elapsedTimeSimulation - startCollectingTime}
              flowsStats={flowsStats}
              utilizationRateValue={utilizationRateValue}
              simplifiedMode={simplifiedMode}
            />
          </CardContent>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CardHeader title="Flows" subheader="Duration statistics" />{' '}
            <FormControlLabel
              control={<Switch checked={considerWaitingTime} onChange={handleChangeConsiderWaitingTime} />}
              label="Consider Waiting Time"
            />
          </Stack>

          <CardContent>
            {atLeastOneTaskFinished && (
              <TasksDurationChartReport
                tasks={tasksAfterStartCollectingTime}
                flows={flows}
                considerWaitingTime={considerWaitingTime}
              />
            )}

            {!atLeastOneTaskFinished && (
              <Alert severity="info">No tasks have been completed yet, thereby there are no tasks to analyze.</Alert>
            )}
          </CardContent>
        </Card>

        {simulationData?.schedulerConfigName && simulationData?.schedulerConfig && (
          <DisplaySchedulerConfig
            schedulerConfig={simulationData.schedulerConfig}
            schedulerConfigName={simulationData.schedulerConfigName}
          />
        )}

        {!generatingPdf && (
          <TaskPerformanceAnalysis
            robotsSimulationData={robotsSimulationData}
            tasksArr={tasksArr}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            simulationData={simulationData}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

interface RobotTimerRowProps {
  robotTimers: RobotTimers;
  battTime: number;
  label: string;
  displayPercents?: boolean;
  flowsStats?: FlowsStats;
  elapsedTime?: number;
}

function RobotTimerRow(props: RobotTimerRowProps): JSX.Element {
  const { robotTimers, label, battTime, displayPercents = true, flowsStats, elapsedTime } = props;

  const isSpecialLabel = label === 'Total' || label === 'Average';

  const taxiTime = (robotTimers?.task.Available ?? 0) + (robotTimers?.task.RunToTaxi ?? 0);
  const trafficTime = (robotTimers?.traffic.AutoWait ?? 0) + (robotTimers?.traffic.AutoNav ?? 0);
  const carryTime = robotTimers?.carry ?? 0;
  const totalTime = robotTimers?.total ?? 0;
  const emptyTime = totalTime - carryTime;

  // when the robot is in taxi, it is empty but we do not want to consider this duration as empty
  const actualEmptyTime = Math.max(emptyTime - taxiTime, 0);

  const hasUnmetObjectives =
    !isSpecialLabel && flowsStats && elapsedTime
      ? hasUnmetThroughputWithAvailableRobots(flowsStats, robotTimers, elapsedTime)
      : false;

  return (
    <StyledTableRow>
      <StyledTableCell>{label}</StyledTableCell>
      <StyledTableCell>
        {formatSecondsToHHMMSS(carryTime)}{' '}
        {displayPercents && <>({((carryTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
      <StyledTableCell>
        {formatSecondsToHHMMSS(actualEmptyTime)}{' '}
        {displayPercents && <>({((actualEmptyTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
      <StyledTableCell
        sx={{
          color: hasUnmetObjectives ? theme.palette.warning.main : undefined,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        {hasUnmetObjectives && (
          <WarningIconTooltip title="The throughput objective was not met despite robots being available. This may indicate a need to create more tasks for this robot." />
        )}
        {formatSecondsToHHMMSS(taxiTime)} {displayPercents && <>({((taxiTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
      <StyledTableCell
        sx={{
          borderLeft: borderStyleSeparator,
        }}
      >
        <Box component="span" data-testid={`battery-time-${label}`}>
          {formatSecondsToHHMMSS(battTime)}{' '}
          {displayPercents && <>({((battTime / totalTime || 0) * 100).toFixed(0)}%)</>}
        </Box>
      </StyledTableCell>
      <StyledTableCell
        sx={{
          borderLeft: borderStyleSeparator,
        }}
      >
        <Box component="span" data-testid={`traffic-time-${label}`}>
          {formatSecondsToHHMMSS(trafficTime)}{' '}
          {displayPercents && <>({((trafficTime / totalTime || 0) * 100).toFixed(0)}%)</>}
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
}
