import { updateMapImagePropertiesAction } from 'actions';
import { countBy } from 'lodash';
import { DialogTypes } from 'models';
import type { CircuitShape, GeoJsonCircuit } from 'models/circuit';
import { localDoc, projectHost } from 'multiplayer/globals';
import type { SnackbarKey } from 'notistack';
import { changeMapImageNameFilterAction } from 'reducers/local/filters.reducer';
import { CircuitService } from 'services/circuit.service';
import { getLoadedCircuitName } from 'services/project';
import { SnackbarUtils } from 'services/snackbar.service';
import { saveOpcConfigurationOnDisk } from 'simulation/opc';
import store from 'store';
import { checkStorageQuota } from 'utils/check-storage-quota';
import { toTwoDigits } from 'utils/circuit';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { computeAgainAllRacks } from 'utils/circuit/compute-again-all-racks';
import { checkUnicityRackPositionsName } from 'utils/circuit/racks';
import { isCircuitNote } from 'utils/circuit/shape-guards';
import { epsilon } from 'utils/circuit/utils';
import { checkFireAlarmsDeviceNames } from 'utils/device';
import { checkSegmentsPortions, recomputeSegmentsPortions } from 'utils/export/check-portions';
import { generateGeoJSON } from 'utils/export/generate-geojson';
import { generatePrefXmlArray } from 'utils/export/generate-pref-dictionary-xml';
import { generateXML } from 'utils/export/generate-xml';
import { MissingCellTemplateError, isMissingCellTemplateError } from 'utils/export/generate-xml-utils.ts';
import { regexMapImageOuster } from 'utils/map-scaling';
import { PreferencesService, writeToFile } from 'utils/preferences';
import { manageMissingCellTemplateError } from '../manage-missing-cell-template-error';
import { exportDevices } from './export-devices';
import { saveNoteImages } from './save-note-images';
import { saveRackAnalysisDateApplied } from './save-rack-analysis-date-applied';
import { saveSchedulerConfigOnDisk } from './save-scheduler-configuration';
import { changeLoadingToSuccessSnackbar, createSavingProjectSnackbar } from './save-snackbar';

export let isSavingProject = false;
export const setIsSavingProject = (newValue = true): void => {
  isSavingProject = newValue;
};

export let snackbarLoadingKey: SnackbarKey | undefined;
export const resetSnackbarLoadingKey = (): void => {
  snackbarLoadingKey = undefined;
};

export async function saveProject(
  archive = false, // for backup purpose, like autosave
  options?: {
    /** do not generate the xml */
    ignoreXml?: boolean;
    /** do not trigger the finish callback (the well saved notification) */
    ignoreFinishCallback?: boolean;
    /** do not save the layout image on disk (only if not present) */
    ignoreSaveLayoutImage?: boolean;
    /** check the segments portions before saving the project */
    checkSegmentsPortions?: boolean;
    /** number of try to save the project */
    nbTry?: number;
  }
): Promise<void> {
  const isAccessRequiredDialogDisplayed =
    store.getState().dialog.type === DialogTypes.AccessRequired && store.getState().dialog.open;
  if (isAccessRequiredDialogDisplayed) {
    // eslint-disable-next-line no-console
    console.error('Access required dialog is displayed, cannot save project');

    return;
  }

  if (!archive && !snackbarLoadingKey) {
    snackbarLoadingKey = createSavingProjectSnackbar();
  }

  const startTime = performance.now();

  const features = CircuitService.getCircuitElements();
  const { zones, stockZones, segments, turns, points, measurers, racks, devices } = features;

  const layers = store.getState().circuit.present.layers.layers;

  const date = new Date();
  const now = `_${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())}_${toTwoDigits(
    date.getHours()
  )}-${toTwoDigits(date.getMinutes())}-${toTwoDigits(date.getSeconds())}`;

  // we check if the users have some disk space left
  checkStorageQuota();

  const checkSegmentsPortionsEnabled = options?.checkSegmentsPortions ?? true;
  if (checkSegmentsPortionsEnabled) {
    const segmentsState = store.getState().circuit.present.segments;
    const segmentsPortionsOk = checkSegmentsPortions(segmentsState);
    if (!segmentsPortionsOk) {
      // eslint-disable-next-line no-console
      console.error(
        `Some segments don't have their portions properly defined, recompute them before saving the project...`
      );

      const nbSegmentsRecomputed = recomputeSegmentsPortions(segmentsState);

      // eslint-disable-next-line no-console
      console.log(`${nbSegmentsRecomputed} segments have been recomputed`);

      SnackbarUtils.warning(
        `${nbSegmentsRecomputed} segment${nbSegmentsRecomputed !== 1 ? 's have' : ' has'} been recomputed`
      );

      if (!options?.nbTry) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            await saveProject(archive, {
              ...options,
              nbTry: (options?.nbTry ?? 0) + 1,
            });

            resolve(null);
          }, 1000);
        });

        return;
      }
    }
  }

  const projectName = PreferencesService.arePreferencesFullyLoaded()
    ? (PreferencesService.getPreferenceValue('general/projectName') as string)
    : 'unknown';

  // eslint-disable-next-line no-console
  console.time('generateGeoJSON');
  let generationGeoJsonOk = false;
  let circuitGeoJSON = '';
  let sha1GeoJSON = '';
  let json: GeoJsonCircuit | undefined;
  try {
    [circuitGeoJSON, sha1GeoJSON, json] = await generateGeoJSON(features, !archive, {
      projectName,
    });

    generationGeoJsonOk = true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while generating the geojson', e);

    if (snackbarLoadingKey) {
      isSavingProject = false;
      SnackbarUtils.closeSnackbar(snackbarLoadingKey);
      snackbarLoadingKey = undefined;
    }

    SnackbarUtils.error(
      'An error occurred while generating the GeoJSON circuit file. Please contact the support to get help.'
    );
  }

  // eslint-disable-next-line no-console
  console.timeEnd('generateGeoJSON');

  if (archive && (localStorage.lastSha1GeoJsonArchive === sha1GeoJSON || !circuitGeoJSON)) {
    // eslint-disable-next-line no-console
    console.debug('Save project - archive mode - save aborted because no change occured');

    return;
  }

  const isThereDuplicateNames = !areAllShapeNamesUnique();
  if (isThereDuplicateNames) {
    const shapes: CircuitShape[] = Object.values(features).flatMap((f: CircuitShape[]) => f);
    const shapesName = shapes
      .filter((shape) => !isCircuitNote(shape)) // we don't want the notes because they can have duplicate names but we don't mind as we don't export them in the xml
      .map((shape) => shape.properties.name)
      .filter((name) => !!name);

    /** We also need to add the slotlines names, see #43316 */
    const stockLines = stockZones?.flatMap((stockZone) => stockZone.properties.slots) ?? [];
    const stockLinesNames = stockLines.map((stockLine) => stockLine.name);
    shapesName.push(...stockLinesNames);

    const slotsNames =
      racks?.flatMap((rack) =>
        rack.properties.columns.flatMap((column) =>
          column.cells.flatMap((cell) => cell.names.flatMap((slot) => slot.flatMap((s) => s.value)))
        )
      ) ?? [];
    shapesName.push(...slotsNames);

    const nbEachName: [string, number][] = Object.entries(countBy(shapesName));
    const duplicatesName = nbEachName.filter(([, nb]) => nb > 1);
    if (duplicatesName.length) {
      // elements with the same name!
      const maxNbNamesMsg = 10;
      const msg = `Elements with the same name detected. Elements: ${duplicatesName
        .map((dupplicatedName, i) => {
          if (i < maxNbNamesMsg) return dupplicatedName[0];
          else if (i === maxNbNamesMsg) return '...';

          return undefined;
        })
        .filter((name) => !!name)
        .join(', ')}`;
      // eslint-disable-next-line no-console
      console.warn(msg, {
        duplicatesName,
      });

      SnackbarUtils.error(msg);
    }

    const positionRackNamesUnicity = checkUnicityRackPositionsName(racks ?? []);
    if (!positionRackNamesUnicity) {
      SnackbarUtils.error('Rack positions names are not unique');
    }
  }

  const circuitFileName = getLoadedCircuitName().split('.');
  const circuitName = circuitFileName.slice(0, circuitFileName.length - 1).join('.');
  const circuitPathGeojson = `${circuitName}${archive ? now : ''}.geojson`;
  const dirHandle = await PreferencesService.getDirectoryHandle();

  let installXMLSavePromise: Promise<void | null> = Promise.resolve(null);
  let modelsXMLSavePromiseArray: Promise<void | null>[] = [];
  let trucksXMLSavePromiseArray: Promise<void | null>[] = [];
  let backgroundLidarTXTSavePromise: Promise<void | null> = Promise.resolve(null);
  let backgroundLidarGEOSavePromise: Promise<void | null> = Promise.resolve(null);

  if (!dirHandle) {
    // eslint-disable-next-line no-console
    console.error('No directory handle found');

    if (snackbarLoadingKey) {
      isSavingProject = false;
      SnackbarUtils.closeSnackbar(snackbarLoadingKey);
      snackbarLoadingKey = undefined;
    }

    SnackbarUtils.error('Access to the disk system denied. The project has not been saved.');

    return;
  }

  if (!projectHost) {
    const installXMLFileName = 'install.xml';

    const prefDirHandle = await dirHandle.getDirectoryHandle('PREF', { create: true });

    const xmls = PreferencesService.getPreferencesXmlDocument();
    if (xmls.install) {
      /* Install XML */
      const serializer = new XMLSerializer();
      const outXmlString = serializer.serializeToString(xmls.install);

      // we add a new line before the <Preferences> tag
      let outXmlStringWithNewLine = outXmlString
        .replace('<Preferences ', '\n<Preferences ')
        .replace('></Preferences>', '>\n</Preferences>');

      // Add the xml header if it's not present
      if (!outXmlStringWithNewLine.startsWith('<?xml version="1.0" encoding="utf-8"?>')) {
        outXmlStringWithNewLine = `<?xml version="1.0" encoding="utf-8"?>${outXmlStringWithNewLine}`;
      }

      const XMLBlob = new Blob([outXmlStringWithNewLine], { type: 'text/xml', endings: 'native' });
      const installFileHandleXML = await prefDirHandle.getFileHandle(installXMLFileName, { create: true });

      installXMLSavePromise = writeToFile(installFileHandleXML, XMLBlob);
    } else {
      // eslint-disable-next-line no-console
      console.error('The install.xml file is missing. The project preferences have not been saved.');
      SnackbarUtils.error('The install.xml file is missing. The project preferences have not been saved.');
    }

    /* Models XMLs */
    const models = xmls.models.map(({ model, document }) => ({
      name: model,
      document,
    }));

    const modelsDirHandle = await prefDirHandle.getDirectoryHandle('MODELS', { create: true });
    modelsXMLSavePromiseArray = generatePrefXmlArray(modelsDirHandle, models);

    /* Trucks XMLs */
    const trucks = xmls.trucks.map(({ serial, document }) => ({
      name: serial,
      document,
    }));

    const trucksDirHandle = await prefDirHandle.getDirectoryHandle('TRUCKS', { create: true });
    trucksXMLSavePromiseArray = generatePrefXmlArray(trucksDirHandle, trucks);

    /* Background-lidar map .TXT */
    let backgroundLidarFileName = store.getState().maps.lidar['background-lidar']?.name;
    const mapFolderHandle = await dirHandle.getDirectoryHandle('MAP', { create: true });

    if (backgroundLidarFileName) {
      backgroundLidarFileName = backgroundLidarFileName.replace(/\.[^.]+$/, '.txt');
      const backgroundLidarFileHandleXML = await mapFolderHandle.getFileHandle(backgroundLidarFileName, {
        create: true,
      });

      let backgroundLidarTXT = '';
      store.getState().maps.lidar['background-lidar']?.coordinates.forEach((coord) => {
        const firstValue = Math.round(coord?.[0] / 4).toFixed(0);
        const secondValue = Math.round(coord?.[1] / 4).toFixed(0);

        backgroundLidarTXT += `${firstValue};${secondValue};${coord?.[2]}\n`;
      });

      const backgroundLidarTXTBlob = new Blob([backgroundLidarTXT], { type: 'text/plain', endings: 'native' });

      backgroundLidarTXTSavePromise = writeToFile(backgroundLidarFileHandleXML, backgroundLidarTXTBlob);
    }

    /* Background-lidar map .GEO */
    const localLidarMap = localDoc.getMap('lidar');

    if (localLidarMap.has('background-uInt8Array')) {
      const uInt8Array = localLidarMap.get('background-uInt8Array') as Uint8Array;
      const backgroundLidarGEOFileName = store.getState().maps.lidar['background-lidar']?.name;

      if (backgroundLidarGEOFileName?.endsWith('.geo') && uInt8Array) {
        const backgroundLidarGEOBlob = new Blob([uInt8Array], { type: 'application/geo', endings: 'native' });
        const backgroundLidarFileHandleGEO = await mapFolderHandle.getFileHandle(backgroundLidarGEOFileName, {
          create: true,
        });

        backgroundLidarGEOSavePromise = writeToFile(backgroundLidarFileHandleGEO, backgroundLidarGEOBlob);
      }
    }

    const opcData = store.getState().project.opcData;

    if (opcData) {
      saveOpcConfigurationOnDisk(opcData);
    }

    /* Layout-image */
    const mapImages = store.getState().maps.mapImage.mapImages;

    if (mapImages) {
      mapImages?.forEach(async (mapImage, index) => {
        const img = new Image();
        img.src = mapImage.URL;
        let imageDecodeOk = false;
        try {
          await img.decode();
          imageDecodeOk = true;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`Failed to decode image ${mapImage.name}, error: ${e}`);

          SnackbarUtils.error(
            `The layout image ${mapImage.name} could not be saved. It may be corrupted or too large.`
          );
        }

        if (imageDecodeOk) {
          const imgComputedHeight = (mapImage.height || 0) / 100;
          const imgComputedX = mapImage.x / 100; // cm -> m
          const imgComputedY = mapImage.y / 100; // cm -> m

          const imgOriginalHeight = img.height;
          const scale = imgOriginalHeight / imgComputedHeight;

          const originX = imgComputedX;
          const originY = imgComputedY - imgComputedHeight;

          const fileName = mapImage.name;
          let fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
          const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);

          const hasScalingInFileName = regexMapImageOuster.test(fileName);
          let includeScaleInFileName = true;
          if (hasScalingInFileName) {
            const resRegex = regexMapImageOuster.exec(fileName);
            const scaleFileName = parseFloat(resRegex?.groups?.scale ?? '');
            const originXFileName = parseFloat(resRegex?.groups?.originX ?? '');
            const originYFilaName = parseFloat(resRegex?.groups?.originY ?? '');

            if (
              Math.abs(scale - scaleFileName) < epsilon &&
              Math.abs(originX - originXFileName) < epsilon &&
              Math.abs(originY - originYFilaName) < epsilon
            ) {
              includeScaleInFileName = false;
            } else {
              const splittedName = fileName.split('_');
              const splittedNameWithoutScaling = splittedName.slice(0, splittedName.length - 3);
              const fileNameWithoutScaling = splittedNameWithoutScaling.join('_');
              fileNameWithoutExtension = fileNameWithoutScaling;
            }
          }

          let newPathMapImageFileName: string;
          if (includeScaleInFileName) {
            newPathMapImageFileName = `${fileNameWithoutExtension}_S${scale}X${originX}Y${originY}.${fileExtension}`;
          } else {
            newPathMapImageFileName = `${fileNameWithoutExtension}.${fileExtension}`;
          }

          const fileHandleMapImageFile = await mapFolderHandle.getFileHandle(newPathMapImageFileName, { create: true });

          try {
            await writeToFile(fileHandleMapImageFile, await fetch(mapImage.URL).then((res) => res.blob()));
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Failed to write ${newPathMapImageFileName} on disk, error: ${e}`);
          }
        }
      });
    }
  }

  let circuitsDirHandle = await dirHandle.getDirectoryHandle('Circuits', { create: true });
  if (archive) circuitsDirHandle = await circuitsDirHandle.getDirectoryHandle('.archives', { create: true });

  const fileHandleGeojson = await circuitsDirHandle.getFileHandle(circuitPathGeojson, { create: true });

  const geojsonBlob = new Blob([circuitGeoJSON], { type: 'application/geo+json' });
  let geojsonSavePromise: Promise<void | null>;
  if (circuitGeoJSON) {
    geojsonSavePromise = writeToFile(fileHandleGeojson, geojsonBlob);
  } else {
    geojsonSavePromise = Promise.resolve(null);
  }

  let circuitXML: string;

  let generationXMLOk = false;
  if (!archive && !options?.ignoreXml) {
    // eslint-disable-next-line no-console
    console.time('generateXML');
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [circuitXML] = await generateXML({
        zones,
        points,
        segments,
        stockZones,
        measurers,
        turns,
        racks,
      });

      generationXMLOk = true;
    } catch (e) {
      if (snackbarLoadingKey) {
        isSavingProject = false;
        SnackbarUtils.closeSnackbar(snackbarLoadingKey);
        snackbarLoadingKey = undefined;
      }

      generationXMLOk = false;
      circuitXML = '';

      if (e instanceof MissingCellTemplateError || isMissingCellTemplateError(e)) {
        manageMissingCellTemplateError(e);
      } else {
        // eslint-disable-next-line no-console
        console.error(e);

        SnackbarUtils.error(`The generation of the XML file failed. Please contact the support to get help.`);

        if (!options?.nbTry) {
          const autoFixSnack = SnackbarUtils.info('Trying to autofix the circuit...', {
            persist: true,
          });
          await computeAgainAllRacks();

          await saveProject(archive, {
            ...(options ?? {}),
            nbTry: (options?.nbTry ?? 0) + 1,
          });

          SnackbarUtils.closeSnackbar(autoFixSnack);
        }
      }

      if (snackbarLoadingKey) {
        isSavingProject = false;
        SnackbarUtils.closeSnackbar(snackbarLoadingKey);
        snackbarLoadingKey = undefined;
      }
    }

    // eslint-disable-next-line no-console
    console.timeEnd('generateXML');
  } else {
    circuitXML = '';
  }

  let xmlSavePromise: Promise<void | null>;
  if (!archive && circuitXML) {
    const circuitBlob = new Blob([circuitXML], { type: 'application/xml' });
    const circuitPathXml = `${circuitName}${archive ? now : ''}.xml`;
    const fileHandleXml = await circuitsDirHandle.getFileHandle(circuitPathXml, { create: true });

    xmlSavePromise = writeToFile(fileHandleXml, circuitBlob);
  } else {
    xmlSavePromise = Promise.resolve(null);
  }

  const endTime = performance.now();

  try {
    await Promise.all([
      installXMLSavePromise,
      ...modelsXMLSavePromiseArray,
      ...trucksXMLSavePromiseArray,
      backgroundLidarTXTSavePromise,
      backgroundLidarGEOSavePromise,
      xmlSavePromise,
      geojsonSavePromise,
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to write a file on disk', e);

    if (snackbarLoadingKey) {
      isSavingProject = false;
      SnackbarUtils.closeSnackbar(snackbarLoadingKey);
      snackbarLoadingKey = undefined;
    }

    SnackbarUtils.error(
      `At least one file has not been saved. The write operation has been denied by the Operating System.`
    );

    return;
  }

  if (archive) localStorage.lastSha1GeoJsonArchive = sha1GeoJSON;
  else localStorage.lastSha1GeoJson = sha1GeoJSON;

  let xml: Document | undefined = undefined;

  /** let's update the devices in the install.xml preferences file */
  if (!archive) {
    // we only update the preferences when the user save the project by himself
    const manageDevicePrefEnabled = store.getState().project.enableDevicePrefManagement;
    if (manageDevicePrefEnabled) {
      xml = PreferencesService.getInstallDocument();
      ({ xml } = await exportDevices({ devices, layers, xml }));
    } else {
      // eslint-disable-next-line no-console
      console.log('Devices Preferences not managed by Road Editor: skipping the update');
    }
  }

  await saveNoteImages(circuitsDirHandle);

  /** let's save the map image file (layout image) */
  let savedNewMapImage = false;
  const mapImages = store.getState().maps.mapImage.mapImages;

  if (mapImages && projectHost && mapImages?.length > 0) {
    for (const [index, mapImage] of mapImages?.entries()) {
      if (!dirHandle) return;

      let newPathMapImageFileName: string | undefined = undefined;

      if (
        json &&
        json.properties &&
        json.properties.mapImageArray &&
        json.properties.mapImageArray[index] &&
        projectHost
      ) {
        const mapImageJson = json.properties.mapImageArray?.[index];

        if (!archive && !options?.ignoreSaveLayoutImage && mapImageJson.name && mapImageJson.height) {
          const mapImageUrl = mapImage.URL;
          const imgComputedHeight = mapImageJson.height ? mapImageJson.height / 100 : undefined;
          const imgComputedX = mapImageJson.x / 100; // cm -> m
          const imgComputedY = mapImageJson.y / 100; // cm -> m
          if (
            mapImageUrl &&
            imgComputedHeight !== undefined &&
            imgComputedX !== undefined &&
            imgComputedY !== undefined
          ) {
            const img = new Image();
            img.src = mapImageUrl;
            let imageDecodeOk = false;
            try {
              await img.decode();
              imageDecodeOk = true;
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(`Failed to decode image ${mapImageJson.name}, error: ${e}`);
              SnackbarUtils.error(
                `The layout image ${mapImageJson.name} could not be saved. It may be corrupted or too large.`
              );
            }

            if (imageDecodeOk) {
              const imgOriginalHeight = img.height;
              const scale = imgOriginalHeight / imgComputedHeight;
              const originX = imgComputedX;
              const originY = imgComputedY - imgComputedHeight;
              const fileName = mapImageJson.name;
              let fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
              const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
              const hasScalingInFileName = regexMapImageOuster.test(fileName);
              let includeScaleInFileName = true;
              if (hasScalingInFileName) {
                const resRegex = regexMapImageOuster.exec(fileName);
                const scaleFileName = parseFloat(resRegex?.groups?.scale ?? '');
                const originXFileName = parseFloat(resRegex?.groups?.originX ?? '');
                const originYFilaName = parseFloat(resRegex?.groups?.originY ?? '');
                if (
                  Math.abs(scale - scaleFileName) < epsilon &&
                  Math.abs(originX - originXFileName) < epsilon &&
                  Math.abs(originY - originYFilaName) < epsilon
                ) {
                  includeScaleInFileName = false;
                } else {
                  const splittedName = fileName.split('_');
                  const splittedNameWithoutScaling = splittedName.slice(0, splittedName.length - 3);
                  const fileNameWithoutScaling = splittedNameWithoutScaling.join('_');
                  fileNameWithoutExtension = fileNameWithoutScaling;
                }
              }

              if (includeScaleInFileName) {
                newPathMapImageFileName = `${fileNameWithoutExtension}_S${scale}X${originX}Y${originY}.${fileExtension}`;
              } else {
                newPathMapImageFileName = `${fileNameWithoutExtension}.${fileExtension}`;
              }

              const mapImageFile = await PreferencesService.getFileByPath(`MAP/${mapImageJson.name}`);
              if (newPathMapImageFileName && (newPathMapImageFileName !== mapImageJson.name || !mapImageFile)) {
                const mapFolderHandle = await dirHandle.getDirectoryHandle('MAP', { create: true });
                const fileHandleMapImageFile = await mapFolderHandle.getFileHandle(newPathMapImageFileName, {
                  create: true,
                });
                try {
                  await writeToFile(fileHandleMapImageFile, await (await fetch(mapImageUrl)).blob());
                  // we try to remove the old file if it exists
                  const oldFileName = mapImageJson.name;
                  let mapFileUpdated = false;
                  if (oldFileName && newPathMapImageFileName !== mapImageJson.name) {
                    try {
                      await mapFolderHandle.removeEntry(oldFileName);
                      mapFileUpdated = true;
                    } catch (e) {
                      if (e instanceof DOMException && e.name === 'NotFoundError') {
                        // it's ok no old file to delete
                      } else {
                        // eslint-disable-next-line no-console
                        console.warn('Delete old map file failed', e);
                      }
                    }
                  }

                  SnackbarUtils.info(
                    mapFileUpdated
                      ? `Layout Image ${newPathMapImageFileName} updated in the MAP folder.`
                      : `Layout Image ${newPathMapImageFileName} copied in the MAP folder.`
                  );
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(`Failed to write ${newPathMapImageFileName} on disk, error: ${e}`);
                }
              }
            } else {
              // eslint-disable-next-line no-console
              console.error('No map url in the store');
            }
          }
        } // useless to copy the file in the project folder if we already found one with the same name

        if (
          newPathMapImageFileName &&
          newPathMapImageFileName !== mapImageJson.name &&
          !options?.ignoreSaveLayoutImage
        ) {
          const newMapImageFile = (await PreferencesService.getFileByPath(`MAP/${newPathMapImageFileName}`)) as File;

          const newUrl = window.URL.createObjectURL(newMapImageFile);

          // if the name of the layout image has been changed, we need to save it in the circuit
          store.dispatch(
            updateMapImagePropertiesAction({
              properties: { name: newPathMapImageFileName, URL: newUrl },
              targetName: mapImageJson.name,
            })
          );

          store.dispatch(
            changeMapImageNameFilterAction({
              name: newPathMapImageFileName,
              targetName: mapImageJson.name,
            })
          );

          savedNewMapImage = true;
        }
      }
    }
  }

  if (!archive) sessionStorage.unsavedChanges = false;

  // eslint-disable-next-line no-console
  console.debug(`Geojson${!archive ? ' & XML generation time' : ''}: ${endTime - startTime} ms.`);

  if (!archive) saveSchedulerConfigOnDisk(dirHandle);

  // alert the user that the circuit has been properly saved
  if (!archive && generationXMLOk && generationGeoJsonOk && !options?.ignoreFinishCallback) {
    saveRackAnalysisDateApplied();

    changeLoadingToSuccessSnackbar();

    checkFireAlarmsDeviceNames();
  }

  if (savedNewMapImage) {
    saveProject(false, {
      ignoreXml: true,
      ignoreFinishCallback: true,
      ignoreSaveLayoutImage: true,
    });
  }
}
