import { remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import type { YArray } from 'yjs/dist/src/internals';

export const useNextFreeIdSubscription = (): void => {
  useLayoutEffect(() => {
    const nextFreeIdArray = remoteDoc?.getArray('nextFreeId') as YArray<number>;

    const observerHandle = (): void => {
      window.nextFreeId = nextFreeIdArray.toArray()[0];
      window.nextFreeIdArray = nextFreeIdArray?.toArray();
    };

    nextFreeIdArray.observe(observerHandle);

    return () => {
      nextFreeIdArray.unobserve(observerHandle);
    };
  }, []);
};
