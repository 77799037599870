import { ActionNode, ActionNodeData, FolderNode } from "models/action";



/**
 * Checks if the given node is a folder node
 */
export function isFolderNode(node: ActionNode): node is FolderNode {
  // A folder node has a `nodes` array
  return Array.isArray((node as FolderNode).nodes);
}

/**
 * Checks if the given node is an action node
 */
export function isActionNode(node: ActionNode): node is ActionNodeData {
  // Anything that does not have a `nodes` array is considered an action
  return !isFolderNode(node);
}
