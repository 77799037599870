import { updateMapImagePropertiesAction } from 'actions';
import * as d3 from 'd3';
import { LayerNames } from 'drawings/layers';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

interface MapImageComponentProps {}

export function MapImageComponent(props: MapImageComponentProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const openedProperties = useAppSelector((state) => state.maps.mapImage.openProperties) as number;
  const mapImages = useAppSelector((state) => state.maps.mapImage.mapImages);
  const openedMapImage = mapImages?.[openedProperties];

  const displayMapImage = useAppSelector((state) => state.local.filters.mapImage);
  const opacityMapImage = useAppSelector((state) => state.local.filters.mapImageOpacity);
  const mapImageArray = useAppSelector((state) => state.local.filters.mapImageArray);

  const mousePressed = useRef(false);
  const [, setRender] = useState(0);

  const startX = useRef(0);
  const startY = useRef(0);
  const dx = useRef(0);
  const dy = useRef(0);

  useEffect(() => {
    if (mapImages?.length) {
      mapImages.forEach((mapImage) => {
        d3.select(`[layer=floor-plan] [name="${mapImage.name}"]`)?.on('.drag', null);
      });

      if (!openedMapImage) return;

      const dragHandler = d3
        .drag<SVGGElement, undefined>()
        .on('start', function () {
          const e = d3.event as d3.D3DragEvent<SVGGElement, any, any>;

          mousePressed.current = true;

          startX.current = e.x;
          startY.current = e.y;

          setRender((r) => r + 1);
        })
        .on('drag', function () {
          if (!openedMapImage) return;

          const e = d3.event as d3.D3DragEvent<SVGGElement, any, any>;

          dx.current = e.x - startX.current;
          dy.current = e.y - startY.current;

          d3.select(`[layer=floor-plan] [name="${openedMapImage.name}"`)
            .attr('x', openedMapImage.x + dx.current)
            .attr('y', -openedMapImage.y + dy.current);
        })
        .on('end', () => {
          if (!openedMapImage) return;

          mousePressed.current = false;

          const nbDigitsRounded = 2;
          const x = parseInt((openedMapImage.x + dx.current).toFixed(nbDigitsRounded), 10);
          const y = parseInt((openedMapImage.y - dy.current).toFixed(nbDigitsRounded), 10);

          dispatch(
            updateMapImagePropertiesAction({
              properties: { ...openedMapImage, x, y },
              targetName: openedMapImage.name,
            })
          );

          startX.current = 0;
          startY.current = 0;
          dx.current = 0;
          dy.current = 0;
        });

      d3.select(`[layer=floor-plan] [name="${openedMapImage?.name}"]`)?.call(dragHandler as any);
    }
  }, [dispatch, mapImages, openedMapImage]);

  if (mapImages?.length) {
    return (
      <g
        {...{ layer: LayerNames.FloorPlan }}
        style={{ display: displayMapImage ? 'inline' : 'none', opacity: opacityMapImage }}
      >
        {displayMapImage && mapImages
          ? mapImages.map((mapImage) => {
              if (!mapImage.height || !mapImage.URL || mapImage.x === undefined || mapImage.y === undefined)
                return null;

              const attr = {
                x: mapImage.x,
                y: -mapImage.y,
                href: mapImage.URL || '',
                height: mapImage.height,
                name: mapImage.name,
              };

              const mapImageFilter = mapImageArray.find((mapImageFilter) => mapImageFilter.name === mapImage.name);

              if (!mapImageFilter?.toggle) return null;

              return (
                <image
                  {...attr}
                  key={mapImage.name}
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    outline:
                      openedMapImage?.name === mapImage.name ? `10px dotted ${theme.palette.primary.main}` : undefined,
                    cursor:
                      openedMapImage?.name === mapImage.name ? (mousePressed.current ? 'grabbing' : 'grab') : undefined,
                    opacity: mapImageFilter?.opacity ?? 1,
                  }}
                />
              );
            })
          : null}
      </g>
    );
  }

  return null;
}
