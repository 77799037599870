import { remoteDoc } from 'multiplayer/globals';
import { setVersionFromYJS } from 'project/project';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import { PreferencesService } from 'utils/preferences';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useCircuitVersionSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const circuitVersionText = remoteDoc?.getText('circuitVersion');

  const updateCircuitVersion = (): void => {
    if (!circuitVersionText || !circuitVersionText.length) return;

    const circuitVersion = circuitVersionText.toString();

    store.dispatch(setVersionFromYJS(circuitVersion));

    if (!PreferencesService.arePreferencesFullyLoaded()) return;

    if (store.getState().dialog.open && store.getState().dialog.type === 'project-settings') {
      SnackbarUtils.info('A user updated the circuit version', {
        autoHideDuration: 3000,
      });
    }
  };

  useConditionalQueueSubscriptionHook({
    YType: circuitVersionText,
    callback: updateCircuitVersion,
    condition: notCircuitEditionMode,
  });
};
