import { remoteDoc } from 'multiplayer/globals';
import { setDevicePrefManagementFromYJS } from 'project/project';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useEnableDevicePrefManagementSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const enableDevicePrefManagementMap = remoteDoc?.getMap('enableDevicePrefManagement');

  const updateDevicePrefManagement = (): void => {
    if (!enableDevicePrefManagementMap) return;

    const enableDevicePrefManagement = enableDevicePrefManagementMap.get('state');

    if (typeof enableDevicePrefManagement !== 'boolean') return;
    store.dispatch(setDevicePrefManagementFromYJS(enableDevicePrefManagement));

    if (store.getState().dialog.open && store.getState().dialog.type === 'project-settings') {
      SnackbarUtils.info('A user updated the devices preferences management', {
        autoHideDuration: 3000,
      });
    }
  };

  useConditionalQueueSubscriptionHook({
    YType: enableDevicePrefManagementMap,
    callback: updateDevicePrefManagement,
    condition: notCircuitEditionMode,
  });
};
