import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { PalletIcon } from 'components/core/icons';
import type { ToolInfo } from 'components/menu-bar/tool-info';
import { TOOL_LIST_CIRCUIT_INITIAL_STATE } from 'components/menu-bar/tool-info';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import { Tools } from 'models/tools';
import { isDefined } from 'utils/ts/is-defined';
import type {
  CustomStep,
  CustomStepEvent,
  DefaultMissionType,
  ExtraStepNames,
  FlowStepWithissionType,
} from './custom-steps.model';

export const allStationPositionTypes = [
  ShapeTypes.PointShape,
  ShapeTypes.RackShape,
  ShapeTypes.StockZoneShape,
  SlotTypes.Slot,
  SlotTypes.StockLine,
] as const;

/**
 * All the shapes or part of shapes that are slots or can contains slots
 */
export type StationPositionType = (typeof allStationPositionTypes)[number];

export const stationPositionTypesIcons: Record<StationPositionType, ToolInfo['icon']> = {
  POINT: TOOL_LIST_CIRCUIT_INITIAL_STATE.find((tool) => tool.name === Tools.DrawPoint)?.icon ?? QuestionMarkIcon,
  RACK: TOOL_LIST_CIRCUIT_INITIAL_STATE.find((tool) => tool.name === Tools.DrawRack)?.icon ?? QuestionMarkIcon,
  SLOT: PalletIcon ?? QuestionMarkIcon,
  STOCK: TOOL_LIST_CIRCUIT_INITIAL_STATE.find((tool) => tool.name === Tools.DrawStockZone)?.icon ?? QuestionMarkIcon,
  STOCKLINE:
    TOOL_LIST_CIRCUIT_INITIAL_STATE.find((tool) => tool.name === Tools.DrawStockZone)?.icon ?? QuestionMarkIcon,
};

export interface StationPosition {
  /** type of the position, can be a shape that is a slot or that contains slots */
  type: StationPositionType;
  /** id of the position, id of a shape or of a slot or a group of slots */
  id: string; // id or ids?
  /** For stock lines/stock zones, where the robot will go (by default = 0, all in the line) */
  positionInLine?: number | 'random';
}

export interface StationPositionWithName extends StationPosition {
  /** name of the position */
  name: string;
  /** whether the position is disabled */
  disabled?: boolean;
}

export interface Station {
  /** id of the station */
  id: string;
  /** name of the station */
  name: string;

  positions: StationPosition[];
}

export interface MultipleStations {
  /** id of the station */
  id: string[];
  /** name of the station */
  name: string[];

  positions: StationPosition[];
}

export interface FlowStep {
  /** id of the station */
  id: string[];
}

export interface Flow {
  /** id of the flow */
  id: string;
  /** name of the flow */
  name: string;

  /** list of stations */
  stations: FlowStepWithissionType[];

  /** objective of the flow [pallets per hour] */
  objective?: number;
  /** maximum duration of a task in the flow [s] */
  maximumTaskTime?: number;
  /** number of pallets per task */
  palletsPerTask: number;

  /** serial of the robots assigned */
  robotsAssigned?: string[] | 'all';
}

export interface FlowsSliceState {
  /** list of stations */
  stations: Station[];
  /** id of the selected station */
  selectedStationId: string | undefined;

  /** list of the flows */
  flows: Flow[];
  /** id of the selected flow */
  selectedFlowId: string | undefined;

  customSteps: CustomStep[];
  selectedCustomStepId: string | undefined;
}

const initialState: FlowsSliceState = {
  stations: [],
  selectedStationId: undefined,
  flows: [],
  selectedFlowId: undefined,
  customSteps: [],
  selectedCustomStepId: undefined,
};

const flowsSliceLocal = createSlice({
  initialState,
  name: 'flow',
  reducers: {
    addStation: (state, action: PayloadAction<Station>) => {
      state.stations.push(action.payload);
    },
    addStationFromYJS: (state, action: PayloadAction<Station>) => {
      flowsSliceLocal.caseReducers.addStation(state, action);
    },
    setStation: (state, action: PayloadAction<Station>) => {
      const index = state.stations.findIndex((station) => station.id === action.payload.id);
      if (index !== -1) {
        state.stations[index] = action.payload;
      }
    },
    setStationFromYJS: (state, action: PayloadAction<Station>) => {
      flowsSliceLocal.caseReducers.setStation(state, action);
    },
    setStations: (state, action: PayloadAction<Station[]>) => {
      state.stations = action.payload;
    },
    setStationName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const index = state.stations.findIndex((station) => station.id === action.payload.id);
      if (index !== -1) {
        state.stations[index].name = action.payload.name;
      }
    },
    addPositionToStation(state, action: PayloadAction<{ stationId: string; position: StationPosition }>) {
      const station = state.stations.find((station) => station.id === action.payload.stationId);
      if (station) {
        const newPositions = [...station.positions, action.payload.position];
        const newPositionsSet = new Set(newPositions);

        station.positions = Array.from(newPositionsSet);
      }
    },
    addPositionToStations(state, action: PayloadAction<{ stationId: string; position: StationPosition }[]>) {
      action.payload.forEach(({ stationId, position }) => {
        const station = state.stations.find((station) => station.id === stationId);
        if (station) {
          const newPositionsSet = new Set(station.positions.map((p) => p.id));

          if (!newPositionsSet.has(position.id)) {
            station.positions.push(position);
          }
        }
      });
    },

    addSeveralPositionsToStation(state, action: PayloadAction<{ stationId: string; positions: StationPosition[] }>) {
      const station = state.stations.find((station) => station.id === action.payload.stationId);
      if (station) {
        const newPositions = [...station.positions, ...action.payload.positions];
        const newPostionsIds = newPositions.map((position) => position.id);
        const newPostionsIdsUnique = Array.from(new Set(newPostionsIds));

        const newPositionsUnique = newPostionsIdsUnique
          .map((id) => newPositions.find((position) => position.id === id))
          .filter(isDefined);

        station.positions = newPositionsUnique;
      }
    },
    removePositionFromStation(state, action: PayloadAction<{ stationId: string; positionId: string }>) {
      const station = state.stations.find((station) => station.id === action.payload.stationId);
      if (station) {
        station.positions = station.positions.filter((position) => position.id !== action.payload.positionId);
      }
    },
    removePositionFromStations(state, action: PayloadAction<{ stationId: string; positionId: string }[]>) {
      action.payload.forEach(({ stationId, positionId }) => {
        const station = state.stations.find((station) => station.id === stationId);
        if (station) {
          // Remove the position with the specified positionId
          station.positions = station.positions.filter((position) => position.id !== positionId);
        }
      });
    },
    togglePositionInLineRandom(state, action: PayloadAction<{ stationId: string; positionId: string }>) {
      const station = state.stations.find((station) => station.id === action.payload.stationId);
      if (station) {
        const position = station.positions.find((position) => position.id === action.payload.positionId);
        if (position) {
          if (position.positionInLine === 'random') {
            delete position.positionInLine;
          } else {
            position.positionInLine = 'random';
          }
        }
      }
    },
    setPositionInLine(
      state,
      action: PayloadAction<{
        stationId: string;
        positionsIds: string[];
        positionInLine: number | 'random' | undefined;
      }>
    ) {
      const station = state.stations.find((station) => station.id === action.payload.stationId);
      if (station) {
        const positions = station.positions.filter((position) => action.payload.positionsIds.includes(position.id));

        positions.forEach((position) => {
          position.positionInLine = action.payload.positionInLine;
        });
      }
    },
    setSelectedStationId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedStationId = action.payload;
    },
    removeStation: (state, action: PayloadAction<string>) => {
      state.stations = state.stations.filter((station) => station.id !== action.payload);

      const newSelectedStation = state.stations[state.stations.length - 1];
      state.selectedStationId = newSelectedStation?.id ?? undefined;
    },
    removeStationFromYJS: (state, action: PayloadAction<string>) => {
      flowsSliceLocal.caseReducers.removeStation(state, action);
    },
    removeElementFromStations: (state, action: PayloadAction<string>) => {
      state.stations.forEach((station) => {
        station.positions = station.positions.filter((position) => position.id !== action.payload);
      });
    },
    removeElementsFromStations: (state, action: PayloadAction<string[]>) => {
      const elsIds = new Set(action.payload);

      state.stations.forEach((station) => {
        station.positions = station.positions.filter((position) => !elsIds.has(position.id));
      });
    },

    addFlow: (state, action: PayloadAction<Flow>) => {
      state.flows.push(action.payload);
    },
    addFlowFromYJS: (state, action: PayloadAction<Flow>) => {
      flowsSliceLocal.caseReducers.addFlow(state, action);
    },
    setFlow: (state, action: PayloadAction<Flow>) => {
      const index = state.flows.findIndex((flow) => flow.id === action.payload.id);
      if (index !== -1) {
        state.flows[index] = action.payload;
      }
    },
    setFlowFromYJS: (state, action: PayloadAction<Flow>) => {
      flowsSliceLocal.caseReducers.setFlow(state, action);
    },
    setMultipleFlows: (state, action: PayloadAction<Flow[]>) => {
      const flows = action.payload;
      flows.forEach((flow) => {
        const index = state.flows.findIndex((f) => f.id === flow.id);
        if (index !== -1) {
          state.flows[index] = flow;
        }
      });
    },
    setFlows: (state, action: PayloadAction<Flow[]>) => {
      const flows = action.payload;

      // Retro-actively add pallets per task for flows in old project
      flows.forEach((flow) => {
        if (flow.palletsPerTask || flow.palletsPerTask === 0) return;

        flow.palletsPerTask = 1;
      });

      state.flows = flows;
    },
    setFlowName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const index = state.flows.findIndex((flow) => flow.id === action.payload.id);
      if (index !== -1) {
        state.flows[index].name = action.payload.name;
      }
    },
    removeFlow: (state, action: PayloadAction<string>) => {
      const flowId = action.payload;

      state.flows = state.flows.filter((flow) => flow.id !== flowId);
    },
    removeFlowFromYJS: (state, action: PayloadAction<string>) => {
      flowsSliceLocal.caseReducers.removeFlow(state, action);
    },
    setSelectedFlowId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedFlowId = action.payload;
    },
    addStationToFlow: (state, action: PayloadAction<{ flowId: string; stationsId: string[] }>) => {
      const flow = state.flows.find((flow) => flow.id === action.payload.flowId);
      if (flow) {
        flow.stations.push({
          id: action.payload.stationsId,
        });
      }
    },
    removeStationFromFlow: (state, action: PayloadAction<{ flowId: string; index: number }>) => {
      const flow = state.flows.find((flow) => flow.id === action.payload.flowId);
      if (flow) {
        flow.stations.splice(action.payload.index, 1);
      }
    },
    reorderStationsInFlow: (
      state,
      action: PayloadAction<{ flowId: string; index: number; direction: 'up' | 'down' }>
    ) => {
      const flow = state.flows.find((flow) => flow.id === action.payload.flowId);
      if (flow) {
        const index = action.payload.index;
        if (index !== -1) {
          if (action.payload.direction === 'up') {
            if (index !== 0) {
              const tmp = flow.stations[index - 1];
              flow.stations[index - 1] = flow.stations[index];
              flow.stations[index] = tmp;
            }
          } else {
            if (index !== flow.stations.length - 1) {
              const tmp = flow.stations[index + 1];
              flow.stations[index + 1] = flow.stations[index];
              flow.stations[index] = tmp;
            }
          }
        }
      }
    },

    setCustomStep: (state, action: PayloadAction<CustomStep>) => {
      const index = state.customSteps.findIndex((customStep) => customStep.id === action.payload.id);
      if (index !== -1) {
        state.customSteps[index] = action.payload;
      }
    },
    setCustomStepFromYJS: (state, action: PayloadAction<CustomStep>) => {
      flowsSliceLocal.caseReducers.setCustomStep(state, action);
    },
    setCustomSteps: (state, action: PayloadAction<CustomStep[]>) => {
      state.customSteps = action.payload;
    },
    addCustomStep: (state, action: PayloadAction<CustomStep>) => {
      state.customSteps.push(action.payload);
    },
    addCustomStepFromYJS: (state, action: PayloadAction<CustomStep>) => {
      flowsSliceLocal.caseReducers.addCustomStep(state, action);
    },
    addEventToCustomStep: (
      state,
      action: PayloadAction<{ customStepId: string; event: CustomStepEvent; stepName: ExtraStepNames }>
    ) => {
      const customStep = state.customSteps.find((customStep) => customStep.id === action.payload.customStepId);
      if (customStep) {
        const events = customStep.customEvents[action.payload.stepName] ?? [];
        customStep.customEvents[action.payload.stepName] = [...events, action.payload.event];
      }
    },
    removeEventToCustomStep: (
      state,
      action: PayloadAction<{ customStepId: string; stepName: ExtraStepNames; eventIndex: number }>
    ) => {
      const customStep = state.customSteps.find((customStep) => customStep.id === action.payload.customStepId);
      if (customStep) {
        const events = customStep.customEvents[action.payload.stepName] ?? [];
        customStep.customEvents[action.payload.stepName] = events.filter(
          (_, index) => index !== action.payload.eventIndex
        );
      }
    },
    moveEventInEventsListInCustomStep: (
      state,
      action: PayloadAction<{
        customStepId: string;
        stepName: ExtraStepNames;
        eventIndex: number;
        direction: 'up' | 'down';
      }>
    ) => {
      const customStep = state.customSteps.find((customStep) => customStep.id === action.payload.customStepId);
      if (customStep) {
        const events = customStep.customEvents[action.payload.stepName] ?? [];
        const event = events[action.payload.eventIndex];
        if (event) {
          if (action.payload.direction === 'up') {
            if (action.payload.eventIndex !== 0) {
              [events[action.payload.eventIndex - 1], events[action.payload.eventIndex]] = [
                events[action.payload.eventIndex],
                events[action.payload.eventIndex - 1],
              ];
            }
          } else {
            if (action.payload.eventIndex !== events.length - 1) {
              [events[action.payload.eventIndex], events[action.payload.eventIndex + 1]] = [
                events[action.payload.eventIndex + 1],
                events[action.payload.eventIndex],
              ];
            }
          }
        }
      }
    },
    setCustomStepEventType: (
      state,
      action: PayloadAction<{
        customStepId: string;
        stepName: ExtraStepNames;
        eventType: CustomStepEvent['actionType'];
        eventIndex: number;
      }>
    ) => {
      const customStep = state.customSteps.find((customStep) => customStep.id === action.payload.customStepId);
      if (customStep) {
        const events = customStep.customEvents[action.payload.stepName] ?? [];
        const event = events[action.payload.eventIndex];
        if (event) {
          event.actionType = action.payload.eventType;

          delete (event as any).waitTime;
          delete (event as any).forkMovement;
        }
      }
    },
    setCustomStepEventName: (
      state,
      action: PayloadAction<{ customStepId: string; stepName: ExtraStepNames; eventIndex: number; name: string }>
    ) => {
      const customStep = state.customSteps.find((customStep) => customStep.id === action.payload.customStepId);
      if (customStep) {
        const events = customStep.customEvents[action.payload.stepName] ?? [];
        const event = events[action.payload.eventIndex];
        if (event) {
          event.name = action.payload.name;
        }
      }
    },
    setCustomStepEventPropertyValue: (
      state,
      action: PayloadAction<{
        customStepId: string;
        stepName: ExtraStepNames;
        eventIndex: number;
        property: string;
        value: number;
      }>
    ) => {
      const customStep = state.customSteps.find((customStep) => customStep.id === action.payload.customStepId);
      if (customStep) {
        const events = customStep.customEvents[action.payload.stepName] ?? [];
        const event = events[action.payload.eventIndex];
        if (event) {
          if (event.actionType === 'none') {
            // eslint-disable-next-line no-console
            console.error('This event has no property');
          } else {
            event[action.payload.property] = action.payload.value;
          }
        }
      }
    },
    setCustomStepToEvent: (
      state,
      action: PayloadAction<{
        customStepId: string;
        formerStepName: ExtraStepNames;
        stepName: ExtraStepNames;
        eventIndex: number;
      }>
    ) => {
      const customStep = state.customSteps.find((customStep) => customStep.id === action.payload.customStepId);
      if (customStep) {
        const formerEvents = customStep.customEvents[action.payload.formerStepName] ?? [];
        const event = formerEvents[action.payload.eventIndex];
        if (event) {
          const events = customStep.customEvents[action.payload.stepName] ?? [];
          customStep.customEvents[action.payload.formerStepName] = formerEvents.filter(
            (_, index) => index !== action.payload.eventIndex
          );
          customStep.customEvents[action.payload.stepName] = [...events, event];

          if (customStep.customEvents[action.payload.formerStepName]?.length === 0) {
            delete customStep.customEvents[action.payload.formerStepName];
          }
        }
      }
    },
    setSelectedCustomStepId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCustomStepId = action.payload;
    },
    removeCustomStep: (state, action: PayloadAction<string>) => {
      state.customSteps = state.customSteps.filter((customStep) => customStep.id !== action.payload);

      state.flows = state.flows.map((flow) => {
        flow.stations = flow.stations.map((station) => {
          if (station.missionType === 'custom' && station.customMissionTypeId === action.payload)
            return {
              id: station.id,
            };

          return station;
        });

        return flow;
      });
    },
    removeCustomStepFromYJS: (state, action: PayloadAction<string>) => {
      flowsSliceLocal.caseReducers.removeCustomStep(state, action);
    },
    setCustomStepName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const index = state.customSteps.findIndex((customStep) => customStep.id === action.payload.id);
      if (index !== -1) {
        state.customSteps[index].name = action.payload.name;
      }
    },
    setCustomStepMissionType: (state, action: PayloadAction<{ id: string; missionType: DefaultMissionType }>) => {
      const index = state.customSteps.findIndex((customStep) => customStep.id === action.payload.id);
      if (index !== -1) {
        state.customSteps[index].missionType = action.payload.missionType;
      }
    },
    setFlowStepMissionType: (
      state,
      action: PayloadAction<{
        flowId: string;
        stepIndex: number;
        missionType: FlowStepWithissionType['missionType'];
        customMissionId?: string;
      }>
    ) => {
      const { stepIndex, flowId } = action.payload;

      const flow = state.flows.find((flow) => flow.id === flowId);
      if (flow) {
        const station = flow.stations[stepIndex];
        if (station) {
          station.missionType = action.payload.missionType;
          if (
            action.payload.missionType === 'custom' &&
            station.missionType === 'custom' &&
            action.payload.customMissionId
          ) {
            station.customMissionTypeId = action.payload.customMissionId;
          }

          if (
            'customMissionTypeId' in station &&
            station.customMissionTypeId &&
            action.payload.missionType !== 'custom'
          ) {
            // eslint-disable-next-line no-console
            console.error('Incoherent state: customMissionTypeId is set but missionType is not custom');
            // prevent inconherent state
            (station as any).customMissionTypeId = undefined;
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(`Station with index ${stepIndex} not found`);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(`Flow with id ${flowId} not found`);
      }
    },
  },
});

export const flowsSlice = {
  reducer: flowsSliceLocal.reducer,
};

export const {
  addStation,
  setSelectedStationId,
  removeStation,
  setStation,
  setStationName,
  addPositionToStation,
  addPositionToStations,
  addSeveralPositionsToStation,
  removePositionFromStation,
  removePositionFromStations,
  addFlow,
  setFlow,
  setFlowName,
  removeFlow,
  setSelectedFlowId,
  addStationToFlow,
  removeStationFromFlow,
  reorderStationsInFlow,
  setStations,
  setFlows,
  removeElementFromStations,
  removeElementsFromStations,
  setMultipleFlows,
  togglePositionInLineRandom,
  addCustomStep,
  setSelectedCustomStepId,
  removeCustomStep,
  setCustomStepName,
  setFlowStepMissionType,
  setCustomStepMissionType,
  addEventToCustomStep,
  setCustomStepEventType,
  setCustomStepEventName,
  setCustomStepToEvent,
  setCustomStepEventPropertyValue,
  removeEventToCustomStep,
  moveEventInEventsListInCustomStep,
  setCustomStep,
  setCustomSteps,
  setPositionInLine,
  addFlowFromYJS,
  removeFlowFromYJS,
  setFlowFromYJS,
  addStationFromYJS,
  removeStationFromYJS,
  setStationFromYJS,
  addCustomStepFromYJS,
  removeCustomStepFromYJS,
  setCustomStepFromYJS,
} = flowsSliceLocal.actions;
