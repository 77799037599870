import { getAvailableSchedulerConfig } from 'components/toolboxes/simulation/get-available-scheduler-config';
import { SnackbarUtils } from 'services/snackbar.service';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import { schedulerConfigFilePath } from 'simulation/scheduler/scheduler-config';
import store from 'store';
import { writeToFile } from 'utils/preferences';

export function getSchedulerConfig(): SchedulerConfig | undefined {
  const schedulerConfig = store.getState().simulation.schedulerConfigurationName;
  if (schedulerConfig !== 'custom') {
    const availableSchedulerConfigs = getAvailableSchedulerConfig();
    const config = availableSchedulerConfigs.find((config) => config.name === schedulerConfig);

    if (!config || !config.configuration) {
      const msg = `The scheduler configuration ${schedulerConfig} is not available in the app. It will not be saved on disk.`;
      // eslint-disable-next-line no-console
      console.error(msg);

      SnackbarUtils.error(msg);

      return;
    }

    return config.configuration;
  }

  return store.getState().project.schedulerConfig;
}

export async function saveSchedulerConfigOnDisk(dirHandle: FileSystemDirectoryHandle): Promise<void> {
  const configData = getSchedulerConfig();

  const strConfig = JSON.stringify(configData, null, 2);
  const schedulerConfigBlob = new Blob([strConfig], {
    type: 'text/plain',
  });

  const schedulerConfigFileHandle = await dirHandle.getFileHandle(schedulerConfigFilePath, { create: true });
  await writeToFile(schedulerConfigFileHandle, schedulerConfigBlob);
}
