import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveIcon from '@mui/icons-material/Save';
import SchoolIcon from '@mui/icons-material/School';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Input,
  Link,
  Switch,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { closeDialogAction } from 'actions';
import { getSchedulerConfig } from 'components/export/save-project/save-scheduler-configuration';

import { HelpIconTooltip } from 'components/utils/tooltips';
import { setSchedulerConfiguration } from 'project/project';
import React, { useCallback, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import StandardSchedulerConfig from 'simulation/scheduler/configurations/standard.json';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import { schedulerConfigSchema } from 'simulation/scheduler/scheduler-config';
import store, { useAppDispatch } from 'store';
import { camelCaseToTitle } from 'utils/helpers';
import { theme } from 'utils/mui-theme';

const schedulerDocumentation =
  'https://docs.google.com/presentation/d/1hK6trq4gb-7MdrD-R0FVKbuhrEryrzvwkIddSL1xHsg/edit#slide=id.p1';

export function UpdateSchedulerConfigDialog(): JSX.Element | null {
  const dispatch = useAppDispatch();

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const [advancedUser, setAdvancedUser] = useState(false);

  const handleChangeAdvancesUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAdvancedUser(event.target.checked);
  };

  const [customSchedulerConfig, setCustomSchedulerConfig] = useState<SchedulerConfig>(
    getSchedulerConfig() || schedulerConfigSchema.parse(StandardSchedulerConfig)
  );

  const handleChangeSchedulerConfig = (category: string, option: string, newValue: number, detail?: string): void => {
    if (newValue < 0 || isNaN(newValue)) {
      newValue = 0;
    }

    // The priority cost weight must be >= 1
    if (detail === 'weight' && newValue < 1 && option === 'priorityCost') {
      newValue = 1;
    }

    setCustomSchedulerConfig((prevConfig) => {
      const newConfig = structuredClone(prevConfig);
      if (detail) {
        newConfig[category][option][detail] = newValue;
      } else {
        newConfig[category][option] = newValue;
      }

      return newConfig;
    });
  };

  const handleSave = (): void => {
    if (!customSchedulerConfig) return;
    store.dispatch(setSchedulerConfiguration(customSchedulerConfig));

    SnackbarUtils.success(`Scheduler config updated`);
    handleClose();
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth={advancedUser ? 'md' : 'sm'} onClose={handleClose}>
      <DialogTitle sx={{ paddingBottom: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Update scheduler config{' '}
        <Link target="_blank" href={schedulerDocumentation} sx={{ display: 'flex', columnGap: theme.spacing(1) }}>
          <SchoolIcon fontSize="small" />
          <Typography variant="body2">Need help? Documentation here</Typography>
          <OpenInNewIcon fontSize="small" />
        </Link>
      </DialogTitle>
      <DialogContent>
        <SchedulerConfigComponent
          customSchedulerConfig={customSchedulerConfig}
          handleChangeSchedulerConfig={handleChangeSchedulerConfig}
          advancedUser={advancedUser}
        />
      </DialogContent>
      <DialogActions sx={{ marginLeft: 2 }}>
        <Stack justifyContent="space-between" direction="row" width={'100%'}>
          <Stack alignItems="center" direction="row">
            <Typography>Advanced user</Typography>
            <Switch
              checked={advancedUser}
              onChange={handleChangeAdvancesUser}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
          <Button variant="outlined" endIcon={<SaveIcon />} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

interface SchedulerConfigComponentProps {
  customSchedulerConfig: SchedulerConfig;
  handleChangeSchedulerConfig: (category: string, option: string, newValue: number, detail?: string) => void;
  advancedUser: boolean;
}

type TooltipOptions =
  | 'priorityCost'
  | 'dueDateCost'
  | 'creationDateCost'
  | 'emptyTravelCost'
  | 'eventToExeCount'
  | 'weight'
  | 'costUpdateTime'
  | 'offset';

export function SchedulerConfigComponent({
  customSchedulerConfig,
  handleChangeSchedulerConfig,
  advancedUser,
}: SchedulerConfigComponentProps): JSX.Element {
  const customSchedulerConfigCategory = Object.keys(customSchedulerConfig);

  const getGoodTooltip = (option: TooltipOptions): string | (string | JSX.Element)[] => {
    switch (option) {
      case 'priorityCost':
        return [
          'The cost associated with the priority of the task. The higher the cost, the more the scheduler will priorize the task when the priority is high.',
          <br />,
          'The priority cost weight must be >= 1.',
        ];

      case 'dueDateCost':
        return 'The cost associated with the due date of the task. Task with the closest due date will be given priority for assignment.';

      case 'creationDateCost':
        return 'The cost associated with the creation date of the task. The higher the cost, the more the scheduler will priorize the task when it was created a long time ago.';

      case 'emptyTravelCost':
        return 'The cost associated with the robots being empty (no load on the forks). The higher the cost, the more the scheduler will priorize the robots close to the pick point.';

      case 'eventToExeCount':
        return 'Number of events to execute per iteration.';

      case 'weight':
        return 'Indicates the relative significance of each cost component in determining the total cost.';

      case 'costUpdateTime':
        return 'The update interval for a periodically calculated cost, measured in seconds.';

      case 'offset':
        return 'Constant value used to modify the outcome of the cost module.';

      default:
        return '';
    }
  };

  return (
    <>
      <SchedulerConfigColumn advancedUser={advancedUser} getGoodTooltip={getGoodTooltip} />

      {customSchedulerConfigCategory.map((category) => (
        <React.Fragment key={category}>
          {Object.keys(customSchedulerConfig[category]).map((option) => (
            <React.Fragment key={option}>
              <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row' }}>
                {option !== 'eventToExeCount' && (
                  <Box
                    component={'div'}
                    sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: 1, paddingBottom: 1 }}
                  >
                    {camelCaseToTitle(option)}
                    <HelpIconTooltip title={getGoodTooltip(option as TooltipOptions)} />
                  </Box>
                )}

                <Box
                  component={'div'}
                  sx={{
                    display: 'flex',
                    flex: 2,
                    justifyContent: advancedUser ? 'space-between' : 'flex-end',
                  }}
                >
                  {Object.keys(customSchedulerConfig[category][option]).map((detail) => (
                    <>
                      {!advancedUser && detail === 'weight' && (
                        <FormControl variant="standard" sx={{ width: 100, paddingTop: 1, paddingBottom: 1 }}>
                          <Input
                            size="small"
                            type="number"
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            defaultValue={customSchedulerConfig[category][option][detail]}
                            onChange={(e) =>
                              handleChangeSchedulerConfig(category, option, Number(e.target.value), detail)
                            }
                          />
                        </FormControl>
                      )}
                      {advancedUser && detail !== 'costLim' && (
                        <FormControl
                          variant="standard"
                          sx={{ width: detail === 'costUpdateTime' ? 165 : 100, paddingTop: 1, paddingBottom: 1 }}
                        >
                          <Input
                            size="small"
                            type="number"
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            defaultValue={customSchedulerConfig[category][option][detail]}
                            onChange={(e) =>
                              handleChangeSchedulerConfig(category, option, Number(e.target.value), detail)
                            }
                          />
                        </FormControl>
                      )}
                    </>
                  ))}
                  {advancedUser &&
                    !customSchedulerConfig[category][option]['costUpdateTime'] &&
                    option !== 'eventToExeCount' && <Box component={'div'} width={165}></Box>}
                </Box>
              </Box>
              {advancedUser && option === 'eventToExeCount' && (
                <>
                  <Divider sx={{ marginTop: 2 }}>Options</Divider>
                  <Box
                    component={'div'}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                      {camelCaseToTitle(option)}
                      <HelpIconTooltip title={getGoodTooltip(option)} />
                    </Box>
                    <FormControl variant="standard" sx={{ width: 100 }}>
                      <Input
                        size="small"
                        type="number"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        defaultValue={customSchedulerConfig[category][option]}
                        onChange={(e) => handleChangeSchedulerConfig(category, option, Number(e.target.value))}
                      />
                    </FormControl>
                  </Box>
                </>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}

interface SchedulerConfigColomnProps {
  advancedUser: boolean;
  getGoodTooltip: (option: TooltipOptions) => string | (string | JSX.Element)[];
}
export function SchedulerConfigColumn({ advancedUser, getGoodTooltip }: SchedulerConfigColomnProps): JSX.Element {
  const details = [
    { name: 'weight', forAdvancedUserOnly: false, columnWidth: 100 },
    { name: 'offset', forAdvancedUserOnly: true, columnWidth: 100 },
    { name: 'costUpdateTime', forAdvancedUserOnly: true, columnWidth: 165 },
  ] as const;

  return (
    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
      <Box component={'div'} sx={{ display: 'flex', flex: 1 }}></Box>
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          flex: 2,
          justifyContent: advancedUser ? 'space-between' : 'flex-end',
        }}
      >
        {details.map(({ name, forAdvancedUserOnly, columnWidth }) => {
          if (!advancedUser && forAdvancedUserOnly) return <></>;

          return (
            <Box
              component={'div'}
              width={columnWidth}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {camelCaseToTitle(name)}
              <HelpIconTooltip title={getGoodTooltip(name)} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
