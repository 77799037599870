import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CloseIcon from '@mui/icons-material/Close';
import DeselectIcon from '@mui/icons-material/Deselect';
import ExploreIcon from '@mui/icons-material/Explore';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  Box,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import type { SearchShapeResult } from 'actions';
import { closeDialogAction } from 'actions';
import {
  clearShapesSelectionAction,
  selectCircuitShapeAction,
  selectMultipleCircuitShapesAction,
  unselectCircuitShapeAction,
  unselectSeveralCircuitShapesAction,
} from 'actions/circuit';
import { DialogTypes } from 'models';
import { ShapeTypes, type CircuitShape } from 'models/circuit';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { SelectedShapesData } from 'reducers/local/state';
import type { RootState } from 'store';
import store, { useAppSelector } from 'store';
import { isCircuitRack, isCircuitZone } from 'utils/circuit/shape-guards';
import { zoomToAllShapes } from 'utils/circuit/zoom-to-all-shapes';
import { zoomToShape } from 'utils/circuit/zoom-to-shape';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      position: 'absolute',
      left: theme.spacing(1),
      top: '0',
      pointerEvents: 'none',
    },
    table: {
      '& tr th, & tr td': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
    },
    card: {
      display: 'flex',
      background: 'white',
      pointerEvents: 'initial',
      zIndex: 401,
      height: 'fit-content',
      minHeight: '214px',
      maxHeight: '550px',
      minWidth: '270px',
      maxWidth: 'max-content',
      resize: 'both',
    },
    cardContent: {
      display: 'flex',
      flexFlow: 'column',
      overflow: 'auto',
      position: 'relative',
    },
    lineThrough: {
      textDecoration: 'line-through',
    },
    hiddenLayerIcon: {
      verticalAlign: 'bottom',
      color: theme.palette.grey[500],
      marginRight: theme.spacing(0.5),
    },
    formTitle: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
  })
);

export const SearchShapeResultSelectionComponent = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchShapeResultSelection = useAppSelector(
    (state: RootState) => state.dialog.type === DialogTypes.SearchShapeResultSelection && state.dialog.open
  );
  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const filteredShapes = useAppSelector(
    (state: RootState) => (state.dialog?.data as SearchShapeResult)?.shapes as CircuitShape[]
  );
  const selectedShapes = useAppSelector((state: RootState) => state.local.selectedShapesData.map((shape) => shape.id));
  const [areAllShapesChipsSelected, setAreAllShapesChipsSelected] = useState(false);

  const handleClose = (): void => {
    dispatch(closeDialogAction());
  };

  const handleZoomToShape = useCallback(
    (shapeId: string) => {
      zoomToShape(shapeId);
    },

    []
  );

  const handleSelectAllShapes = (): void => {
    if (areAllShapesChipsSelected) {
      dispatch(clearShapesSelectionAction());

      return;
    }

    const shapeToSelect = filteredShapes.map((shape) => ({ id: shape.id as string, type: shape.properties.type }));
    dispatch(selectMultipleCircuitShapesAction(shapeToSelect));
  };

  const handleZoomToAllShapes = (): void => {
    if (!store.getState().local.selectedShapesData.length) {
      return;
    }

    zoomToAllShapes(store.getState().local.selectedShapesData.map((shape) => shape.id));
  };

  useEffect(() => {
    if (
      !filteredShapes.length ||
      filteredShapes.length !== selectedShapes.length ||
      filteredShapes.map((shape) => shape.id as string).some((shapeId) => !selectedShapes.includes(shapeId))
    ) {
      if (!areAllShapesChipsSelected) {
        return;
      }

      setAreAllShapesChipsSelected(false);

      return;
    }

    if (areAllShapesChipsSelected) {
      return;
    }

    setAreAllShapesChipsSelected(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShapes]);

  return !searchShapeResultSelection ? (
    <></>
  ) : (
    <Box component="div" className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box component="div" className={classes.formTitle}>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Tooltip title={areAllShapesChipsSelected ? 'Deselect all shapes' : 'Select all shapes'}>
                <IconButton size="medium" onClick={handleSelectAllShapes}>
                  {areAllShapesChipsSelected ? <DeselectIcon /> : <SelectAllIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title={'Zoom to all selected shapes'}>
                <IconButton size="medium" onClick={handleZoomToAllShapes}>
                  <CenterFocusStrongIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Typography variant="h6">Search shape result selection</Typography>
            <Tooltip title="Close Menu">
              <IconButton onClick={handleClose} size="large">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          {filteredShapes.length ? (
            <TableContainer>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Layer</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredShapes.map((shape, index) => {
                    const rowIsSelected = selectedShapes.includes(shape.id as string);

                    const handleRowClick = (): void => {
                      if (isCircuitZone(shape)) {
                        const shapesToSelect: SelectedShapesData = [];
                        const gravityRackId = shape.properties.gravityRack;

                        if (gravityRackId) {
                          shapesToSelect.push({ id: shape.id as string, type: shape.properties.type });
                          const racks = store.getState().circuit.present.racks.entities;
                          const racksIds = store.getState().circuit.present.racks.ids;

                          racksIds.forEach((rackId) => {
                            const rack = racks[rackId];

                            const isAssociatedRack = rack.properties.gravityRack?.id === gravityRackId;

                            if (isAssociatedRack) {
                              shapesToSelect.push({ id: rackId, type: rack.properties.type });
                            }
                          });
                        }

                        if (shapesToSelect.length > 0) {
                          if (rowIsSelected) {
                            dispatch(
                              unselectSeveralCircuitShapesAction({
                                unselectedShapes: shapesToSelect,
                                userAction: true,
                              })
                            );

                            return;
                          }

                          dispatch(selectMultipleCircuitShapesAction(shapesToSelect));
                        }
                      }

                      if (rowIsSelected) {
                        //unselect the associated zone when the rack is a gravity rack
                        if (isCircuitRack(shape)) {
                          const shapesToUnselect: SelectedShapesData = [];

                          if (shape.properties.gravityRack) {
                            shapesToUnselect.push({ id: shape.id as string, type: shape.properties.type });

                            const gravityRackZoneId = shape.properties.gravityRack.zone;
                            const isGravityZoneSelected = selectedShapes.includes(gravityRackZoneId);

                            if (isGravityZoneSelected) {
                              shapesToUnselect.push({ id: gravityRackZoneId, type: ShapeTypes.ZoneShape });
                            }
                          }

                          dispatch(
                            unselectSeveralCircuitShapesAction({
                              unselectedShapes: shapesToUnselect,
                              userAction: true,
                            })
                          );

                          return;
                        }

                        dispatch(
                          unselectCircuitShapeAction({
                            unselectedShapeId: shape.id as string,
                            unselectedShapeType: shape.properties.type,
                          })
                        );

                        return;
                      }

                      dispatch(
                        selectCircuitShapeAction({
                          selectedShapeId: shape.id as string,
                          selectedShapeType: shape.properties.type,
                        })
                      );
                    };

                    return (
                      <TableRow
                        key={shape.id as string}
                        onClick={handleRowClick}
                        sx={{
                          cursor: 'pointer',
                          boxShadow: `inset 3px 0px 0px 0px ${layers[shape.properties.layerId]?.color}`,
                        }}
                        selected={rowIsSelected}
                      >
                        <TableCell component="th" scope="row">
                          {shape.properties.type}
                        </TableCell>
                        <TableCell>{shape.properties?.name}</TableCell>
                        <TableCell
                          className={layers[shape.properties.layerId]?.isDraft ? classes.lineThrough : undefined}
                        >
                          {!layers[shape.properties.layerId]?.visibility && (
                            <VisibilityOffIcon className={classes.hiddenLayerIcon} />
                          )}
                          {layers[shape.properties.layerId]?.name}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            title="Zoom-in to the shape"
                            onClick={() => handleZoomToShape(shape.id as string)}
                            size="large"
                          >
                            <ExploreIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="warning">No shapes found</Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
