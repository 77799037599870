import { alpha, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

export const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    flexDirection: 'row-reverse',
    borderRadius: theme.spacing(0.7),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    [`&.Mui-expanded:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon`]: {
      color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

interface CustomLabelProps {
  children: React.ReactNode;
  icon?: React.ElementType;
  expandable?: boolean;
  onEditClick?: (e: React.MouseEvent) => void; //
}

export function CustomLabel({ icon: Icon, onEditClick, children }: CustomLabelProps): JSX.Element {
  return (
    <Box component="div" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      {Icon && <Icon style={{ marginRight: '0.5rem', fontSize: '1.2rem', color: 'inherit' }} />}
      <Typography sx={{ flexGrow: 1 }}>{children}</Typography>
    </Box>
  );
}
