import type { CaseReducer, PayloadAction, Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { removeActionById } from 'components/core/project-settings/RMI-actions/helpers';
import type { ActionNode, RobotManagerArgTypes } from 'models/action';

const actionLsKey = 'actions';

function getInitState(): ActionNode[] {
  const actionRaw = localStorage.getItem(actionLsKey);
  if (!actionRaw) return [];

  return JSON.parse(actionRaw) as ActionNode[];
}

const initialState: ActionNode[] = getInitState();

// Defining a type for the state.
type ActionsState = ActionNode[];

interface ActionsReducers extends SliceCaseReducers<ActionsState> {
  updateActionsAction: CaseReducer<ActionsState, PayloadAction<ActionNode[]>>;
  deleteActionAction: CaseReducer<ActionsState, PayloadAction<string>>;
  triggerActionAction: CaseReducer<
    ActionsState,
    PayloadAction<{
      id: string;
      displayName: string;
      callback: string;
      data: { args: RobotManagerArgTypes[] };
    }>
  >;
}

export const actionsSlice: Slice<ActionsState, ActionsReducers, 'actions'> = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    updateActionsAction: (_state, action: PayloadAction<ActionNode[]>) => {
      localStorage.setItem(actionLsKey, JSON.stringify(action.payload));

      return action.payload;
    },
    deleteActionAction: (state, action: PayloadAction<string>) => {
      const updatedState = removeActionById(state, action.payload);
      localStorage.setItem(actionLsKey, JSON.stringify(updatedState));

      return updatedState;
    },
    triggerActionAction: (
      _state,
      _action: PayloadAction<{
        id: string;
        displayName: string;
        callback: string;
        data: { args: RobotManagerArgTypes[] };
      }>
    ) => {
      // ...
    },
  },
});

export const { updateActionsAction, deleteActionAction } = actionsSlice.actions;

export const actionsReducer = actionsSlice.reducer;
