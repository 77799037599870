import { loadCircuitFromYJSAction } from 'actions/circuit';
import { remoteDoc } from 'multiplayer/globals';
import type { CircuitState } from 'reducers/circuit/state';
import store, { useAppSelector } from 'store';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useCircuitSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const circuitMap = remoteDoc?.getMap('circuit');

  const updateCircuit = (): void => {
    if (!circuitMap) return;

    const circuit = circuitMap.get('circuit') as CircuitState;

    store.dispatch(loadCircuitFromYJSAction({ circuit: circuit }));
  };

  useConditionalQueueSubscriptionHook({
    YType: circuitMap,
    callback: updateCircuit,
    condition: notCircuitEditionMode,
    updateLastCircuitTransactionOrigin: true,
  });
};
