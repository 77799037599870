import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Box, Card, CardContent, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { TreeView } from '@mui/x-tree-view';
import { updateActionsAction } from 'actions/actionsSlice';
import type { ActionNode, RobotManagerInteractionArg } from 'models/action';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarUtils } from 'services/snackbar.service';
import type { RootState } from 'store';
import { PreferencesService } from 'utils/preferences';
import { isActionNode, isFolderNode } from './actions.guard';
import { collectImages, convertNodesToJSON, parseJSONtoNodes, removeActionById, updateNodes } from './helpers';
import { JsonFiledEditor } from './json-field-editor';
import TreeRenderer from './tree-renderer';

interface ActionEditorProps {
  onSave?: (data: ActionNode[]) => void;
}

export const ActionEditor: React.FC<ActionEditorProps> = ({ onSave }) => {
  const dispatch = useDispatch();

  // The full tree from Redux
  const data = useSelector((state: RootState) => state.actions);

  // The node the user is currently editing in the Right Pane
  const [selectedAction, setSelectedAction] = useState<ActionNode | null>(null);

  // For controlling the tree expansion in the left panel
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  // We still keep a ref for file inputs (for images)
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const newFolderNode = {
    id: crypto.randomUUID(),
    displayName: 'New Folder',
    description: '',
    callback: '',
    nodes: [],
  };

  const newActionNode = {
    id: crypto.randomUUID(),
    displayName: 'New Action',
    description: '',
    callback: '',
    requiresConfirmation: false,
    imgName: '',
    imgData: '',
    args: [],
  };

  /**
   * Create a new folder or action at the ROOT level
   */
  const createNodeAtRoot = (nodeType: 'folder' | 'action'): void => {
    // 1) Build a blank node
    let newNode: ActionNode;
    if (nodeType === 'folder') {
      newNode = newFolderNode;
    } else {
      newNode = newActionNode;
    }

    // 2) Insert into the root
    const newData = [...data, newNode];

    // 3) Update Redux & optional callback
    dispatch(updateActionsAction(newData));
    if (onSave) onSave(newData);
  };

  /**
   * Create a new folder or action INSIDE the *currently selected* folder.
   */
  const createNodeInsideSelectedFolder = (nodeType: 'folder' | 'action'): void => {
    if (!selectedAction || !isFolderNode(selectedAction)) {
      return;
    }

    // 1) Building a blank node
    let newNode: ActionNode;
    if (nodeType === 'folder') {
      newNode = newFolderNode;
    } else {
      newNode = newActionNode;
    }

    // 2) Insert it into selectedAction.nodes
    const updatedFolder = {
      ...selectedAction,
      nodes: [...(selectedAction.nodes || []), newNode],
    };

    // 3) Update the entire tree
    const newData = updateNodes(data, updatedFolder);
    dispatch(updateActionsAction(newData));
    if (onSave) onSave(newData);

    // 4) We DO NOT auto select the new node => remain on the parent
    setSelectedAction(updatedFolder);

    // 5) Auto expand the folder so we can see the newly added child
    setExpandedNodes((prev) => Array.from(new Set([...prev, selectedAction.id])));
  };

  // Updating an existing node in the tree
  const handleActionUpdate = (updatedNode: ActionNode): void => {
    // Also update local state
    setSelectedAction(updatedNode);

    // Then update Redux
    const newData = updateNodes(data, updatedNode);
    dispatch(updateActionsAction(newData));
    if (onSave) onSave(newData);
  };

  // Clicking a node in the Tree on the left side
  const handleLeafClick = (node: ActionNode): void => {
    setSelectedAction(node);
  };

  // saving the json file in the project folder/CONFIGURATION FILES/ROBOT_MANAGER_INTERFACE
  const handleSaveJSON = async (): Promise<void> => {
    try {
      const clonedNodes = structuredClone(data);
      const images: Record<string, string> = {};
      const nodesWithoutImageData = collectImages(clonedNodes, images);
      const finalNodes = convertNodesToJSON(nodesWithoutImageData);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const finalExport = [...finalNodes, { images }];

      const success = await PreferencesService.saveActionJSON(
        '_CONFIGURATION FILES/ROBOT_MANAGER_INTERFACE/ACTIONS',
        finalExport
      );

      if (success) {
        SnackbarUtils.success('JSON saved successfully.');
      } else {
        SnackbarUtils.error('Error saving JSON.');
      }
    } catch (error) {
      SnackbarUtils.error('Error saving JSON.');
    }
  };

  // Loading the json file from the project folder/CONFIGURATION FILES/ROBOT_MANAGER_INTERFACE
  const handleLoadJSON = async (): Promise<void> => {
    try {
      // Load the JSON data
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const loadedData = await PreferencesService.loadActionJSON(
        '_CONFIGURATION FILES/ROBOT_MANAGER_INTERFACE/ACTIONS'
      );

      if (loadedData && Array.isArray(loadedData)) {
        let images: Record<string, string> = {};

        // Check if the last element contains the images mapping
        if (loadedData.length && loadedData[loadedData.length - 1].images) {
          // Remove the last element from the array and use its `images` property.
          const imagesEntry = loadedData.pop() as { images: Record<string, string> };
          images = imagesEntry.images;
        }

        // Now parsing the rest of the JSON using helper,
        const parsedNodes = parseJSONtoNodes(loadedData, images);

        // Update state/Redux with the parsed nodes
        dispatch(updateActionsAction(parsedNodes));
        if (onSave) onSave(parsedNodes);

        SnackbarUtils.success('Actions loaded successfully.');
      } else {
        SnackbarUtils.error('Failed to load actions JSON.');
      }
    } catch (error) {
      SnackbarUtils.error('Error loading actions JSON.');
    }
  };

  // Download JSON
  const handleDownload = (): void => {
    // Step 1: Grab  Redux data
    const originalStateNodes = data;

    // Step 2: Making a deep clone (to avoid any mutations to the Redux state)
    const clonedNodes = structuredClone(originalStateNodes);

    // Step 3: Preparing an empty object to collect images
    const images: Record<string, string> = {};

    // Step 4: Collecting images and remove imgData from the nodes.
    const nodesWithoutImageData = collectImages(clonedNodes, images);

    // Step 5: Converting the cleaned nodes to JSON-friendly objects.
    const finalNodes = convertNodesToJSON(nodesWithoutImageData);

    // Step 6: Building the final export object.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const finalExport = [...finalNodes, { images }];

    // Step 7: Stringify and trigger the download.
    const jsonStr = JSON.stringify(finalExport, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'updated-actions.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  // Deleting an item from the tree
  const handleDeleteAction = (id: string): void => {
    const newData = removeActionById(data, id);
    dispatch(updateActionsAction(newData));
    if (onSave) onSave(newData);

    // If we just deleted the selected node, clear the selection
    if (selectedAction && selectedAction.id === id) {
      setSelectedAction(null);
    }
  };

  // "Add Arg" is only relevant if we have an Action selected
  // (since Folders don't have args)
  const addArg = (): void => {
    if (!selectedAction || !isActionNode(selectedAction)) return;

    const newArg: RobotManagerInteractionArg = {
      label: '',
      description: '',
      inputType: 'text',
      required: false,
    };
    const updatedAction = {
      ...selectedAction,
      args: [...(selectedAction.args || []), newArg],
    };
    setSelectedAction(updatedAction);
    handleActionUpdate(updatedAction);
  };

  const handleUploadImageClick = (): void => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleImageSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!selectedAction || !isActionNode(selectedAction)) return;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Validate file type
      if (!file.type.startsWith('image/')) {
        const errorMessage = 'Please select a valid image file (JPEG, PNG, GIF, BMP, etc.)';
        SnackbarUtils.error(errorMessage);

        e.target.value = ''; // Reset file input

        return;
      }

      const fileName = file.name;

      const updatedAction: ActionNode = {
        ...selectedAction,
        imgName: fileName,
      };

      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        if (readerEvent.target?.result) {
          const base64String = readerEvent.target.result as string;
          const finalAction = {
            ...updatedAction,
            imgData: base64String,
          };
          setSelectedAction(finalAction);
          handleActionUpdate(finalAction);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (): void => {
    if (!selectedAction || !isActionNode(selectedAction)) return;
    const updatedAction = { ...selectedAction, imgName: '', imgData: '' };
    setSelectedAction(updatedAction);
    handleActionUpdate(updatedAction);
  };

  // to help creating folder or actions inside the main root or inside the selected folder
  const createNode = (nodeType: 'folder' | 'action'): void => {
    // If a folder is selected, add the node inside it.
    if (selectedAction && isFolderNode(selectedAction)) {
      createNodeInsideSelectedFolder(nodeType);
    } else {
      // Otherwise, add the node to the root.
      createNodeAtRoot(nodeType);
    }
  };

  // State for the dropdown menu
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setMenuAnchor(null);
  };

  return (
    <Box component="div" display="flex" sx={{ height: '100%', py: 4 }}>
      {/* LEFT PANE: Tree */}
      <Card sx={{ width: '25%', borderRight: '1px solid #ccc', px: 2, pb: 3 }}>
        <CardContent sx={{ overflowY: 'auto' }}>
          {/* Top bar with Download + Add Action + Add Folder + save + load actions */}
          <Box
            component="div"
            display="flex"
            justifyContent="flex-end"
            mb={2}
            sx={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'background.paper' }}
          >
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
              <MenuItem
                onClick={() => {
                  handleSaveJSON();
                  handleMenuClose();
                }}
              >
                <SaveIcon fontSize="small" sx={{ mr: 1 }} />
                Save JSON
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDownload();
                  handleMenuClose();
                }}
              >
                <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
                Download JSON
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLoadJSON();
                  handleMenuClose();
                }}
              >
                <RefreshIcon fontSize="small" sx={{ mr: 1 }} />
                Load JSON
              </MenuItem>
            </Menu>

            <Tooltip title="Add Action">
              <IconButton color="primary" onClick={() => createNode('action')}>
                <SettingsSuggestIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Add Folder">
              <IconButton color="primary" onClick={() => createNode('folder')}>
                <CreateNewFolderIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <TreeView expandedItems={expandedNodes} onExpandedItemsChange={(_event, ids) => setExpandedNodes(ids)}>
            <TreeRenderer nodes={data} handleNodeClick={handleLeafClick} handleNodeEditClick={handleLeafClick} />
          </TreeView>
        </CardContent>
      </Card>

      {/* RIGHT PANE: Editor for whichever node is selected */}
      <Card sx={{ overflowY: 'auto', pb: 3, px: 2, width: '75%' }}>
        <CardContent>
          {!data.length ? (
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Click on "Add Action" or "Add Folder" to create items.
            </Typography>
          ) : !selectedAction ? (
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Select a folder or action to edit.
            </Typography>
          ) : (
            <JsonFiledEditor
              action={selectedAction}
              onChange={handleActionUpdate}
              onDelete={handleDeleteAction}
              // "Add Arg" button is only displayed for actions
              addArg={isActionNode(selectedAction) ? addArg : undefined}
              allActions={data}
              // Image functions
              fileInputRef={fileInputRef}
              handleImageSelected={handleImageSelected}
              handleRemoveImage={handleRemoveImage}
              handleUploadImageClick={handleUploadImageClick}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
