import { remoteDoc } from 'multiplayer/globals';
import { useCallback } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import { PreferencesService } from 'utils/preferences';
import { projectHost } from './../../../multiplayer/globals';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useInstallSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const installText = remoteDoc?.getText('install');

  const updateInstallText = useCallback((): void => {
    if (!installText || !installText.length) return;

    PreferencesService.setInstallDocumentFromYJS(installText.toString());

    if (!PreferencesService.arePreferencesFullyLoaded()) return;

    if (store.getState().dialog.open && store.getState().dialog.type === 'project-settings') {
      SnackbarUtils.info('A user updated a preference', {
        autoHideDuration: 3000,
      });
    }

    document.dispatchEvent(new CustomEvent('update-pref-remote'));

    if (!projectHost) return;
    PreferencesService.saveYJSPreferences();
  }, [installText]);

  useConditionalQueueSubscriptionHook({
    YType: installText,
    callback: updateInstallText,
    condition: notCircuitEditionMode,
  });
};
