import { isEqual } from 'lodash';
import type { Trigger } from 'models/simulation';
import { Tools } from 'models/tools';
import { remoteDoc } from 'multiplayer/globals';
import { useRef } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { addTriggerFromYJS, removeTriggerFromYJS, setTriggerFromYJS } from 'simulation/triggers';
import store, { useAppSelector } from 'store';
import type { YMap } from 'yjs/dist/src/internals';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export function useTriggersSubscription(): void {
  const inSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const triggersMap = remoteDoc?.getMap('triggers') as YMap<Trigger>;
  const initRef = useRef(true);

  const updateTrigger = (): void => {
    const triggers = store.getState().triggers.triggers;
    if (triggers?.length) {
      triggers.forEach((trigger) => {
        if (triggersMap.has(trigger.id)) return;

        store.dispatch(removeTriggerFromYJS(trigger.id));

        if (!initRef.current) {
          SnackbarUtils.info('A user deleted a trigger', {
            autoHideDuration: 3000,
          });
        }
      });
    }

    if (!triggersMap.size) return;

    triggersMap.forEach((yjsTrigger, key) => {
      const localTriggerExists = triggers?.find((trigger) => trigger.id === key);
      if (!localTriggerExists) {
        store.dispatch(addTriggerFromYJS(yjsTrigger));

        if (!initRef.current) {
          SnackbarUtils.info('A user created a trigger', {
            autoHideDuration: 3000,
          });
        }

        return;
      }

      const sameTrigger = isEqual(localTriggerExists, yjsTrigger);
      if (sameTrigger) return;

      store.dispatch(setTriggerFromYJS(yjsTrigger));

      if (!initRef.current) {
        SnackbarUtils.info('A user updated a trigger', {
          autoHideDuration: 3000,
        });
      }
    });

    if (initRef.current) {
      initRef.current = false;
    }
  };

  useConditionalQueueSubscriptionHook({ YType: triggersMap, callback: updateTrigger, condition: inSimulation });
}
