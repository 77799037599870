import { CollapseMore } from 'components/utils/collapse-more';
import { useAppSelector } from 'store';
import { ImportSimulationConfigBtn } from './import-simulation-config';
import { SelectBalyoSimulation } from './select-balyo-simulation';

interface SimulationSettingsSectionProps {
  projectOpened: boolean;
}
export function SimulationSettingsSection(props: SimulationSettingsSectionProps): JSX.Element {
  const { projectOpened } = props;

  const simulationMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');

  return (
    <CollapseMore title="Simulation settings" defaultExpended={simulationMode} forceDefaultState={simulationMode}>
      {projectOpened && <ImportSimulationConfigBtn />}
      <SelectBalyoSimulation />
    </CollapseMore>
  );
}
