import { projectHost, remoteDoc } from 'multiplayer/globals';
import { useCallback } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import { PreferencesService } from 'utils/preferences';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useXmlTruckSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const xmlTruckText = remoteDoc?.getText('xmlTruckUpdate');

  const updateXmlTruckText = useCallback((): void => {
    if (!xmlTruckText) return;

    if (!PreferencesService.arePreferencesFullyLoaded()) return;
    const prefMap = remoteDoc?.getMap('pref');
    if (!prefMap) return;

    const xmls = prefMap.get('xmls');
    PreferencesService.setTrucksDocumentFromYJS({ xmls });

    if (store.getState().dialog.open && store.getState().dialog.type === 'robot-data') {
      SnackbarUtils.info('A user updated a preference', {
        autoHideDuration: 3000,
      });
    }

    document.dispatchEvent(new CustomEvent('update-robot-remote'));

    if (!projectHost) return;
    PreferencesService.saveYJSPreferences(xmlTruckText.toString());
  }, [xmlTruckText]);

  useConditionalQueueSubscriptionHook({
    YType: xmlTruckText,
    callback: updateXmlTruckText,
    condition: notCircuitEditionMode,
  });
};
