import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { addFlow, setSelectedFlowId } from 'flows/flows';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { type FlowCheckResult } from '../flow-configuration';
import { CheckFlowButton } from './check-flow-button';
import { CheckResultAlert } from './check-result-alert';
import { FlowForm } from './flow-form';
import { FlowListAccordion } from './flow-list-accordion';

export const maximumFlowNameLength = 30;

export interface FlowsContentProps {
  setSelectedTab: (tabToSelect: string) => void;
}
export function FlowsContent(props: FlowsContentProps): JSX.Element {
  const { setSelectedTab } = props;

  const flows = useAppSelector((state) => state.flows.flows);
  const [allFlowsCheckResults, setAllFlowsCheckResults] = useState<Record<string, FlowCheckResult>>({});

  const dispatch = useAppDispatch();

  const [isFlowCreation, setIsFlowCreation] = useState(false);
  const [mode, setMode] = useState<'creation' | 'edition'>('creation');

  const handleCreateFlow = useCallback(() => {
    let newFlowName = 'New Flow';
    let nb = 1;
    // eslint-disable-next-line no-loop-func
    while (flows.find((flow) => flow.name === newFlowName)) {
      newFlowName = `New Flow ${nb++}`;
    }

    const newFlowId = generateShapeId();
    dispatch(
      addFlow({
        id: newFlowId,
        name: newFlowName,
        stations: [],
        robotsAssigned: 'all',
        palletsPerTask: 1,
      })
    );

    dispatch(setSelectedFlowId(newFlowId));

    setMode('creation');
    setIsFlowCreation(true);
  }, [dispatch, flows]);

  const handleAllFlowsCheckResults = useCallback((results: Record<string, FlowCheckResult>) => {
    setAllFlowsCheckResults(results);
  }, []);

  return (
    <Box component="div">
      {!isFlowCreation ? (
        <>
          <Stack direction="row" spacing={1} sx={{ pb: 2, pr: 1 }}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              onClick={handleCreateFlow}
              endIcon={<AddCircleIcon />}
              sx={{
                marginTop: (theme) => theme.spacing(1),
              }}
            >
              Create flow
            </Button>
          </Stack>
          <FlowListAccordion
            flows={flows}
            setIsFlowCreation={setIsFlowCreation}
            setMode={setMode}
            setSelectedTab={setSelectedTab}
          />

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

          <CheckFlowButton flowIds={flows.map((f) => f.id)} onCheckResult={handleAllFlowsCheckResults} />
          {Object.entries(allFlowsCheckResults).map(([flowId, result]) => {
            const flowName = flows.find((f) => f.id === flowId)?.name;

            return (
              <Box component="div" key={flowId} sx={{ marginTop: 1 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Flow: {flowName}
                </Typography>
                <CheckResultAlert checkResult={result} />
              </Box>
            );
          })}
        </>
      ) : (
        <FlowForm flows={flows} mode={mode} setIsFlowCreation={setIsFlowCreation} setSelectedTab={setSelectedTab} />
      )}
    </Box>
  );
}
