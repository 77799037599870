import { Box, Tooltip } from '@mui/material';
import type { ReactNode } from 'react';

interface DisableChildrenConditionallyProps {
  /** The children to be disabled conditionally */
  children: ReactNode;

  /** Condition to disable the children */
  condition: boolean;

  /** Tooltip to be displayed */
  tooltip?: string;
}

export function DisableChildrenConditionally(props: DisableChildrenConditionallyProps): JSX.Element {
  const { children, condition, tooltip } = props;

  if (!condition) return <>{children}</>;

  return (
    <Tooltip title={tooltip} followCursor>
      <Box component="div">
        <Box component="div" sx={{ opacity: 0.5, pointerEvents: 'none', userSelect: 'none' }}>
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
}
