import store from 'store';

/**
 * Creates a new Redmine ticket with pre-filled information about the current project.
 * This function retrieves project details from the Redux store, constructs a ticket
 * description with relevant information, and opens a new browser window with the
 * Redmine issue creation page.
 */
export function createRedmineTicket(): void {
  const projectInfo = store.getState().project;
  const { projectName, balyoSimulationVersion, gitBranchInformation } = projectInfo;
  const branch = gitBranchInformation?.branch;
  const sha = gitBranchInformation?.sha;

  const ticketSubject = `Issue in ${projectName} (be more specific if possible)`;

  const ticketDescription = `Hello the Road Editor team,

I am having issue while ... (describe as much as possible, include attachments and screenshot if possible).

*Information to reproduce*
    Project name: ${projectName ?? 'no project opened'}
    Balyo simulation version: ${balyoSimulationVersion}
    Git branch: ${branch ?? `no git data`}
    Git sha: ${sha ?? `no git data`}
        `;

  const baseUrl = isLindeUser()
    ? 'https://redmine.balyo.com/projects/linde-road-editor'
    : 'https://redmine.balyo.com/projects/r-d-road-editor';

  const link = `${baseUrl}/issues/new?issue[description]=${encodeURIComponent(
    ticketDescription
  )}&issue[subject]=${encodeURIComponent(ticketSubject)}`;

  window.open(link, '_blank');
}

function isLindeUser(): boolean {
  const profile = store.getState().auth.profile;
  if (!profile || !('email' in profile)) return false;

  return profile.company?.includes('linde') ?? false;
}
