import { remoteDoc, setProjectHost } from 'multiplayer/globals';
import { useLayoutEffect, useRef } from 'react';
import { useAppSelector } from 'store';
import { PreferencesService } from 'utils/preferences';
import type { YMap } from 'yjs/dist/src/internals';

export const useProjectHostChangedSubscription = (): void => {
  const simulationMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const queuedChange = useRef<boolean>(false);

  const changeProjectHost = (): void => {
    const prefMap = remoteDoc?.getMap('pref');
    if (!prefMap) return;

    const xmls = prefMap.get('xmls');
    const projectName = prefMap.get('projectName');
    const projectNEWVersion = prefMap.get('projectNEWVersion');
    const availableCircuits = prefMap.get('availableCircuits');

    PreferencesService.setInitialPref({ xmls, projectName, projectNEWVersion, availableCircuits });

    setProjectHost(false);
  };

  useLayoutEffect(() => {
    const projectHostChangedMap = remoteDoc?.getMap('projectHostChanged') as YMap<boolean>;
    const prefMap = remoteDoc?.getMap('pref');

    const observerHandle = (event, transaction): void => {
      if (!prefMap) return;

      const isTransactionLocal = transaction.origin === 'local' || !!transaction.local;

      if (isTransactionLocal && projectHostChangedMap.get('bool')) {
        projectHostChangedMap.set('bool', false);

        return;
      }

      if (isTransactionLocal) return;

      if (simulationMode) {
        queuedChange.current = true;

        return;
      }

      changeProjectHost();
    };

    projectHostChangedMap.observe(observerHandle);

    return () => {
      projectHostChangedMap.unobserve(observerHandle);
    };
  }, [simulationMode]);

  useLayoutEffect(() => {
    if (simulationMode || !queuedChange.current) return;

    changeProjectHost();
    queuedChange.current = false;
  }, [simulationMode]);
};
