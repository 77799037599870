import type { UpdatedShapesAction } from 'epics/circuit.epic';
import { remoteDoc } from 'multiplayer/globals';
import { useCallback, useLayoutEffect, useRef } from 'react';
import store, { useAppSelector } from 'store';
import type { YArrayEvent } from 'yjs';
import type { YArray } from 'yjs/dist/src/internals';
import { setLastCircuitTransactionOrigin } from '../../../multiplayer/globals';
import { applyUpdatedShapesFromYJSAction } from './../../../actions/circuit';

export const useUpdatedShapesActionsSubscription = (): void => {
  const shouldQueueEvents = useAppSelector((state) => state.core.loading || state.editor.editorMode !== 'circuit');
  const queuedEvents = useRef<UpdatedShapesAction[]>([]);

  const handleUpdate = useCallback(
    (event: YArrayEvent<UpdatedShapesAction>): void => {
      const addedShapesActionsSet = event.changes.added.values();

      for (const item of addedShapesActionsSet) {
        const newShapesActionsArray = item.content.getContent() as UpdatedShapesAction[];

        if (shouldQueueEvents) {
          queuedEvents.current.push(...newShapesActionsArray);
          continue;
        }

        store.dispatch(applyUpdatedShapesFromYJSAction(newShapesActionsArray));
      }
    },
    [shouldQueueEvents]
  );

  useLayoutEffect(() => {
    const updatedShapesActionsArray = remoteDoc?.getArray('updatedShapesActions') as YArray<UpdatedShapesAction>;

    const observerHandle = (event: YArrayEvent<UpdatedShapesAction>, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      setLastCircuitTransactionOrigin(isTransactionLocal ? 'local' : 'remote');
      if (isTransactionLocal) return;

      handleUpdate(event);
    };

    updatedShapesActionsArray.observe(observerHandle);

    return () => {
      updatedShapesActionsArray.unobserve(observerHandle);
    };
  }, [handleUpdate]);

  useLayoutEffect(() => {
    if (shouldQueueEvents || queuedEvents.current.length === 0) return;

    store.dispatch(applyUpdatedShapesFromYJSAction(queuedEvents.current));
    queuedEvents.current = [];
  }, [queuedEvents, shouldQueueEvents]);
};
