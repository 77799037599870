import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import type { Flow } from 'flows/flows';
import {
  addCustomStep,
  addEventToCustomStep,
  addFlow,
  addPositionToStation,
  addPositionToStations,
  addSeveralPositionsToStation,
  addStation,
  addStationToFlow,
  moveEventInEventsListInCustomStep,
  removeCustomStep,
  removeElementFromStations,
  removeElementsFromStations,
  removeEventToCustomStep,
  removeFlow,
  removePositionFromStation,
  removePositionFromStations,
  removeStation,
  removeStationFromFlow,
  reorderStationsInFlow,
  setCustomStep,
  setCustomStepEventName,
  setCustomStepEventType,
  setCustomStepName,
  setCustomSteps,
  setFlow,
  setFlowName,
  setFlows,
  setFlowStepMissionType,
  setMultipleFlows,
  setPositionInLine,
  setStation,
  setStationName,
  setStations,
  togglePositionInLineRandom,
} from 'flows/flows';
import type { Trigger } from 'models/simulation';
import { localDoc } from 'multiplayer/globals';
import { setOpcData, setSchedulerConfiguration } from 'project/project';
import type { AppState } from 'reducers/state';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SnackbarUtils } from 'services/snackbar.service';
import type { OPCConfiguration } from 'simulation/opc';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import type { RobotBattery } from 'simulation/simulation';
import { setRobotsInitialBatteryLevel } from 'simulation/simulation';
import {
  addTasksToReplayTrigger,
  addTrigger,
  removeTaskFromReplayTrigger,
  removeTrigger,
  removeTriggers,
  setTrigger,
  setTriggerName,
  setTriggers,
} from 'simulation/triggers';
import store from 'store';

export function removeTriggersAssociatedWithAFlow$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<typeof removeTriggers | void> {
  return actions$.pipe(
    ofType(removeFlow.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      const flowId = action.payload as string;
      const flowName = state.flows.flows.find((flow) => flow.id === flowId)?.name;

      const triggers = state.triggers.triggers;

      const triggersToRemove: Trigger[] = [];

      triggers.forEach((trigger) => {
        if ('flowId' in trigger && trigger.flowId === flowId) {
          triggersToRemove.push(trigger);
        } else if (flowName && 'tasks' in trigger) {
          const flowNamesUsedWithThisTrigger = new Set(
            trigger.tasks.flatMap((tasks) => ('tasks' in tasks ? tasks.tasks : [tasks]).map((task) => task.flowName))
          );
          if (flowNamesUsedWithThisTrigger.has(flowName)) {
            triggersToRemove.push(trigger);
          }
        }
      });

      if (triggersToRemove.length > 0) {
        store.dispatch(removeTriggers(triggersToRemove.map((trigger) => trigger.id)));

        const triggersToRemoveNames = triggersToRemove.map((trigger) => trigger.name).join(', ');

        SnackbarUtils.info(
          `${triggersToRemove.length} trigger${
            triggersToRemove.length > 1 ? 's were' : ' was'
          } associated with the flow and ${
            triggersToRemove.length > 1 ? 'have' : 'has'
          } been removed (${triggersToRemoveNames}).`
        );
      }
    })
  );
}

export function removeStationFromFlows$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<typeof setMultipleFlows | void> {
  return actions$.pipe(
    ofType(removeStation.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      const stationId = action.payload as string;
      const flows = state.flows.flows;
      const flowsEdited: Flow[] = [];

      flows.forEach((flow) => {
        const stationInFlow = flow.stations.filter((stationInFlow) => stationInFlow.id.includes(stationId));

        if (stationInFlow.length > 0) {
          const newFlow: Flow = {
            ...flow,
            stations: flow.stations.filter((stationInFlow) => !stationInFlow.id.includes(stationId)),
          };
          flowsEdited.push(newFlow);
        }
      });

      if (flowsEdited.length > 0) {
        const flowsEditedNames = flowsEdited.map((flow) => flow.name).join(', ');

        store.dispatch(setMultipleFlows(flowsEdited));

        SnackbarUtils.info(
          `${flowsEdited.length} flow${
            flowsEdited.length > 1 ? 's have' : ' has'
          } been updated to remove the station (${flowsEditedNames}).`
        );
      }
    })
  );
}

export function shareOpcConfig$(actions$: ActionsObservable<any>, state$: StateObservable<AppState>): Observable<void> {
  return actions$.pipe(
    ofType(setOpcData.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const opcData = action.payload as OPCConfiguration;
      const localOpcConfigStr = localDoc.getText('opcConfig');

      const strConfig = JSON.stringify(opcData, null, 2);

      localDoc.transact(() => {
        localOpcConfigStr.delete(0, localOpcConfigStr?.length);
        localOpcConfigStr.insert(0, strConfig);
      });

      syncYJSLocalToRemote();
    })
  );
}

export function shareSchedulerConfig$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(setSchedulerConfiguration.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const schedulerConfig = action.payload as SchedulerConfig;
      const localSchedulerConfigStr = localDoc.getText('schedulerConfig');

      const strConfig = JSON.stringify(schedulerConfig, null, 2);

      localDoc.transact(() => {
        localSchedulerConfigStr.delete(0, localSchedulerConfigStr?.length);
        localSchedulerConfigStr.insert(0, strConfig);
      });

      syncYJSLocalToRemote();
    })
  );
}

export function shareRobotBatteryLevel$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(setRobotsInitialBatteryLevel.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const robotBatteryLevel = action.payload.robots as RobotBattery[];
      const localRobotBatteryLevelStr = localDoc.getText('robotBatteryLevel');

      const strConfig = JSON.stringify(robotBatteryLevel, null, 2);

      localDoc.transact(() => {
        localRobotBatteryLevelStr.delete(0, localRobotBatteryLevelStr?.length);
        localRobotBatteryLevelStr.insert(0, strConfig);
      });

      syncYJSLocalToRemote();
    })
  );
}

export function shareRemoveStation$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(removeStation.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const stationId = action.payload as string;

      const localStationsMap = localDoc.getMap('stations');
      localStationsMap.delete(stationId);

      syncYJSLocalToRemote();
    })
  );
}

export function shareMultipleStations$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(
      setStations.type,
      addPositionToStations.type,
      removePositionFromStations.type,
      removeElementFromStations.type,
      removeElementsFromStations.type
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions = [];
      if (!state.multiplayer.multiplayer) return actions;

      const stations = state.flows.stations;
      const localStationsMap = localDoc.getMap('stations');

      localDoc.transact(() => {
        localStationsMap.clear();
        stations.forEach((station) => {
          localStationsMap.set(station.id, station);
        });
      });

      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function shareSingleStation$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(
      addStation.type,
      setStation.type,
      setStationName.type,
      addPositionToStation.type,
      addSeveralPositionsToStation.type,
      removePositionFromStation.type,
      togglePositionInLineRandom.type,
      setPositionInLine.type
    ),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const stationId = (action.payload.stationId || action.payload.id || action.payload) as string;

      const stations = state.flows.stations;
      const station = stations.find((station) => station.id === stationId);
      const localStationsMap = localDoc.getMap('stations');

      localStationsMap.set(stationId, station);

      syncYJSLocalToRemote();
    })
  );
}

export function shareRemoveFlow$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(removeFlow.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const flowId = action.payload as string;

      const localFlowsMap = localDoc.getMap('flows');
      localFlowsMap.delete(flowId);

      syncYJSLocalToRemote();
    })
  );
}

export function shareMultipleFlows$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    ofType(setMultipleFlows.type, setFlows.type),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions = [];
      if (!state.multiplayer.multiplayer) return actions;

      const flows = state.flows.flows;
      const localFlowsMap = localDoc.getMap('flows');

      localDoc.transact(() => {
        localFlowsMap.clear();
        flows.forEach((flow) => {
          localFlowsMap.set(flow.id, flow);
        });
      });

      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function shareSingleFlow$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(
      addFlow.type,
      setFlow.type,
      setFlowName.type,
      addStationToFlow.type,
      removeStationFromFlow.type,
      reorderStationsInFlow.type,
      setFlowStepMissionType.type
    ),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const flowId = (action.payload.flowId || action.payload.id || action.payload) as string;

      const flows = state.flows.flows;
      const flow = flows.find((flow) => flow.id === flowId);
      const localFlowsMap = localDoc.getMap('flows');

      localFlowsMap.set(flowId, flow);

      syncYJSLocalToRemote();
    })
  );
}

export function shareRemoveCustomStep$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(removeCustomStep.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const customStepId = action.payload as string;

      const localCustomStepsMap = localDoc.getMap('customSteps');
      localCustomStepsMap.delete(customStepId);

      syncYJSLocalToRemote();
    })
  );
}

export function shareMultipleCustomSteps$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    ofType(setCustomSteps.type),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions = [];
      if (!state.multiplayer.multiplayer) return actions;

      const customSteps = state.flows.customSteps;
      const localCustomStepsMap = localDoc.getMap('customSteps');

      localDoc.transact(() => {
        localCustomStepsMap.clear();
        customSteps.forEach((customStep) => {
          localCustomStepsMap.set(customStep.id, customStep);
        });
      });

      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function shareSingleCustomStep$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(
      setCustomStep.type,
      addCustomStep.type,
      addEventToCustomStep.type,
      removeEventToCustomStep.type,
      moveEventInEventsListInCustomStep.type,
      setCustomStepEventType.type,
      setCustomStepEventName.type,
      setCustomStepEventName.type,
      setCustomStepName.type
    ),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const customStepId = (action.payload.customStepId || action.payload.id || action.payload) as string;

      const customSteps = state.flows.customSteps;
      const customStep = customSteps.find((customStep) => customStep.id === customStepId);
      const localCustomStepsMap = localDoc.getMap('customSteps');

      localCustomStepsMap.set(customStepId, customStep);

      syncYJSLocalToRemote();
    })
  );
}

export function shareRemoveTrigger$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(removeTrigger.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const triggerId = action.payload as string;

      const localTriggersMap = localDoc.getMap('triggers');
      localTriggersMap.delete(triggerId);

      syncYJSLocalToRemote();
    })
  );
}

export function shareMultipleTriggers$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    ofType(setTriggers.type, removeTriggers.type),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions = [];
      if (!state.multiplayer.multiplayer) return actions;

      const triggers = state.triggers.triggers;
      const localTriggersMap = localDoc.getMap('triggers');

      localDoc.transact(() => {
        localTriggersMap.clear();
        triggers.forEach((trigger) => {
          localTriggersMap.set(trigger.id, trigger);
        });
      });

      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function shareSingleTrigger$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void> {
  return actions$.pipe(
    ofType(
      setTrigger.type,
      addTrigger.type,
      setTriggerName.type,
      removeTaskFromReplayTrigger.type,
      addTasksToReplayTrigger.type
    ),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      const triggerId = (action.payload.triggerId || action.payload.id || action.payload) as string;

      const triggers = state.triggers.triggers;
      const trigger = triggers.find((trigger) => trigger.id === triggerId);
      const localTriggersMap = localDoc.getMap('triggers');

      localTriggersMap.set(triggerId, trigger);

      syncYJSLocalToRemote();
    })
  );
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const combineSimulationConfigurationEpics = combineEpics(
  removeTriggersAssociatedWithAFlow$,
  removeStationFromFlows$,
  shareOpcConfig$,
  shareSchedulerConfig$,
  shareRobotBatteryLevel$,
  shareRemoveStation$,
  shareMultipleStations$,
  shareSingleStation$,
  shareRemoveFlow$,
  shareMultipleFlows$,
  shareSingleFlow$,
  shareRemoveCustomStep$,
  shareMultipleCustomSteps$,
  shareSingleCustomStep$,
  shareRemoveTrigger$,
  shareMultipleTriggers$,
  shareSingleTrigger$
);
