import type { Flow } from 'flows/flows';
import { addFlowFromYJS, removeFlowFromYJS, setFlowFromYJS } from 'flows/flows';
import { isEqual } from 'lodash';
import { Tools } from 'models/tools';
import { remoteDoc } from 'multiplayer/globals';
import { useRef } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import type { YMap } from 'yjs/dist/src/internals';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export function useFlowsSubscription(): void {
  const inSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const flowsMap = remoteDoc?.getMap('flows') as YMap<Flow>;
  const initRef = useRef(true);

  const updateFlow = (): void => {
    const flows = store.getState().flows.flows;
    if (flows?.length) {
      flows.forEach((flow) => {
        if (flowsMap.has(flow.id)) return;

        store.dispatch(removeFlowFromYJS(flow.id));

        if (!initRef.current) {
          SnackbarUtils.info('A user deleted a flow', {
            autoHideDuration: 3000,
          });
        }
      });
    }

    if (!flowsMap.size) return;

    flowsMap.forEach((yjsFlow, key) => {
      const localFlowExists = flows?.find((flow) => flow.id === key);
      if (!localFlowExists) {
        store.dispatch(addFlowFromYJS(yjsFlow));

        if (!initRef.current) {
          SnackbarUtils.info('A user created a flow', {
            autoHideDuration: 3000,
          });
        }

        return;
      }

      const sameFlow = isEqual(localFlowExists, yjsFlow);
      if (sameFlow) return;

      store.dispatch(setFlowFromYJS(yjsFlow));

      if (!initRef.current) {
        SnackbarUtils.info('A user updated a flow', {
          autoHideDuration: 3000,
        });
      }
    });

    if (initRef.current) {
      initRef.current = false;
    }
  };

  useConditionalQueueSubscriptionHook({ YType: flowsMap, callback: updateFlow, condition: inSimulation });
}
