import { Table as MantineTable } from '@mantine/core';
import { PlayCircleFilled, StopCircle } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import CableIcon from '@mui/icons-material/Cable';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FunctionsIcon from '@mui/icons-material/Functions';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import TuneIcon from '@mui/icons-material/Tune';
import { Alert, Box, Button, Chip, Divider, List, ListItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { openDialogAction } from 'actions';
import {
  bringToBackAction,
  bringToFrontAction,
  changeLockStateSelectionAction,
  cleanUpExcessSegmentLengthsAction,
  clearShapesSelectionAction,
  saveCircuitToHistoryAction,
  selectMultipleCircuitShapesAction,
  transferToAnotherLayerAction,
} from 'actions/circuit';
import { saveZoneAction } from 'actions/zones';
import type { OpenContextMenuParams } from 'drawings/base.drawing';
import { copySelectionAction, getClipboardData, pasteSelectionAction } from 'drawings/copy-paste';
import EventEmitter from 'events';
import { segmentEventBus } from 'events/segmentEventBus';
import type { Station } from 'flows/flows';
import { addSeveralPositionsToStation, type StationPosition } from 'flows/flows';
import { zoneAddEdge, zoneRemoveEdge } from 'librarycircuit/utils/zones-edges';
import { useConfirm } from 'material-ui-confirm';
import { DialogTypes } from 'models';
import type { CircuitShape } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import type { SnackbarKey } from 'notistack';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { SelectedShapeData, SelectedShapesData } from 'reducers/local/state';
import { resetSelectedRobots, setAutoSelectedRobots, setManualSelectedRobots } from 'robots/robots';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { computeNbLoadsInRack, computeNbLoadsInStockZone } from 'utils/circuit/compute-nb-loads';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';
import { AlignElements } from './alignElements';
import { RotateSelectedShapes } from './rotate-selected-shapes';

/* file named right click menu but it is commonly called context menu */

const useStyles = makeStyles((theme) =>
  createStyles({
    menu: {},
    menuIcon: {
      marginRight: theme.spacing(1),
    },
    spaceRightTip: {
      marginRight: theme.spacing(4),
    },
    listItemIconOverride: {
      minWidth: '28px',
    },
    chipToTheRight: {
      float: 'right',
      marginLeft: '20px',
    },
  })
);

// event bus used for "connect all to segment"
export const eventBus = new EventEmitter();

export const RightClickMenu = (): JSX.Element => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [open, setOpen] = useState(false);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  /** in meters */
  const posCircuit = useRef<[number, number]>([0, 0]);

  const dispatch = useAppDispatch();
  const selectedShapes = useAppSelector((state) => state.local.selectedShapesData);
  const selectedRobotsIndexes = useAppSelector((state) => state.robots.selectedRobotsIndexes);
  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const stations = useAppSelector((state) => state.flows.stations);

  const selectedShapeType = useMemo(() => {
    if (selectedShapes.length === 1) {
      return selectedShapes[0].type;
    }

    return null;
  }, [selectedShapes]);

  const isOnlyTurnsSelected = useMemo(() => {
    return (
      !!selectedShapes.length &&
      selectedShapes.every((selectedShape) => {
        return selectedShape.type === ShapeTypes.TurnShape;
      })
    );
  }, [selectedShapes]);

  const atLeastASegmentIsSelected = useMemo(() => {
    return selectedShapes.some((shape) => {
      return shape.type === ShapeTypes.SegmentShape;
    });
  }, [selectedShapes]);

  const isOnlyRackAndStockZoneAndPointSelected = useMemo(() => {
    return (
      !!selectedShapes.length &&
      selectedShapes.every((selectedShape) => {
        return [ShapeTypes.RackShape, ShapeTypes.StockZoneShape, ShapeTypes.PointShape].includes(selectedShape.type);
      })
    );
  }, [selectedShapes]);

  const isOnlySegmentsSelected = useMemo(() => {
    return (
      !!selectedShapes.length &&
      selectedShapes.every((selectedShape) => {
        return selectedShape.type === ShapeTypes.SegmentShape;
      })
    );
  }, [selectedShapes]);

  const isOnlyExtendedLengthSelected = useMemo(() => {
    if (isOnlySegmentsSelected) {
      const segments = store.getState().circuit.present.segments.entities;

      return !!selectedShapes.every((selectedShape) => {
        return segments[selectedShape.id].properties.rack || segments[selectedShape.id].properties.stockLine;
      });
    }

    return false;
  }, [isOnlySegmentsSelected, selectedShapes]);

  const isOnlyMeasurersSelected = useMemo(() => {
    return (
      !!selectedShapes.length &&
      selectedShapes.every((selectedShape) => {
        return selectedShape.type === ShapeTypes.MeasurerShape;
      })
    );
  }, [selectedShapes]);

  const isOnlyMeasurersAndSegmentsSelected = useMemo(() => {
    return (
      !!selectedShapes.length &&
      selectedShapes.every((selectedShape) => {
        return [ShapeTypes.MeasurerShape, ShapeTypes.SegmentShape].includes(selectedShape.type);
      })
    );
  }, [selectedShapes]);

  const shapesCanBeAddedToAStation =
    !isOnlyMeasurersAndSegmentsSelected && !isOnlySegmentsSelected && !isOnlyMeasurersSelected;

  const atLeastARackOrStockZoneSelected = useMemo(() => {
    return selectedShapes.some((shape) => {
      return [ShapeTypes.RackShape, ShapeTypes.StockZoneShape].includes(shape.type);
    });
  }, [selectedShapes]);

  const isStockZoneSelected = useMemo(() => {
    return selectedShapes.some((shape) => {
      return shape.type === ShapeTypes.StockZoneShape;
    });
  }, [selectedShapes]);

  const isRackSelected = useMemo(() => {
    return selectedShapes.some((shape) => {
      return shape.type === ShapeTypes.RackShape;
    });
  }, [selectedShapes]);

  const layersArr = useMemo(() => {
    return Object.values(layers);
  }, [layers]);

  const [nbShapesInClipboard, setNbShapesInClipboard] = useState(0);
  const [onlyTurnInClipboard, setOnlyTurnInClipboard] = useState(false);
  const updateClipboardData = useCallback(async () => {
    const metadata = await getClipboardData();
    if (metadata.isRoadEditorData) {
      // if we don't have this property, it is not proper road editor data in the clipboard
      setNbShapesInClipboard(metadata.nbShapes - metadata.nbTurns);

      if (metadata.nbShapes === 1 && metadata.nbTurns === metadata.nbShapes) {
        setOnlyTurnInClipboard(true);
      }
    } else {
      setNbShapesInClipboard(0);
    }
  }, []);

  const handleOpen = useCallback(
    (e) => {
      const detail = e.detail as OpenContextMenuParams;

      setPosX(detail.x || 0);
      setPosY(detail.y || 0);
      posCircuit.current = [detail.xCircuit, detail.yCircuit] as [number, number];
      setOpen(true);

      updateClipboardData();
    },
    [updateClipboardData]
  );

  const handleClose = useCallback(() => {
    setOnlyTurnInClipboard(false);
    setOpen(false);
  }, []);

  const getRidOfDefaultContextMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
  }, []);

  const menuElRef = useCallback((el: HTMLDivElement | null) => {
    if (!el) return;

    el.addEventListener('open-context-menu', handleOpen);
    el.addEventListener('contextmenu', getRidOfDefaultContextMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopy = useCallback(() => {
    dispatch(copySelectionAction());

    handleClose();
  }, [dispatch, handleClose]);

  const handlePaste = useCallback(() => {
    dispatch(pasteSelectionAction(true));

    handleClose();
  }, [dispatch, handleClose]);

  const handleTurnPaste = useCallback(() => {
    dispatch(pasteSelectionAction(true));

    handleClose();
  }, [dispatch, handleClose]);

  const handleLock = useCallback(() => {
    dispatch(
      changeLockStateSelectionAction({
        newLockState: true,
        userAction: true,
      })
    );

    handleClose();
  }, [dispatch, handleClose]);
  const handleUnlock = useCallback(() => {
    dispatch(
      changeLockStateSelectionAction({
        newLockState: false,
        userAction: true,
      })
    );

    handleClose();
  }, [dispatch, handleClose]);

  const handleBringToFront = useCallback(() => {
    const selectedShape = selectedShapes[0];
    dispatch(bringToFrontAction({ id: selectedShape.id, type: selectedShape.type }));

    handleClose();
  }, [dispatch, handleClose, selectedShapes]);
  const handleBringToBack = useCallback(() => {
    const selectedShape = selectedShapes[0];
    dispatch(bringToBackAction({ id: selectedShape.id, type: selectedShape.type }));

    handleClose();
  }, [dispatch, handleClose, selectedShapes]);

  const handleEditStockZoneParameters = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.StockZoneSettings,
        payload: undefined,
      })
    );

    handleClose();
  }, [dispatch, handleClose]);

  const connectExtendedLengthsToSegment = useCallback(() => {
    handleClose();

    if (selectedShapes.length !== 1) {
      // eslint-disable-next-line no-console
      console.error('connectExtendedLengthsToSegment: expected 1 shape selected, got', selectedShapes.length, 'shapes');

      return;
    }

    const selectedShape = selectedShapes[0];

    if (selectedShape.type !== ShapeTypes.RackShape && selectedShape.type !== ShapeTypes.StockZoneShape) {
      // eslint-disable-next-line no-console
      console.error('connectExtendedLengthsToSegment: expected shape type Rack or stockzone, got', selectedShapeType);

      return;
    }

    const circuitState = store.getState().circuit.present;
    const rack = circuitState.racks.entities[selectedShape.id];
    const stockZone = circuitState.stockZones.entities[selectedShape.id];

    if (!rack && !stockZone) {
      // eslint-disable-next-line no-console
      console.error('connectExtendedLengthsToSegment: rack or stockzone not found (shape id:', selectedShape.id, ')');

      return;
    }

    const extendedLengthIds = (() => {
      if (rack) {
        return rack.properties.columns.flatMap((column) =>
          (column.extendedLengthSegments ?? []).flatMap((extendedLengthSegment) => extendedLengthSegment[0])
        );
      }

      // it's a stockzone
      const segmentsIds = circuitState.segments.ids;
      const segments = circuitState.segments.entities;
      const stockLinesIds = stockZone.properties.slots.map((slots) => slots.id);

      return segmentsIds
        .map((segmentId) => {
          const segment = segments[segmentId];
          const isStockLine = segment.properties.stockLine
            ? stockLinesIds.includes(segment.properties.stockLine)
            : false;

          return isStockLine ? segmentId : null;
        })
        .filter(isDefined);
    })();

    const isRender2Enabled = store.getState().editor.isRender2Enabled;
    const segmentsEntities = store.getState().circuit.present.segments.entities;

    if (!isRender2Enabled) {
      extendedLengthIds.forEach((extendedLengthId) => {
        const segment = segmentsEntities[extendedLengthId];
        if (!segment) return;

        const coords = segment.geometry.coordinates[1];
        const x = coords[0];
        const y = -Math.abs(coords[1]);

        const event = new CustomEvent('fakeDrag', {
          bubbles: true,
          detail: {
            x,
            y,
            forceDrag: true,
          },
        });

        document.querySelector(`[uuid="${extendedLengthId}"]`)?.dispatchEvent(event);
      });

      const event = new CustomEvent('startConnectSegments', {
        bubbles: true,
        detail: {
          segmentsIds: extendedLengthIds,
        },
      });

      const rootNode = document.querySelector('#root-node-svg');
      if (!rootNode) {
        // eslint-disable-next-line no-console
        console.error('connectExtendedLengthsToSegment: root node not found');

        return;
      }

      rootNode.dispatchEvent(event);
    } else {
      // RENDER 2
      segmentEventBus.emit('connectAllToSegmentStart', { extendedLengthIds });
    }
  }, [handleClose, selectedShapeType, selectedShapes]);

  const transferToAnotherLayerListItemRef = useRef<HTMLLIElement | null>(null);
  const [openMenuTransferAnotherLayer, setOpenMenuTransferAnotherLayer] = useState(false);

  const handleCloseMenuTransferAnotherLayer = useCallback(() => {
    setOpenMenuTransferAnotherLayer(false);
  }, []);

  const handleOpenMenuTransferAnotherLayer = useCallback(() => {
    setOpenMenuTransferAnotherLayer(true);
  }, []);

  const handleTransferToAnotherLayer = useCallback(
    (layerId: string) => {
      handleCloseMenuTransferAnotherLayer();
      handleClose();

      dispatch(
        transferToAnotherLayerAction({
          layerId,
          shapes: selectedShapes,
        })
      );
    },
    [dispatch, handleClose, handleCloseMenuTransferAnotherLayer, selectedShapes]
  );

  const computeNbLoadsSelected = useCallback(async () => {
    handleClose();

    const storeState = store.getState();
    const racks = storeState.circuit.present.racks.entities;
    const stockZones = storeState.circuit.present.stockZones.entities;

    const selectedRacks = selectedShapes
      .filter((shape) => shape.type === ShapeTypes.RackShape)
      .map((shape) => racks[shape.id]);
    const selectedStockZones = selectedShapes
      .filter((shape) => shape.type === ShapeTypes.StockZoneShape)
      .map((shape) => stockZones[shape.id]);

    const nbLoadsRacks = {
      max: 0,
    };

    const nbLoadsConveyors = {
      max: 0,
    };

    let nbLoadsStockZones = 0;

    selectedRacks.forEach((rack) => {
      const { maxMinusDeactivedLoads } = computeNbLoadsInRack(rack);

      // we separate the count of loads in conveyors and racks
      if (rack.properties.conveyor) {
        nbLoadsConveyors.max += maxMinusDeactivedLoads;
      } else {
        nbLoadsRacks.max += maxMinusDeactivedLoads;
      }
    });

    selectedStockZones.forEach((stockZone) => {
      nbLoadsStockZones += computeNbLoadsInStockZone(stockZone);
    });

    const total = nbLoadsRacks.max + nbLoadsConveyors.max + nbLoadsStockZones;

    await confirm({
      title: 'Computed data about the selected racks and stockzones',
      allowClose: false,
      content: (
        <>
          <Alert severity="info" sx={{ marginBottom: theme.spacing(2) }}>
            The maximum number of pallets does not include disabled slots. When cell template have different loads
            configuration, the only one with the maximum number of pallets is considered.
          </Alert>
          <MantineTable>
            <thead>
              <tr>
                <th>Maximum number of pallets</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>In racks</td>
                <td>{nbLoadsRacks.max}</td>
              </tr>
              <tr>
                <td>In conveyors</td>
                <td>{nbLoadsConveyors.max}</td>
              </tr>
              <tr>
                <td>In stockzones</td>
                <td>{nbLoadsStockZones}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>
                  <strong>{total}</strong>
                </td>
              </tr>
            </tbody>
          </MantineTable>
        </>
      ),
      cancellationButtonProps: {
        sx: { display: 'none' },
      },
    });
  }, [confirm, handleClose, selectedShapes]);

  const handleSelectAllConnectedTurns = useCallback(() => {
    if (isStockZoneSelected) {
      const circuitState = store.getState().circuit.present;

      // Gather all stock lines from selected stock zones
      const stockLinesIdsSet = new Set<string>();
      selectedShapes.forEach((selectedShape) => {
        const stockZone = circuitState.stockZones.entities[selectedShape.id];
        if (stockZone) {
          stockZone.properties.slots.forEach((slots) => {
            stockLinesIdsSet.add(slots.id);
          });
        }
      });

      // Find all connected segments for the stock lines
      const segments = circuitState.segments.entities;
      const connectedSegmentIds: string[] = [];
      Object.values(segments).forEach((segment) => {
        if (typeof segment.properties.stockLine === 'string') {
          if (stockLinesIdsSet.has(segment.properties.stockLine)) {
            connectedSegmentIds.push(segment.id as string);
          }
        }
      });

      // Find all connected turns for the connected segments
      const turns = circuitState.turns.entities;
      const connectedTurnIds: string[] = [];
      Object.values(turns).forEach((turn) => {
        if (connectedSegmentIds.includes(turn.properties.originId || (turn.properties.destinationId as string))) {
          connectedTurnIds.push(turn.id as string);
        }
      });

      if (connectedTurnIds.length > 0) {
        dispatch(clearShapesSelectionAction());
        dispatch(selectMultipleCircuitShapesAction(connectedTurnIds.map((id) => ({ id, type: ShapeTypes.TurnShape }))));
      }
    } else if (isRackSelected) {
      const circuitState = store.getState().circuit.present;
      selectedShapes.forEach((selectedShape) => {
        const rack = circuitState.racks.entities[selectedShape.id];
        if (rack) {
          const rackExtendedSegmentSet = new Set(
            rack.properties.columns.flatMap((column) =>
              (column.extendedLengthSegments ?? []).flatMap((extendedLengthSegment) => extendedLengthSegment[0])
            )
          );
          const turns = circuitState.turns.entities;
          const connectedTurnIds: string[] = [];
          Object.values(turns).forEach((turn) => {
            if (rackExtendedSegmentSet.has(turn.properties.originId || (turn.properties.destinationId as string))) {
              connectedTurnIds.push(turn.id as string);
            }
          });
          if (connectedTurnIds.length > 0) {
            dispatch(clearShapesSelectionAction());
            dispatch(
              selectMultipleCircuitShapesAction(connectedTurnIds.map((id) => ({ id, type: ShapeTypes.TurnShape })))
            );
          }
        }
      });
    }

    handleClose();
  }, [dispatch, handleClose, isRackSelected, isStockZoneSelected, selectedShapes]);

  const selectAllConnectedTurnsMenuItem = useCallback(
    (shapeType): JSX.Element => (
      <MenuItem onClick={handleSelectAllConnectedTurns} dense={true} key={`${shapeType}-menuitem-2`}>
        <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
          <Rotate90DegreesCwIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<>Select All Connected Turns</>}></ListItemText>
      </MenuItem>
    ),
    [classes.listItemIconOverride, handleSelectAllConnectedTurns]
  );

  const handleResetSelectedRobots = useCallback(() => {
    handleClose();

    dispatch(resetSelectedRobots());
  }, [dispatch, handleClose]);

  const handleSetManuSelectedRobots = useCallback(() => {
    handleClose();

    dispatch(setManualSelectedRobots());
  }, [dispatch, handleClose]);

  const handleSetAutoSelectedRobots = useCallback(() => {
    handleClose();

    dispatch(setAutoSelectedRobots());
  }, [dispatch, handleClose]);

  const robotSelected = selectedRobotsIndexes.length > 0;

  const addOrRemoveZoneHandler = useCallback(
    (action: 'add' | 'remove', selectedShape: SelectedShapeData) => {
      // Retrieve zone in the selected shape
      const zone = store.getState().circuit.present.zones.entities[selectedShape.id];
      if (!zone) {
        // eslint-disable-next-line no-console
        console.error('[AddOrRemoveZoneHandler]: zone not found (shape id:', selectedShape.id, ')');
        handleClose();

        return;
      }

      // Remove the zone handle
      const coords = zone.geometry.coordinates[0] as [number, number][];
      let newCoords: undefined | [number, number][];
      if (action === 'add') {
        newCoords = zoneAddEdge({
          coords,
          referencePoint: posCircuit.current.map((c) => c * 100) as [number, number],
        });
      } else {
        newCoords = zoneRemoveEdge({
          coords,
          referencePoint: posCircuit.current.map((c) => c * 100) as [number, number],
        });
      }

      if (!newCoords) {
        // eslint-disable-next-line no-console
        console.error('[AddOrRemoveZoneHandler]: unable to ', action, ' an edge', zone, newCoords, posCircuit.current);
        handleClose();

        return;
      }

      // Update the zone
      dispatch(
        saveZoneAction({
          id: selectedShape.id,
          geometry: {
            ...zone.geometry,
            coordinates: [newCoords],
          },
        })
      );

      handleClose();
    },
    [dispatch, handleClose]
  );

  const handleCleanUpExcessSegmentLengths = useCallback(() => {
    const storeState = store.getState();
    const segments = storeState.circuit.present.segments.entities;

    const selectedSegments = selectedShapes
      .filter((shape) => shape.type === ShapeTypes.SegmentShape)
      .map((shape) => segments[shape.id]);

    const shapes: CircuitShape[] = [];

    shapes.push(...Object.values(selectedSegments));

    dispatch(saveCircuitToHistoryAction());

    dispatch(
      cleanUpExcessSegmentLengthsAction({
        shapes: shapes,
      })
    );

    // We want the number of segment that are not locked but also just the segment under 3 portions (segments not concerned by the clean up action) and the segment locked
    const numberOfUnconcernedSegments = selectedSegments.filter(
      (segment) => (segment.properties.portions.length < 3 && !segment.properties.locked) || segment.properties.locked
    ).length;

    const numberOfConcernedSegments = selectedSegments.length - numberOfUnconcernedSegments;

    if (numberOfConcernedSegments <= 0) {
      SnackbarUtils.info("No segments have been cleaned because they don't have enough portions or are locked");

      return;
    }

    const concernedSeg = numberOfConcernedSegments === 1 ? 'segment has' : 'segments have';

    if (numberOfUnconcernedSegments <= 0) {
      SnackbarUtils.success(`${numberOfConcernedSegments} ${concernedSeg} been cleaned`);

      return;
    }

    const unconcernedSeg = numberOfUnconcernedSegments === 1 ? 'segment' : 'segments';

    SnackbarUtils.info(
      <List sx={{ listStyleType: 'disc', padding: 0, marginLeft: 3 }}>
        <ListItem sx={{ display: 'list-item', padding: 0 }}>
          <ListItemText>
            {numberOfConcernedSegments} {concernedSeg} been cleaned
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: 'list-item', padding: 0 }}>
          <ListItemText>
            {numberOfUnconcernedSegments} {unconcernedSeg} not affected (locked or cleaning not needed)
          </ListItemText>
        </ListItem>
      </List>
    );
  }, [dispatch, selectedShapes]);

  return (
    <>
      <Menu
        id="context-menu-editor"
        keepMounted
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: posY, left: posX }}
        disableAutoFocusItem={true}
        onClose={handleClose}
        ref={menuElRef}
        transitionDuration={100}
        MenuListProps={{
          className: classes.menu,
        }}
      >
        {robotSelected && (
          <Box component="span">
            <MenuItem onClick={handleResetSelectedRobots} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <RestartAltIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={`Reset the ${
                  selectedRobotsIndexes.length !== 1 ? selectedRobotsIndexes.length : ''
                } selected robot${selectedRobotsIndexes.length > 1 ? 's' : ''}`}
              ></ListItemText>
            </MenuItem>
            <MenuItem onClick={handleSetManuSelectedRobots} disabled={!selectedRobotsIndexes.length} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <StopCircle fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={`Switch the ${
                  selectedRobotsIndexes.length !== 1 ? selectedRobotsIndexes.length : ''
                } selected robot${selectedRobotsIndexes.length > 1 ? 's' : ''} to manual`}
              ></ListItemText>
            </MenuItem>
            <MenuItem onClick={handleSetAutoSelectedRobots} disabled={!selectedRobotsIndexes.length} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <PlayCircleFilled fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={`Switch the ${
                  selectedRobotsIndexes.length !== 1 ? selectedRobotsIndexes.length : ''
                } selected robot${selectedRobotsIndexes.length > 1 ? 's' : ''} to auto`}
              ></ListItemText>
            </MenuItem>
          </Box>
        )}

        {selectedShapes.length === 1 && selectedShapes[0].type === ShapeTypes.StockZoneShape ? (
          <Box component="span">
            <MenuItem onClick={handleEditStockZoneParameters} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <TuneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Edit Stock Zone Parameters"></ListItemText>
            </MenuItem>
            <Divider />
          </Box>
        ) : null}
        {selectedShapes.length === 1 && selectedShapes[0].type === ShapeTypes.ZoneShape ? (
          <Box component="span">
            <MenuItem
              onClick={() => {
                addOrRemoveZoneHandler('add', selectedShapes[0]);
              }}
              dense={true}
            >
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Add handle"></ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                addOrRemoveZoneHandler('remove', selectedShapes[0]);
              }}
              dense={true}
            >
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <RemoveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Remove closest handle"></ListItemText>
            </MenuItem>
            <Divider />
          </Box>
        ) : null}

        {!robotSelected && selectedShapes.length ? (
          <MenuItem onClick={handleCopy} disabled={!selectedShapes.length} dense={true}>
            <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
              <FileCopyOutlinedIcon fontSize="small"></FileCopyOutlinedIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  Copy {selectedShapes.length || ''}
                  {selectedShapes.length > 0 ? ' shape' : ''}
                  {selectedShapes.length > 1 ? 's' : ''}
                </>
              }
            ></ListItemText>
            <Chip variant="outlined" size="small" label="CTRL+C" className={classes.chipToTheRight} />
          </MenuItem>
        ) : null}
        {!robotSelected ? (
          <MenuItem onClick={handlePaste} disabled={!nbShapesInClipboard} dense={true}>
            <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
              <AssignmentReturnedOutlinedIcon fontSize="small"></AssignmentReturnedOutlinedIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  Paste {nbShapesInClipboard || ''}
                  {nbShapesInClipboard > 0 ? ' shape' : ''}
                  {nbShapesInClipboard > 1 ? 's' : ''}
                </>
              }
            ></ListItemText>
            <Chip variant="outlined" size="small" label="CTRL+V" className={classes.chipToTheRight} />
          </MenuItem>
        ) : null}
        {!!isOnlyTurnsSelected ? (
          <MenuItem onClick={handleTurnPaste} disabled={onlyTurnInClipboard === false} dense={true}>
            <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
              <AssignmentReturnedOutlinedIcon fontSize="small"></AssignmentReturnedOutlinedIcon>
            </ListItemIcon>
            <ListItemText primary="Paste turn parameters"></ListItemText>
            <Chip variant="outlined" size="small" label="CTRL+V" className={classes.chipToTheRight} />
          </MenuItem>
        ) : null}

        {!robotSelected && !isOnlyExtendedLengthSelected && selectedShapes.length ? (
          <Box component="span">
            <Divider />
            <MenuItem onClick={handleLock} disabled={!selectedShapes.length} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <LockOutlinedIcon fontSize="small"></LockOutlinedIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    Lock {selectedShapes.length || ''}
                    {selectedShapes.length > 0 ? ' shape' : ''}
                    {selectedShapes.length > 1 ? 's' : ''}
                  </>
                }
              ></ListItemText>
              <Chip variant="outlined" size="small" label="CTRL+L" className={classes.chipToTheRight} />
            </MenuItem>
            <MenuItem onClick={handleUnlock} disabled={!selectedShapes.length} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <LockOpenOutlinedIcon fontSize="small"></LockOpenOutlinedIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    Unlock {selectedShapes.length || ''}
                    {selectedShapes.length > 0 ? ' shape' : ''}
                    {selectedShapes.length > 1 ? 's' : ''}
                  </>
                }
              ></ListItemText>
              <Chip variant="outlined" size="small" label="CTRL+U" className={classes.chipToTheRight} />
            </MenuItem>
            <Box component={'span'}>
              <RotateSelectedShapes selectedShapes={selectedShapes} handleClose={handleClose} />
            </Box>
            <Divider />
          </Box>
        ) : null}

        {selectedShapes.length === 1 && (
          <Box component="span">
            <MenuItem onClick={handleBringToFront} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <FlipToFrontIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={<>Bring to front</>}></ListItemText>
              <Chip variant="outlined" size="small" label="f" className={classes.chipToTheRight} />
            </MenuItem>
            <MenuItem onClick={handleBringToBack} dense={true}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <FlipToBackIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={<>Bring to back</>}></ListItemText>
              <Chip variant="outlined" size="small" label="b" className={classes.chipToTheRight} />
            </MenuItem>
            <Divider />
          </Box>
        )}

        {isOnlyRackAndStockZoneAndPointSelected && selectedShapes.length > 1 && (
          <Box component="span">
            <AlignElements selectedShapes={selectedShapes} handleClose={handleClose} />
            <Divider />
          </Box>
        )}

        {selectedShapeType === ShapeTypes.RackShape && [
          <MenuItem onClick={connectExtendedLengthsToSegment} dense={true} key="rackshape-menuitem-1">
            <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
              <CableIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<>Connect all to a segment</>}></ListItemText>
          </MenuItem>,
          selectAllConnectedTurnsMenuItem ? selectAllConnectedTurnsMenuItem('rack') : null,
        ]}
        {selectedShapeType === ShapeTypes.StockZoneShape && [
          <MenuItem onClick={connectExtendedLengthsToSegment} dense={true} key="stockzoneshape-menuitem-2">
            <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
              <CableIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<>Connect all to a segment</>}></ListItemText>
          </MenuItem>,
          selectAllConnectedTurnsMenuItem ? selectAllConnectedTurnsMenuItem('stockzone') : null,
        ]}
        {atLeastASegmentIsSelected && [
          <MenuItem onClick={handleCleanUpExcessSegmentLengths} dense={true} key="segment-menuitem-3">
            <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
              <CleaningServicesIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<>Clean up excess segment lengths</>}></ListItemText>
          </MenuItem>,
        ]}
        {!!(selectedShapes && selectedShapes.length) && (
          <Box component="div" onMouseLeave={handleCloseMenuTransferAnotherLayer}>
            <MenuItem
              onMouseEnter={handleOpenMenuTransferAnotherLayer}
              dense={true}
              ref={transferToAnotherLayerListItemRef}
            >
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <MoveUpIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={<>Transfer to another layer</>}></ListItemText>
              <ArrowRightIcon />
            </MenuItem>
            <Menu
              anchorEl={transferToAnotherLayerListItemRef.current}
              open={openMenuTransferAnotherLayer}
              onClose={handleCloseMenuTransferAnotherLayer}
              MenuListProps={{
                className: classes.menu,
                onMouseLeave: handleCloseMenuTransferAnotherLayer,
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                maxHeight: '50vh',
              }}
              hideBackdrop
            >
              {layersArr.map((layer) => {
                return (
                  <MenuItem
                    key={layer.id}
                    onClick={() => {
                      handleTransferToAnotherLayer(layer.id);
                    }}
                    dense={true}
                    sx={{
                      boxShadow: `inset 3px 0px 0px 0px ${layer.color}`,
                    }}
                  >
                    {layer.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        )}
        {!!(selectedShapes && selectedShapes.length && shapesCanBeAddedToAStation) && (
          <AddToStation stations={stations} selectedShapes={selectedShapes} handleClose={handleClose} />
        )}
        {!!atLeastARackOrStockZoneSelected && (
          <MenuItem onClick={computeNbLoadsSelected} dense={true}>
            <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
              <FunctionsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<>Compute number of pallets</>}></ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export interface AddToStationProps {
  stations: Station[];
  selectedShapes: SelectedShapesData;
  handleClose: () => void;
}

export function AddToStation({ stations, selectedShapes, handleClose }: AddToStationProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const addToAStationListItemRef = useRef<HTMLLIElement | null>(null);
  const [openMenuAddToAStation, setOpenMenuAddToAStation] = useState(false);

  const handleCloseMenuAddToAStation = useCallback(() => {
    setOpenMenuAddToAStation(false);
  }, []);

  const handleClickAddToAStation = useCallback(() => {
    setOpenMenuAddToAStation(true);
  }, []);

  const snackbarAddToStation = useRef<SnackbarKey | undefined>(undefined);

  const handleAddToAStationMultipleShapeTypes = useCallback(
    (station: Station, stationPositions: StationPosition[], type: 'points' | 'racks/stockZones') => {
      if (type === 'points') {
        const filteredStationPosition = stationPositions.filter(
          (stationPosition) => stationPosition.type === ShapeTypes.PointShape
        );

        dispatch(
          addSeveralPositionsToStation({
            stationId: station.id,
            positions: filteredStationPosition,
          })
        );

        const message =
          filteredStationPosition.length > 1
            ? `Every selected points have been added to ${station.name}`
            : `The selected point has been added to ${station.name}`;

        SnackbarUtils.closeSnackbar(snackbarAddToStation.current);
        snackbarAddToStation.current = undefined;
        SnackbarUtils.success(message);
      } else {
        const filteredStationPosition = stationPositions.filter(
          (stationPosition) => stationPosition.type !== ShapeTypes.PointShape
        );

        dispatch(
          addSeveralPositionsToStation({
            stationId: station.id,
            positions: filteredStationPosition,
          })
        );

        const message =
          filteredStationPosition.length > 1
            ? `Every selected racks and stockZones have been added to ${station.name}`
            : `The selected rack or stockZone has been added to ${station.name}`;

        SnackbarUtils.closeSnackbar(snackbarAddToStation.current);
        snackbarAddToStation.current = undefined;
        SnackbarUtils.success(message);
      }
    },
    [dispatch]
  );

  const handleAddToAStation = useCallback(
    (stationId: string) => {
      handleCloseMenuAddToAStation();
      handleClose();

      const station = stations.find((station) => station.id === stationId);

      const stationPositions: StationPosition[] = [];
      const stationPositionsWithoutDuplicates: StationPosition[] = [];

      selectedShapes.forEach((shape) => {
        const shapeTypeMapping: {
          [key: string]: ShapeTypes.PointShape | ShapeTypes.RackShape | ShapeTypes.StockZoneShape | undefined;
        } = {
          POINT: ShapeTypes.PointShape,
          RACK: ShapeTypes.RackShape,
          STOCK: ShapeTypes.StockZoneShape,
        } as const;

        const type = shapeTypeMapping[shape.type];

        if (!type) {
          return;
        }

        const position: StationPosition = {
          id: shape.id,
          type: type,
        };

        const isPositionAlreadyInStation = !!station?.positions.find(
          (stationPosition) => stationPosition.id === position.id
        );

        if (!isPositionAlreadyInStation) {
          stationPositionsWithoutDuplicates.push(position);
        }

        stationPositions.push(position);
      });

      const isPointsStation = !!station?.positions.every((position) => {
        return position.type === ShapeTypes.PointShape;
      });

      const isOnlyRacksOrStockZones = !!stationPositions.every((stationPosition) => {
        return stationPosition.type !== ShapeTypes.PointShape;
      });

      const isOnlyPoints = !!stationPositions.every((stationPosition) => {
        return stationPosition.type === ShapeTypes.PointShape;
      });

      const isMultipleShapeTypesSelection = !isOnlyPoints && !isOnlyRacksOrStockZones;

      if (station && station?.positions.length > 0) {
        //if only points are selected and the chosen station is a points station, add the new points to the station. Same for racks/stockZones.
        if ((isOnlyPoints && isPointsStation) || (isOnlyRacksOrStockZones && !isPointsStation)) {
          dispatch(
            addSeveralPositionsToStation({
              stationId,
              positions: stationPositions,
            })
          );

          const message =
            stationPositionsWithoutDuplicates.length === 0
              ? 'Selected shape(s) already added in the station'
              : stationPositionsWithoutDuplicates.length > 1
                ? `Every selected ${isOnlyPoints ? 'points' : 'racks and stock zones'} have been added to ${
                    station.name
                  }`
                : `The selected ${isOnlyPoints ? 'point' : 'rack or stock zone'} has been added to ${station.name}`;

          stationPositionsWithoutDuplicates.length === 0 ? SnackbarUtils.info(message) : SnackbarUtils.success(message);
        }

        //if multiple shape types are selected and the chosen station is a points station, only add the points to the station.
        if (isPointsStation && isMultipleShapeTypesSelection) {
          const filteredStationPosition = stationPositionsWithoutDuplicates.filter(
            (stationPosition) => stationPosition.type === ShapeTypes.PointShape
          );

          dispatch(
            addSeveralPositionsToStation({
              stationId,
              positions: filteredStationPosition,
            })
          );

          const message =
            filteredStationPosition.length === 0
              ? 'Selected shape(s) already added in the station'
              : stationPositionsWithoutDuplicates.length > 1
                ? `Only the selected points have been added to ${station.name}`
                : `Only the selected point has been added to ${station.name}`;

          filteredStationPosition.length === 0 ? SnackbarUtils.info(message) : SnackbarUtils.success(message);

          const warningMessage =
            stationPositions.length === 0
              ? null
              : stationPositions.filter((stationPosition) => stationPosition.type !== ShapeTypes.PointShape).length > 1
                ? `The selected racks and stock zones can not be added to ${station.name} because the station include points`
                : `The selected rack or stock zone can not be added to ${station.name} because the station include points`;

          if (warningMessage) {
            SnackbarUtils.warning(warningMessage);
          }
        }

        //if multiple shape types are selected and the chosen station is a racks/stockZones station, only add the racks/stockZones to the station.
        if (!isPointsStation && isMultipleShapeTypesSelection) {
          const filteredStationPosition = stationPositionsWithoutDuplicates.filter(
            (stationPosition) =>
              stationPosition.type === ShapeTypes.RackShape || stationPosition.type === ShapeTypes.StockZoneShape
          );

          dispatch(
            addSeveralPositionsToStation({
              stationId,
              positions: filteredStationPosition,
            })
          );

          const message =
            filteredStationPosition.length === 0
              ? 'Selected shape(s) already added in the station'
              : stationPositionsWithoutDuplicates.length > 1
                ? `Only the selected racks and stockZones have been added to ${station.name}`
                : `Only the selected rack or stockZone has been added to ${station.name}`;

          filteredStationPosition.length === 0 ? SnackbarUtils.info(message) : SnackbarUtils.success(message);

          const warningMessage =
            stationPositions.length === 0
              ? null
              : stationPositions.filter((station) => station.type === ShapeTypes.PointShape).length > 1
                ? `The selected points can not be added to ${station.name} because the station include racks and stock zones`
                : `The selected point can not be added to ${station.name} because the station include racks and stock zones`;

          if (warningMessage) {
            SnackbarUtils.warning(warningMessage);
          }
        }

        if (isPointsStation && isOnlyRacksOrStockZones) {
          const message =
            stationPositions.length === 0
              ? null
              : stationPositions.length > 1
                ? `The selected racks and stock zones can not be added to ${station.name} because the station include points`
                : `The selected rack or stock zone can not be added to ${station.name} because the station include points`;

          if (message) {
            SnackbarUtils.warning(message);
          }
        }

        if (!isPointsStation && isOnlyPoints) {
          const message =
            stationPositions.length === 0
              ? null
              : stationPositions.length > 1
                ? `The selected points can not be added to ${station.name} because the station include racks and stock zones`
                : `The selected point can not be added to ${station.name} because the station include racks and stock zones`;

          if (message) {
            SnackbarUtils.warning(message);
          }
        }
      }

      if (station && station.positions.length === 0) {
        //if only points are selected and the chosen station is empty, add the new points to the station. Same for racks/stockZones.
        if (isOnlyPoints || isOnlyRacksOrStockZones) {
          dispatch(
            addSeveralPositionsToStation({
              stationId,
              positions: stationPositions,
            })
          );

          const message =
            stationPositions.length > 1
              ? `Every selected ${isOnlyPoints ? 'points' : 'racks and stock zones'} have been added to ${station.name}`
              : `The selected ${isOnlyPoints ? 'point' : 'rack or stock zone'} has been added to ${station.name}`;

          SnackbarUtils.success(message);
        }

        //if points and racks/stockZones are selected and the chosen station is empty,
        //display a snackBar to let the user choose if he want to add only the racks/stockZones or only the points.
        if (isMultipleShapeTypesSelection) {
          snackbarAddToStation.current = SnackbarUtils.info(
            <Box component="span">
              {`A station can not have points and stock zones/racks at the same time.`}
              <Button
                color="primary"
                variant="contained"
                sx={{ marginLeft: 2 }}
                onClick={() => {
                  handleAddToAStationMultipleShapeTypes(station, stationPositions, 'points');
                }}
              >
                Only add selected points
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{ marginLeft: 2 }}
                onClick={() => {
                  handleAddToAStationMultipleShapeTypes(station, stationPositions, 'racks/stockZones');
                }}
              >
                Only add selected racks/stock zones
              </Button>
            </Box>
          );
        }
      }
    },
    [
      dispatch,
      handleAddToAStationMultipleShapeTypes,
      handleClose,
      handleCloseMenuAddToAStation,
      selectedShapes,
      stations,
    ]
  );

  const handleOpenCreateNewStationDialog = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.CreateNewStationFromRightClick,
        payload: { stations: stations, selectedShapes: selectedShapes },
      })
    );

    handleCloseMenuAddToAStation();
    handleClose();
  }, [dispatch, handleClose, handleCloseMenuAddToAStation, selectedShapes, stations]);

  return (
    <Box component="div" onMouseLeave={handleCloseMenuAddToAStation}>
      <MenuItem onMouseEnter={handleClickAddToAStation} dense={true} ref={addToAStationListItemRef}>
        <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
          <AddLocationIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<>Add to a station</>}></ListItemText>
        <ArrowRightIcon />
      </MenuItem>
      {!!(addToAStationListItemRef.current && openMenuAddToAStation) && (
        <Menu
          anchorEl={addToAStationListItemRef.current}
          open={openMenuAddToAStation}
          onClose={handleCloseMenuAddToAStation}
          MenuListProps={{
            className: classes.menu,
            onMouseLeave: handleCloseMenuAddToAStation,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            maxHeight: '50vh',
          }}
          hideBackdrop
        >
          <Box
            component={'div'}
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 2,
            }}
          >
            <MenuItem dense={true} onClick={handleOpenCreateNewStationDialog}>
              <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
                <AddCircleOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={<> Add to a new station</>} sx={{ marginRight: 1 }}></ListItemText>
            </MenuItem>
            <Divider />
          </Box>

          {stations.map((station) => {
            return (
              <MenuItem
                key={station.id}
                onClick={() => {
                  handleAddToAStation(station.id);
                }}
                dense={true}
              >
                {station.name}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Box>
  );
}
