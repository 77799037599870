import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { updateActionsAction } from 'actions/actionsSlice';
import type { ActionNode } from 'models/action';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { FolderNodeInMenu } from './helpers';
import { buildFolderTree, moveActionById } from './helpers';
import { FolderMenus } from './nestet-menu-items';

interface MoveDialogProps {
  open: boolean;
  onClose: () => void;
  action: ActionNode;
  allActions: ActionNode[];
}

/**
 * This dialog component allows the user to move an action or a folder to a different folder or main root.
 */

export const ActionsFolderMoveDialog: React.FC<MoveDialogProps> = ({ open, onClose, action, allActions }) => {
  const dispatch = useDispatch();
  const folderTree = useMemo(() => buildFolderTree(allActions), [allActions]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [selectedFolderLabel, setSelectedFolderLabel] = useState<string>('');

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleFolderClick = (folderId: string): void => {
    const findLabel = (tree: FolderNodeInMenu[]): string | null => {
      for (const node of tree) {
        if (node.id === folderId) return node.displayName;
        const sub = findLabel(node.children);
        if (sub) return sub;
      }

      return null;
    };

    const label = findLabel(folderTree) || folderId;
    setSelectedFolderId(folderId);
    setSelectedFolderLabel(label);
    handleCloseMenu();
  };

  const handleCancel = (): void => {
    setSelectedFolderId('');
    setSelectedFolderLabel('');
    onClose();
  };

  const handleMove = (): void => {
    const updatedTree = moveActionById(allActions, action.id, selectedFolderId || '');
    dispatch(updateActionsAction(updatedTree));
    handleCancel();
  };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="xs">
      <DialogTitle>Move “{action.displayName}” to...</DialogTitle>
      <DialogContent>
        <Box component="div" mt={2}>
          {/* Styled button acting as a Select with dynamic icon */}
          <Button
            variant="outlined"
            onClick={handleOpenMenu}
            fullWidth
            endIcon={anchorEl ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          >
            {selectedFolderLabel || 'Select Folder'}
          </Button>

          {/* Nested folder selection menu */}
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} disableAutoFocusItem>
            <FolderMenus folders={folderTree} onFolderClick={handleFolderClick} parentMenuOpen={Boolean(anchorEl)} />

            {/* Move to Root Option */}
            <MenuItem
              onClick={() => {
                setSelectedFolderId('');
                setSelectedFolderLabel('(Root)');
                handleCloseMenu();
              }}
            >
              Move to Root
            </MenuItem>
          </Menu>

          {/* Display selected folder */}
          {selectedFolderLabel && (
            <Typography variant="body1" mt={2}>
              {`Selected: ${selectedFolderLabel}`}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="error">
          Cancel
        </Button>
        <Button onClick={handleMove} variant="contained" color="primary" disabled={selectedFolderId == null}>
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};
