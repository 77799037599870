import { remoteDoc } from 'multiplayer/globals';
import { setDescriptionFromYJS } from 'project/project';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import { PreferencesService } from 'utils/preferences';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useCircuitDescriptionSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const circuitDescriptionText = remoteDoc?.getText('circuitDescription');

  const updateCircuitDescription = (): void => {
    if (!circuitDescriptionText) return;

    const circuitDescription = circuitDescriptionText.toString();

    store.dispatch(setDescriptionFromYJS(circuitDescription));

    if (!PreferencesService.arePreferencesFullyLoaded()) return;

    if (store.getState().dialog.open && store.getState().dialog.type === 'project-settings') {
      SnackbarUtils.info('A user updated the circuit description', {
        autoHideDuration: 3000,
      });
    }
  };

  useConditionalQueueSubscriptionHook({
    YType: circuitDescriptionText,
    callback: updateCircuitDescription,
    condition: notCircuitEditionMode,
  });
};
