import EventNoteIcon from '@mui/icons-material/EventNote';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@mui/material';
import { openDialogAction } from 'actions';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import { DialogTypes } from 'models';
import { Tools } from 'models/tools';
import { useCallback, useMemo } from 'react';
import { setSchedulerConfigurationName } from 'simulation/simulation';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { getAvailableSchedulerConfig } from './get-available-scheduler-config';

export function SchedulerConfigToolbox(): JSX.Element {
  const dispatch = useAppDispatch();
  const availableConfigs = useMemo(() => getAvailableSchedulerConfig(), []);

  const schedulerConfigurationName = useAppSelector((state) => state.simulation.schedulerConfigurationName);

  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.SchedulerConfiguration)?.icon;
  }, []);

  const handleChangeConfig = useCallback(
    (newConfigName: string) => {
      if (newConfigName === schedulerConfigurationName) return;

      dispatch(setSchedulerConfigurationName(newConfigName));
    },
    [dispatch, schedulerConfigurationName]
  );

  const handleOpenUpdateSchedulerConfig = useCallback(() => {
    dispatch(openDialogAction({ type: DialogTypes.UpdateSchedulerConfig, payload: {} }));
  }, [dispatch]);

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '400px',
        overflowY: 'auto',
        maxHeight: '95%',
      }}
    >
      <CardHeader
        title="Scheduler Configuration"
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>

      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <List component="nav" dense>
          {availableConfigs.map((config) => (
            <ListItemButton
              key={config.name}
              selected={schedulerConfigurationName === config.name}
              onClick={() => handleChangeConfig(config.name)}
              sx={{
                textTransform: 'capitalize',
              }}
            >
              <ListItemIcon>
                <Radio checked={schedulerConfigurationName === config.name} />
              </ListItemIcon>
              <ListItemText primary={config.name} secondary={config.description} />
            </ListItemButton>
          ))}
        </List>
      </CardContent>
      {schedulerConfigurationName === 'custom' && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" endIcon={<EventNoteIcon />} onClick={handleOpenUpdateSchedulerConfig}>
            Update Scheduler Config
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
