/**
 * 0. The text
 */
export const zNames = 0.001;

/**
 * 1. The grid
 */
export const zAxis = -0.001;
export const zLabel = -0.001;

/**
 * 2. The robots
 */
export const zItineraries = 0.004;
export const zTraffic = 0.0041;
export const zNextItinerary = 0.0042;
export const zTruckPatterns = 0.0043;
export const zRobots = 0.005;

/**
 * 3. The shapes
 */
export const zDefaultShapes = 0.0001;

export const zGabarits = -3 * zDefaultShapes;
export const zDoors = -3 * zDefaultShapes;
export const zZones = -3 * zDefaultShapes;
export const zRacks = -2 * zDefaultShapes;
export const zSlots = -2 * zDefaultShapes;
export const zStockZones = -2 * zDefaultShapes;
export const zTurns = -1.1 * zDefaultShapes;
export const zSelectionHelper = -1 * zDefaultShapes;

export const zSegments = 1 * zDefaultShapes;
export const zMeasurer = 1 * zDefaultShapes;

export const zArrows = 3 * zDefaultShapes;

export const zPoints = 5 * zDefaultShapes;
export const zDevices = 5 * zDefaultShapes;
export const zNotes = 5 * zDefaultShapes;

export const zDragRefTurn = 10 * zDefaultShapes;

/**
 * 4. The lidar map
 */
export const zLidarMap = -0.001;

/**
 * The layout image map
 */
export const zLayoutMap = zLidarMap * 2;

/**
 * The order of rendering
 */

const renderOrderFactor = 1000000;

export const simulationRenderOrder = 5 * renderOrderFactor;

export const itinerariesRenderOrder = simulationRenderOrder + 1;
export const trafficRenderOrder = simulationRenderOrder + 2;
export const nextItineraryRenderOrder = simulationRenderOrder + 3;
export const truckPatternsRenderOrder = simulationRenderOrder + 4;
export const robotsRenderOrder = simulationRenderOrder + 5;

export const textRenderOrder = 4 * renderOrderFactor;

export const smallShapesRenderOrder = 3 * renderOrderFactor;
export const mediumShapesRenderOrder = 2 * renderOrderFactor;
export const bigShapesRenderOrder = 1 * renderOrderFactor;
export const layoutImagesRenderOrder = -1;
export const lidarMapRenderOrder = -2;
