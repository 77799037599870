import { Button, Checkbox, Divider } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useRobotsEmulation } from 'components/toolboxes/simulation/use-set-robots';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow, Station } from 'flows/flows';
import { removeFlow, setFlow } from 'flows/flows';
import { isEqual } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import type { BufferTrigger, IntervalTrigger, Trigger } from 'models/simulation';
import { useCallback, useMemo, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { addTrigger, setTrigger } from 'simulation/triggers';
import store, { useAppDispatch, useAppSelector } from 'store';
import { getNextFreeId } from 'utils/circuit/next-free-id';
import { integerInputIfNeeded } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { maximumFlowNameLength } from './flows';
import { ListStationsOfFlow } from './list-stations-flow';
import { MaximumTaskTimeTextfield } from './maximum-task-time-textfield';
import { ObjectiveTextfield } from './objective-textfield';
import { PalletsPerTaskTextfield } from './pallets-per-task-textfield';
import { RenameFlow } from './rename-flow';

const marginFactor = 1.2;

interface FlowFormProps {
  flows: Flow[];
  mode: 'creation' | 'edition';
  setIsFlowCreation: (value: boolean) => void;
  setSelectedTab: (tabToSelect: string) => void;
}
export function FlowForm(props: FlowFormProps): JSX.Element {
  const { flows, mode, setIsFlowCreation, setSelectedTab } = props;
  const stations: (Station | undefined)[] = useAppSelector((state) => state.flows.stations);
  const triggers = useAppSelector((state) => state.triggers.triggers);
  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);

  const flow = useMemo(() => {
    return flows.find((flow) => flow.id === selectedFlowId);
  }, [flows, selectedFlowId]);

  const formerState = useMemo(
    () => structuredClone(store.getState().flows.flows.find((f) => f.id === selectedFlowId)),
    [selectedFlowId]
  );
  const confirm = useConfirm();

  const dispatch = useAppDispatch();

  const [isAutomaticTriggerEnabled, setIsAutomaticTriggerEnabled] = useState(true);
  const [robots] = useRobotsEmulation();

  const stationsOfSelectedFlow = useMemo(() => {
    const selectedStations: Station[] = [];

    if (flow) {
      for (let i = 0; i < flow.stations.length; i++) {
        stations.forEach((st) => {
          if (st && flow.stations[i].id.includes(st.id)) {
            selectedStations.push(st);
          }
        });
      }
    }

    return [...new Set(selectedStations)];
  }, [flow, stations]);

  const isInvalidName = useCallback(
    (newName: string | undefined) => {
      const nameWihtoutBlank = newName?.trimStart().trimEnd();
      if (nameWihtoutBlank?.length && nameWihtoutBlank.length > maximumFlowNameLength) {
        return true;
      }

      // Check if the name contains the forbidden character '/':  Name with '/' break the simulation
      if (newName?.includes('/')) {
        return true;
      }

      const existingFlow = flows.find((f) => f.name === nameWihtoutBlank && f.id !== selectedFlowId);
      if (!nameWihtoutBlank || existingFlow) {
        return true;
      }

      return false;
    },
    [selectedFlowId, flows]
  );

  // Add Flow
  const handleCreateFlow = useCallback(() => {
    // Show success notification
    SnackbarUtils.success(`Flow "${flow?.name}" ${isAutomaticTriggerEnabled ? 'and its trigger were' : 'was'} created`);

    if (isAutomaticTriggerEnabled) {
      // Generate trigger ID
      const id = getNextFreeId().toString();
      const triggerBase = {
        id,
        name: `${flow?.name} Trigger`,
        flowId: flow?.id || '',
        priority: 1,
        enabled: true,
        linked: true,
      };

      // Determine trigger type and properties
      const trigger: Trigger = flow?.objective
        ? {
            ...triggerBase,
            type: 'interval',
            interval: Number(integerInputIfNeeded(3600 / (flow.objective * marginFactor), 1)),
            count: 1,
          }
        : {
            ...triggerBase,
            type: 'buffer',
            size: robots.length + 1,
          };

      dispatch(addTrigger(trigger));
    }

    setIsFlowCreation(false);
  }, [dispatch, flow, isAutomaticTriggerEnabled, robots.length, setIsFlowCreation]);

  const handleCancelAdding = useCallback(() => {
    if (flow) dispatch(removeFlow(flow.id));

    setIsFlowCreation(false);
  }, [dispatch, flow, setIsFlowCreation]);

  // Edit Flow
  const handleSaveFlow = useCallback(() => {
    if (!flow) return;

    SnackbarUtils.success(`Flow "${flow?.name}" ${isAutomaticTriggerEnabled ? 'and its trigger were' : 'was'} edited`);

    const triggerLinked = triggers.find(
      (trigger) =>
        (trigger.type === 'interval' || trigger.type === 'buffer') && trigger.flowId === flow?.id && trigger.linked
    );

    if (triggerLinked) {
      const updateTrigger = (updatedTrigger: Trigger): void => dispatch(setTrigger(updatedTrigger));

      if (flow?.objective) {
        const newInterval = Number(integerInputIfNeeded(3600 / (flow.objective * marginFactor), 1));
        if ((triggerLinked as IntervalTrigger).interval !== newInterval || triggerLinked.type !== 'interval') {
          updateTrigger({ ...triggerLinked, type: 'interval', interval: newInterval, count: 1, flowId: flow.id });
        }
      } else {
        const newSize = robots.length + 1;
        if ((triggerLinked as BufferTrigger).size !== newSize || triggerLinked.type !== 'buffer') {
          updateTrigger({ ...triggerLinked, type: 'buffer', size: newSize, flowId: flow.id });
        }
      }
    }

    setIsFlowCreation(false);
  }, [dispatch, flow, isAutomaticTriggerEnabled, robots.length, setIsFlowCreation, triggers]);

  const handleCancelEdition = useCallback(() => {
    if (!flow) return;

    const hasChanges = isEqual(formerState, flow);

    if (!hasChanges) {
      confirm({
        title: `Keep ${flow.name} unchanged?`,
        confirmationText: 'Keep it unchanged',
        cancellationText: 'Let me edit it',
        cancellationButtonProps: {
          color: 'error',
          autoFocus: true,
        },
      })
        .then(() => {
          if (formerState) dispatch(setFlow(formerState));
          setIsFlowCreation(false);
        })
        .catch(() => undefined);
    } else {
      setIsFlowCreation(false);
    }
  }, [confirm, dispatch, flow, formerState, setIsFlowCreation]);

  if (!flow) return <></>;

  return (
    <div>
      {mode === 'creation' && (
        <>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
            <Box component="span">
              Create associated trigger
              <HelpIconTooltip
                sx={{ fontSize: '1.15rem' }}
                title="When enabled, a locked trigger is automatically created for this flow. This trigger is protected and cannot be manually modified, ensuring its settings remain fixed and consistent."
              />
            </Box>
            <Checkbox
              checked={isAutomaticTriggerEnabled}
              onChange={(event) => setIsAutomaticTriggerEnabled(event.target.checked)}
            />
          </Stack>
          <Divider sx={{ mt: 0.5, mb: 0.5 }} />
        </>
      )}
      <Stack direction={'row'} spacing={1} sx={{ mt: 3 }}>
        <RenameFlow
          flow={flow}
          formerState={mode === 'edition' ? formerState : undefined}
          isInvalidName={isInvalidName}
        />
      </Stack>

      <>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            marginTop: theme.spacing(2),
          }}
        >
          <ObjectiveTextfield selectedFlow={flow} />

          <MaximumTaskTimeTextfield selectedFlow={flow} />
        </Stack>
        <Box
          component={'div'}
          sx={{
            marginTop: theme.spacing(2),
          }}
        >
          <PalletsPerTaskTextfield selectedFlow={flow} />
        </Box>
      </>

      <Border>
        <ListStationsOfFlow
          canEditStation={true}
          key={flow.id}
          stations={stationsOfSelectedFlow ?? []}
          flow={flow}
          setSelectedTab={setSelectedTab}
        />
      </Border>
      <Divider variant="middle" sx={{ mt: 2, mb: 0.5 }} />
      <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button variant="text" color="error" onClick={mode === 'creation' ? handleCancelAdding : handleCancelEdition}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={mode === 'creation' ? handleCreateFlow : handleSaveFlow}
          disabled={isInvalidName(flow.name)}
        >
          {mode === 'creation' ? 'Create' : 'Save'}
        </Button>
      </Stack>
    </div>
  );
}
