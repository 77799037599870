import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Card, CardContent, IconButton, Tooltip } from '@mui/material';
import type { SxProps, Theme } from '@mui/system';
import { Box, Stack } from '@mui/system';
import { animated, useSpring } from '@react-spring/web';
import { changeLockStateAction, deleteShapeAction, saveCircuitToHistoryAction } from 'actions/circuit';
import { copySelectionAction, pasteSelectionAction } from 'drawings/copy-paste';
import { capitalize } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import type { CircuitShape } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { useCallback, useMemo, useState } from 'react';
import store, { useAppDispatch } from 'store';
import { checkIsExtendedLength } from 'utils/circuit/is-extended-length';
import { isCircuitRack } from 'utils/circuit/shape-guards';
import { theme } from 'utils/mui-theme';
import { rackDeletionConfirmText } from 'utils/texts';

interface PropertiesComponentProps {
  shapeId: string;
  shape: CircuitShape;
  shapeType: ShapeTypes;
  children: React.ReactNode;
  isDebug?: boolean;
  sx?: SxProps<Theme> | undefined;
  /** disable the ability for the user to click on the delete shape button */
  disableDelete?: boolean;
  /** disable the ability for the user to click on the copy shape button */
  disableCopy?: boolean;
  /** Title to be displayed, default to the shape type */
  title?: string;
}
export function PropertiesComponent({
  shapeId,
  shape,
  shapeType,
  children,
  isDebug = true,
  sx,
  disableDelete = false,
  disableCopy = false,
  title,
}: PropertiesComponentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const locked = shape && shape.properties && 'locked' in shape.properties ? !!shape.properties.locked : false;
  const isExtendedLength = checkIsExtendedLength(shape);
  const [pasteParametersEnabled, setPasteParametersEnabled] = useState(localStorage.turnInTheClipBoard === 'true');

  const displayShapeType = useMemo(() => {
    return capitalize(shapeType);
  }, [shapeType]);

  const handleDeleteShape = useCallback(() => {
    const action = deleteShapeAction({ shapeId, shapeType });

    if (shapeType === ShapeTypes.RackShape) {
      confirm({
        title: rackDeletionConfirmText,
        allowClose: false,
        confirmationButtonProps: { autoFocus: true },
      }).then(() => {
        dispatch(saveCircuitToHistoryAction());
        dispatch(action);
      });
    } else {
      dispatch(saveCircuitToHistoryAction());
      dispatch(action);
    }
  }, [confirm, dispatch, shapeId, shapeType]);

  const [springStyleCopyButton, animateStyleCopyButton] = useSpring(
    {
      from: { rotateZ: 0 },
    },
    []
  );
  const [springStyleLockButton, animateStyleLockButton] = useSpring(
    {
      from: { rotateY: 0 },
      config: { duration: 150 },
    },
    []
  );

  const [springStylePasteButton, animateStylePasteButton] = useSpring(
    {
      from: { rotateY: 0 },
    },
    []
  );

  const onCopyHandler = useCallback(
    (event) => {
      animateStyleCopyButton.start({
        to: { rotateZ: 360 },
        reset: true,
      });

      dispatch(copySelectionAction());

      if (shapeType === ShapeTypes.TurnShape) {
        setTimeout(() => {
          setPasteParametersEnabled(localStorage.turnInTheClipBoard === 'true');
        }, 200);
      }
    },
    [animateStyleCopyButton, dispatch, shapeType]
  );

  const onLockHandler = useCallback(() => {
    const newLockedValue = !locked;

    animateStyleLockButton.start({
      to: { rotateY: 90 },
      onRest: () => {
        animateStyleLockButton.start({
          to: { rotateY: 0 },
        });
      },
    });

    if (isCircuitRack(shape)) {
      if (shape.properties.gravityRack) {
        const gravityRackId = shape.properties.gravityRack.id;

        const racksEntities = store.getState().circuit.present.racks.entities;
        const racksIds = store.getState().circuit.present.racks.ids;

        racksIds.forEach((id) => {
          const rack = racksEntities[id];

          if (rack.id && rack.properties.gravityRack && rack.properties.gravityRack.id === gravityRackId) {
            dispatch(
              changeLockStateAction({
                idShape: rack.id.toString(),
                newLockState: newLockedValue,
                shapeType: rack.properties.type,
                userAction: true,
              })
            );
          }
        });

        const zonesEntities = store.getState().circuit.present.zones.entities;
        const zonesIds = store.getState().circuit.present.zones.ids;

        zonesIds.forEach((id) => {
          const zone = zonesEntities[id];

          if (zone.id && zone.properties.gravityRack && zone.properties.gravityRack === gravityRackId) {
            dispatch(
              changeLockStateAction({
                idShape: zone.id.toString(),
                newLockState: newLockedValue,
                shapeType: zone.properties.type,
                userAction: true,
              })
            );
          }
        });
      }
    }

    dispatch(
      changeLockStateAction({
        idShape: shapeId,
        newLockState: newLockedValue,
        shapeType,
        userAction: true,
      })
    );
  }, [animateStyleLockButton, dispatch, locked, shape, shapeId, shapeType]);

  const onPasteHandler = useCallback(
    (event) => {
      animateStylePasteButton.start({
        to: { rotateY: 360 },
        reset: true,
      });

      dispatch(pasteSelectionAction(true));
    },
    [animateStylePasteButton, dispatch]
  );

  if (!shape) {
    return <></>;
  }

  return (
    <Box
      component="div"
      sx={{
        ...sx,
        display: 'flex',
        height: '100%',
        position: 'absolute',
        right: theme.spacing(1),
        top: '0',
        pointerEvents: 'none',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          alignSelf: 'center',
          background: 'white',
          pointerEvents: 'initial',
          zIndex: 401,
          maxHeight: '90%',
          overflowY: 'auto',
          boxSizing: 'content-box',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexFlow: 'column',
            boxSizing: 'content-box',
            paddingTop: '8px',
            paddingBottom: '0 !important',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            {shapeType !== ShapeTypes.TurnShape && (
              <Tooltip title={locked ? 'Unlock' : 'Lock'}>
                <Box component="span">
                  <IconButton
                    onClick={onLockHandler}
                    size="large"
                    disabled={isExtendedLength}
                    sx={{ padding: '4px 12px' }}
                  >
                    {locked ? (
                      <animated.span style={springStyleLockButton}>
                        <LockIcon fontSize="small" />
                      </animated.span>
                    ) : (
                      <animated.span style={springStyleLockButton}>
                        <LockOpenIcon fontSize="small" />
                      </animated.span>
                    )}
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            <Tooltip title={shapeType === ShapeTypes.TurnShape ? 'Copy turn parameters' : 'Copy'}>
              <Box component="span">
                <IconButton
                  onClick={onCopyHandler}
                  size="large"
                  disabled={disableCopy || isExtendedLength}
                  sx={{ padding: '4px 12px' }}
                >
                  <animated.span style={springStyleCopyButton}>
                    <FileCopyIcon fontSize="small" />
                  </animated.span>
                </IconButton>
              </Box>
            </Tooltip>
            {shapeType === ShapeTypes.TurnShape && (
              <Tooltip title="Paste turn parameters">
                <Box component="span">
                  <IconButton
                    onClick={onPasteHandler}
                    disabled={!pasteParametersEnabled}
                    size="large"
                    sx={{ padding: '4px 12px' }}
                  >
                    <animated.span style={springStylePasteButton}>
                      <AssignmentReturnedIcon fontSize="small" />
                    </animated.span>
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            <span title={isDebug ? shapeId : undefined}>{title ?? displayShapeType}</span>
            <Tooltip title="Delete">
              <Box component="span">
                <IconButton onClick={handleDeleteShape} size="large" disabled={locked || disableDelete}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Tooltip>
          </Stack>

          <Stack
            spacing={1.5}
            sx={{
              '& > :last-child': {
                marginBottom: '16px',
              },
            }}
          >
            {children}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
