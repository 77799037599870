/**
 * Returns if it is a dev web server or not
 * @returns {boolean}
 */
export function isDevVersion(): boolean {
  return !!document.location.port || document.location.host.includes('dev');
}

/**
 * Returns if it is a staging or not
 * @returns {boolean}
 */
export function isStaging(): boolean {
  return document.location.host.includes('staging');
}

export function isDevOrStaging(): boolean {
  return isDevVersion() || isStaging();
}

/**
 * Returns whether the sentry replays mode is enabled or not
 * @returns whether the sentry replays mode is enabled or not
 */
export function isSentryReplaysModeEnabled(): boolean {
  const replaysUserEnabled = localStorage.getItem('sentryReplays') === 'enabled';
  const environment = !isDevVersion() ? (document.location.host.includes('staging') ? 'staging' : 'production') : 'dev';

  return (
    replaysUserEnabled || environment === 'staging' || (environment === 'dev' && document.location.host.includes('dev'))
  );
}
