import type { CustomStep } from 'flows/custom-steps.model';
import { addCustomStepFromYJS, removeCustomStepFromYJS, setCustomStepFromYJS } from 'flows/flows';
import { isEqual } from 'lodash';
import { Tools } from 'models/tools';
import { remoteDoc } from 'multiplayer/globals';
import { useRef } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import type { YMap } from 'yjs/dist/src/internals';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export function useCustomStepsSubscription(): void {
  const inSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const customStepsMap = remoteDoc?.getMap('customSteps') as YMap<CustomStep>;
  const initRef = useRef(true);

  const updateCustomStep = (): void => {
    const customSteps = store.getState().flows.customSteps;
    if (customSteps?.length) {
      customSteps.forEach((customStep) => {
        if (customStepsMap.has(customStep.id)) return;

        store.dispatch(removeCustomStepFromYJS(customStep.id));

        if (!initRef.current) {
          SnackbarUtils.info('A user deleted a custom step', {
            autoHideDuration: 3000,
          });
        }
      });
    }

    if (!customStepsMap.size) return;

    customStepsMap.forEach((yjsCustomStep, key) => {
      const localCustomStepExists = customSteps?.find((customStep) => customStep.id === key);
      if (!localCustomStepExists) {
        store.dispatch(addCustomStepFromYJS(yjsCustomStep));

        if (!initRef.current) {
          SnackbarUtils.info('A user created a custom step', {
            autoHideDuration: 3000,
          });
        }

        return;
      }

      const sameCustomStep = isEqual(localCustomStepExists, yjsCustomStep);
      if (sameCustomStep) return;

      store.dispatch(setCustomStepFromYJS(yjsCustomStep));

      if (!initRef.current) {
        SnackbarUtils.info('A user updated a custom step', {
          autoHideDuration: 3000,
        });
      }
    });

    if (initRef.current) {
      initRef.current = false;
    }
  };

  useConditionalQueueSubscriptionHook({ YType: customStepsMap, callback: updateCustomStep, condition: inSimulation });
}
