import type { CircuitImageData } from 'models/images';
import { remoteDoc } from 'multiplayer/globals';
import { loadCircuitImageFromYJS, removeCircuitImageFromYJS } from 'reducers/images/images';
import store, { useAppSelector } from 'store';
import type { YMap } from 'yjs/dist/src/internals';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useCircuitImagesSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const circuitImageMap = remoteDoc?.getMap('circuitImages') as YMap<{
    data: CircuitImageData;
    uInt8Array: Uint8Array;
  }>;

  const updateCircuitImages = (): void => {
    const circuitImages = store.getState().images.circuitImages;
    if (circuitImages?.length) {
      circuitImages.forEach((circuitImage) => {
        if (circuitImageMap.has(circuitImage.id)) return;

        store.dispatch(removeCircuitImageFromYJS(circuitImage));
      });
    }

    if (!circuitImageMap.size) return;

    circuitImageMap.forEach((_, key) => {
      const circuitImagePropagated = circuitImages?.find((circuitImage) => circuitImage.id === key);

      if (circuitImagePropagated && circuitImagePropagated.url) return;

      store.dispatch(loadCircuitImageFromYJS({ id: key }));
    });
  };

  useConditionalQueueSubscriptionHook({
    YType: circuitImageMap,
    callback: updateCircuitImages,
    condition: notCircuitEditionMode,
  });
};
