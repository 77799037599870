import type { ActionNode, ActionNodeData, FolderNode, RobotManagerInteractionArg } from 'models/action';
import { isActionNode, isFolderNode } from './actions.guard';

/**
 * Removing an action from the tree by its ID.
 */
export function removeActionById(actions: ActionNode[], idToRemove: string): ActionNode[] {
  return actions
    .filter((node) => node.id !== idToRemove)
    .map((node) => {
      // Only folders have nodes
      if (isFolderNode(node) && node.nodes && node.nodes.length > 0) {
        return {
          ...node,
          nodes: removeActionById(node.nodes, idToRemove),
        };
      }

      return node;
    });
}

export function collectImages(nodes: ActionNode[], images: Record<string, string>): ActionNode[] {
  return nodes.map((node) => {
    if (isActionNode(node)) {
      // Destructure imgData and imgName from the node
      const { imgData, imgName, ...rest } = node;
      if (imgData && imgName) {
        // Save the actual image data in the images mapping
        images[imgName] = imgData;
      }
      // Return the node with:
      // - imgName preserved

      return { ...rest, ...(imgName ? { imgName, imgData: imgName } : {}) };
    }
    // For folders, process children recursively.

    if (isFolderNode(node)) {
      return {
        ...node,
        nodes: node.nodes ? collectImages(node.nodes, images) : [],
      };
    }

    return node;
  });
}

export function flattenActions(nodes: ActionNode[]): ActionNode[] {
  return nodes.reduce<ActionNode[]>((acc, node) => {
    acc.push(node);

    // Only folders have child nodes
    if (isFolderNode(node) && node.nodes && node.nodes.length > 0) {
      acc.push(...flattenActions(node.nodes));
    }

    return acc;
  }, []);
}

/**
 * Extracting the action or folder from tree.
 */
export function extractActionById(actions: ActionNode[], idToExtract: string): [ActionNode | null, ActionNode[]] {
  let found: ActionNode | null = null;

  const newActions = actions
    .map((item) => {
      if (item.id === idToExtract) {
        found = item;

        return null;
      }

      // If it's a folder, maybe the target is in its children
      if (isFolderNode(item) && item.nodes && item.nodes.length > 0) {
        const [childFound, childRemaining] = extractActionById(item.nodes, idToExtract);
        if (childFound) {
          found = childFound;

          return { ...item, nodes: childRemaining };
        }
      }

      return item;
    })
    // filter out anything that became null
    .filter(Boolean) as ActionNode[];

  return [found, newActions];
}

/**
 * Insert `target` under a new parent's .nodes by ID.
 */
export function insertActionIntoParent(actions: ActionNode[], newParentId: string, target: ActionNode): ActionNode[] {
  return actions.map((item) => {
    // If this item is the new parent, add `target` into .nodes
    if (item.id === newParentId && isFolderNode(item)) {
      const newNodes = [...(item.nodes || []), target];

      return { ...item, nodes: newNodes };
    }

    // If it's a folder, recurse into .nodes
    if (isFolderNode(item) && item.nodes && item.nodes.length > 0) {
      return {
        ...item,
        nodes: insertActionIntoParent(item.nodes, newParentId, target),
      };
    }

    // Otherwise unchanged
    return item;
  });
}

/**
 * Move the node (action or folder) from wherever it is to the new parent's .nodes.
 */
export function moveActionById(actions: ActionNode[], idToMove: string, newParentId: string): ActionNode[] {
  // Extract the node from wherever it currently is
  const [actionToMove, prunedTree] = extractActionById(actions, idToMove);
  if (!actionToMove) return actions;

  // If newParentId is empty => place at root
  if (!newParentId) {
    return [...prunedTree, actionToMove];
  }

  // Insert under the new parent's .nodes
  return insertActionIntoParent(prunedTree, newParentId, actionToMove);
}

/**
 * Replace the node with the same .id as updatedAction, recursing into folders if needed.
 */
export function updateNodes(items: ActionNode[], updatedAction: ActionNode): ActionNode[] {
  return items.map((item) => {
    if (item.id === updatedAction.id) {
      // Return the newly updated node
      return updatedAction;
    }

    // If it's a folder, we might need to update a child
    if (isFolderNode(item) && item.nodes && item.nodes.length > 0) {
      return { ...item, nodes: updateNodes(item.nodes, updatedAction) };
    }

    // Otherwise unchanged
    return item;
  });
}

export interface FolderNodeInMenu {
  id: string;
  displayName: string;
  children: FolderNodeInMenu[];
}

/**
 * Build a tree of folder-only structures
 */
export function buildFolderTree(nodes: ActionNode[]): FolderNodeInMenu[] {
  return nodes.filter(isFolderNode).map((folder) => ({
    id: folder.id,
    displayName: folder.displayName,
    children: folder.nodes && folder.nodes.length ? buildFolderTree(folder.nodes) : [],
  }));
}

/**
 * Parse JSON data into ActionNode objects.
 */
export function parseJSONtoNodes(raw: unknown[], images: Record<string, string>): ActionNode[] {
  return raw.map((item: unknown) => {
    if (typeof item !== 'object' || item === null) {
      throw new Error('Invalid JSON structure: Expected an object');
    }

    const obj = item as Record<string, unknown>;

    // Check if `nodes` is a folder or an action
    const jsonActionNode = item as Partial<ActionNode>;

    const isFolder = isFolderNode(jsonActionNode as ActionNode);
    if (isFolder) {
      return {
        id: obj.id as string,
        displayName: obj.displayName as string,
        description: obj.description as string,
        callback: obj.callback as string,
        nodes: parseJSONtoNodes(obj.nodes as unknown[], images),
      } as FolderNode;
    }

    // Get the image key from imgData and use it to look up the actual image
    const imgName = obj.imgName as string;

    const imgData = imgName ? images[imgName] || '' : '';

    return {
      id: obj.id as string,
      displayName: obj.displayName as string,
      description: obj.description as string,
      callback: obj.callback as string,
      requiresConfirmation: obj.requiresConfirmation as boolean,
      imgName,
      imgData,
      args: obj.args as RobotManagerInteractionArg[],
    } as ActionNodeData;
  });
}

export function convertNodesToJSON(nodes: ActionNode[]): any[] {
  return nodes.map((node) => {
    if (isFolderNode(node)) {
      // Return folder
      return {
        id: node.id,
        displayName: node.displayName,
        description: node.description,
        callback: node.callback,
        nodes: node.nodes?.length ? convertNodesToJSON(node.nodes) : [],
      };
    }

    // Return action  (no nodes inside action)
    return {
      id: node.id,
      displayName: node.displayName,
      description: node.description,
      callback: node.callback,
      requiresConfirmation: node.requiresConfirmation,
      imgName: node.imgName,
      imgData: node.imgData,
      args: node.args,
    };
  });
}
