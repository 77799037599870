import { Replay } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import type { NewTaskDispatcherWithTime, NewTasksDispatcherWithTime, ReplayTrigger } from 'models/simulation';
import { useCallback, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { addTrigger } from 'simulation/triggers';
import { useAppDispatch } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { formatTime } from 'utils/time';

interface CreateReplayTriggerBtnProps {
  tasks: (NewTaskDispatcherWithTime | NewTasksDispatcherWithTime)[];
}

export function CreateReplayTriggerBtn(props: CreateReplayTriggerBtnProps): JSX.Element {
  const { tasks } = props;

  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();

  const handleCreateReplayTrigger = useCallback(() => {
    setDisabled(true);

    const newTrigger: ReplayTrigger = {
      id: generateShapeId(),
      type: 'replay',
      enabled: false,
      name: `Replay ${formatTime(new Date())}`,
      tasks: tasks,
    };

    dispatch(addTrigger(newTrigger));

    SnackbarUtils.success('Replay trigger created');

    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }, [dispatch, tasks]);

  return (
    <Tooltip
      title={
        <>
          <Typography variant="caption">Create a replay trigger with the same tasks as this simulation</Typography>
          {tasks.length === 0 && (
            <div style={{ textAlign: 'center' }}>
              <Stack spacing={2} sx={{ mt: 1, ml: 1 }} direction="row" alignItems="center">
                <WarningIcon color="secondary" />
                <Typography variant="caption" sx={{ fontSize: 10 }}>
                  No tasks available to create a replay trigger
                </Typography>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateReplayTrigger}
                startIcon={<Replay />}
                disabled={disabled}
                sx={{ mt: 1, p: 1 }}
              >
                Force Create Replay Trigger
              </Button>
            </div>
          )}
        </>
      }
      arrow
    >
      <Box component="span">
        <Button
          variant="contained"
          color="inherit"
          onClick={handleCreateReplayTrigger}
          startIcon={<Replay />}
          disabled={disabled || tasks.length === 0}
        >
          Create replay trigger
        </Button>
      </Box>
    </Tooltip>
  );
}
