import { loadLidarFromYJSAction } from 'actions';
import { remoteDoc } from 'multiplayer/globals';
import type { LidarState } from 'reducers/maps';
import store, { useAppSelector } from 'store';
import { useConditionalQueueSubscriptionHook } from './useConditionalQueueSubscriptionHook';

export const useLidarSubscription = (): void => {
  const notCircuitEditionMode = useAppSelector((state) => state.editor.editorMode !== 'circuit');
  const lidarMap = remoteDoc?.getMap('lidar');

  const updateLidarMap = (): void => {
    if (!lidarMap) return;

    const lidar = lidarMap.get('lidar') as LidarState;

    store.dispatch(loadLidarFromYJSAction({ lidar }));
  };

  useConditionalQueueSubscriptionHook({ YType: lidarMap, callback: updateLidarMap, condition: notCircuitEditionMode });
};
